import { useEffect, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { IconChevronUp, IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Button, Loader } from 'ui';
import PropTypes from 'prop-types';

import { RECOMMENDED_TASK_TYPES } from '../../core/constants';
import { useRecommendedTasks } from '../../store/recommended-tasks/hooks';
import { useCampaigns } from '../../store/campaigns/hooks';

const BadgeCount = (props) => {
  const { count, className } = props;

  return (
    <div className={`${className} flex h-[17px] w-[14px] items-center justify-center rounded bg-red-500`}>
      <span className="text-xs font-semibold">{count}</span>
    </div>
  );
};

BadgeCount.propTypes = {
  count: PropTypes.number.isRequired,
  className: PropTypes.string,
};

const RecommendedTasksDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { loading, pendingTasks, getPendingRecommendedTasks } = useRecommendedTasks();
  const { resetCampaignDetails, resetCampaignTemplate, updateCampaignDetails } = useCampaigns();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Skip fetching pending tasks if on home dashboard page; recommended tasks widget will handle it
    if (location.pathname !== '/') {
      getPendingRecommendedTasks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPendingRecommendedTasks]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (task) => {
    handleClose();
    if (task.type === RECOMMENDED_TASK_TYPES.weeklyCampaignScheduling) {
      resetCampaignDetails();
      resetCampaignTemplate();
      const sendTime = new Date(task.expirationTimestamp);
      sendTime.setHours(new Date().getHours() + 1);
      updateCampaignDetails({ sendTime: sendTime.toISOString() });
      navigate('/new-campaign');
    } else {
      navigate(`/suggested-drafts/${task.suggestedCampaignId}`);
    }
  };

  const pendingTasksCount = pendingTasks.filter((task) => !task.seen).length;
  const hasPendingTasks = pendingTasks.length > 0;

  return (
    <>
      <Button
        title="Recommended Tasks"
        color="primary"
        LeftIcon={pendingTasksCount ? (props) => <BadgeCount count={pendingTasksCount} {...props} /> : undefined}
        RightIcon={open ? IconChevronUp : IconChevronDown}
        onClick={handleClick}
      />

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        classes={{ paper: 'w-[268px] !rounded-lg mt-1.5 !shadow-custom !max-h-[412px] relative', list: '!p-0' }}
      >
        {pendingTasks.map((task) => (
          <MenuItem
            key={task.taskId}
            onClick={() => handleItemClick(task)}
            className={`flex-col 
              !items-start 
              !border-b 
              !border-solid 
              !border-gray-50 
              !p-4 
              !pl-6  
              ${
                task.seen
                  ? ''
                  : `
                !bg-primary-50 
                before:absolute 
                before:left-[9px] 
                before:top-[calc(50%-3px)] 
                before:size-[6px] 
                before:rounded-md 
                before:bg-primary-500 
                before:content-['']
              `
              }
            `}
          >
            <p className="text-wrap text-base">{task.description}</p>
            <p className="text-sm text-gray-600">Created&nbsp;{format(task.createdAt * 1000, "d MMM 'at' h:mm a")}</p>
          </MenuItem>
        ))}

        {!loading && !hasPendingTasks && (
          <li className="flex flex-1 items-center justify-center p-4 pt-6">
            <p className="text-base text-gray-600">No pending tasks. Nice work!</p>
          </li>
        )}

        <li className="flex items-center justify-center p-1">
          <Button
            title="View all"
            color="primary"
            variant="outlined"
            RightIcon={IconChevronRight}
            iconProps={{ size: 16 }}
            onClick={() => {
              handleClose();
              navigate('/recommended-tasks');
            }}
            className="border-0 !px-3  !py-2 !shadow-none	focus:!ring-0 focus:ring-offset-0"
          />
        </li>

        {loading && <Loader />}
      </Menu>
    </>
  );
};

export default RecommendedTasksDropdown;
