import { IconInfoCircle } from '@tabler/icons-react';
import PropTypes from 'prop-types';

const HelperText = ({ message, error, containerClassName }) => {
  return (
    <div className={`flex space-x-1 ${containerClassName}`}>
      <IconInfoCircle size={16} className={`mt-px min-w-[16px] ${error ? 'text-red-500' : 'text-gray-400'}`} />
      <p className={`text-sm ${error ? 'text-red-500' : 'text-gray-600'}`}>{message}</p>
    </div>
  );
};

HelperText.propTypes = {
  message: PropTypes.string.isRequired,
  error: PropTypes.bool,
  containerClassName: PropTypes.string,
};

HelperText.defaultProps = {
  error: false,
  containerClassName: '',
};

export default HelperText;
