import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { getNetworkError, replaceItemAtIndex } from 'common/utils';

import {
  getDonations as getDonationsApi,
  refundDonation as refundDonationApi,
  resendReceipt as resendReceiptApi,
  cancelSubscription as cancelSubscriptionApi,
} from '../../api';

const SET_ERROR = 'donations/SET_ERROR';
const SET_SUCCESS = 'donations/SET_SUCCESS';
const GET_DONATIONS = 'donations/GET_DONATIONS';
const REFUND_DONATION = 'donations/REFUND_DONATION';
const RESEND_RECEIPT = 'donations/RESEND_RECEIPT';
const CANCEL_SUBSCRIPTION = 'donations/CANCEL_SUBSCRIPTION';
const UPDATE_DONATIONS = 'donations/UPDATE_DONATIONS';
const UPDATE_FILTERS = 'donations/UPDATE_FILTERS';
const RESET_FILTERS = 'donations/RESET_FILTERS';

export const setError = createAction(SET_ERROR);

export const setSuccess = createAction(SET_SUCCESS);

export const updateDonations = createAction(UPDATE_DONATIONS);

export const updateFilters = createAction(UPDATE_FILTERS);

export const resetFilters = createAction(RESET_FILTERS);

export const getDonations = createAsyncThunk(GET_DONATIONS, async () => {
  try {
    const donations = await getDonationsApi();
    return donations;
  } catch (error) {
    Sentry.captureException(error);
    const errorMessage = getNetworkError(error);
    throw new Error(errorMessage);
  }
});

export const refundDonation = createAsyncThunk(REFUND_DONATION, async (donationId, { dispatch, getState }) => {
  try {
    await refundDonationApi(donationId);
    dispatch(setSuccess('Donation refunded successfully!'));
    // Optimistic update
    const {
      donations: { donations },
    } = getState();
    const donationIndex = donations.findIndex((donation) => donation.donationId === donationId);
    const donation = donations[donationIndex];
    if (donation) {
      const updatedDonations = replaceItemAtIndex(donations, donationIndex, { ...donation, status: 'refunded' });
      dispatch(updateDonations(updatedDonations));
    }
  } catch (error) {
    Sentry.captureException(error);
    const errorMessage = getNetworkError(error);
    throw new Error(errorMessage);
  }
});

export const resendReceipt = createAsyncThunk(RESEND_RECEIPT, async (donationId, { dispatch }) => {
  try {
    await resendReceiptApi(donationId);
    dispatch(setSuccess('Receipt resent successfully!'));
  } catch (error) {
    Sentry.captureException(error);
    const errorMessage = getNetworkError(error);
    throw new Error(errorMessage);
  }
});

export const cancelSubscription = createAsyncThunk(CANCEL_SUBSCRIPTION, async (donationId, { dispatch, getState }) => {
  try {
    await cancelSubscriptionApi(donationId);
    dispatch(setSuccess('Subscription cancelled successfully!'));
    // Optimistic update
    const {
      donations: { donations },
    } = getState();
    const subscriptionId = donations.find((don) => don.donationId === donationId)?.paymentInfo?.subscription?.id;
    if (!subscriptionId) {
      // No optimistic update
      dispatch(getDonations());
      return;
    }
    const subscriptionDonationIndexes = donations
      .map((don, index) => (don.paymentInfo?.subscription?.id === subscriptionId ? index : null))
      .filter(Boolean);
    if (subscriptionDonationIndexes.length === 0) {
      // No optimistic update
      dispatch(getDonations());
      return;
    }
    let updatedDonations = [...donations];
    subscriptionDonationIndexes.forEach((subscriptionIndex) => {
      const donation = donations[subscriptionIndex];
      updatedDonations = replaceItemAtIndex(updatedDonations, subscriptionIndex, {
        ...donation,
        paymentInfo: {
          ...donation.paymentInfo,
          subscription: { ...donation.paymentInfo.subscription, status: 'canceled' },
        },
      });
    });
    dispatch(updateDonations(updatedDonations));
  } catch (error) {
    Sentry.captureException(error);
    const errorMessage = getNetworkError(error);
    throw new Error(errorMessage);
  }
});
