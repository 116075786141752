import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import HelperText from './HelperText';

const Checkbox = forwardRef((props, ref) => {
  const { id, label, className, inputClassName, labelClassName, error, helperText, ...rest } = props;

  const renderLabel = () => {
    if (!label) return null;

    if (typeof label === 'string') {
      return (
        <label
          htmlFor={id}
          className={`
            whitespace-normal 
            text-base 
            font-medium	
            text-gray-900 
            ${labelClassName}`}
        >
          {label}
        </label>
      );
    }

    return label;
  };

  return (
    <div>
      <div className={`flex space-x-2 ${className}`}>
        <input
          id={id}
          type="checkbox"
          ref={ref}
          className={`
            mt-[2px]
            size-4
            min-w-[16px]
            cursor-auto
            rounded
            border
            bg-white-100
            text-primary-700
            focus:outline-none
            focus:ring-2
            focus:ring-primary-300
            focus:ring-offset-1
            disabled:cursor-not-allowed
            disabled:bg-gray-10
            ${error ? 'border-red-500' : 'border-gray-200'}
            ${inputClassName}
          `}
          {...rest}
        />
        {renderLabel()}
      </div>
      {!!helperText && <HelperText message={helperText} error={error} containerClassName="mt-1.5" />}
    </div>
  );
});

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.any,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

Checkbox.defaultProps = {
  id: '',
  label: '',
  className: '',
  inputClassName: '',
  labelClassName: '',
  error: false,
  helperText: '',
};

export default Checkbox;
