/* eslint-disable react/prop-types */
import { useState, useCallback, useMemo } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';
import PropTypes from 'prop-types';

import ConfirmDeleteModal from '../modals/ConfirmDeleteModal';
import { useSegments } from '../../store/segments/hook';
import SegmentNameModal from '../new-segment/SegmentNameModal';
import { useCampaigns } from '../../store/campaigns/hooks';
import CustomPagination from '../tables/CustomPagination';
import { SEGMENT_ACTIONS } from '../../core/constants';
import RowActions from '../tables/RowActions';

const options = {
  editable: [
    SEGMENT_ACTIONS.createCampaign,
    SEGMENT_ACTIONS.view,
    SEGMENT_ACTIONS.rename,
    SEGMENT_ACTIONS.edit,
    SEGMENT_ACTIONS.delete,
  ],
  nonEditable: [SEGMENT_ACTIONS.createCampaign, SEGMENT_ACTIONS.view],
};

const Table = ({ data }) => {
  const [deleteData, setDeleteData] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const { deleteSegment, updateSegmentDetails, updateSegment, loading } = useSegments();
  const { resetCampaignDetails, updateCampaignDetails } = useCampaigns();
  const navigate = useNavigate();

  const onSegmentAction = useCallback(
    (segment, action) => {
      switch (action) {
        case SEGMENT_ACTIONS.edit:
          updateSegmentDetails(segment);
          navigate('/edit-segment');
          break;
        case SEGMENT_ACTIONS.createCampaign:
          resetCampaignDetails();
          updateCampaignDetails({ segmentId: segment.segmentId });
          navigate('/new-campaign');
          break;
        case SEGMENT_ACTIONS.view:
          navigate('/contacts', { state: { id: segment.segmentId, type: 'segment' } });
          break;
        case SEGMENT_ACTIONS.rename:
          setSelectedSegment(segment);
          break;
        case SEGMENT_ACTIONS.delete:
          setDeleteData({ id: segment.segmentId, name: segment.name, entity: 'segment' });
          break;
        default:
          break;
      }
    },
    [navigate, resetCampaignDetails, updateCampaignDetails, updateSegmentDetails],
  );

  const onDeleteSegment = () => {
    if (!deleteData?.id) return;
    deleteSegment(deleteData.id);
    setDeleteData(null);
  };

  const onUpdateName = (name) => {
    if (!selectedSegment) return;
    updateSegment({ segmentId: selectedSegment.segmentId, updateData: { ...selectedSegment, name } });
    setSelectedSegment(null);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: () => 'Name',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'createdAt',
        header: () => 'Created',
        footer: (props) => props.column.id,
        cell: (info) => format(new Date(info.getValue() * 1000), 'd MMM yyyy, h:mm a'),
      },
      {
        accessorKey: 'segmentId',
        header: () => '',
        footer: (props) => props.column.id,
        cell: (info) => {
          const nonEditable = info.row.original.editable === false;
          return (
            <RowActions
              actions={nonEditable ? options.nonEditable : options.editable}
              visibleActions={nonEditable ? 2 : 1}
              onSelectAction={(action) => onSegmentAction(info.row.original, action)}
            />
          );
        },
        size: 104,
        minSize: 104,
      },
    ],
    [onSegmentAction],
  );

  const table = useReactTable({
    data,
    columns,
    pageCount: data.totalPages,
    defaultColumn: {
      size: 'fit-content',
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const emptyRows = 5 - data.length;

  return (
    <div className="space-y-6">
      <div className="relative overflow-x-auto sm:rounded-lg">
        <table className="w-full text-left text-base text-gray-950 dark:text-gray-400">
          <thead className="bg-white-100 text-sm uppercase text-gray-400 dark:bg-gray-700 dark:text-gray-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="px-4 py-3"
                      style={{ width: header.column.columnDef.size, minWidth: header.column.columnDef.minSize }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className="px-4 py-2"
                        style={{ width: cell.column.columnDef.size, minWidth: cell.column.columnDef.minSize }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {emptyRows > 0 &&
              Array(emptyRows)
                .fill('')
                .map((row, index) => (
                  <tr
                    key={`emptyRow-${index}`}
                    className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                  >
                    <td className="px-4 py-2" colSpan={15} style={{ minWidth: 110 }}></td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <CustomPagination table={table} total={data.length} />

      <ConfirmDeleteModal data={deleteData} onClose={() => setDeleteData(null)} onDelete={onDeleteSegment} />

      {selectedSegment && (
        <SegmentNameModal
          open
          onClose={() => setSelectedSegment(null)}
          initialName={selectedSegment.name}
          updateName={onUpdateName}
          loading={loading}
        />
      )}
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Table;
