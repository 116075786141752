import Proptypes from 'prop-types';

import { EXPORT_JOB_TYPE_LABELS } from '../../core/constants';

const getContainerColorClassNames = (type) => {
  switch (type) {
    case 'donations':
      return 'border-green-100 bg-green-50 text-green-500';
    default:
      // contacts
      return 'border-primary-200 bg-primary-50 text-primary-700';
  }
};

const ExportTypeLabel = (props) => {
  const { type, containerClassName = '' } = props;

  return (
    <div className={`w-fit rounded border px-1.5 py-0.5 ${getContainerColorClassNames(type)} ${containerClassName}`}>
      <p className="truncate text-caption1 font-semibold uppercase">{EXPORT_JOB_TYPE_LABELS[type]}</p>
    </div>
  );
};

ExportTypeLabel.propTypes = {
  type: Proptypes.string.isRequired,
  containerClassName: Proptypes.string,
};

export default ExportTypeLabel;
