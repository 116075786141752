import { IconArrowRight } from '@tabler/icons-react';
import PropTypes from 'prop-types';

const Stepper = ({ steps, activeStep }) => {
  return (
    <ol className="flex items-center space-x-3 px-2">
      {steps.map((step, index) => (
        <li
          key={step}
          className={`flex items-center space-x-2 text-base font-semibold ${
            activeStep === index
              ? 'after:content-[" "] relative text-primary-700 after:absolute after:-bottom-3 after:left-0 after:h-1 after:w-full after:bg-primary-700 dark:text-primary-600'
              : 'text-gray-400 dark:text-gray-300'
          }`}
        >
          <span>{step}</span>
          {index !== steps.length - 1 && <IconArrowRight size={16} />}
        </li>
      ))}
    </ol>
  );
};

Stepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default Stepper;
