import { useState, useCallback, useEffect } from 'react';
import { Drawer } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import format from 'date-fns/format';
import { Button, Loader, InfoRow } from 'ui';
import { getNetworkError } from 'common/utils';

import { getContact as getContactApi } from '../../api';
import { useContacts } from '../../store/contacts/hooks';
import ContactStatusLabel from './ContactStatusLabel';
import { getContactName, getContactAddress } from '../../core/utils';
import DonationHistory from './DonationHistory';

const ContactDrawer = (props) => {
  const { open, onClose, contactDetails, archived } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [contact, setContact] = useState(null);
  const { updateContactInList } = useContacts();

  const contactId = contactDetails?.id;

  const getContact = useCallback(
    async (id) => {
      setLoading(true);
      try {
        const contact = await getContactApi(id, archived);
        setContact(contact);
        updateContactInList({ contact });
      } catch (err) {
        Sentry.captureException(err);
        setError(getNetworkError(err));
      }
      setLoading(false);
    },
    [archived, updateContactInList],
  );

  useEffect(() => {
    if (contactId) {
      getContact(contactId);
    } else {
      setContact(null);
    }
  }, [contactId, getContact]);

  const handleClose = () => {
    if (loading) return;
    onClose();
  };

  const contactData = contact || contactDetails;

  const getStatus = () => {
    if (!contactData) return '-';
    if (contactData.archived_at) {
      return 'archived';
    }
    return contactData.unsubscribed ? 'unsubscribed' : 'subscribed';
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose} sx={{ position: 'relative' }}>
      <div className="h-full w-[600px] space-y-4 p-6 [&>div:last-child]:!pb-6" data-testid="contact-drawer">
        <Button
          title="Close"
          size="sm"
          LeftIcon={IconX}
          iconProps={{ size: 20 }}
          onClick={handleClose}
          className="
              border-0 
              !p-0 
              !text-gray-600 
              !shadow-none 
              focus:!ring-0 
              enabled:hover:!text-gray-500
              enabled:focus:!text-gray-500
              enabled:active:!text-gray-700
            "
          disabled={loading}
        />

        <div className="relative space-y-4">
          <h3 className="text-h3 font-semibold" data-testid="campaign-drawer-heading">
            {getContactName(contactData) || '-'}
          </h3>

          <InfoRow
            primaryLabel="Created At"
            value={contactData?.created_at ? format(new Date(contactData?.created_at), 'd MMM yyyy, h:mm a') : '-'}
          />

          {contactData?.archived_at && (
            <InfoRow
              primaryLabel="Archived At"
              value={contactData.archived_at ? format(new Date(contactData.archived_at), 'd MMM yyyy, h:mm a') : '-'}
            />
          )}

          <InfoRow primaryLabel="Status" value={<ContactStatusLabel status={getStatus()} />} />

          <InfoRow primaryLabel="Source" value={contactData?.import_filename || '-'} />

          <InfoRow primaryLabel="Email" value={contactData?.email || '-'} />

          <InfoRow primaryLabel="Phone" value={contactData?.phone_number || '-'} />

          <InfoRow primaryLabel="Address" value={getContactAddress(contactData) || '-'} />

          {!!error && <p className="m-6 py-1 text-center text-base text-red-500">{error}</p>}

          {/* Only display loader if contact data is not already locally available */}
          {!contactData?.first_name && loading && <Loader />}
        </div>

        <hr className="!my-5 border-gray-50" />

        {contactData?.id && <DonationHistory donorId={contactData.id} />}
      </div>
    </Drawer>
  );
};

ContactDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contactDetails: PropTypes.object,
  archived: PropTypes.bool,
};

export default ContactDrawer;
