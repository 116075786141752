import { useState } from 'react';
import PropTypes from 'prop-types';

import {
  AccountInfoStep,
  Sidebar,
  DomainAuthStep,
  ConnectStripeStep,
  DonationPageStep,
  ImportContactsStep,
  CompleteStep,
} from '../components/onboarding';
import { ONBOARDING_STEPS } from '../core/constants';
import SubscribeStep from '../components/onboarding/SubscribeStep';
import ScheduleCallStep from '../components/onboarding/ScheduleCallStep';
import StartOnboardingStep from '../components/onboarding/StartOnboardingStep';

const Onboarding = ({ initialStep }) => {
  const [step, setStep] = useState(initialStep);

  const handlePrev = () => {
    setStep((prevStep) => Math.max(prevStep - 1, ONBOARDING_STEPS.accountInfo));
  };

  const handleNext = () => {
    setStep((prevStep) => Math.min(prevStep + 1, ONBOARDING_STEPS.completed));
  };

  const renderStep = () => {
    switch (step) {
      case ONBOARDING_STEPS.accountInfo:
        return <AccountInfoStep onNext={handleNext} />;
      case ONBOARDING_STEPS.domainAuthentication:
        return <DomainAuthStep onPrev={handlePrev} onNext={handleNext} />;
      case ONBOARDING_STEPS.stripeAccount:
        return <ConnectStripeStep onPrev={handlePrev} onNext={handleNext} />;
      case ONBOARDING_STEPS.donationPage:
        return <DonationPageStep onPrev={handlePrev} onNext={handleNext} />;
      case ONBOARDING_STEPS.importContacts:
        return <ImportContactsStep onPrev={handlePrev} onNext={handleNext} />;
      case ONBOARDING_STEPS.congrats:
        return <CompleteStep />;
      default:
        return null;
    }
  };

  if (step === ONBOARDING_STEPS.subscription) {
    return <SubscribeStep setStep={setStep} />;
  }

  if (step === ONBOARDING_STEPS.scheduleCall) {
    return <ScheduleCallStep setStep={setStep} />;
  }

  if (step === ONBOARDING_STEPS.startOnboarding) {
    return <StartOnboardingStep setStep={setStep} />;
  }

  return (
    <div className="flex grow overflow-y-hidden bg-white-100">
      <Sidebar currentStep={step} />

      <div className="grow overflow-y-auto overflow-x-clip px-8 py-12">{renderStep()}</div>
    </div>
  );
};

Onboarding.propTypes = {
  initialStep: PropTypes.number.isRequired,
};

export default Onboarding;
