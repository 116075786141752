import { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { IconDotsVertical, IconMailMinus, IconArchive } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { IconButton } from 'ui';

const MoreMenu = (props) => {
  const { onUnsubscribe, onArchived } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUnsubscribe = () => {
    handleClose();
    onUnsubscribe();
  };

  const handleArchived = () => {
    handleClose();
    onArchived();
  };

  return (
    <>
      <IconButton
        Icon={<IconDotsVertical size={18} className="text-gray-950" />}
        srOnly="More Actions"
        className="
          size-10 
          border 
          border-gray-150 
          bg-white-100 
          enabled:hover:bg-white-100 
          enabled:focus:bg-white-100
          enabled:active:bg-white-100
        "
        onClick={handleClick}
      />

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} classes={{ paper: '!rounded-lg mt-0.5 -ml-4' }}>
        <MenuItem classes={{ root: 'space-x-2.5 !py-2 !px-3.5' }} onClick={handleUnsubscribe}>
          <IconMailMinus size={18} className="text-gray-800" />
          <p className="text-base text-gray-950">Unsubscribe Contact</p>
        </MenuItem>
        <MenuItem classes={{ root: 'space-x-2.5 !py-2 !px-3.5' }} onClick={handleArchived}>
          <IconArchive size={18} className="text-gray-800" />
          <p className="text-base text-gray-950">Archived Contacts</p>
        </MenuItem>
      </Menu>
    </>
  );
};

MoreMenu.propTypes = {
  onUnsubscribe: PropTypes.func.isRequired,
  onArchived: PropTypes.func.isRequired,
};

export default MoreMenu;
