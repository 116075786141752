import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { setError, setSuccess, getDomains, addDomain, validateDomain, deleteDomain } from './actions';

const initialState = {
  loading: false,
  error: false,
  success: false,
  domains: [],
  validationResults: null,
};

const domainsStore = createSlice({
  name: 'domains',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(getDomains.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.domains = payload;
    });

    builder.addCase(addDomain.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.domains = [payload];
    });

    builder.addCase(validateDomain.fulfilled, (state, { payload: { valid, validationResults } }) => {
      state.loading = false;
      if (valid && state.domains[0]) state.domains[0].valid = true;
      state.validationResults = validationResults;
    });

    builder.addCase(deleteDomain.fulfilled, (state) => {
      state.loading = false;
      state.domains = [];
      state.success = 'Domain successfully deleted!';
    });

    builder.addMatcher(
      isAnyOf(getDomains.pending, addDomain.pending, validateDomain.pending, deleteDomain.pending),
      (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      },
    );

    builder.addMatcher(
      isAnyOf(getDomains.rejected, addDomain.rejected, validateDomain.rejected, deleteDomain.rejected),
      (state, { error }) => {
        state.loading = false;
        state.error = error.message || true;
      },
    );
  },
});

export default domainsStore.reducer;
