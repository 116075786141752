import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import Chip from '../chip/Chip';

const TagChips = (props) => {
  const { number, tags, containerClassName } = props;
  return (
    <div className={`flex min-w-max cursor-default flex-wrap items-center ${containerClassName}`}>
      {tags
        .sort((a, b) => a.name.localeCompare(b.name))
        .slice(0, number)
        .map((list) => {
          return <Chip key={list.id} label={list.name} />;
        })}
      {tags.length > number && <span className="cursor-default align-middle text-2xl">&middot;&middot;&middot;</span>}
    </div>
  );
};

TagChips.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired, name: PropTypes.string.isRequired }))
    .isRequired,
  number: PropTypes.number,
  containerClassName: PropTypes.string,
};

TagChips.defaultProps = {
  containerClassName: '',
};

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    maxWidth: 350,
    border: '1px solid #dadde9',
  },
}));

const CustomTooltip = ({ tags }) => {
  return (
    <HtmlTooltip
      disableHoverListener={tags?.length < 3}
      title={<TagChips tags={tags} containerClassName="min-w-full"></TagChips>}
    >
      {tags?.length > 0 ? (
        <div className="text-left">
          <TagChips number={2} tags={tags}></TagChips>
        </div>
      ) : (
        <div className="text-left text-gray-400">No tags</div>
      )}
    </HtmlTooltip>
  );
};

CustomTooltip.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired, name: PropTypes.string.isRequired })),
};

CustomTooltip.defaultProps = {
  tags: [],
};

export default CustomTooltip;
