import PropTypes from 'prop-types';

const SwitchInput = ({ id, label, checked, onChange, className, disabled = false }) => {
  return (
    <label className={`relative inline-flex cursor-pointer items-center ${className}`}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        className="peer sr-only"
        disabled={disabled}
      />
      <div
        className="peer-checked:after:border-white
        peer 
        h-5 
        w-9 
        rounded-full
        bg-gray-100
        after:absolute
        after:left-[2px] 
        after:top-0.5 
        after:size-4 
        after:rounded-full 
        after:bg-white-100 
        after:shadow-md 
        after:transition-all 
        after:content-[''] 
        peer-checked:bg-primary-700
        peer-checked:after:translate-x-full 
        peer-focus:ring-2 
        peer-focus:ring-primary-300 
        peer-focus:ring-offset-1"
      />
      <span className="ml-3 text-base font-medium text-gray-950">{label}</span>
    </label>
  );
};

SwitchInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

SwitchInput.defaultProps = {
  className: '',
  disabled: false,
};

export default SwitchInput;
