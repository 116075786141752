import { useState, useEffect, useCallback } from 'react';
import { Dialog } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import * as yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import IconButton from '../buttons/IconButton';
import Input from '../inputs/Input';
import Button from '../buttons/Button';
import appSettings from '../../app-settings';
import useSessionStorage from '../../hooks/useSessionStorage';
import { getNetworkError } from '../../core/utils';
import Loader from '../loader/Loader';

const AUTH_URL = `${appSettings.baseUrl}/auth`;

const formValidation = yup.object({
  code: yup
    .string()
    .matches(/^\d{6}$/, 'Invalid code.')
    .required('Code is required.'),
});

const EnableTOTP2FAModal = ({ open, onClose, onConfirm, onError }) => {
  // Generate a QR code only once per session. Once a user logs out, the session storage is cleared;
  // the next time a user logs in and opens this modal a new QR code is requested and displayed.
  const [qRCodeBase64, setQRCodeBase64] = useSessionStorage('TOTP2FA-DS', '');
  const [qRLoading, setQRLoading] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const form = useForm({
    initialValues: { code: '' },
    validate: yupResolver(formValidation),
    validateInputOnBlur: true,
  });

  const getQrCodeBase64 = useCallback(async () => {
    setQRLoading(true);
    try {
      const res = await axios.get(`${AUTH_URL}/totp/secret`);
      setQRCodeBase64(res.data.qrCode);
    } catch (err) {
      Sentry.captureException(err);
      if (onError) onError(getNetworkError(err));
    }
    setQRLoading(false);
  }, [onError, setQRCodeBase64]);

  useEffect(() => {
    if (!open || qRCodeBase64) return;
    getQrCodeBase64();
  }, [getQrCodeBase64, open, qRCodeBase64]);

  const handleClose = () => {
    if (qRLoading || verifying) return;
    onClose();
    form.reset();
  };

  const handleVerification = async (values) => {
    setVerifying(true);
    try {
      await axios.post(`${AUTH_URL}/totp/verify`, { code: values.code });
      onConfirm();
      handleClose();
    } catch (err) {
      Sentry.captureException(err);
      if (onError) onError(getNetworkError(err));
    }
    setVerifying(false);
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      sx={{ '.MuiPaper-root': { padding: 0, margin: 0, borderRadius: '12px', width: '100%' } }}
    >
      <form noValidate className="space-y-6 p-6" onSubmit={form.onSubmit(handleVerification)}>
        <div className="relative flex">
          <h3 className="text-h6 font-semibold">Enable Two-Factor Authentication</h3>

          <IconButton
            Icon={<IconX size={20} className="text-gray-400" />}
            srOnly="Close"
            onClick={handleClose}
            color="transparent"
            className="absolute -right-1 -top-1 !p-1"
          />
        </div>

        <p className="text-base">
          Scan the QR code with your authenticator app, then enter the authentication code that it generates.
        </p>

        <div className="relative flex h-[214px] items-center justify-center">
          {!!qRCodeBase64 && <img src={qRCodeBase64} alt="QR code" width={214} height={214} />}
          {qRLoading && <Loader />}
        </div>

        <Input
          id="auth-code"
          label="Authentication code"
          size="xl"
          required
          value={form.values.code}
          onChange={(e) => form.setFieldValue('code', e.target.value)}
          error={!!form.errors.code}
          helperText={form.errors.code}
          disabled={verifying}
        />

        <Button
          title="Verify"
          type="submit"
          color="primary"
          className="w-full"
          loading={verifying}
          disabled={verifying}
        />
      </form>
    </Dialog>
  );
};

EnableTOTP2FAModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

export default EnableTOTP2FAModal;
