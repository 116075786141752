import { useEffect, useState } from 'react';

import ConfirmDeleteModal from '../components/modals/ConfirmDeleteModal';
import DomainAuthForm from '../components/domain-auth/DomainAuthForm';
import { useDomains } from '../store/domains/hooks';

const DomainAuthentication = () => {
  const [deleteData, setDeleteData] = useState(null);
  const { loading, deleteDomain, getDomains } = useDomains();

  useEffect(() => {
    getDomains();
  }, [getDomains]);

  const handleDeleteDomain = (domain) => {
    setDeleteData({ id: String(domain.id), name: domain.domain, entity: 'domain' });
  };

  const handleDeleteDomainConfirm = async (domainId) => {
    await deleteDomain(domainId);
    setDeleteData(null);
  };

  return (
    <>
      <h1 className="mb-6 text-h3 dark:text-white-100">Domain Authentication</h1>

      <DomainAuthForm onDelete={handleDeleteDomain} />

      <ConfirmDeleteModal
        data={deleteData}
        onClose={() => setDeleteData(null)}
        onDelete={handleDeleteDomainConfirm}
        loading={loading}
      />
    </>
  );
};

export default DomainAuthentication;
