import { useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { IconTag } from '@tabler/icons-react';

import { useCampaigns } from '../../../store/campaigns/hooks';
import { DEBOUNCE_DELAY_TIME_MS, formStoreTargets } from '../../../core/constants';
import Button from '../../buttons/Button';
import MergeTagBuilderModal from '../../modals/MergeTagBuilderModal';
import { useEmailTemplates } from '../../../store/email-templates/hooks';
import Input from '../../inputs/Input';

// eslint-disable-next-line react-refresh/only-export-components
export const subjectFormValidation = yup.object({
  subject: yup.string().required('Subject is required.').min(2, 'Subject is invalid.'),
  previewText: yup.string().required('Preview text is required.').min(2, 'Preview text is invalid.'),
});

const SubjectForm = ({ storeTarget, disabled }) => {
  const [mergeTagBuilderOpen, setMergeTagBuilderOpen] = useState(false);
  const { template, updateTemplate } = useEmailTemplates();
  const { campaignDetails, updateCampaignDetails } = useCampaigns();

  const getTarget = () => (storeTarget === formStoreTargets.template ? template : campaignDetails);

  const updater = useMemo(
    () => (storeTarget === formStoreTargets.template ? updateTemplate : updateCampaignDetails),
    [storeTarget, updateTemplate, updateCampaignDetails],
  );

  const form = useForm({
    initialValues: {
      subject: getTarget().emailConfig.subject,
      previewText: getTarget().emailConfig.previewText,
    },
    validate: yupResolver(subjectFormValidation),
    validateInputOnChange: true,
  });

  const debouncedUpdateTarget = useMemo(() => debounce(updater, DEBOUNCE_DELAY_TIME_MS), [updater]);

  const handleSubjectEdit = (e) => {
    const subject = e.target.value;
    form.setFieldValue('subject', subject);
    debouncedUpdateTarget({ emailConfig: { subject } });
  };

  const handlePreviewTextChange = (e) => {
    const previewText = e.target.value;
    form.setFieldValue('previewText', previewText);
    debouncedUpdateTarget({ emailConfig: { previewText } });
  };

  return (
    <>
      <form className="space-y-6">
        <div className="space-y-4">
          <div className="space-y-4">
            <div className="space-y-1">
              <p className="text-base font-semibold">Subject Line</p>
              <p className="text-base">Be personal, concise, and descriptive.</p>
            </div>
            <Input
              id="subjectline"
              label="Subject Line"
              value={form.values.subject}
              onChange={handleSubjectEdit}
              placeholder="Insert Subject"
              required
              disabled={disabled}
              error={!!form.errors.subject}
              helperText={form.errors.subject || ''}
              size="xl"
            />
          </div>
          {!disabled && (
            <Button
              title="Merge Tags"
              size="sm"
              className="!mt-3 border-0 !p-0 text-primary-700 !shadow-none focus:!ring-0"
              onClick={() => setMergeTagBuilderOpen(true)}
              LeftIcon={IconTag}
              iconProps={{ size: 16 }}
            />
          )}
        </div>
        <div className="space-y-4">
          <div className="space-y-1">
            <p className="text-base font-semibold">Preview Text</p>
            <p className="text-base">Preview text appears in the inbox next to the subject line.</p>
          </div>
          <Input
            id="previewtext"
            label="Preview Text"
            value={form.values.previewText}
            onChange={handlePreviewTextChange}
            placeholder="Insert Preview"
            required
            disabled={disabled}
            error={!!form.errors.previewText}
            helperText={form.errors.previewText || ''}
            size="xl"
          />
        </div>
      </form>

      <MergeTagBuilderModal open={mergeTagBuilderOpen} onClose={() => setMergeTagBuilderOpen(false)} />
    </>
  );
};

SubjectForm.propTypes = {
  storeTarget: PropTypes.string,
  disabled: PropTypes.bool,
};

SubjectForm.defaultProps = {
  disabled: false,
};

export default SubjectForm;
