import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import 'react-day-picker/dist/style.css';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Dashboard from '../pages/dashboard';
import Contacts from '../pages/contacts';
import Emails from '../pages/emails';
import EmailEdits from '../pages/email-edits';
import EmailTemplates from '../pages/email-templates';
import Payment from '../pages/payment';
import SignIn from '../pages/sign-in';
import Uploads from '../pages/upload';
import Tags from '../pages/tags';
import NewCampaign from '../pages/newcampaign';
import Donations from '../pages/donations';
import Reports from '../pages/reports';
import TemplatePreview from '../pages/template-preview';
import Segments from '../pages/segments';
import NewSegment from '../pages/new-segment';
import PreviewSegment from '../pages/preview-segment';
import Imports from '../pages/imports';
import PaymentOptions from '../pages/payment-options';
import AccountInfo from '../pages/account-info';
import DomainAuth from '../pages/domain-auth';
import Transactional from '../pages/transactional';
import Recurring from '../pages/recurring';
import EmailConfig from '../pages/email-config';
import Exports from '../pages/exports';
import EmailSettings from '../pages/email-settings';
import SignUp from '../pages/signup';
import UserSeats from '../pages/user-seats';
import ProtectedRoute from './ProtectedRoute';
import Subscription from '../pages/subscription';
import Settings from '../pages/settings';
import SuggestedDrafts from '../pages/suggested-drafts';
import RecommendedTasks from '../pages/recommended-tasks';
import Integrations from '../pages/integrations';
import OptInPage from '../pages/opt-in-page';
import DonationPages from '../pages/donation-pages';
import DonationPage from '../pages/donation-page';
import UserRoleProtectedRoute from './UserRoleProtectedRoute';
import { USER_ROLES } from '../core/constants';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppRouter = () => {
  const { integrationsPage, optInPageEditor } = useFlags();

  return (
    <Router>
      <SentryRoutes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route index element={<Dashboard />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="contacts/:id" element={<Contacts />} />
          <Route path="emails" element={<Emails />} />
          <Route path="emails/:selectedCampaignId" element={<Emails />} />
          <Route path="payment" element={<Payment />} />
          <Route path="signin" element={<SignIn />} />
          <Route path="upload" element={<Uploads />} />
          <Route path="tags" element={<Tags />} />
          <Route path="new-campaign" element={<NewCampaign />} />
          <Route path="edit-campaign/:campaignId" element={<NewCampaign />} />
          <Route path="donations" element={<Donations />} />
          <Route path="donations/:contactId" element={<Donations />} />
          <Route path="payment-options" element={<PaymentOptions />} />
          <Route path="domain-auth" element={<DomainAuth />} />
          <Route path="reports" element={<Reports />} />
          <Route path="segments" element={<Segments />} />
          <Route path="new-segment" element={<NewSegment />} />
          <Route path="preview-segment" element={<PreviewSegment />} />
          <Route path="edit-segment" element={<NewSegment />} />
          <Route path="imports" element={<Imports />} />
          <Route path="account-info" element={<AccountInfo />} />
          <Route path="recurring" element={<Recurring />} />
          <Route path="transactional" element={<Transactional />} />
          <Route path="exports" element={<Exports />} />
          <Route path="email-templates" element={<EmailTemplates />} />
          <Route path="email-editor" element={<EmailEdits />} />
          <Route path="email-settings" element={<EmailSettings />} />
          <Route
            path="subscription"
            element={
              <UserRoleProtectedRoute allowedRoles={[USER_ROLES.administrator]}>
                <Subscription />
              </UserRoleProtectedRoute>
            }
          />
          <Route path="settings" element={<Settings />} />
          <Route path="suggested-drafts" element={<SuggestedDrafts />} />
          <Route path="suggested-drafts/:selectedCampaignId" element={<SuggestedDrafts />} />
          <Route
            path="user-seats"
            element={
              <UserRoleProtectedRoute allowedRoles={[USER_ROLES.administrator]}>
                <UserSeats />
              </UserRoleProtectedRoute>
            }
          />
          <Route path="recommended-tasks" element={<RecommendedTasks />} />
          {integrationsPage && <Route path="integrations" element={<Integrations />} />}
          {optInPageEditor && <Route path="opt-in-page" element={<OptInPage />} />}
          <Route path="donation-pages" element={<DonationPages />} />
          <Route path="donation-pages/:slug" element={<DonationPage />} />
        </Route>

        <Route
          path="/template-preview"
          element={
            <ProtectedRoute>
              <TemplatePreview />
            </ProtectedRoute>
          }
        />
        <Route
          path="/email-config"
          element={
            <ProtectedRoute>
              <EmailConfig />
            </ProtectedRoute>
          }
        />
        <Route path="/signup/:inviteId" element={<SignUp />} />
        <Route path="*" element={<Navigate to="/" />} />
      </SentryRoutes>
    </Router>
  );
};

export default AppRouter;
