import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import fileDownload from 'js-file-download';

import appSettings from '../../app-settings';
import { getNetworkError } from '../../core/utils';

const CONTACTS_URL = `${appSettings.baseUrl}/contacts`;

const SET_ERROR = 'imports/SET_ERROR';
const SET_SUCCESS = 'imports/SET_SUCCESS';
const UPDATE_NEW_IMPORT = 'imports/UPDATE_NEW_IMPORT';
const RESET_NEW_IMPORT = 'imports/RESET_NEW_IMPORT';
const GET_IMPORTS = 'imports/GET_IMPORTS';
const DOWNLOAD_FILE = 'imports/DOWNLOAD_FILE';
const UPDATE_FIELD_MAPPINGS = 'import/UPDATE_FIELD_MAPPINGS';
const SET_IMPORT_ONBOARDING = 'import/SET_IMPORT_ONBOARDING';

export const setError = createAction(SET_ERROR);

export const setSuccess = createAction(SET_SUCCESS);

export const updateNewImport = createAction(UPDATE_NEW_IMPORT);

export const resetNewImport = createAction(RESET_NEW_IMPORT);

export const updateFieldMappings = createAction(UPDATE_FIELD_MAPPINGS);

export const setImportOnboarding = createAction(SET_IMPORT_ONBOARDING);

export const goBack = () => (dispatch, getState) => {
  const {
    imports: {
      newImport: { activeStep },
    },
  } = getState();
  dispatch(updateNewImport({ activeStep: Math.max(activeStep - 1, 0) }));
};

export const goNext = () => (dispatch, getState) => {
  const {
    imports: {
      newImport: { activeStep },
    },
  } = getState();
  dispatch(updateNewImport({ activeStep: Math.min(activeStep + 1, 7) }));
};

export const getImports = createAsyncThunk(GET_IMPORTS, async () => {
  try {
    const response = await axios.get(`${CONTACTS_URL}/import`);
    return response.data;
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const downloadFile = createAsyncThunk(DOWNLOAD_FILE, async ({ bucketName, documentKey, fileName }) => {
  try {
    const {
      data: { url: downloadUrl },
    } = await axios.post(`${CONTACTS_URL}/download-url`, {
      bucket_name: bucketName,
      document_key: documentKey,
    });
    const res = await axios.get(downloadUrl, { responseType: 'blob', headers: { Authorization: '' } });
    fileDownload(res.data, fileName);
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});
