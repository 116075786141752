import { useState } from 'react';
import { Dialog } from '@mui/material';
import { IconX, IconAlertCircle, IconCopy } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import Button from '../buttons/Button';
import Input from '../inputs/Input';
import appSettings from '../../app-settings';
import Loader from '../loader/Loader';
import SelectInput from '../inputs/SelectInput';
import { AI_CONTENT_TONES } from '../../core/constants';
import { useAccount } from '../../store/account/hooks';
import { getNetworkError } from '../../core/utils';
import AlertMessage from '../alerts/AlertMessage';
import IconButton from '../buttons/IconButton';
import Pagination from '../pagination/Pagination';

const ARTIFICIAL_INTELLIGENCE_URL = `${appSettings.baseUrl}/artificial-intelligence/completions`;

const formValidation = yup.object({
  topic: yup.string().required('Topic is required.'),
  organizationDescription: yup.string().required('Description is required.'),
  tone: yup.string(),
});

const ContentGeneratorModal = ({ open, onClose }) => {
  const [content, setContent] = useState('');
  const [completions, setCompletions] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { account } = useAccount();
  const form = useForm({
    initialValues: {
      topic: '',
      organizationDescription: account.organizationDescription ?? '',
      tone: AI_CONTENT_TONES[0].value,
    },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });

  const handleGenerate = async (values) => {
    setLoading(true);
    setContent('');
    try {
      const { topic, organizationDescription, tone } = values;
      const { organizationName } = account;

      const prompt = `
        generate a fundraising email body about ${topic} 
        for a non-profit organization called ${organizationName} 
        that focuses on ${organizationDescription} in a ${tone} tone. 
        Emphasize the impact of the donation and the consequences of failing to donate. 
        Include a call to action asking the reader to donate.
      `;

      const result = await axios.post(ARTIFICIAL_INTELLIGENCE_URL, { prompt });
      const { completion } = result.data;

      setContent(completion);
      setCompletions([{ content: completion, form: values }, ...completions]);
      setPage(1);
    } catch (error) {
      Sentry.captureException(error);
      setError(getNetworkError(error));
    }
    setLoading(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(content);
  };

  const handlePageChange = (value) => {
    setPage(value);

    const completion = completions[value - 1];
    setContent(completion.content);
    form.setValues(completion.form);
  };

  return (
    <>
      <Dialog
        maxWidth={false}
        open={open}
        onClose={onClose}
        sx={{ '.MuiPaper-root': { padding: 0, margin: 0, borderRadius: '12px', width: '100%', maxWidth: 960 } }}
      >
        <div className="space-y-6 p-6">
          <div className="relative flex">
            <h3 className="text-h6 font-semibold">Generate AI Content</h3>

            <IconButton
              Icon={<IconX size={20} className="text-gray-400" />}
              srOnly="Close"
              onClick={onClose}
              color="transparent"
              className="absolute -right-1 -top-1 !p-1"
            />
          </div>

          <div className="flex space-x-3">
            <IconAlertCircle size={20} className="text-red-500" />
            <p className="text-base">
              <span className="font-semibold text-red-500">This is a draft!</span> Any generated content should be
              reviewed and edited before being sent.
            </p>
          </div>

          <div className="flex gap-6">
            <form noValidate className="w-[260px] space-y-6" onSubmit={form.onSubmit(handleGenerate)}>
              <div className="space-y-4">
                <p className="text-base font-semibold">The main topic to discuss in the email</p>
                <Input
                  id="topic"
                  label="Topic"
                  value={form.values.topic}
                  onChange={(e) => {
                    form.setFieldValue('topic', e.target.value);
                  }}
                  error={!!form.errors.topic}
                  helperText={form.errors.topic}
                  required
                  size="xl"
                />
              </div>

              <div className="space-y-4">
                <p className="text-base font-semibold">The mission of the organization to emphasize in the email</p>
                <Input
                  id="organization-description"
                  label="Organization Mission Description"
                  value={form.values.organizationDescription}
                  onChange={(e) => {
                    form.setFieldValue('organizationDescription', e.target.value);
                  }}
                  error={!!form.errors.organizationDescription}
                  helperText={form.errors.organizationDescription}
                  required
                  size="xl"
                />
              </div>

              <SelectInput
                label="Tone of voice"
                options={AI_CONTENT_TONES}
                value={form.values.tone}
                onChange={(e) => {
                  form.setFieldValue('tone', e.target.value);
                }}
                error={!!form.errors.tone}
                helperText={form.errors.tone}
                required
                size="lg"
              />

              <Button title="Generate" color="primary" type="submit" className="w-full" />
            </form>

            <div className="flex flex-1 flex-col space-y-4">
              <div className=" relative h-[376px] rounded-lg bg-gray-10">
                {loading && <Loader message="Generating... this could take up to 15 seconds" isMessageHidden={false} />}

                {!!content && (
                  <>
                    <div className="h-full overflow-y-scroll whitespace-pre-line p-6">{content}</div>

                    <Button
                      title="Copy"
                      size="sm"
                      className="
                        absolute 
                        right-7 
                        top-3  
                        !border-0 
                        !bg-gray-10 
                        !p-0 
                        text-sm 
                        !text-gray-800 
                        !shadow-none	
                        focus:!ring-0 
                        focus:ring-offset-0
                        enabled:hover:!text-gray-600
                        enabled:focus:!text-gray-600
                        enabled:active:!text-gray-800
                      "
                      onClick={handleCopy}
                      LeftIcon={IconCopy}
                      iconProps={{ size: 16 }}
                    />
                  </>
                )}
              </div>

              <Pagination
                count={completions.length}
                page={page}
                onChange={handlePageChange}
                containerClassName="!justify-end"
              />
            </div>
          </div>
        </div>
      </Dialog>

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </>
  );
};

ContentGeneratorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ContentGeneratorModal;
