import { useMemo, useState } from 'react';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import debounce from 'lodash.debounce';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import Collapse from '@mui/material/Collapse';

import { useAccount } from '../../store/account/hooks';
import { useDomains } from '../../store/domains/hooks';
import FormInput from './FormInput';
import EmailWithDomainInput from './EmailWithDomainInput';
import { DEBOUNCE_DELAY_TIME_MS } from '../../core/constants';
import Button from '../buttons/Button';
import Loader from '../loader/Loader';
import { emailPreferencesInitialValues } from '../../store/account/reducers';
import ImageInput from './ImageInput';

const formValidation = yup.object({
  fromName: yup.string().min(2, 'Name is invalid.').required('From name is required.'),
  replyAddress: yup
    .string()
    .test('email-prefix', 'Invalid value.', (val) => {
      if (val?.length < 2) return false;
      return !val.includes('@');
    })
    .required('Reply address is required.'),
  primaryColor: yup.string(),
  bodyBackground: yup.string(),
  headerColor: yup.string(),
  logo: yup.string(),
  textColor: yup.string(),
  footerBackground: yup.string(),
  footerTextColor: yup.string(),
});

const getInitialValues = (emailPreferences, account) => {
  return {
    ...emailPreferences,
    fromName: emailPreferences.fromName ? emailPreferences.fromName : account?.organizationName ?? '',
    replyAddress: emailPreferences.replyAddress ? emailPreferences.replyAddress : account?.customerSupportEmail ?? '',
    logo: emailPreferences.logo.includes('placeholder') && account.logoUrl ? account.logoUrl : emailPreferences.logo,
  };
};

const EmailPreferences = () => {
  const [advancedExpanded, setAdvancedExpanded] = useState(false);
  const { authenticatedDomain } = useDomains();
  const { loading, account, updateEmailPreferences, saveEmailPreferences } = useAccount();
  const [initialValues] = useState(
    getInitialValues(account?.emailPreferences ?? emailPreferencesInitialValues, account),
  );
  const [logoFile, setLogoFile] = useState(null);
  const form = useForm({
    initialValues: {
      ...initialValues,
      replyAddress: initialValues.replyAddress?.split('@')[0] ?? '',
    },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });

  const debouncedUpdateEmailPreferences = useMemo(
    () => debounce(updateEmailPreferences, DEBOUNCE_DELAY_TIME_MS),
    [updateEmailPreferences],
  );

  const handleChange = (field, value) => {
    if (field === 'logo') {
      const url = URL.createObjectURL(value);
      setLogoFile(value);
      form.setFieldValue(field, url);
      debouncedUpdateEmailPreferences({ [field]: url });
      return;
    }
    form.setFieldValue(field, value);
    debouncedUpdateEmailPreferences({ [field]: value });
  };

  const handleCancel = (field) => {
    let initialValue = initialValues[field];
    if (field === 'replyAddress' && initialValue.includes('@')) {
      initialValue = initialValue.split('@')[0];
    }
    form.setFieldValue(field, initialValue);
    debouncedUpdateEmailPreferences({ [field]: initialValue });
    setTimeout(() => form.clearFieldError(field), 0);
  };

  const handleSaveChanges = async () => {
    await saveEmailPreferences(logoFile);
    form.resetDirty();
  };

  const hasBeenEdited = form.isDirty();

  return (
    <div className="relative mb-10 rounded-lg bg-white-100 shadow-md">
      <div className="flex items-center justify-between px-4 py-3">
        <h2 className="text-xl dark:text-white-100">Transactional Emails Preferences</h2>
      </div>

      <div className="border-t border-gray-50 pt-6">
        <form noValidate onSubmit={form.onSubmit(handleSaveChanges)}>
          <div className="space-y-6 px-4">
            <FormInput
              id="bodyBackground"
              type="color"
              label="Body Background"
              placeholder="#c5cfd8"
              value={form.values.bodyBackground}
              onChange={(value) => handleChange('bodyBackground', value)}
              error={form.errors.bodyBackground}
              onReset={() => handleCancel('bodyBackground')}
            />

            <FormInput
              id="textColor"
              type="color"
              label="Text Color"
              placeholder="#4e5c6a"
              value={form.values.textColor}
              onChange={(value) => handleChange('textColor', value)}
              error={form.errors.textColor}
              onReset={() => handleCancel('textColor')}
            />
          </div>

          <div
            className="my-8 flex cursor-pointer items-center justify-between border border-gray-50 bg-gray-10 px-4 py-2"
            onClick={() => setAdvancedExpanded((prev) => !prev)}
          >
            <h3 className="font-bold">Advanced Preferences</h3>

            {advancedExpanded ? (
              <IconChevronUp size={20} className="mr-2" />
            ) : (
              <IconChevronDown size={20} className="mr-2" />
            )}
          </div>

          <Collapse in={advancedExpanded}>
            <div className={`space-y-6 px-4 ${advancedExpanded ? 'pb-6' : ''}`}>
              <FormInput
                id="fromName"
                label="From Name"
                placeholder="Henry Carroll"
                value={form.values.fromName}
                onChange={(e) => handleChange('fromName', e.target.value)}
                error={form.errors.fromName}
                onReset={() => handleCancel('fromName')}
                required
              />

              <EmailWithDomainInput
                id="replyAddress"
                label="Reply Address"
                placeholder="info@donorspring.org"
                value={form.values.replyAddress}
                onChange={(e) => handleChange('replyAddress', e.target.value)}
                error={form.errors.replyAddress}
                onReset={() => handleCancel('replyAddress')}
                authenticatedDomain={authenticatedDomain}
                required
              />

              <FormInput
                id="primaryColor"
                type="color"
                label="Primary Color"
                placeholder="#4588d3"
                value={form.values.primaryColor}
                onChange={(value) => handleChange('primaryColor', value)}
                error={form.errors.primaryColor}
                onReset={() => handleCancel('primaryColor')}
              />

              <FormInput
                id="headerColor"
                type="color"
                label="Header Color"
                placeholder="#ffffff"
                value={form.values.headerColor}
                onChange={(value) => handleChange('headerColor', value)}
                error={form.errors.headerColor}
                onReset={() => handleCancel('headerColor')}
              />

              <ImageInput
                label="Logo"
                value={form.values.logo}
                onChange={(file) => handleChange('logo', file)}
                onReset={() => handleCancel('logo')}
              />

              <FormInput
                id="footerBackground"
                type="color"
                label="Footer Background"
                placeholder="#ffffff"
                value={form.values.footerBackground}
                onChange={(value) => handleChange('footerBackground', value)}
                error={form.errors.footerBackground}
                onReset={() => handleCancel('footerBackground')}
              />

              <FormInput
                id="footerTextColor"
                type="color"
                label="Footer Text Color"
                placeholder="#4e5c6a"
                value={form.values.footerTextColor}
                onChange={(value) => handleChange('footerTextColor', value)}
                error={form.errors.footerTextColor}
                onReset={() => handleCancel('footerTextColor')}
              />
            </div>
          </Collapse>

          <div className="bg-primary-50 p-4 pt-6">
            <Button title="Save Changes" color="primary" type="submit" disabled={!hasBeenEdited} />
          </div>
        </form>
      </div>

      {loading && <Loader />}
    </div>
  );
};

export default EmailPreferences;
