import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconPlus } from '@tabler/icons-react';
import { Button } from 'ui';

import BarChartCard from '../components/dashboard/BarChartCard';
import AreaChartCard from '../components/dashboard/AreaChartCard';
import { useDashboard } from '../store/dashboard/hooks';
import { useReports } from '../store/reports/hooks.js';
import Loader from '../components/loader/Loader';
import AlertMessage from '../components/alerts/AlertMessage';
import {
  formatDonationsAmountSpread,
  formatDonationsByDay,
  formatDonationsByHour,
  getDonationAmountStats,
  getDonationCountStats,
  getEmailReportStats,
  getTodayDonationStats,
} from '../core/utils';
import SelectCampaign from '../components/dashboard/SelectCampaign';
import DonationHistory from '../components/dashboard/DonationHistory';
import CustomDonationSpreadXAxisTick from '../components/dashboard/CustomDonationSpreadXAxisTick';
import DonorsWidget from '../components/dashboard/DonorsWidget';
import StatsWidget from '../components/dashboard/StatsWidget';
import { useCampaigns } from '../store/campaigns/hooks';
import DateRangePicker from '../components/dashboard/DateRangePicker';
import RecommendedTasksWidget from '../components/dashboard/RecommendedTasksWidget';

const Dashboard = () => {
  const {
    loading: dashboardLoading,
    error: dashboardError,
    donations,
    startDate,
    endDate,
    reports,
    setError: setDashboardError,
    getDonationsData,
  } = useDashboard();
  const { error: reportsError, setError: setReportsError, getReports } = useReports();
  const { resetCampaignDetails, resetCampaignTemplate } = useCampaigns();
  const navigate = useNavigate();

  useEffect(() => {
    getDonationsData();
    getReports();
  }, [getDonationsData, getReports]);

  const toggleError = () => {
    if (dashboardError) setDashboardError(false);
    if (reportsError) setReportsError(false);
  };

  const goToNewCampaign = () => {
    resetCampaignDetails();
    resetCampaignTemplate();
    navigate('/new-campaign');
  };

  const donationAmountStats = useMemo(
    () => getDonationAmountStats(donations, startDate, endDate),
    [donations, startDate, endDate],
  );

  const todayDonationStats = useMemo(() => getTodayDonationStats(donations), [donations]);

  const donationCountStats = useMemo(
    () => getDonationCountStats(donations, startDate, endDate),
    [donations, startDate, endDate],
  );

  const emailReportStats = useMemo(
    () => getEmailReportStats(reports, startDate, endDate),
    [reports, startDate, endDate],
  );

  const donationsByHour = useMemo(() => formatDonationsByHour(donations), [donations]);

  const donationsAmountSpread = useMemo(() => formatDonationsAmountSpread(donations), [donations]);

  const donationsByDay = useMemo(() => formatDonationsByDay(donations), [donations]);

  const error = dashboardError || reportsError;

  return (
    <>
      <div className="flex min-w-[1110px] max-w-7xl flex-col space-y-6">
        <div className="flex flex-row justify-between">
          <SelectCampaign />

          <div className="flex items-end space-x-3">
            <DateRangePicker />

            <Button title="New Campaign" color="primary" LeftIcon={IconPlus} onClick={goToNewCampaign} />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-2 grid grid-cols-2 gap-4">
            <StatsWidget
              title={donationAmountStats.title}
              totalAmount={donationAmountStats.totalAmount}
              trendDirection={donationAmountStats.trendDirection}
              trendAmount={donationAmountStats.trendAmount}
              trendLabel={donationAmountStats.trendLabel}
              dataSections={donationAmountStats.dataSections}
            />

            <StatsWidget
              title={todayDonationStats.title}
              totalAmount={todayDonationStats.totalAmount}
              trendDirection={todayDonationStats.trendDirection}
              trendAmount={todayDonationStats.trendAmount}
              trendLabel={todayDonationStats.trendLabel}
              dataSections={todayDonationStats.dataSections}
            />

            <StatsWidget
              title={donationCountStats.title}
              totalAmount={donationCountStats.totalAmount}
              trendDirection={donationCountStats.trendDirection}
              trendAmount={donationCountStats.trendAmount}
              trendLabel={donationCountStats.trendLabel}
              dataSections={donationCountStats.dataSections}
            />

            <StatsWidget
              title={emailReportStats.title}
              totalAmount={emailReportStats.totalAmount}
              trendDirection={emailReportStats.trendDirection}
              trendAmount={emailReportStats.trendAmount}
              trendLabel={emailReportStats.trendLabel}
              dataSections={emailReportStats.dataSections}
            />
          </div>

          <RecommendedTasksWidget />
        </div>

        <div className="grid grid-cols-3 gap-4">
          <div className="relative col-span-2">
            <AreaChartCard />
            {dashboardLoading && <Loader />}
          </div>

          <DonorsWidget />
        </div>

        <div className="grid grid-cols-3 gap-4">
          <div className="relative">
            <BarChartCard
              name="Donation Amount Spread"
              data={donationsAmountSpread}
              xDataKey="amountCategory"
              XAxisTick={CustomDonationSpreadXAxisTick}
            />
            {dashboardLoading && <Loader />}
          </div>
          <div className="relative">
            <BarChartCard
              name="Donations By Hour"
              data={donationsByHour}
              xDataKey="hour"
              XAxisTicks={['4 AM', '8 AM', '12 PM', '4 PM', '8 PM']}
            />
            {dashboardLoading && <Loader />}
          </div>
          <div className="relative">
            <BarChartCard name="Donations By Day" data={donationsByDay} xDataKey="day" interval={0} />
            {dashboardLoading && <Loader />}
          </div>
        </div>

        <DonationHistory />
      </div>

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={toggleError}
        severity="error"
      />
    </>
  );
};

export default Dashboard;
