import React from 'react';
import ReactDOM from 'react-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import * as Sentry from '@sentry/react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import './index.css';
import App from './App';
import appSettings from './app-settings';

Sentry.init({
  dsn: appSettings.sentryDsn,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: true,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: appSettings.sentryTracesSampleRate,
  replaysSessionSampleRate: appSettings.sentryReplaysSessionSampleRate,
  replaysOnErrorSampleRate: appSettings.sentryReplaysOnErrorSampleRate,
});

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: appSettings.launchDarklyClientSideId,
  });

  ReactDOM.render(
    <LDProvider>
      <App />
    </LDProvider>,
    document.getElementById('root'),
  );
})();
