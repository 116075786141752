import { memo, useState } from 'react';
import { IconCircleX } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import capitalize from 'lodash.capitalize';

import SelectInput from '../inputs/SelectInput';
import { MAP_DATA_TYPE_INPUT_TYPE, SEGMENT_CONDITIONS } from '../../core/constants';
import { parseSegmentFieldName } from '../../core/utils';
import Input from '../inputs/Input';

const conditionOptions = [
  { label: 'Condition', value: '' },
  ...SEGMENT_CONDITIONS.map((sc) => ({ label: sc, value: sc })),
];

const FieldCondition = ({ index, setFieldValue, initialValues, onDelete, errors, contactFields }) => {
  const [field, setField] = useState(initialValues.field);
  const [condition, setCondition] = useState(initialValues.condition);
  const [value, setValue] = useState(initialValues.value);

  const fieldOptions = [
    { label: 'Field', value: '' },
    ...contactFields.map((cF) => {
      // Capitalize, convert underscores to spaces, remove 'donor.', and make labels in the format of "last x at" more readable as "most recent x (days ago)"
      const label = capitalize(parseSegmentFieldName(cF.field));
      return { label, value: cF.field };
    }),
  ];

  const selectedOption = fieldOptions.find((fO) => fO.value === field);

  const handleFieldChange = (e) => {
    const val = e.target.value;
    setField(val);
    setFieldValue(`rules.${index}.field`, val);
  };

  const handleConditionChange = (e) => {
    const val = e.target.value;
    setCondition(val);
    setFieldValue(`rules.${index}.condition`, val);
  };

  const handleValueChange = (e) => {
    const val = e.target.value;
    setValue(val);
    setFieldValue(`rules.${index}.value`, val);
  };

  return (
    <div className="flex w-full space-x-4 py-6">
      <SelectInput
        options={fieldOptions}
        value={field}
        onChange={handleFieldChange}
        error={!!errors.field}
        helperText={errors.field}
        containerClassName="w-full"
      />
      <SelectInput
        options={conditionOptions}
        value={condition}
        onChange={handleConditionChange}
        error={!!errors.condition}
        helperText={errors.condition}
        containerClassName="w-full"
      />
      <Input
        type={MAP_DATA_TYPE_INPUT_TYPE[selectedOption?.type] ?? 'text'}
        value={value}
        onChange={handleValueChange}
        placeholder=""
        required
        error={!!errors.value}
        helperText={errors.value}
        containerClassName="w-full"
      />
      <button
        type="button"
        onClick={onDelete}
        className="inline-flex
          items-center 
        space-x-2 
          rounded-lg
          p-1.5 
          text-center
          font-medium 
          text-red-600
          decoration-solid 
          outline-0
          focus:ring-4
          focus:ring-red-300"
      >
        <IconCircleX />
        <span>Delete</span>
      </button>
    </div>
  );
};

FieldCondition.propTypes = {
  index: PropTypes.number.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    field: PropTypes.string.isRequired,
    condition: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    field: PropTypes.string,
    condition: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  contactFields: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
};

const MemoizedFieldCondition = memo(FieldCondition);

export default MemoizedFieldCondition;
