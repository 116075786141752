import { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import { IconX, IconCopy } from '@tabler/icons-react';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';

import Button from '../buttons/Button';
import SelectInput from '../inputs/SelectInput';
import Input from '../inputs/Input';
import {
  ACCOUNT_INFO_FIELDS,
  CONTACT_FIELDS,
  MAP_ACCOUNT_INFO_TO_LABEL,
  MAP_ACCOUNT_INFO_TO_TAG_NAME,
  MAP_CONTACT_FIELD_TO_LABEL,
  MAP_CONTACT_FIELD_TO_TAG_NAME,
} from '../../core/constants';
import IconButton from '../buttons/IconButton';

const contactFieldOptions = [{ label: 'Field', value: '' }].concat(
  CONTACT_FIELDS.map((field) => ({
    label: MAP_CONTACT_FIELD_TO_LABEL[field],
    value: field,
  })),
);

const accountInfoFieldOptions = ACCOUNT_INFO_FIELDS.map((field) => ({
  label: MAP_ACCOUNT_INFO_TO_LABEL[field],
  value: field,
}));

const formValidation = yup.object({
  field: yup.string().required('Detail is required.'),
  fallback: yup.string(),
});

const MergeTagBuilderModal = ({ open, onClose, includeAccountInfo }) => {
  const [mergeTag, setMergeTag] = useState('');
  const form = useForm({
    initialValues: {
      field: '',
      fallback: '',
    },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });

  const handleGenerate = (values) => {
    const tagName = MAP_CONTACT_FIELD_TO_TAG_NAME[values.field] ?? MAP_ACCOUNT_INFO_TO_TAG_NAME[values.field];
    const fallback = values.fallback;
    const mergeTag = fallback ? `{{${tagName} | ${values.fallback}}}` : `{{${tagName}}}`;

    setMergeTag(mergeTag);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(mergeTag);
  };

  const handleClose = () => {
    setMergeTag('');
    form.reset();
    onClose();
  };

  const options = includeAccountInfo ? [...contactFieldOptions, ...accountInfoFieldOptions] : contactFieldOptions;

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      sx={{ '.MuiPaper-root': { padding: 0, margin: 0, borderRadius: '12px', width: '100%' } }}
    >
      <div className="space-y-6 p-6">
        <div className="relative flex">
          <h3 className="text-h6 font-semibold">Contact Details</h3>

          <IconButton
            Icon={<IconX size={20} className="text-gray-400" />}
            srOnly="Close"
            onClick={handleClose}
            color="transparent"
            className="absolute -right-1 -top-1 !p-1"
          />
        </div>

        <p className="text-base">
          {includeAccountInfo
            ? "Merge tags will automatically populate with a contact's, your organization details or use the fallback if that detail is not available for the contact or for your account information."
            : "Merge tags will automatically populate with a contact's details or use the fallback if that detail is not available for the contact."}
        </p>

        <form noValidate className="space-y-6" onSubmit={form.onSubmit(handleGenerate)}>
          <SelectInput
            label="Select a detail"
            options={options}
            value={form.values.field}
            onChange={(e) => {
              setMergeTag('');
              form.setFieldValue('field', e.target.value);
            }}
            error={!!form.errors.field}
            helperText={form.errors.field}
            required
            size="lg"
          />

          <Input
            id="fallback"
            label="Fallback (optional)"
            value={form.values.fallback}
            onChange={(e) => {
              setMergeTag('');
              form.setFieldValue('fallback', e.target.value);
            }}
            error={!!form.errors.fallback}
            helperText={form.errors.fallback}
            size="xl"
          />

          {!!mergeTag && (
            <div
              className="
              flex
              w-full
              items-center
              justify-between
              rounded-lg
              bg-gray-10
              px-4
              py-2.5
              text-base
            "
            >
              <p className="flex-1 text-base text-gray-950">{mergeTag}</p>

              <Button
                title="Copy"
                size="sm"
                onClick={handleCopy}
                className="
                !border-0 
                !bg-gray-10 
                !p-0 
                text-sm 
                !text-gray-800 
                !shadow-none	
                focus:!ring-0 
                focus:ring-offset-0
                enabled:hover:!text-gray-600
                enabled:focus:!text-gray-600
                enabled:active:!text-gray-800
              "
                LeftIcon={IconCopy}
                iconProps={{ size: 16 }}
              />
            </div>
          )}

          <Button title="Generate" color="primary" type="submit" className="w-full" />
        </form>
      </div>
    </Dialog>
  );
};

MergeTagBuilderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  includeAccountInfo: PropTypes.bool,
};

MergeTagBuilderModal.defaultProps = {
  includeAccountInfo: false,
};

export default MergeTagBuilderModal;
