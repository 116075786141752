import { useState, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { IconUpload, IconTrash } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import HelperText from '../inputs/HelperText';

const allowedFileTypes = { 'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'] };

const maxSize = 1024 * 1024 * 5; // 5MB

const errorCodeLabels = {
  'file-too-large': 'File is too large.',
  'too-many-files': 'Please select only one file.',
  'file-invalid-type': 'Invalid file type.',
};

const ImageInput = ({ name, onChange, onDelete, imageClassName, error, setError, disabled, imageUrl }) => {
  const [file, setFile] = useState(null);

  const handleDelete = () => {
    setFile(null);
    setError(undefined);
    onDelete();
  };

  const onDrop = useCallback(
    async (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        const error = fileRejections[0].errors?.[0] ?? {};
        setError(errorCodeLabels[error.code] || 'Invalid file.');
        return;
      }
      const acceptedFile = acceptedFiles[0];
      try {
        setError(undefined);
        setFile(acceptedFile);
        onChange(acceptedFile);
      } catch (err) {
        setError('Something went wrong. Please try again.');
      }
    },
    [onChange, setError],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: allowedFileTypes,
    maxSize,
    maxFiles: 1,
    onDrop,
    disabled,
  });

  const previewUrl = useMemo(() => {
    if (!file) return imageUrl;
    return URL.createObjectURL(file);
  }, [imageUrl, file]);

  return (
    <div className="flex items-center space-x-6">
      <div
        {...getRootProps()}
        className={`
            relative 
            flex 
            size-[128px] 
            items-center 
            justify-center 
            rounded-lg 
            border 
            border-gray-50 
            bg-gray-10 
            ${disabled ? 'cursor-not-allowed opacity-80' : 'cursor-pointer'}
            ${imageClassName}
          `}
      >
        <p className="text-base capitalize text-gray-300">{name}</p>
        <input {...getInputProps()} />
        {!!previewUrl && (
          <img src={previewUrl} alt={name} className="absolute left-0 top-0 size-full rounded-lg object-cover" />
        )}
      </div>

      <div className="flex-1 space-y-3">
        <div className="flex flex-wrap gap-2">
          <Button
            title="Upload image"
            size="sm"
            LeftIcon={IconUpload}
            iconProps={{ size: 18 }}
            onClick={open}
            disabled={disabled}
          />
          {!!file && (
            <Button
              title="Delete"
              size="sm"
              color="destructive"
              variant="outlined"
              LeftIcon={IconTrash}
              iconProps={{ size: 18 }}
              onClick={handleDelete}
              disabled={disabled}
            />
          )}
        </div>
        <p className="text-base text-gray-600">JPEG or PNG, max size 5MB.</p>
        {!!error && <HelperText message={error} error />}
      </div>
    </div>
  );
};

ImageInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  setError: PropTypes.func,
  error: PropTypes.string,
  imageClassName: PropTypes.string,
  disabled: PropTypes.bool,
  imageUrl: PropTypes.string,
};

ImageInput.defaultProps = {
  imageClassName: '',
  setError: () => {},
  error: '',
  disabled: false,
};

export default ImageInput;
