import { useSelector, shallowEqual } from 'react-redux';

import useActionCreator from '../../hooks/useActionCreator';
import {
  setError,
  setSuccess,
  getImports,
  downloadFile,
  updateNewImport,
  goBack,
  goNext,
  resetNewImport,
  updateFieldMappings,
  setImportOnboarding,
} from './actions';

export const useImports = () => {
  const importsState = useSelector((state) => state.imports, shallowEqual);

  return {
    ...importsState,
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    getImports: useActionCreator(getImports),
    downloadFile: useActionCreator(downloadFile),
    updateNewImport: useActionCreator(updateNewImport),
    goBack: useActionCreator(goBack),
    goNext: useActionCreator(goNext),
    resetNewImport: useActionCreator(resetNewImport),
    updateFieldMappings: useActionCreator(updateFieldMappings),
    setImportOnboarding: useActionCreator(setImportOnboarding),
  };
};
