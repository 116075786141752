import { useEffect, useState } from 'react';
import { Menu } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { Button, Checkbox } from 'ui';

const UnsubscribedFilter = (props) => {
  const { selectedValue, onSelect } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(selectedValue);

  const open = Boolean(anchorEl);

  useEffect(() => {
    // Needed to reconcile the initial state of the dropdown with the current
    // state of the selected contact type ids when the dropdown is closed and reopened.
    // Use case: user toggles local state selection but does not apply the changes.
    if (!open) {
      setValue(selectedValue);
    }
  }, [open, selectedValue]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (val) => {
    setValue((prev) => (prev === val ? '' : val));
  };

  const handleReset = () => {
    setValue('');
  };

  const handleApply = () => {
    onSelect(value);
    handleClose();
  };

  return (
    <>
      <Button
        title="Unsubscribed"
        className={selectedValue ? 'border-primary-200 !bg-primary-50' : ''}
        RightIcon={open ? IconChevronUp : IconChevronDown}
        onClick={handleClick}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: 'w-[209px] !rounded-lg mt-0.5 !overflow-hidden', list: '!p-0' }}
      >
        <ul className="space-y-3 p-4">
          <li>
            <Checkbox
              id="unsubscribed"
              name="unsubscribed"
              label="Unsubscribed"
              value="true"
              checked={value === 'true'}
              onChange={() => handleChange('true')}
            />
          </li>

          <li>
            <Checkbox
              id="subscribed"
              name="subscribed"
              label="Subscribed"
              value="false"
              checked={value === 'false'}
              onChange={() => handleChange('false')}
            />
          </li>
        </ul>

        <li className="flex items-center justify-between border-t border-gray-50 p-4">
          <Button title="Reset" onClick={handleReset} />

          <Button title="Apply" color="primary" onClick={handleApply} />
        </li>
      </Menu>
    </>
  );
};

UnsubscribedFilter.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default UnsubscribedFilter;
