import { IconHelp } from '@tabler/icons-react';
import PropTypes from 'prop-types';

const Label = ({ id, label, labelTooltip, labelClassName, required, requiredClassName }) => {
  return (
    <label
      htmlFor={id}
      className={`
              mb-1.5
              flex
              items-center
              space-x-1
              text-base
              font-medium 
              text-gray-950
              ${labelClassName}
            `}
    >
      <span className="z-[1]">{label}</span>
      {required && <span className={`z-[1] text-base text-red-500 ${requiredClassName}`}>*</span>}
      {!!labelTooltip && <IconHelp size={16} className="z-[1] text-gray-600" />}
    </label>
  );
};

Label.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  labelTooltip: PropTypes.string,
  labelClassName: PropTypes.string,
  required: PropTypes.bool,
  requiredClassName: PropTypes.string,
};

Label.defaultProps = {
  labelClassName: '',
  required: false,
  requiredClassName: '',
};

export default Label;
