import { ThemeProvider } from '@mui/material/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import './index.css';
import './App.css';
import './core/interceptors';
import { store, persistor } from './store';
import { theme } from './core/theme';
import AppRouter from './router/AppRouter';
import FallbackComponent from './router/FallbackComponent';

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <div className="flex h-screen flex-col items-stretch text-gray-950">
              <AppRouter />
            </div>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

const AppWithProfiler = Sentry.withProfiler(App);

export default AppWithProfiler;
