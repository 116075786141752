import { useImports } from '../../store/imports/hooks';
import Button from '../buttons/Button';
import ChoiceInput from './ChoiceInput';

const OnboardingStep = () => {
  const {
    newImport: { sendRecurringEmails },
    updateNewImport,
    goBack,
    goNext,
  } = useImports();

  return (
    <div className="w-full max-w-xl space-y-6 py-3">
      <h1 className="text-3xl font-medium">Onboard your contacts</h1>

      <div className="max-w-sm">
        <ChoiceInput
          id="send-recurring-emails"
          label="Send onboarding email series to this list?"
          selected={sendRecurringEmails}
          onChange={(value) => updateNewImport({ sendRecurringEmails: value })}
          containerClassName="mt-4"
        />
      </div>

      <div className="flex items-center space-x-3">
        <Button title="Go Back" onClick={goBack} />
        <Button title="Continue" color="primary" onClick={goNext} />
      </div>
    </div>
  );
};

export default OnboardingStep;
