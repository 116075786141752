import { Dialog } from '@mui/material';
import { IconX, IconAlertCircleFilled } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import IconButton from '../buttons/IconButton';

const ConfirmDeleteModal = ({ open, onConfirm, onClose, title, description, loading }) => {
  const handleClose = () => {
    if (loading) return;
    onClose();
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      sx={{ '.MuiPaper-root': { padding: 0, margin: 0, borderRadius: '12px', width: '100%' } }}
    >
      <div className="space-y-6 p-6">
        <div className="relative flex">
          <div className="flex items-center space-x-3">
            <IconAlertCircleFilled size={20} className="text-red-500" />
            <h3 className="text-h6 font-semibold">{title}</h3>
          </div>

          <IconButton
            Icon={<IconX size={20} className="text-gray-400" />}
            srOnly="Close"
            onClick={handleClose}
            color="transparent"
            className="absolute -right-1 -top-1 !p-1"
            disabled={loading}
          />
        </div>

        <p className="text-base">{description}</p>

        <div className="flex justify-end space-x-3">
          <Button title="No, cancel" onClick={handleClose} disabled={loading} />

          <Button title="Yes, I'm sure" color="destructive" onClick={onConfirm} loading={loading} disabled={loading} />
        </div>
      </div>
    </Dialog>
  );
};

ConfirmDeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ConfirmDeleteModal;
