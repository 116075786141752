import { IconPencil, IconBrowserCheck } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { Button } from 'ui';

import EmbeddedPage from '../embedded-page/EmbeddedPage';

const DonationPageCard = (props) => {
  const { name, pageUrl, isDefault = false, onEdit, onMakeDefault } = props;
  return (
    <div className="space-y-6 overflow-hidden rounded-xl bg-white-100 p-6 shadow-md">
      <div className="h-[228px] w-[312px] overflow-hidden">
        <EmbeddedPage
          pageUrl={pageUrl}
          scrolling={false}
          interactive={false}
          className="h-[768px] w-[1024px] origin-top-left scale-[0.304]"
        />
      </div>

      <h3 className="text-center text-base font-semibold">{name}</h3>

      <div className="flex items-center justify-between">
        <Button
          title={isDefault ? 'Default' : 'Make Default'}
          size="sm"
          LeftIcon={IconBrowserCheck}
          disabled={isDefault}
          onClick={onMakeDefault}
        />
        <Button title="Edit" size="sm" LeftIcon={IconPencil} onClick={onEdit} />
      </div>
    </div>
  );
};

DonationPageCard.propTypes = {
  name: PropTypes.string.isRequired,
  pageUrl: PropTypes.string.isRequired,
  isDefault: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onMakeDefault: PropTypes.func.isRequired,
};

export default DonationPageCard;
