import { createSlice } from '@reduxjs/toolkit';
import mergeWith from 'lodash.mergewith';

import { resetTemplate, updateTemplate } from './actions';

const initialState = {
  loading: false,
  error: false,
  success: false,
  design: {},
  template: {
    emailConfig: {
      senderName: '',
      senderEmail: '',
      subject: '',
      returnEmail: '',
      previewText: '',
    },
  },
};

const emailTemplatesStore = createSlice({
  name: 'emailTemplates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateTemplate, (state, { payload }) => {
      state.template = mergeWith({}, state.template, payload);
    });

    builder.addCase(resetTemplate, (state) => {
      state.template = initialState.template;
    });
  },
});

export default emailTemplatesStore.reducer;
