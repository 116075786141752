import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import appSettings from '../../app-settings';
import {
  MAP_TRANSACTIONAL_TEMPLATE_NAME_TO_KEY,
  MAP_TRANSACTIONAL_EMAILS_TO_TEMPLATE_NAME,
} from '../../core/constants';
import { getNetworkError, uploadFile } from '../../core/utils';

const EMAIL_TEMPLATES_URL = `${appSettings.baseUrl}/email-templates`;

const postTransactionalEmail = async (email) => {
  await axios.post(`${EMAIL_TEMPLATES_URL}/transactional`, email);
};

const createEmailImageUploadUrl = async (templateName) => {
  const response = await axios.post(`${EMAIL_TEMPLATES_URL}/images`, { templateName });
  return response.data;
};

const SET_ERROR = 'transactional/SET_ERROR';
const SET_SUCCESS = 'transactional/SET_SUCCESS';
const UPDATE_TRANSACTIONAL_EMAIL = 'transactional/UPDATE_TRANSACTIONAL_EMAIL';
const GET_TRANSACTIONAL_EMAILS = 'transactional/GET_TRANSACTIONAL_EMAILS';
const SAVE_TRANSACTIONAL_EMAILS = 'transactional/SAVE_TRANSACTIONAL_EMAILS';

export const setError = createAction(SET_ERROR);

export const setSuccess = createAction(SET_SUCCESS);

export const updateTransactionalEmail = createAction(UPDATE_TRANSACTIONAL_EMAIL);

export const getTransactionalEmails = createAsyncThunk(GET_TRANSACTIONAL_EMAILS, async () => {
  try {
    const response = await axios.get(EMAIL_TEMPLATES_URL, { params: { type: 'transactional' } });
    return response.data.reduce((prev, template) => {
      const emailKey = MAP_TRANSACTIONAL_TEMPLATE_NAME_TO_KEY[template.templateName];
      if (!emailKey) return prev;
      prev[emailKey] = {
        send: template.emailConfig.send,
        subject: template.emailConfig.subject,
        image: template.emailConfig.imageUrl,
        heading: template.emailConfig.heading,
        text: template.emailConfig.content,
      };
      return prev;
    }, {});
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const saveTransactionalEmails = createAsyncThunk(
  SAVE_TRANSACTIONAL_EMAILS,
  async (imageFiles, { getState, dispatch }) => {
    try {
      const {
        transactional: { edits },
      } = getState();

      const imageFilesKeys = Object.keys(imageFiles);
      const imageUrls = {};

      if (imageFilesKeys.length > 0) {
        await Promise.all(
          imageFilesKeys.map(async (imageKey) => {
            const templateName = MAP_TRANSACTIONAL_EMAILS_TO_TEMPLATE_NAME[imageKey];
            const { uploadUrl, s3Key } = await createEmailImageUploadUrl(templateName);
            const file = imageFiles[imageKey];
            const arrayBuffer = await file.arrayBuffer();
            await uploadFile(uploadUrl, arrayBuffer, file.type);
            imageUrls[imageKey] = `${appSettings.emailImagesDomain}/${s3Key}`;
          }),
        );
      }

      await Promise.all(
        Object.keys(edits).map((key) => {
          const data = edits[key];
          const templateName = MAP_TRANSACTIONAL_EMAILS_TO_TEMPLATE_NAME[key];
          return postTransactionalEmail({
            templateName,
            subject: data.subject,
            send: data.send,
            imageUrl: imageUrls[key] ?? data.image,
            heading: data.heading,
            content: data.text,
          });
        }),
      );

      dispatch(getTransactionalEmails());
    } catch (err) {
      Sentry.captureException(err);
      const errorMessage = getNetworkError(err);
      throw new Error(errorMessage);
    }
  },
);
