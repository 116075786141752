import { useState } from 'react';
import { Menu, MenuItem, Collapse } from '@mui/material';
import { IconFilter, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import Checkbox from '../inputs/Checkbox';

const Filters = ({ initialStatus, options, onSelect, active }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [stageOpen, setStageOpen] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(initialStatus);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (selectedStatus) => {
    setSelectedStatus((prev) => (prev === selectedStatus ? '' : selectedStatus));
  };

  const handleReset = () => {
    onSelect('');
    setSelectedStatus('');
    handleClose();
  };

  const handleApply = () => {
    onSelect(selectedStatus);
    handleClose();
  };

  return (
    <>
      <Button
        title="Filters"
        LeftIcon={IconFilter}
        onClick={handleClick}
        className={`${active ? 'border-primary-200 !bg-primary-50' : ''}`}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: 'w-[220px] !rounded-lg mt-0.5', list: '!p-0 divide-y divide-gray-50' }}
      >
        <li className="flex items-center justify-between px-4 py-3">
          <p className="text-base font-semibold text-gray-950">Filters</p>
          <button className="text-sm text-gray-600" onClick={handleReset}>
            Reset
          </button>
        </li>

        <MenuItem
          classes={{ root: '!justify-between !py-3 !border-solid' }}
          onClick={() => setStageOpen((prev) => !prev)}
        >
          <p className="text-caption1 font-semibold uppercase text-gray-950">Status</p>
          {stageOpen ? (
            <IconChevronDown size={16} className="text-gray-600" />
          ) : (
            <IconChevronUp size={16} className="text-gray-600" />
          )}
        </MenuItem>

        <Collapse in={stageOpen}>
          <li className="space-y-3 px-4 py-3">
            {options.map(({ label, value }) => (
              <Checkbox
                key={`checkbox-${value}`}
                id={value}
                label={label}
                value={value}
                checked={value === selectedStatus}
                onChange={() => handleSelect(value)}
              />
            ))}
          </li>
        </Collapse>

        <li className="p-4">
          <Button title="Apply" color="primary" className="w-full" onClick={handleApply} />
        </li>
      </Menu>
    </>
  );
};

Filters.propTypes = {
  initialStatus: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

Filters.defaultProps = {
  active: false,
};

export default Filters;
