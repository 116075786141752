export const isStrongPassword = (password) => {
  const hasMinimumLength = password.length >= 8;
  const hasLowerCaseLetter = /[a-z]/.test(password);
  const hasUpperCaseLetter = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  // eslint-disable-next-line no-useless-escape
  const hasSpecialCharacter = /[~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g.test(password);

  return hasMinimumLength && hasLowerCaseLetter && hasUpperCaseLetter && hasNumber && hasSpecialCharacter;
};
