import PropTypes from 'prop-types';

import { ReactComponent as CompletedIcon } from '../../assets/icons/success-check.svg';
import { ReactComponent as IncompleteIcon } from '../../assets/icons/warning-check.svg';

const Step = ({ step, title, subtitle, active, completed, incomplete }) => {
  const renderIcon = () => {
    if (incomplete) {
      return <IncompleteIcon className="size-5" />;
    }

    if (completed) {
      return <CompletedIcon className="size-5" />;
    }

    return (
      <div
        className={`
        flex 
        size-5 
        items-center 
        justify-center 
        rounded-full
        ${active ? 'bg-white-100 ' : 'bg-primary-800'}
      `}
      >
        <span className={`text-caption1 font-semibold ${active ? 'text-primary-700' : 'text-white-100'}`}>{step}</span>
      </div>
    );
  };

  return (
    <div className={`flex space-x-3 ${active || completed ? 'opacity-100' : 'opacity-50'}`}>
      {renderIcon()}
      <div>
        <p className="text-base font-semibold">{title}</p>
        <p className="text-sm">{subtitle}</p>
      </div>
    </div>
  );
};

Step.propTypes = {
  step: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  active: PropTypes.bool,
  completed: PropTypes.bool,
  incomplete: PropTypes.bool,
};

Step.defaultProps = {
  active: false,
  completed: false,
  incomplete: false,
};

export default Step;
