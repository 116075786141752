import { useState } from 'react';
import { Menu } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import Checkbox from '../inputs/Checkbox';
import { donationTypeLabels, donationTypes } from '../../core/constants';

const TypeFilter = ({ initialType, onSelect, active }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [type, setType] = useState(initialType);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (selectedType) => {
    setType((prev) => (prev === selectedType ? '' : selectedType));
  };

  const handleReset = () => {
    onSelect('');
    setType('');
    handleClose();
  };

  const handleApply = () => {
    onSelect(type);
    handleClose();
  };

  return (
    <>
      <div className="flex w-fit">
        <Button
          title="Type"
          className={`w-[110px] justify-between rounded-lg ${active ? 'border-primary-200 !bg-primary-50' : ''}`}
          RightIcon={open ? IconChevronUp : IconChevronDown}
          onClick={handleClick}
        />

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          classes={{ paper: 'w-[180px] !rounded-lg mt-1', list: '!p-0 divide-y divide-gray-50' }}
        >
          <li className="space-y-3 p-4">
            <Checkbox
              id={donationTypes.oneTime}
              name={donationTypes.oneTime}
              label={donationTypeLabels['one-time']}
              value={donationTypes.oneTime}
              onChange={() => handleSelect(donationTypes.oneTime)}
              checked={type === donationTypes.oneTime}
            />

            <Checkbox
              id={donationTypes.recurring}
              name={donationTypes.recurring}
              label={donationTypeLabels.recurring}
              value={donationTypes.recurring}
              onChange={() => handleSelect(donationTypes.recurring)}
              checked={type === donationTypes.recurring}
            />
          </li>

          <li className="flex items-center justify-between p-4">
            <Button title="Reset" onClick={handleReset} />

            <Button title="Apply" color="primary" onClick={handleApply} />
          </li>
        </Menu>
      </div>
    </>
  );
};

TypeFilter.propTypes = {
  initialType: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

TypeFilter.defaultProps = {
  initialType: '',
  active: false,
};

export default TypeFilter;
