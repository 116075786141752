import { useState } from 'react';
import { Dialog } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import * as yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import { getNetworkError } from '../../core/utils';
import IconButton from '../buttons/IconButton';
import Input from '../inputs/Input';
import Button from '../buttons/Button';
import appSettings from '../../app-settings';

const AUTH_URL = `${appSettings.baseUrl}/auth`;

const parsePhoneNumberString = (value) => {
  return value.replace(/[^0-9]/g, '');
};

const formValidation = yup.object({
  smsSent: yup.boolean(),
  phoneNumber: yup.string().when('smsSent', {
    is: true,
    then: yup.string(),
    otherwise: yup
      .string()
      .test({
        name: 'is-valid-phone-number',
        skipAbsent: true,
        test: (value, ctx) => {
          if (parsePhoneNumberString(value).length !== 10) {
            return ctx.createError({
              message: 'Invalid phone number',
            });
          }
          return true;
        },
      })
      .required('Phone number is required.'),
  }),
  code: yup.string().when('smsSent', {
    is: true,
    then: yup
      .string()
      .matches(/^\d{6}$/, 'Invalid code.')
      .required('Code is required.'),
    otherwise: yup.string(),
  }),
});

const EnableSMS2FAModal = ({ open, onClose, onConfirm, onError, onSuccess }) => {
  const [sendingSMS, setSendingSMS] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const form = useForm({
    initialValues: { smsSent: false, phoneNumber: '', code: '' },
    validate: yupResolver(formValidation),
    validateInputOnBlur: true,
  });

  const handlePhoneNumberChange = (event) => {
    const parsedValue = parsePhoneNumberString(event.target.value);
    const charLength = parsedValue.length;
    if (charLength > 10) {
      // phone numbers are 10 digits long
      return;
    }
    let nextValue = parsedValue;
    if (charLength > 6) {
      nextValue = `(${parsedValue.slice(0, 3)}) ${parsedValue.slice(3, 6)}-${parsedValue.slice(6)}`;
    } else if (charLength > 3) {
      nextValue = `(${parsedValue.slice(0, 3)}) ${parsedValue.slice(3)}`;
    }
    form.setFieldValue('phoneNumber', nextValue);
  };

  const handleClose = () => {
    if (sendingSMS || verifying) return;
    onClose();
    form.reset();
  };

  const handleSendCode = async () => {
    const { hasErrors } = form.validateField('phoneNumber');
    if (hasErrors) return;
    setSendingSMS(true);
    try {
      const phoneNumber = `+1${parsePhoneNumberString(form.values.phoneNumber)}`;
      await axios.post(`${AUTH_URL}/sms/send`, { phoneNumber });
      form.setFieldValue('smsSent', true);
      if (onSuccess) onSuccess('Verification code sent successfully!');
    } catch (err) {
      Sentry.captureException(err);
      if (onError) onError(getNetworkError(err));
    }
    setSendingSMS(false);
  };

  const handleVerification = async (values) => {
    setVerifying(true);
    try {
      await axios.post(`${AUTH_URL}/sms/verify`, { code: values.code });
      onConfirm();
      handleClose();
    } catch (err) {
      Sentry.captureException(err);
      if (onError) onError(getNetworkError(err));
    }
    setVerifying(false);
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      sx={{ '.MuiPaper-root': { padding: 0, margin: 0, borderRadius: '12px', width: '100%' } }}
    >
      <form noValidate className="space-y-6 p-6" onSubmit={form.onSubmit(handleVerification)}>
        <div className="relative flex">
          <h3 className="text-h6 font-semibold">Enable Two-Factor Authentication</h3>

          <IconButton
            Icon={<IconX size={20} className="text-gray-400" />}
            srOnly="Close"
            onClick={handleClose}
            color="transparent"
            className="absolute -right-1 -top-1 !p-1"
          />
        </div>

        <p className="text-base">We&apos;ll send a temporary code to verify your phone number.</p>

        <div className="flex w-full items-center space-x-6">
          <Input
            id="phone-number"
            label="Phone number"
            size="xl"
            required
            value={form.values.phoneNumber}
            onChange={handlePhoneNumberChange}
            error={!!form.errors.phoneNumber}
            helperText={form.errors.phoneNumber}
            containerClassName="flex-1"
            disabled={sendingSMS || verifying}
          />

          <Button
            title="Send Code"
            color="primary"
            className="min-w-[120px]"
            titleClassName="whitespace-nowrap"
            onClick={handleSendCode}
            loading={sendingSMS}
            disabled={sendingSMS || verifying}
          />
        </div>

        <Input
          id="verification-code"
          label="Verification code"
          size="xl"
          required
          value={form.values.code}
          onChange={(e) => form.setFieldValue('code', e.target.value)}
          error={!!form.errors.code}
          helperText={form.errors.code}
          disabled={verifying || !form.values.smsSent}
        />

        <Button
          title="Verify"
          type="submit"
          color="primary"
          className="w-full"
          loading={verifying}
          disabled={verifying || !form.values.smsSent}
        />
      </form>
    </Dialog>
  );
};

EnableSMS2FAModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default EnableSMS2FAModal;
