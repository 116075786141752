import { useEffect, useState } from 'react';
import { Menu } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { Button, Checkbox } from 'ui';

import { useSegments } from '../../store/segments/hook';

const SegmentsFilter = (props) => {
  const { selectedSegmentIds, onSelect } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIds, setSelectedIds] = useState(selectedSegmentIds);
  const { loading, segments } = useSegments();

  const open = Boolean(anchorEl);

  useEffect(() => {
    // Needed to reconcile the initial state of the dropdown with the current
    // state of the selected segments ids when the dropdown is closed and reopened.
    // Use case: user toggles local state selection but does not apply the changes.
    if (!open) {
      setSelectedIds(selectedSegmentIds);
    }
  }, [open, selectedSegmentIds]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputChange = (segmentId) => {
    setSelectedIds((prev) => (prev.includes(segmentId) ? prev.filter((id) => id !== segmentId) : [...prev, segmentId]));
  };

  const handleApply = () => {
    onSelect(selectedIds);
    handleClose();
  };

  return (
    <>
      <Button
        title="Segments"
        className={selectedSegmentIds.length > 0 ? 'border-primary-200 !bg-primary-50' : ''}
        RightIcon={open ? IconChevronUp : IconChevronDown}
        onClick={handleClick}
        loading={loading}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{
          paper: 'w-[320px] max-h-[412px] !rounded-lg mt-0.5 !overflow-hidden',
          list: '!p-0',
        }}
      >
        <ul className="max-h-[340px] space-y-3 overflow-y-auto p-4">
          {segments.map((segment) => (
            <li key={segment.segmentId}>
              <Checkbox
                id={segment.segmentId}
                name={segment.name}
                label={segment.name}
                value={segment.segmentId}
                checked={selectedIds.includes(segment.segmentId)}
                onChange={() => handleInputChange(segment.segmentId)}
              />
            </li>
          ))}
        </ul>

        <li className="border-t border-gray-50 p-4">
          <Button title="Apply" color="primary" onClick={handleApply} className="w-full" />
        </li>
      </Menu>
    </>
  );
};

SegmentsFilter.propTypes = {
  selectedSegmentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SegmentsFilter;
