import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TableSorters, TableFilters, Loader, AlertMessage, ActiveFilters } from 'ui';
import { SORT_DIRECTIONS, CAMPAIGN_SEND_STATUS_LABELS, CAMPAIGN_SEND_STATUS } from 'common/constants';
import { sortDataWithDirection } from 'common/utils';

import { campaignSortBy, campaignSortByLabels } from '../core/constants';
import Table from '../components/emails/Table';
import { useCampaigns } from '../store/campaigns/hooks';
import Drawer from '../components/emails/Drawer';

const sortByOptions = [
  { label: campaignSortByLabels[campaignSortBy.sendStatus], value: campaignSortBy.sendStatus },
  { label: campaignSortByLabels[campaignSortBy.updatedAt], value: campaignSortBy.updatedAt },
  { label: campaignSortByLabels[campaignSortBy.name], value: campaignSortBy.name },
];

const filterSections = [
  {
    title: campaignSortByLabels[campaignSortBy.sendStatus],
    propKey: campaignSortBy.sendStatus,
    options: [
      { label: CAMPAIGN_SEND_STATUS_LABELS[CAMPAIGN_SEND_STATUS.draft], value: CAMPAIGN_SEND_STATUS.draft },
      { label: CAMPAIGN_SEND_STATUS_LABELS[CAMPAIGN_SEND_STATUS.scheduled], value: CAMPAIGN_SEND_STATUS.scheduled },
      { label: CAMPAIGN_SEND_STATUS_LABELS[CAMPAIGN_SEND_STATUS.sending], value: CAMPAIGN_SEND_STATUS.sending },
      { label: CAMPAIGN_SEND_STATUS_LABELS[CAMPAIGN_SEND_STATUS.sent], value: CAMPAIGN_SEND_STATUS.sent },
      { label: CAMPAIGN_SEND_STATUS_LABELS[CAMPAIGN_SEND_STATUS.error], value: CAMPAIGN_SEND_STATUS.error },
      { label: CAMPAIGN_SEND_STATUS_LABELS[CAMPAIGN_SEND_STATUS.suggested], value: CAMPAIGN_SEND_STATUS.suggested },
    ],
  },
];

const SuggestedDrafts = () => {
  const [sortBy, setSortBy] = useState(campaignSortBy.updatedAt);
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTIONS.desc);
  const [selectedFilters, setSelectedFilters] = useState({});
  const { suggestedCampaigns, loading, error, success, getSuggestedCampaigns, setError, setSuccess } = useCampaigns();
  const { selectedCampaignId } = useParams();

  useEffect(() => {
    getSuggestedCampaigns();
  }, [getSuggestedCampaigns]);

  const selectedCampaign = suggestedCampaigns.find((campaign) => campaign.campaignId === selectedCampaignId);

  const handleDeleteActiveFilter = (propKey, value) => {
    setSelectedFilters((prev) => ({ ...prev, [propKey]: (prev[propKey] ?? []).filter((val) => val.value !== value) }));
  };

  const activeFilterStringValues = useMemo(
    () =>
      Object.values(selectedFilters)
        .flat()
        .map((val) => val?.value),
    [selectedFilters],
  );

  const data = useMemo(() => {
    let filteredSuggestedDrafts = suggestedCampaigns;

    if (activeFilterStringValues.length > 0) {
      const propKeys = Object.keys(selectedFilters);

      propKeys.forEach((propKey) => {
        if (propKey in filteredSuggestedDrafts[0]) {
          filteredSuggestedDrafts = filteredSuggestedDrafts.filter((suggestedDraft) => {
            const filterValues = selectedFilters[propKey]?.map((filter) => filter.value) ?? [];
            // We're only managing string values of suggested drafts - update to handle different types
            return filterValues.includes(suggestedDraft[propKey]);
          });
        }
      });
    }

    if (sortBy) {
      filteredSuggestedDrafts = sortDataWithDirection(filteredSuggestedDrafts, sortBy, sortDirection);
    }

    return filteredSuggestedDrafts;
  }, [activeFilterStringValues.length, selectedFilters, sortBy, sortDirection, suggestedCampaigns]);

  return (
    <div className="w-full space-y-6">
      <div className="flex justify-between space-x-3">
        <h1 className="text-h3">Suggested Drafts</h1>

        <div className="flex items-center space-x-3">
          <TableSorters
            options={sortByOptions}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
          />

          <TableFilters
            key={activeFilterStringValues.join(',')}
            filterSections={filterSections}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        </div>
      </div>

      {activeFilterStringValues.length > 0 && (
        <ActiveFilters
          filters={selectedFilters}
          onDelete={handleDeleteActiveFilter}
          onReset={() => setSelectedFilters({})}
        />
      )}

      <div className="relative">
        <Table data={data} />

        {loading && <Loader />}
      </div>

      <Drawer selectedCampaign={selectedCampaign} />

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => setSuccess(false)}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </div>
  );
};

export default SuggestedDrafts;
