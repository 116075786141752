import { createSlice } from '@reduxjs/toolkit';
import { subDays, startOfDay, endOfDay } from 'date-fns';

import { DASHBOARD_ACTIVE_CHART } from '../../core/constants';
import { getTimeInSeconds } from '../../core/utils';

import { setError, setSuccess, getDonationsData, setActiveChart, setCampaignId, setDateRange } from './actions';

const sevenDaysAgo = subDays(new Date(), 7);
const yesterday = subDays(new Date(), 1);

const initialState = {
  loading: false,
  error: false,
  success: false,
  donationsData: [],
  activeChart: DASHBOARD_ACTIVE_CHART.donations_usd,
  campaignId: '',
  // Default date range = 'Last 7 Days'
  startDate: getTimeInSeconds(startOfDay(sevenDaysAgo)),
  endDate: getTimeInSeconds(endOfDay(yesterday)),
};

const dashboardStore = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(setActiveChart, (state, { payload }) => {
      state.activeChart = payload;
    });

    builder.addCase(setCampaignId, (state, { payload }) => {
      state.campaignId = payload;
    });

    builder.addCase(setDateRange, (state, { payload: { startDate, endDate } }) => {
      state.startDate = startDate;
      state.endDate = endDate;
    });

    builder.addCase(getDonationsData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.donationsData = payload;
    });

    builder.addCase(getDonationsData.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    });

    builder.addCase(getDonationsData.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message || true;
    });
  },
});

export default dashboardStore.reducer;
