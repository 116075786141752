import { useState } from 'react';
import { Dialog } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as yup from 'yup';
import * as Sentry from '@sentry/react';

import appSettings from '../../app-settings';
import { getNetworkError } from '../../core/utils';
import Button from '../buttons/Button';
import Input from '../inputs/Input';
import IconButton from '../buttons/IconButton';

const DOMAINS_URL = `${appSettings.baseUrl}/domains`;

const sendDNSRecordsEmail = async (email) => {
  await axios.post(`${DOMAINS_URL}/email-dns`, { email });
};

const emailFormValidation = yup.string().required('Email is required.').email('Email is invalid.');

const EmailDNSRecordsModal = ({ open, onClose, setError, setSuccess }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState('');

  const handleClose = () => {
    if (loading) return;
    setEmail('');
    setValidationError('');
    onClose();
  };

  const handleConfirm = async () => {
    setValidationError('');
    setError(false);
    setLoading(true);
    try {
      emailFormValidation.validateSync(email);
      await sendDNSRecordsEmail(email);
      setSuccess('Email sent successfully.');
      handleClose();
    } catch (err) {
      if (['required', 'email'].includes(err.type || '')) {
        setValidationError(err.message);
      } else {
        Sentry.captureException(err);
        setError(getNetworkError(err));
      }
    }
    setLoading(false);
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      sx={{ '.MuiPaper-root': { padding: 0, margin: 0, borderRadius: '12px', width: '100%' } }}
    >
      <div className="space-y-6 p-6">
        <div className="relative flex">
          <h3 className="text-h6 font-semibold">Email DNS Records</h3>

          <IconButton
            Icon={<IconX size={20} className="text-gray-400" />}
            srOnly="Close"
            onClick={handleClose}
            color="transparent"
            className="absolute -right-1 -top-1 !p-1"
          />
        </div>

        <Input
          id="dns-email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          disabled={loading}
          error={!!validationError}
          helperText={validationError}
          size="xl"
        />

        <div className="flex justify-end space-x-3">
          <Button title="Cancel" onClick={handleClose} disabled={loading} />

          <Button title="Confirm" color="primary" onClick={handleConfirm} loading={loading} disabled={loading} />
        </div>
      </div>
    </Dialog>
  );
};

EmailDNSRecordsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
};

export default EmailDNSRecordsModal;
