import { useState } from 'react';
import { Menu, MenuItem, Collapse } from '@mui/material';
import { IconFilter, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import Checkbox from '../inputs/Checkbox';
import { campaignSendStatus, campaignSendStatusLabels } from '../../core/constants';

const getButtonClassName = (open, active) => {
  if (open) return 'text-primary-600';
  if (active) return 'text-primary-700 !bg-primary-50 border-primary-200';
  return '';
};

const Filters = ({ sendStatusFilters, setSendStatusFilters }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [stageOpen, setStageOpen] = useState(true);
  const [selectedSendStatus, setSelectedSendStatus] = useState(sendStatusFilters);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectSendStatus = (sendStatus) => {
    if (selectedSendStatus.includes(sendStatus)) {
      setSelectedSendStatus((prev) => prev.filter((s) => s !== sendStatus));
      return;
    }
    setSelectedSendStatus((prev) => [...prev, sendStatus]);
  };

  const handleApplyFilters = () => {
    setSendStatusFilters(selectedSendStatus);
    handleClose();
  };

  return (
    <>
      <Button
        title="Filters"
        LeftIcon={IconFilter}
        onClick={handleClick}
        className={getButtonClassName(open, selectedSendStatus.length > 0)}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: 'w-[220px] !rounded-lg mt-0.5', list: '!p-0 divide-y divide-gray-50' }}
      >
        <li className="flex items-center justify-between px-4 py-3">
          <p className="text-base font-semibold text-gray-950">Filters</p>
          <button className="text-sm text-gray-600" onClick={() => setSelectedSendStatus([])}>
            Reset all
          </button>
        </li>

        <MenuItem
          classes={{ root: '!justify-between !py-3 !border-solid' }}
          onClick={() => setStageOpen((prev) => !prev)}
        >
          <p className="text-caption1 font-semibold uppercase text-gray-950">Stage</p>
          {stageOpen ? (
            <IconChevronDown size={16} className="text-gray-600" />
          ) : (
            <IconChevronUp size={16} className="text-gray-600" />
          )}
        </MenuItem>

        <Collapse in={stageOpen}>
          <li className="space-y-3 px-4 py-3">
            {Object.keys(campaignSendStatus).map((sendStatus) => (
              <Checkbox
                key={`checkbox-${sendStatus}`}
                id={sendStatus}
                label={campaignSendStatusLabels[sendStatus]}
                value={sendStatus}
                checked={selectedSendStatus.includes(sendStatus)}
                onChange={() => handleSelectSendStatus(sendStatus)}
              />
            ))}
          </li>
        </Collapse>

        <li className="p-4">
          <Button title="Apply" color="primary" className="w-full" onClick={handleApplyFilters} />
        </li>
      </Menu>
    </>
  );
};

Filters.propTypes = {
  sendStatusFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSendStatusFilters: PropTypes.func.isRequired,
};

export default Filters;
