import PropTypes from 'prop-types';

import { reportStatus, reportStatusLabels } from '../../core/constants';

const getContainerColorClassNames = (status) => {
  switch (status) {
    case reportStatus.sending:
      return 'border-green-100 bg-green-50 text-green-500';
    case reportStatus.sent:
    case reportStatus.stage3:
      return 'border-purple-100 bg-purple-50 text-purple-500';
    case reportStatus.failure:
      return 'border-red-100 bg-red-50 text-red-500';
    case reportStatus.scheduled:
      return 'border-orange-200 bg-orange-50 text-orange-700';
    default:
      // reportStatus.draft
      // reportStatus.stage0
      // reportStatus.stage1
      // reportStatus.stage2
      return 'border-gray-100 bg-gray-10 text-gray-500';
  }
};

const StatusLabel = ({ status }) => {
  return (
    <div className={`w-fit rounded border px-1.5 py-0.5 ${getContainerColorClassNames(status)}`}>
      <p className="text-caption1 font-semibold uppercase">{reportStatusLabels[status]}</p>
    </div>
  );
};

StatusLabel.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusLabel;
