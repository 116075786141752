import PropTypes from 'prop-types';

import { useImports } from '../../store/imports/hooks';
import Button from '../buttons/Button';
import { IMPORT_TYPES } from '../../core/constants';

const SuccessStep = ({ onClose }) => {
  const { onboarding, newImport } = useImports();

  return (
    <div className="w-full max-w-xl space-y-6 py-3">
      <div className="space-y-2">
        <h1 className="text-3xl font-medium">
          We&apos;re importing your {newImport.importType === IMPORT_TYPES.contacts ? 'contacts' : 'suppression list'}{' '}
          now
        </h1>

        <p className="text-base">You can leave this page.</p>
      </div>

      <Button title={onboarding ? 'Continue' : 'Visit Imports Overview'} onClick={onClose} color="primary" />
    </div>
  );
};

SuccessStep.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SuccessStep;
