import { createSlice } from '@reduxjs/toolkit';

import { setError, setSuccess, getReports } from './actions';

const initialState = {
  loading: false,
  error: false,
  success: false,
  reports: [],
};

const reportsStore = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(getReports.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.reports = payload;
    });

    builder.addCase(getReports.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    });

    builder.addCase(getReports.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message || true;
    });
  },
});

export default reportsStore.reducer;
