import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Loader } from 'ui';

const PageEditor = forwardRef(({ pageUrl, loading, onLoad }, ref) => {
  const [editorLoading, setEditorLoading] = useState(true);

  const handleLoad = async () => {
    try {
      await onLoad();
      setEditorLoading(false);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  return (
    <div className="relative size-full bg-white-100">
      <iframe src={pageUrl} width="100%" height="100%" onLoad={handleLoad} ref={ref} />
      {(loading || editorLoading) && <Loader />}
    </div>
  );
});

PageEditor.displayName = 'PageEditor';

PageEditor.propTypes = {
  pageUrl: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default PageEditor;
