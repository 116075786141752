import axios from 'axios';

import appSettings from '../app-settings';

const DONATIONS_URL = `${appSettings.baseUrl}/donations`;

export const getDonations = async () => {
  const res = await axios.get(DONATIONS_URL);
  return res.data;
};

export const refundDonation = async (donationId) => {
  await axios.post(`${DONATIONS_URL}/refund/${donationId}`);
};

export const resendReceipt = async (donationId) => {
  await axios.post(`${DONATIONS_URL}/resend-receipt/${donationId}`);
};

export const cancelSubscription = async (donationId) => {
  await axios.post(`${DONATIONS_URL}/cancel-subscription/${donationId}`);
};

export const getDonorDonations = async (donorId) => {
  const res = await axios.get(`${DONATIONS_URL}/donor/${donorId}`);
  return res.data;
};

export const createDonationsExportJob = async ({ exportFilename, filters }) => {
  await axios.post(`${DONATIONS_URL}/export`, { exportFilename, filters });
};

export const getStripeAccountSession = async () => {
  const response = await axios.get(`${DONATIONS_URL}/stripe-account-session`);
  return response.data;
};
