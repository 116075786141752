import { IconX, IconTrash } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import { ReactComponent as PendingFileIcon } from '../../assets/icons/pending-file.svg';
import { ReactComponent as SavedFileIcon } from '../../assets/icons/saved-file.svg';
import { ReactComponent as FailedFileIcon } from '../../assets/icons/failed-file.svg';
import IconButton from '../buttons/IconButton';
import ProgressBar from '../progress-bar/ProgressBar';
import Spinner from '../loader/Spinner';
import { FILE_STATUS } from '../../core/constants';

const FileIcon = ({ status }) => {
  if (status === FILE_STATUS.pending) {
    return <PendingFileIcon className="size-6" />;
  }

  if (status === FILE_STATUS.saved) {
    return <SavedFileIcon className="size-6" />;
  }

  return <FailedFileIcon className="size-6" />;
};

FileIcon.propTypes = {
  status: PropTypes.oneOf([FILE_STATUS.pending, FILE_STATUS.saved, FILE_STATUS.failed]).isRequired,
};

const FileAction = ({ status, progress, onDelete, onCancel, errorMessage }) => {
  if (status === FILE_STATUS.pending) {
    return (
      <div className="flex items-center space-x-2">
        <ProgressBar progress={progress} />
        <IconButton
          Icon={<IconX size={18} className="text-gray-400" />}
          color="transparent"
          className="!p-1"
          srOnly="Cancel upload"
          onClick={onCancel}
        />
      </div>
    );
  }

  if (status === FILE_STATUS.saved) {
    return (
      <IconButton
        Icon={<IconTrash size={16} className="text-gray-400" />}
        color="transparent"
        className="!p-1"
        onClick={onDelete}
        srOnly="Delete file"
      />
    );
  }

  return <p className="text-sm font-medium text-red-500">{errorMessage}</p>;
};

FileAction.propTypes = {
  status: PropTypes.oneOf([FILE_STATUS.pending, FILE_STATUS.saved, FILE_STATUS.failed]).isRequired,
  progress: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  errorMessage: PropTypes.string,
};

FileAction.defaultProps = {
  errorMessage: 'Uploading failed',
  onCancel: () => {},
};

const FileInfo = ({ name, size, status, progress, loading, onDelete, onCancel, errorMessage, withAction }) => {
  const renderAction = () => {
    if (!withAction) return null;

    if (loading) {
      return <Spinner className="mr-0 size-4" />;
    }

    return (
      <FileAction
        status={status}
        progress={progress}
        onDelete={onDelete}
        onCancel={onCancel}
        errorMessage={errorMessage}
      />
    );
  };

  return (
    <div className="flex h-8 w-full items-center justify-between rounded-lg border border-gray-100 p-2">
      <div className="flex items-center space-x-2">
        <FileIcon status={status} />
        <p className="text-sm font-medium">{name}</p>
        {!!size && <p className="text-sm text-gray-400">{(size / (1024 * 1024)).toFixed(1)}MB</p>}
      </div>
      {renderAction()}
    </div>
  );
};

FileInfo.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.oneOf([FILE_STATUS.pending, FILE_STATUS.saved, FILE_STATUS.failed]).isRequired,
  size: PropTypes.number,
  progress: PropTypes.number,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
  withAction: PropTypes.bool,
};

FileInfo.defaultProps = {
  onDelete: () => {},
  onCancel: () => {},
  progress: 0,
  loading: false,
  size: 0,
  withAction: true,
};

export default FileInfo;
