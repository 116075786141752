import { IconChevronRight } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';

import { useDashboard } from '../../store/dashboard/hooks';
import Button from '../buttons/Button';
import { donationTypeLabels } from '../../core/constants';
import { parseDonationAmount } from '../../core/utils';
import StatusLabel from '../donations/StatusLabel';
import Loader from '../loader/Loader';

const DonationHistory = () => {
  const navigate = useNavigate();
  const { loading, donations } = useDashboard();

  const last7Donations = donations.slice(0, 7);

  return (
    <div className="space-y-2 rounded-xl bg-white-100 px-6 py-4">
      <h3 className="text-lg font-semibold">Donation History</h3>

      <div className="relative !mt-4 overflow-x-auto">
        <table className="donations-table w-full text-left text-base text-gray-950">
          <thead className="text-sm uppercase text-gray-400">
            <tr>
              <th className="px-4 py-2">Date</th>
              <th className="px-4 py-2">Donor</th>
              <th className="px-4 py-2">Campaign</th>
              <th className="px-4 py-2">Type</th>
              <th className="px-4 py-2">Amount</th>
              <th className="px-4 py-2">Status</th>
            </tr>
          </thead>
          <tbody>
            {last7Donations.map((donation) => (
              <tr key={donation.donationId} className="h-[52px] border-b border-gray-50">
                <td className="px-4 py-2">
                  {donation.createdAt ? format(new Date(donation.createdAt * 1000), 'd MMM yyyy') : ''}
                </td>
                <td className="px-4 py-2">{donation.donorName}</td>
                <td className="px-4 py-2">{donation.campaignName}</td>
                <td className="px-4 py-2">{donationTypeLabels[donation.type]}</td>
                {/* netAmount is in cents */}
                <td className="px-4 py-2">{parseDonationAmount(donation.netAmount / 100)}</td>
                <td className="px-4 py-2">
                  <StatusLabel status={donation.status} />
                </td>
              </tr>
            ))}
            {last7Donations.length === 0 &&
              Array(5)
                .fill('')
                .map((row, index) => (
                  <tr key={`emptyRow-${index}`} className="h-[52px] border-b border-gray-50">
                    <td className="px-4 py-2" colSpan={6}></td>
                  </tr>
                ))}
          </tbody>
        </table>

        {loading && <Loader />}
      </div>

      <div className="flex items-center justify-center">
        <Button
          title="View all"
          color="primary"
          variant="outlined"
          RightIcon={IconChevronRight}
          iconProps={{ size: 16 }}
          onClick={() => navigate('/donations')}
          className="border-0 !px-3  !py-2 !shadow-none	focus:!ring-0 focus:ring-offset-0"
        />
      </div>
    </div>
  );
};

export default DonationHistory;
