import { useMemo, useState, useEffect } from 'react';
import Collapse from '@mui/material/Collapse';
import PropTypes from 'prop-types';
import { IconChevronDown, IconChevronUp, IconEye } from '@tabler/icons-react';
import { useForm, yupResolver } from '@mantine/form';
import debounce from 'lodash.debounce';

import Button from '../buttons/Button';
import FormInput from './FormInput';
import { useTransactional } from '../../store/transactional/hooks';
import {
  DEBOUNCE_DELAY_TIME_MS,
  donationTypes,
  MAP_TAG_NAME_TO_ACCOUNT_INFO,
  MAP_TRANSACTIONAL_EMAILS_TO_TEMPLATE_NAME,
  PREVIEW_HTML_KEY,
  TRANSACTIONAL_EMAILS,
} from '../../core/constants';
import ImageInput from './ImageInput';
import {
  getCustomSubstitutionTagDefaultValues,
  replaceCustomTagsWithSendgridTags,
  substituteTagWithValue,
} from '../../core/utils';
import TextEditorInput from './TextEditorInput';
import { useAccount } from '../../store/account/hooks';
import { emailValidation } from './validation';
import appSettings from '../../app-settings';
import EmailStatus from './EmailStatus';

const ExpandableForm = ({ isExpanded, onClick, title, keyAccessor, setImageFiles }) => {
  const [editorKey, setEditorKey] = useState(Date.now().toString());
  const { emails, updateTransactionalEmail } = useTransactional();
  const { account } = useAccount();

  const initialValues = emails[keyAccessor];

  const form = useForm({
    initialValues,
    validate: yupResolver(emailValidation),
    validateInputOnChange: true,
  });

  useEffect(() => {
    let logo = initialValues.image;

    if (initialValues.image.includes('placeholder')) {
      if (account?.emailPreferences?.logo && !account.emailPreferences.logo.includes('placeholder')) {
        logo = account.emailPreferences.logo;
      } else if (account?.logoUrl) {
        logo = account.logoUrl;
      }
    }

    if (logo !== initialValues.image) {
      form.setFieldValue('image', logo);
      updateTransactionalEmail({ email: keyAccessor, image: logo });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    account?.emailPreferences?.logo,
    keyAccessor,
    initialValues.image,
    account?.logoUrl,
    updateTransactionalEmail,
    form.setFieldValue,
  ]);

  const debouncedUpdateTransactionalEmail = useMemo(
    () => debounce(updateTransactionalEmail, DEBOUNCE_DELAY_TIME_MS),
    [updateTransactionalEmail],
  );

  const handlePreview = async (e) => {
    e.stopPropagation();

    const templateName = MAP_TRANSACTIONAL_EMAILS_TO_TEMPLATE_NAME[keyAccessor];

    let html = (await import(`./templates/${templateName}.html?raw`))?.default;

    if (!html) return;

    html = html.replace(
      /<div id="main"(.*?)<\/div>/,
      `
      <div style="line-height: 140%; text-align: left; word-wrap: break-word; font-family: Lato, sans-serif; font-size: 16px; color:{{TextColor}}">
        ${form.values.text}
      </div>
      <br />
    `,
    );

    const customTagDefaultValues = getCustomSubstitutionTagDefaultValues(html);
    html = replaceCustomTagsWithSendgridTags(html);

    const testDonationAmount = 250;
    let donationType = donationTypes.recurring;

    if (keyAccessor === TRANSACTIONAL_EMAILS.oneTimeFailed) {
      donationType = donationTypes.oneTime;
    }

    const donationUrl = account?.donationPageSubdomain
      ? `https://${account?.donationPageSubdomain}.${appSettings.donationPageDomain}/?amount=${250}&type=${donationType}`
      : '';

    const substitutionTags = {
      ...customTagDefaultValues,
      Heading: form.values.heading,
      PrimaryColor: account?.emailPreferences?.primaryColor,
      BodyBackground: account?.emailPreferences?.bodyBackground,
      HeaderColor: account?.emailPreferences?.headerColor,
      Logo: form.values.image || account?.emailPreferences?.logo,
      TextColor: account?.emailPreferences?.textColor,
      FooterBackground: account?.emailPreferences?.footerBackground,
      FooterTextColor: account?.emailPreferences?.footerTextColor,
      FirstName: 'friend',
      DonationAmount: testDonationAmount.toFixed(2),
      DonationUrl: donationUrl,
    };

    Object.keys(customTagDefaultValues).forEach((tagName) => {
      const value = substitutionTags[tagName] || account[MAP_TAG_NAME_TO_ACCOUNT_INFO[tagName]] || '';
      html = substituteTagWithValue(html, tagName, value);
    });

    localStorage.setItem(PREVIEW_HTML_KEY, html);
    window.open('/template-preview', '_blank');
  };

  const handleChange = (field, value) => {
    if (field === 'image') {
      const url = URL.createObjectURL(value);
      setImageFiles((prevState) => ({ ...prevState, [keyAccessor]: value }));
      form.setFieldValue(field, url);
      debouncedUpdateTransactionalEmail({ email: keyAccessor, [field]: url });
      return;
    }
    form.setFieldValue(field, value);
    debouncedUpdateTransactionalEmail({ email: keyAccessor, [field]: value });
  };

  const handleCancel = (field) => {
    const initialValue = emails[keyAccessor][field] ?? '';
    form.setFieldValue(field, initialValue);
    debouncedUpdateTransactionalEmail({ email: keyAccessor, [field]: initialValue });
    setTimeout(() => form.clearFieldError(field), 0);
    if (field === 'text') {
      setEditorKey(Date.now().toString());
    }
  };

  const headerColorClassName = form.values.send ? 'text-gray-950' : 'text-gray-400';

  return (
    <div className="w-full px-4 py-2">
      <div className="flex cursor-pointer items-center justify-between" onClick={onClick}>
        <div className="flex flex-1 space-x-2">
          {isExpanded ? (
            <IconChevronUp size={20} className={`mt-0.5 ${headerColorClassName}`} />
          ) : (
            <IconChevronDown size={20} className={`mt-0.5 ${headerColorClassName}`} />
          )}
          <div className="space-y-2">
            <p className={`font-bold ${headerColorClassName}`}>{title}</p>
            {isExpanded && <EmailStatus enabled={form.values.send} />}
          </div>
        </div>
        {isExpanded && <Button title="Preview" color="primary" onClick={handlePreview} LeftIcon={IconEye} />}
      </div>
      <Collapse in={isExpanded}>
        <div className="space-y-6 pb-4 pl-8 pr-2 pt-8">
          <FormInput
            id="subject"
            label="Subject"
            value={form.values.subject}
            onChange={(e) => handleChange('subject', e.target.value)}
            error={form.errors.subject}
            onReset={() => handleCancel('subject')}
            required
          />
          <ImageInput
            value={form.values.image}
            onChange={(file) => handleChange('image', file)}
            onReset={() => handleCancel('image')}
            required
          />
          <FormInput
            id="heading"
            label="Heading"
            value={form.values.heading}
            onChange={(e) => handleChange('heading', e.target.value)}
            error={form.errors.heading}
            onReset={() => handleCancel('heading')}
            required
          />
          <TextEditorInput
            key={editorKey}
            id={keyAccessor}
            label="Text"
            value={form.values.text}
            onChange={(value) => handleChange('text', value)}
            error={form.errors.text}
            onReset={() => handleCancel('text')}
            required
          />
        </div>
      </Collapse>
    </div>
  );
};

ExpandableForm.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  keyAccessor: PropTypes.string.isRequired,
  setImageFiles: PropTypes.func.isRequired,
};

export default ExpandableForm;
