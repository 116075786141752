import axios from 'axios';
import * as Sentry from '@sentry/react';
import { getNetworkError } from 'common/utils';

import appSettings from '../app-settings';

const CONTACTS_URL = `${appSettings.baseUrl}/contacts`;
const TAGS_URL = `${appSettings.baseUrl}/tags`;

export const getContact = async (id, archived = false) => {
  try {
    const res = await axios.get(`${CONTACTS_URL}/${id}`, { params: { archived } });
    return res.data;
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
};

export const deleteContactTag = async (tagId, contactId) => {
  await axios.delete(`${TAGS_URL}/${tagId}/contacts`, {
    data: { contact_ids: [contactId] },
  });
};

export const archiveContacts = async (contactIds) => {
  await axios.patch(`${CONTACTS_URL}/archive`, { contactIds });
};

export const unarchiveContacts = async (contactIds) => {
  await axios.patch(`${CONTACTS_URL}/unarchive`, { contactIds });
};
