import { useEffect } from 'react';

import AccountInfoForm from '../components/account-info/AccountInfoForm';
import { useAccount } from '../store/account/hooks';

const AccountInfo = () => {
  const { getAccount } = useAccount();

  useEffect(() => {
    getAccount();
  }, [getAccount]);

  return (
    <div className="relative w-full space-y-6">
      <h1 className="text-h3 dark:text-white-100">Account Info</h1>

      <AccountInfoForm />
    </div>
  );
};

export default AccountInfo;
