import { useEffect, useMemo } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';

import { useCampaigns } from '../../../store/campaigns/hooks';
import { DEBOUNCE_DELAY_TIME_MS } from '../../../core/constants';
import HelperText from '../../inputs/HelperText';
import DatePicker from '../DatePicker';
import TimePicker from '../TimePicker';

const getInitialValues = (sendTime) => {
  if (sendTime) {
    return new Date(sendTime);
  }
  const defaultDate = new Date();
  defaultDate.setHours(defaultDate.getHours() + 1);
  return defaultDate;
};

// eslint-disable-next-line react-refresh/only-export-components
export const timeFormValidation = yup.object({
  sendTime: yup
    .date()
    .required('Send time is required.')
    .test('is-future-date', 'Send time must be a date and time at least 15 minutes into the future.', (value) =>
      value ? value.getTime() > Date.now() + 1000 * 60 * 15 : false,
    ),
});

const TimeForm = ({ disabled }) => {
  const { campaignDetails, updateCampaignDetails } = useCampaigns();
  const form = useForm({
    initialValues: {
      sendTime: getInitialValues(campaignDetails.sendTime),
    },
    validate: yupResolver(timeFormValidation),
    validateInputOnChange: true,
  });

  useEffect(() => {
    if (disabled) return;
    if (form.values.sendTime) {
      // Update initial value
      updateCampaignDetails({ sendTime: form.values.sendTime.toISOString() });
    }
    form.validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedUpdateCampaignDetails = useMemo(
    () => debounce(updateCampaignDetails, DEBOUNCE_DELAY_TIME_MS),
    [updateCampaignDetails],
  );

  const handleChange = (date) => {
    form.setFieldValue('sendTime', date);
    debouncedUpdateCampaignDetails({ sendTime: date.toISOString() });
  };

  return (
    <form>
      <div className="flex items-center space-x-3">
        <DatePicker value={form.values.sendTime} onChange={handleChange} required disabled={disabled} />

        <TimePicker value={form.values.sendTime} onChange={handleChange} required disabled={disabled} />
      </div>
      {!!form.errors.sendTime && <HelperText message={form.errors.sendTime} error containerClassName="mt-1.5" />}
    </form>
  );
};

TimeForm.propTypes = {
  disabled: PropTypes.bool,
};

TimeForm.defaultProps = {
  disabled: false,
};

export default TimeForm;
