import { useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import * as Sentry from '@sentry/react';
import CustomDialog from '../modals/CustomDialog';
import Button from '../buttons/Button';
import Input from '../inputs/Input';

// eslint-disable-next-line react-refresh/only-export-components
export const nameFormValidation = yup
  .string()
  .required('Name is required.')
  .min(2, 'Name is invalid.')
  .test('untitled', 'Name cannot be "Untitled"', (val) => val?.trim().toLocaleLowerCase() !== 'untitled');

const SegmentNameModal = ({ open, onClose, initialName, initialError = '', updateName, entity = 'segment' }) => {
  const [name, setName] = useState(initialName);
  const [error, setError] = useState(initialError);

  const handleClose = () => {
    onClose();
    setName('');
    setError('');
  };

  const handleUpdate = () => {
    try {
      setError('');
      nameFormValidation.validateSync(name);
      updateName(name);
      onClose();
    } catch (err) {
      Sentry.captureException(err);
      setError(err.message);
    }
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      title={`Change ${entity} Name`}
      containerClassName="sm:min-w-[448px]"
      titleClassName="!text-left !text-2xl"
    >
      <Input
        id={`${entity}-name`}
        label="New Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder={`New ${entity}`}
        required
        error={!!error}
        helperText={error}
        containerClassName="mb-8 mt-2"
      />

      <div className="flex w-full items-center space-x-4">
        <Button title="Cancel" onClick={onClose} />

        <Button title="Confirm" color="primary" onClick={handleUpdate} />
      </div>
    </CustomDialog>
  );
};

SegmentNameModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialName: PropTypes.string.isRequired,
  initialError: PropTypes.string,
  updateName: PropTypes.func.isRequired,
  entity: PropTypes.string,
};

export default SegmentNameModal;
