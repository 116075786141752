import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  setError,
  setSuccess,
  getContactFields,
  getContacts,
  deleteContacts,
  createContact,
  unsubscribeContact,
  updateContactsList,
  updateContact,
  archiveContacts,
  unarchiveContacts,
} from './actions';

const initialState = {
  fields: {
    loading: false,
    error: false,
    data: [],
  },
  loading: false,
  error: false,
  success: false,
  data: {
    page: 1,
    totalPages: 0,
    limit: 10,
    count: 0,
    contacts: [],
  },
  totalCount: 0,
};

const contactsStore = createSlice({
  name: 'contacts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(updateContactsList, (state, { payload }) => {
      state.data.contacts = payload;
    });

    builder.addCase(getContactFields.pending, (state) => {
      state.fields.error = false;
      state.fields.loading = true;
    });

    builder.addCase(getContactFields.fulfilled, (state, { payload }) => {
      state.fields.loading = false;
      state.fields.data = payload;
    });

    builder.addCase(getContactFields.rejected, (state, { error }) => {
      state.fields.loading = false;
      state.fields.error = error.message || true;
    });

    builder.addCase(getContacts.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
      state.totalCount = payload.totalCount;
    });

    builder.addCase(deleteContacts.fulfilled, (state) => {
      state.loading = false;
      state.success = 'Contact(s) successfully deleted!';
    });

    builder.addCase(createContact.fulfilled, (state) => {
      state.loading = false;
      state.success = 'Contact successfully created!';
    });

    builder.addCase(unsubscribeContact.fulfilled, (state) => {
      state.loading = false;
      state.success = 'Contact successfully unsubscribed!';
    });

    builder.addCase(updateContact.fulfilled, (state) => {
      state.loading = false;
      state.success = 'Contact successfully updated!';
    });

    builder.addCase(archiveContacts.fulfilled, (state) => {
      state.loading = false;
      state.success = 'Contact(s) successfully archived!';
    });

    builder.addCase(unarchiveContacts.fulfilled, (state) => {
      state.loading = false;
      state.success = 'Contact(s) successfully unarchived!';
    });

    builder.addMatcher(
      isAnyOf(
        getContacts.pending,
        deleteContacts.pending,
        createContact.pending,
        unsubscribeContact.pending,
        updateContact.pending,
        archiveContacts.pending,
        unarchiveContacts.pending,
      ),
      (state) => {
        state.error = false;
        state.loading = true;
      },
    );

    builder.addMatcher(
      isAnyOf(
        getContacts.rejected,
        deleteContacts.rejected,
        createContact.rejected,
        unsubscribeContact.rejected,
        updateContact.rejected,
        archiveContacts.rejected,
        unarchiveContacts.rejected,
      ),
      (state, { error }) => {
        state.loading = false;
        state.error = error.message || true;
      },
    );
  },
});

export default contactsStore.reducer;
