import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { IconAlertCircleFilled } from '@tabler/icons-react';
import { Tooltip } from '@mui/material';

import { useTags } from '../../../store/tags/hook';
import { useCampaigns } from '../../../store/campaigns/hooks';
import { useSegments } from '../../../store/segments/hook';
import Spinner from '../../loader/Spinner';
import MultipleSelectInput from '../../inputs/MultipleSelectInput';
import SwitchInput from '../../inputs/SwitchInput';
import { getNetworkError } from '../../../core/utils';
import useIsMounted from '../../../hooks/useIsMounted';
import { getAudienceSizeCount } from '../../../api';

const DEBOUNCE_DELAY_TIME_MS = 750;

const MAX_CONDITIONS = 5;

const ToForm = ({ disabled }) => {
  const { updateCampaignDetails, campaignDetails, setError } = useCampaigns();
  const [selectedAudience, setSelectedAudience] = useState(() => {
    const audienceIds = [...(campaignDetails.sendTo.tagIds ?? []), ...(campaignDetails.sendTo.segmentIds ?? [])];
    if (campaignDetails.segmentId) {
      audienceIds.push(campaignDetails.segmentId);
    }
    if (campaignDetails.sendTo.all) {
      audienceIds.push('all');
    }
    return audienceIds;
  });
  const [excludeContacts, setExcludeContacts] = useState(
    () => !!(campaignDetails.exclude?.segmentIds?.length || campaignDetails.exclude?.tagIds?.length),
  );
  const [excludedSegments, setExcludedSegments] = useState(() => [...(campaignDetails.exclude?.segmentIds ?? [])]);
  const [excludedTags, setExcludedTags] = useState(() => [...(campaignDetails.exclude?.tagIds ?? [])]);
  const [audienceSize, setAudienceSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [audienceSizeWarning, setAudienceSizeWarning] = useState(false);
  const { tags } = useTags();
  const { segments } = useSegments();
  const isMounted = useIsMounted();

  const audience = useMemo(() => {
    return {
      all: !!selectedAudience.includes('all'),
      tagIds: selectedAudience.filter((audience) => !!tags.find((tag) => tag.id === audience)),
      segmentIds: selectedAudience.filter((audience) => !!segments.find((segment) => segment.segmentId === audience)),
      excludeSegmentIds: excludedSegments,
      excludeTagIds: excludedTags,
    };
  }, [selectedAudience, tags, segments, excludedSegments, excludedTags]);

  const updateCampaignAudience = useCallback(() => {
    if (disabled) return;
    const update = {
      sendTo: {
        all: audience.all,
        tagIds: audience.tagIds,
        segmentIds: audience.segmentIds,
      },
      exclude: {
        tagIds: audience.excludeTagIds,
        segmentIds: audience.excludeSegmentIds,
      },
    };
    updateCampaignDetails(update);
  }, [audience, updateCampaignDetails, disabled]);

  const getAudienceSize = useCallback(async () => {
    if (disabled) return;
    if (!selectedAudience.length && !excludedSegments.length && !excludedTags.length) {
      if (isMounted()) setAudienceSize(0);
      return;
    }
    if (isMounted()) setLoading(true);
    try {
      const audienceCount = await getAudienceSizeCount({
        segmentIds: audience.segmentIds,
        tagIds: audience.tagIds,
        excludeSegmentIds: audience.excludeSegmentIds,
        excludeTagIds: audience.excludeTagIds,
      });
      if (isMounted()) {
        setAudienceSize(audienceCount);
        setAudienceSizeWarning(audienceCount === 0);
      }
    } catch (err) {
      if (isMounted()) setError(getNetworkError(err));
    }
    if (isMounted()) setLoading(false);
  }, [
    disabled,
    selectedAudience.length,
    excludedSegments.length,
    excludedTags.length,
    isMounted,
    audience.segmentIds,
    audience.tagIds,
    audience.excludeSegmentIds,
    audience.excludeTagIds,
    setError,
  ]);

  const getCampaignAudienceSize = useCallback(() => {
    updateCampaignAudience();
    getAudienceSize();
  }, [updateCampaignAudience, getAudienceSize]);

  const debouncedGetAudienceSize = useMemo(
    () => debounce(getCampaignAudienceSize, DEBOUNCE_DELAY_TIME_MS),
    [getCampaignAudienceSize],
  );

  useEffect(() => {
    debouncedGetAudienceSize();
  }, [debouncedGetAudienceSize]);

  const audienceSections = useMemo(() => {
    let sections = [];
    if (tags.length > 0) {
      sections.push({
        title: 'Tags',
        options: tags.map((tag) => ({ id: tag.id, name: tag.name, type: 'tag' })),
      });
    }
    if (segments.length > 0) {
      sections.push({
        title: 'Segments',
        options: segments.map((segment) => ({ id: segment.segmentId, name: segment.name, type: 'segment' })),
      });
    }
    sections.push({ title: 'All', options: [{ id: 'all', name: 'All Contacts', type: 'all' }] });
    return sections;
  }, [tags, segments]);

  // "All Contacts" is not considered to be a condition
  const totalConditions = [...selectedAudience.filter((sA) => sA !== 'all'), ...excludedSegments, ...excludedTags]
    .length;

  const handleExcludeContacts = (exclude) => {
    if (disabled) return;
    setExcludeContacts(exclude);
    if (!exclude) {
      setExcludedTags([]);
      setExcludedSegments([]);
    }
  };

  const handleSelectAudience = (values) => {
    if (disabled) return;

    let audienceIds = values;

    if (values.slice(-1)[0] === 'all') {
      audienceIds = ['all'];
    } else if (values.includes('all')) {
      audienceIds = values.filter((v) => v !== 'all');
    }

    if (!audienceIds.length && (excludedSegments.length || excludedTags.length)) {
      audienceIds = ['all'];
    }

    if (
      !audienceIds.includes('all') &&
      audienceIds.length > selectedAudience.length &&
      totalConditions === MAX_CONDITIONS
    ) {
      if (audienceIds.length !== 2) {
        return;
      }
      audienceIds = audienceIds.slice(-1);
    }

    setSelectedAudience(audienceIds);
    setExcludedSegments((prevState) => prevState.filter((eS) => !audienceIds.includes(eS)));
    setExcludedTags((prevState) => prevState.filter((eT) => !audienceIds.includes(eT)));
  };

  const updateExcludedAudience = (excludedIds) => {
    setSelectedAudience((prevState) => {
      if (!prevState.length) return ['all'];
      return prevState.filter((sA) => !excludedIds.includes(sA));
    });
  };

  const handleSelectExcludedSegments = (values) => {
    if (disabled || (values.length > excludedSegments.length && totalConditions === MAX_CONDITIONS)) {
      return;
    }

    setExcludedSegments(values);
    updateExcludedAudience(values);
  };

  const handleSelectExcludedTags = (values) => {
    if (disabled || (values.length > excludedTags.length && totalConditions === MAX_CONDITIONS)) {
      return;
    }

    setExcludedTags(values);
    updateExcludedAudience(values);
  };

  return (
    <form className="space-y-4">
      <div className="flex w-full space-x-6">
        <MultipleSelectInput
          id="audience"
          label="Select Audience"
          sections={audienceSections}
          value={selectedAudience}
          onChange={handleSelectAudience}
          containerClassName="flex-1 max-w-[290px]"
          disabled={disabled}
        />

        {!disabled && (
          <div className="flex flex-1 items-center space-x-2">
            <p className="text-base italic">
              Estimated audience size: <span className="font-semibold">{audienceSize} contacts</span>
            </p>
            {loading && <Spinner className="size-5" />}
            {!loading && !!selectedAudience.length && audienceSizeWarning && (
              <Tooltip title="Campaign should have an audience of more than 0" arrow>
                <IconAlertCircleFilled size={20} className="size-5 text-orange-500" />
              </Tooltip>
            )}
          </div>
        )}
      </div>

      <SwitchInput
        label={excludeContacts ? 'Exclude the following:' : 'Do not exclude any segments'}
        checked={excludeContacts}
        onChange={handleExcludeContacts}
        disabled={disabled}
      />

      {excludeContacts && (
        <div className="!mt-5 flex w-full space-x-3">
          <MultipleSelectInput
            id="segment"
            label="Segment"
            options={audienceSections.find((audience) => audience.title === 'Segments')?.options}
            value={excludedSegments}
            onChange={handleSelectExcludedSegments}
            containerClassName="flex-1 max-w-[290px]"
            disabled={disabled}
          />

          <MultipleSelectInput
            id="tag"
            label="Tag"
            options={audienceSections.find((audience) => audience.title === 'Tags')?.options}
            value={excludedTags}
            onChange={handleSelectExcludedTags}
            containerClassName="flex-1 max-w-[290px]"
            disabled={disabled}
          />
        </div>
      )}

      <div className="!mt-1.5">
        <p className="text-base italic">You can only select up to {MAX_CONDITIONS} conditions.</p>
        {totalConditions === MAX_CONDITIONS && (
          <p className="text-base font-semibold italic">{totalConditions} selected conditions</p>
        )}
      </div>
    </form>
  );
};

ToForm.propTypes = {
  disabled: PropTypes.bool,
};

ToForm.defaultProps = {
  disabled: false,
};

export default ToForm;
