import PropTypes from 'prop-types';
import { Button } from 'ui';

import { ReactComponent as StripeLogo } from '../../assets/icons/stripe-logo.svg';
import { usePaymentOptions } from '../../store/payment-options/hooks';
import ConnectionStatus, { CONNECTION_STATUS } from './ConnectionStatus';

const AccountStatus = (props) => {
  const { onboarding, onConnect } = props;
  const { isStripeAccountConnected, isStripeConnectionComplete, stripeAccount, loading, disconnectStripeAccount } =
    usePaymentOptions();

  const getConnectionStatus = () => {
    if (isStripeAccountConnected) {
      if (isStripeConnectionComplete) {
        return CONNECTION_STATUS.connected;
      }
      return CONNECTION_STATUS.pending;
    }
    return CONNECTION_STATUS.notConnected;
  };

  return (
    <div
      className={`space-y-8 border border-gray-100 bg-white-100 ${
        onboarding ? 'rounded-lg p-6' : 'max-w-6xl rounded-xl p-8'
      }`}
    >
      {!onboarding && (
        <p className="text-lg">
          Stripe is a leading payment processor used by thousands of major companies and nonprofit organizations. Once
          you connect your Stripe account you will be able to process donations.
        </p>
      )}

      <div className="flex items-center space-x-4">
        <div className="flex flex-1 items-center space-x-6">
          <StripeLogo />

          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <p className="text-h5 font-semibold">Stripe</p>
              <ConnectionStatus status={getConnectionStatus()} />
            </div>

            {isStripeAccountConnected ? (
              <p className="text-base">Account connected {stripeAccount.email}</p>
            ) : (
              <p className="text-base">Connect your account to process payments.</p>
            )}
          </div>
        </div>

        {isStripeAccountConnected && (
          <Button title="Disconnect account" onClick={disconnectStripeAccount} loading={loading} />
        )}

        {!isStripeConnectionComplete && (
          <Button
            title={isStripeAccountConnected ? 'Complete Stripe connection' : 'Connect Stripe account'}
            color="primary"
            onClick={onConnect}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

AccountStatus.propTypes = {
  onConnect: PropTypes.func.isRequired,
  onboarding: PropTypes.bool,
};

AccountStatus.defaultProps = {
  onboarding: false,
};

export default AccountStatus;
