import { IconX } from '@tabler/icons-react';
import PropTypes from 'prop-types';

const Chip = ({ label, onDelete, className, iconClassName }) => {
  return (
    <div
      className={`m-1 
      flex 
      w-fit 
      items-center 
      justify-center 
      rounded-full 
      border 
      border-gray-300 
      bg-gray-300 
      px-2 
      py-1 
      font-medium 
      text-white-100 
      ${className}`}
    >
      <div className="max-w-full flex-initial whitespace-nowrap text-base font-medium leading-none">{label}</div>
      {onDelete && (
        <div
          tabIndex={0}
          role="button"
          className="ml-1.5 flex flex-auto flex-row-reverse"
          onMouseDown={(event) => event.stopPropagation()}
        >
          <IconX size={16} className={`cursor-pointer ${iconClassName}`} onClick={onDelete} />
        </div>
      )}
    </div>
  );
};

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

Chip.defaultProps = {
  className: '',
  iconClassName: '',
};

export default Chip;
