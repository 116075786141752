import PropTypes from 'prop-types';

const CustomDonationSpreadXAxisTick = (props) => {
  const {
    x,
    y,
    payload: { value },
    fill,
  } = props;

  const textX = value === '$5K+' ? 0 : 12;

  return (
    <g transform={`translate(${x},${y})`}>
      {value === '$50' && (
        <text x={-12} y={0} dy={12} textAnchor="end" fill={fill} fontSize="12px">
          $0
        </text>
      )}
      <text x={textX} y={0} dy={12} textAnchor="start" fill={fill} fontSize="12px">
        {value}
      </text>
    </g>
  );
};

CustomDonationSpreadXAxisTick.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  payload: PropTypes.object.isRequired,
  fill: PropTypes.string.isRequired,
};

export default CustomDonationSpreadXAxisTick;
