import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay, isToday } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { CampaignStatusLabel } from 'ui';

import { useCampaigns } from '../../store/campaigns/hooks';
import { campaignSendStatus } from '../../core/constants';
import IconButton from '../buttons/IconButton';
import Button from '../buttons/Button';
import SegmentControl from '../segment-control/SegmentControl';

const TOOLBAR_SEGMENTS = [{ segment: 'month' }, { segment: 'week' }, { segment: 'day' }, { segment: 'agenda' }];

const locales = { 'en-US': enUS };

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const AgendaEvent = (props) => {
  const { event } = props;
  return (
    <CampaignStatusLabel
      label={event.title}
      status={event.campaign.sendStatus}
      containerClassName="cursor-pointer pointer-events-auto"
    />
  );
};

AgendaEvent.propTypes = {
  event: PropTypes.object.isRequired,
};

const getEventStyle = (style) => ({
  top: `${style.top}%`,
  left: `${style.xOffset}%`,
  width: `${style.width}%`,
  height: `${style.height}%`,
  position: 'absolute',
  maxHeight: '100%',
  minHeight: 22,
  display: 'flex',
  flexFlow: 'column wrap',
  overflow: 'hidden',
});

const EventWrapper = (props) => {
  const { event, style, onSelect, onClick } = props;

  const handleClick = () => {
    if (onSelect) onSelect(event);
    else if (onClick) onClick(event);
  };

  return (
    <div
      role="button"
      tabIndex={0}
      style={style ? getEventStyle(style) : undefined}
      onKeyPress={(e) => {
        // 13 - Enter, 32 - Space
        if (e.charCode === 13 || e.charCode === 32) {
          handleClick();
        }
      }}
      onClick={handleClick}
      className="rounded bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primary-300 focus:ring-offset-1"
    >
      <CampaignStatusLabel label={event.title} status={event.campaign.sendStatus} containerClassName="w-full" />
    </div>
  );
};

EventWrapper.propTypes = {
  event: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  style: PropTypes.object,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
};

const Toolbar = (props) => {
  const { date, label, view, onNavigate, onView } = props;

  const isDateToday = isToday(date);

  return (
    <div className="flex items-center py-4">
      <div className="w-[324px]">
        <div className="flex w-fit items-center space-x-2 rounded-[10px] bg-gray-50 p-0.5">
          <IconButton
            Icon={<IconChevronLeft size={24} className="text-gray-600" />}
            srOnly="Go Back"
            color="white"
            circle
            className="enabled:hover:bg-transparent"
            onClick={() => onNavigate('PREV')}
          />
          <div className={isDateToday ? 'rounded-lg shadow-custom-sm' : undefined}>
            <Button
              title="Today"
              variant="outlined"
              color={isDateToday ? 'primary' : 'default'}
              className={isDateToday ? '!border-0 font-semibold ring-offset-gray-50' : 'text-gray-600 !shadow-none'}
              onClick={() => onNavigate('TODAY')}
            />
          </div>
          <IconButton
            Icon={<IconChevronRight size={24} className="text-gray-600" />}
            srOnly="Go Back"
            color="white"
            circle
            className="enabled:hover:bg-transparent"
            onClick={() => onNavigate('NEXT')}
          />
        </div>
      </div>

      <div className="flex-1">
        <p className="text-center text-lg font-semibold">{label}</p>
      </div>

      <div className="flex w-[324px] items-center">
        <SegmentControl
          segments={TOOLBAR_SEGMENTS}
          selectedSegment={view}
          onSelect={(segment) => onView(segment)}
          segmentWidth={80}
          containerClassName="bg-gray-50"
        />
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  date: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  view: PropTypes.oneOf(['month', 'week', 'day', 'agenda']).isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
};

const CalendarView = () => {
  const {
    campaigns,
    resetCampaignTemplate,
    updateCampaignDetails,
    calendarDate,
    calendarView,
    setCalendarDate,
    setCalendarView,
  } = useCampaigns();
  const navigate = useNavigate();

  const handleDateChange = useCallback(
    (date) => {
      setCalendarDate(date.toISOString());
    },
    [setCalendarDate],
  );

  const handleSelectCampaign = useCallback(
    (event) => {
      const { campaign } = event;
      resetCampaignTemplate();
      updateCampaignDetails(campaign);

      if (campaign.sendStatus === campaignSendStatus.draft || campaign.sendStatus === campaignSendStatus.error) {
        // Edit campaign
        navigate(`/edit-campaign/${campaign.campaignId}`);
      } else {
        // View campaign
        navigate(`/emails/${campaign.campaignId}`);
      }
    },
    [resetCampaignTemplate, updateCampaignDetails, navigate],
  );

  const events = useMemo(() => {
    return campaigns.map((campaign) => {
      let date = new Date(campaign.createdAt * 1000);
      if (campaign.sendTime) date = new Date(campaign.sendTime);
      return { title: campaign.name, start: date, end: date, campaign };
    });
  }, [campaigns]);

  const components = useMemo(
    () => ({
      toolbar: Toolbar,
      eventWrapper: EventWrapper,
      agenda: { event: AgendaEvent },
    }),
    [],
  );

  const date = useMemo(() => new Date(calendarDate), [calendarDate]);

  return (
    <div className="h-[630px]">
      <Calendar
        localizer={localizer}
        events={events}
        components={components}
        startAccessor="start"
        endAccessor="end"
        date={date}
        view={calendarView}
        onSelectEvent={handleSelectCampaign}
        onNavigate={handleDateChange}
        onView={setCalendarView}
      />
    </div>
  );
};

export default CalendarView;
