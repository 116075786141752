/* eslint-disable react/prop-types */
import { useState, useCallback, useMemo } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';
import PropTypes from 'prop-types';

import ConfirmDeleteModal from '../modals/ConfirmDeleteModal';
import SegmentNameModal from '../new-segment/SegmentNameModal';
import { useCampaigns } from '../../store/campaigns/hooks';
import CustomPagination from '../tables/CustomPagination';
import { useTags } from '../../store/tags/hook';
import { TAG_ACTIONS } from '../../core/constants';
import RowActions from '../tables/RowActions';

const options = [TAG_ACTIONS.createCampaign, TAG_ACTIONS.view, TAG_ACTIONS.rename, TAG_ACTIONS.delete];

const TagsTable = ({ data }) => {
  const [deleteData, setDeleteData] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const { resetCampaignDetails, setSendTo } = useCampaigns();
  const { renameTag, getTags, deleteTag, loading } = useTags();
  const navigate = useNavigate();

  const onTagAction = useCallback(
    (tag, action) => {
      switch (action) {
        case TAG_ACTIONS.view:
          navigate('/contacts', { state: { id: tag.id, type: 'tag' } });
          break;
        case TAG_ACTIONS.createCampaign:
          resetCampaignDetails();
          setSendTo({ tagIds: [tag.id] });
          navigate('/new-campaign');
          break;
        case TAG_ACTIONS.rename:
          setSelectedTag(tag);
          break;
        case TAG_ACTIONS.delete:
          setDeleteData({ id: tag.id, name: tag.name, entity: 'tag' });
          break;
        default:
          break;
      }
    },
    [navigate, resetCampaignDetails, setSendTo],
  );

  const onDeleteSegment = async () => {
    if (!deleteData?.id) return;
    try {
      await deleteTag(deleteData.id).unwrap();
      setDeleteData(null);
      getTags();
    } catch (err) {
      // Error handled by redux
    }
  };

  const onUpdateName = async (name) => {
    if (!selectedTag?.id) return;
    try {
      await renameTag({ tagId: selectedTag?.id, name }).unwrap();
      setSelectedTag(null);
      getTags();
    } catch (err) {
      // Error handled by redux
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: () => 'Name',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'created_at',
        header: () => 'Created',
        footer: (props) => props.column.id,
        cell: (info) => format(new Date(info.getValue()), 'd MMM yyyy, h:mm a'),
      },
      {
        accessorKey: 'id',
        header: () => '',
        footer: (props) => props.column.id,
        cell: (info) => (
          <RowActions
            actions={options}
            visibleActions={2}
            onSelectAction={(action) => onTagAction(info.row.original, action)}
          />
        ),
        size: 138,
        minSize: 138,
      },
    ],
    [onTagAction],
  );

  const table = useReactTable({
    data,
    columns,
    pageCount: data.totalPages,
    defaultColumn: {
      size: 'fit-content',
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const emptyRows = 5 - data.length;

  return (
    <div className="space-y-6">
      <div className="relative overflow-x-auto sm:rounded-lg">
        <table className="w-full text-left text-base text-gray-950 dark:text-gray-400">
          <thead className="bg-white-100 text-sm uppercase text-gray-400 dark:bg-gray-700 dark:text-gray-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="px-4 py-3"
                      style={{ width: header.column.columnDef.size, minWidth: header.column.columnDef.minSize }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className="px-4 py-2"
                        style={{ width: cell.column.columnDef.size, minWidth: cell.column.columnDef.minSize }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {emptyRows > 0 &&
              Array(emptyRows)
                .fill('')
                .map((row, index) => (
                  <tr
                    key={`emptyRow-${index}`}
                    className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                  >
                    <td className="px-4 py-2" colSpan={15} style={{ minWidth: 110 }}></td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <CustomPagination table={table} total={data.length} />

      <ConfirmDeleteModal
        data={deleteData}
        onClose={() => setDeleteData(null)}
        onDelete={onDeleteSegment}
        loading={loading}
      />

      {selectedTag && (
        <SegmentNameModal
          open
          onClose={() => setSelectedTag(null)}
          initialName={selectedTag.name}
          updateName={onUpdateName}
          entity="tag"
        />
      )}
    </div>
  );
};

TagsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TagsTable;
