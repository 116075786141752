import { combineReducers } from '@reduxjs/toolkit';

import donationsReducer from './donations/reducers';
import tagsReducer from './tags/reducers';
import campaignsReducer from './campaigns/reducers';
import segmentsReducer from './segments/reducers';
import contactsReducer from './contacts/reducers';
import dashboardReducer from './dashboard/reducers';
import reportsReducer from './reports/reducers';
import domainsReducer from './domains/reducers';
import accountReducer from './account/reducers';
import emailTemplatesReducer from './email-templates/reducers';
import transactionalReducer from './transactional/reducers';
import importsReducer from './imports/reducers';
import paymentOptionsReducer from './payment-options/reducers';
import recommendedTasksReducer from './recommended-tasks/reducers';
import pagesReducer from './pages/reducers';

const rootReducer = combineReducers({
  donations: donationsReducer,
  tags: tagsReducer,
  campaigns: campaignsReducer,
  segments: segmentsReducer,
  contacts: contactsReducer,
  dashboard: dashboardReducer,
  reports: reportsReducer,
  domains: domainsReducer,
  account: accountReducer,
  emailTemplates: emailTemplatesReducer,
  transactional: transactionalReducer,
  imports: importsReducer,
  paymentOptions: paymentOptionsReducer,
  recommendedTasks: recommendedTasksReducer,
  pages: pagesReducer,
});

export default rootReducer;
