import { useEffect, useMemo, useState } from 'react';
import { ActiveFilters, AlertMessage, Loader, TableFilters, TableSorters } from 'ui';
import { SORT_DIRECTIONS } from 'common/constants';

import Table from '../components/recommended-tasks/Table';
import {
  RECOMMENDED_TASK_SORT_BY,
  RECOMMENDED_TASK_SORT_BY_LABELS,
  RECOMMENDED_TASK_STATUS,
  RECOMMENDED_TASK_STATUS_LABELS,
} from '../core/constants';
import { sortRecommendedTasks } from '../core/utils';
import { useRecommendedTasks } from '../store/recommended-tasks/hooks';

const sortByOptions = [
  { label: RECOMMENDED_TASK_SORT_BY_LABELS[RECOMMENDED_TASK_SORT_BY.name], value: RECOMMENDED_TASK_SORT_BY.name },
  { label: RECOMMENDED_TASK_SORT_BY_LABELS[RECOMMENDED_TASK_SORT_BY.status], value: RECOMMENDED_TASK_SORT_BY.status },
  {
    label: RECOMMENDED_TASK_SORT_BY_LABELS[RECOMMENDED_TASK_SORT_BY.expirationTimestamp],
    value: RECOMMENDED_TASK_SORT_BY.expirationTimestamp,
  },
];

const filterSections = [
  {
    title: RECOMMENDED_TASK_SORT_BY_LABELS[RECOMMENDED_TASK_SORT_BY.status],
    propKey: RECOMMENDED_TASK_SORT_BY.status,
    options: [
      {
        label: RECOMMENDED_TASK_STATUS_LABELS[RECOMMENDED_TASK_STATUS.pending],
        value: RECOMMENDED_TASK_STATUS.pending,
      },
      {
        label: RECOMMENDED_TASK_STATUS_LABELS[RECOMMENDED_TASK_STATUS.completed],
        value: RECOMMENDED_TASK_STATUS.completed,
      },
      {
        label: RECOMMENDED_TASK_STATUS_LABELS[RECOMMENDED_TASK_STATUS.expired],
        value: RECOMMENDED_TASK_STATUS.expired,
      },
    ],
  },
];

const RecommendedTasks = () => {
  const [sortBy, setSortBy] = useState(RECOMMENDED_TASK_SORT_BY.expirationTimestamp);
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTIONS.desc);
  const [selectedFilters, setSelectedFilters] = useState({});
  const { tasks, loading, error, getRecommendedTasks, setError } = useRecommendedTasks();

  useEffect(() => {
    getRecommendedTasks();
  }, [getRecommendedTasks]);

  const handleDeleteActiveFilter = (propKey, value) => {
    setSelectedFilters((prev) => ({ ...prev, [propKey]: (prev[propKey] ?? []).filter((val) => val.value !== value) }));
  };

  const activeFilterStringValues = useMemo(
    () =>
      Object.values(selectedFilters)
        .flat()
        .map((val) => val?.value),
    [selectedFilters],
  );

  const data = useMemo(() => {
    let filteredRecommendedTasks = tasks;

    if (activeFilterStringValues.length > 0) {
      const propKeys = Object.keys(selectedFilters);

      propKeys.forEach((propKey) => {
        if (propKey in filteredRecommendedTasks[0]) {
          filteredRecommendedTasks = filteredRecommendedTasks.filter((recommendedTask) => {
            const filterValues = selectedFilters[propKey]?.map((filter) => filter.value) ?? [];
            // We're only managing string values of suggested drafts - update to handle different types
            return filterValues.includes(recommendedTask[propKey]);
          });
        }
      });
    }

    if (sortBy) {
      filteredRecommendedTasks = sortRecommendedTasks(filteredRecommendedTasks, sortBy, sortDirection);
    }

    return filteredRecommendedTasks;
  }, [activeFilterStringValues.length, selectedFilters, sortBy, sortDirection, tasks]);

  return (
    <div className="w-full space-y-6">
      <div className="flex justify-between space-x-3">
        <h1 className="text-h3">Recommended Tasks</h1>

        <div className="flex items-center space-x-3">
          <TableSorters
            options={sortByOptions}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
          />

          <TableFilters
            key={activeFilterStringValues.join(',')}
            filterSections={filterSections}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        </div>
      </div>

      {activeFilterStringValues.length > 0 && (
        <ActiveFilters
          filters={selectedFilters}
          onDelete={handleDeleteActiveFilter}
          onReset={() => setSelectedFilters({})}
        />
      )}

      <div className="relative">
        <Table data={data} />
        {loading && <Loader />}
      </div>

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </div>
  );
};

export default RecommendedTasks;
