import { useSelector, shallowEqual } from 'react-redux';

import useActionCreator from '../../hooks/useActionCreator';
import {
  setError,
  setSuccess,
  updateTransactionalEmail,
  getTransactionalEmails,
  saveTransactionalEmails,
} from './actions';

export const useTransactional = () => {
  const transactionalState = useSelector((state) => state.transactional, shallowEqual);

  return {
    ...transactionalState,
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    updateTransactionalEmail: useActionCreator(updateTransactionalEmail),
    getTransactionalEmails: useActionCreator(getTransactionalEmails),
    saveTransactionalEmails: useActionCreator(saveTransactionalEmails),
  };
};
