import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import FileDrop from '../file-drop/FileDrop';
import { useImports } from '../../store/imports/hooks';
import { IMPORT_TYPES } from '../../core/constants';

const ImportStep = ({ importFile, onUploadFile }) => {
  const [fileError, setFileError] = useState('');
  const [loading, setLoading] = useState(false);
  const { goNext, goBack, newImport } = useImports();

  const handleFileChange = async (file) => {
    try {
      setLoading(true);
      await onUploadFile(file);
      setLoading(false);
    } catch (error) {
      setFileError(error?.message || 'Something went wrong, please try again.');
    }
  };

  const handleNext = async () => {
    if (!importFile) {
      setFileError('Please upload your file.');
      return;
    }
    goNext();
  };

  return (
    <div className="w-full max-w-xl space-y-6 py-3">
      <div className="space-y-2">
        <h1 className="text-3xl font-medium">
          {newImport.importType === IMPORT_TYPES.contacts ? 'Import contacts' : 'Import suppression list'}
        </h1>

        <p className="text-base">
          {newImport.importType === IMPORT_TYPES.contacts
            ? 'Upload a list of contacts to start using them in your campaigns.'
            : 'Upload a list of email addresses to to prevent them from receiving campaign emails. DonorSpring will not include any addresses from your suppression list when we provide you with new contacts. If anyone in the uploaded suppression list is currently in your contacts database, we will automatically unsubscribe them.'}
        </p>
      </div>

      <FileDrop
        onChange={handleFileChange}
        acceptedFileExtension=".csv"
        fileError={fileError}
        loading={loading}
        importFile={importFile}
        containerClassName="!w-[448px] !h-[122px]"
        shortDescription="CSV file"
      />

      <div className="flex items-center space-x-3">
        <Button title="Go Back" onClick={goBack} />
        <Button title="Continue" color="primary" onClick={handleNext} />
      </div>
    </div>
  );
};

ImportStep.propTypes = {
  importFile: PropTypes.object,
  onUploadFile: PropTypes.func.isRequired,
};

ImportStep.defaultProps = {
  importFile: null,
};

export default ImportStep;
