import { useState } from 'react';
import PropTypes from 'prop-types';

import FileDrop from '../file-drop/FileDrop';
import Button from '../buttons/Button';

const allowedFileTypes = { 'image/*': ['.jpeg', '.jpg', '.png'] };

const ImageInput = ({ value, onChange, onReset, label, required }) => {
  const [editable, setEditable] = useState(false);

  const handleEdit = () => {
    setEditable(true);
  };

  const handleCancelEdit = () => {
    setEditable(false);
    onReset();
  };

  const renderContent = () => {
    if (editable) {
      return <FileDrop onChange={onChange} allowedFileTypes={allowedFileTypes} disabled={!editable} />;
    }

    if (!value) {
      return <p className="ml-1 text-base text-gray-700 dark:text-gray-300">No Image</p>;
    }

    return (
      <div className="border-2 border-gray-50" style={{ width: 200 }}>
        <img
          // Add timestamp to bust browser cache - not needed for placeholder images
          src={`${value}${value.includes('placeholder') ? '' : `?${Date.now()}`}`}
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </div>
    );
  };

  return (
    <div className="flex">
      <div className="flex w-3/4">
        <p className="mr-[5%] w-[125px] text-base font-medium text-gray-900">
          <span>{label}</span>
          {required && <span className="ml-1 text-base text-red-500">*</span>}
        </p>

        {renderContent()}
      </div>
      <div className="flex w-1/4 flex-col">
        <Button
          title={editable ? 'Cancel' : 'Edit'}
          className="self-end"
          onClick={editable ? handleCancelEdit : handleEdit}
        />
      </div>
    </div>
  );
};

ImageInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
};

ImageInput.defaultProps = {
  onReset: () => {},
  label: 'Image',
  required: false,
};

export default ImageInput;
