import { IconCopy, IconAlertCircleFilled, IconCircleCheckFilled } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import IconButton from '../buttons/IconButton';

const DnsRecordsTable = ({ dnsRecords, validationResults }) => {
  return (
    <div className="overflow-y-auto pl-8">
      <table className="w-full table-auto text-left text-gray-950">
        <thead className="bg-gray-10 text-caption1 font-semibold uppercase text-gray-600">
          <tr className="h-8">
            <th className="px-3 py-2">Type</th>
            <th className="px-3 py-2">Host</th>
            <th className="px-3 py-2">Points To</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {Object.keys(dnsRecords).map((dnsKey) => {
            const dnsRecord = dnsRecords[dnsKey];
            const isValid = dnsRecord.valid || !!validationResults?.[dnsKey]?.valid;
            return (
              <tr key={dnsKey} className="h-10 border-b border-gray-50">
                <td className="px-3 py-2 uppercase">
                  <div className="relative flex items-center">
                    {!!validationResults && !isValid && (
                      <IconAlertCircleFilled size={16} className="absolute -left-10 text-red-500" />
                    )}
                    {isValid && <IconCircleCheckFilled size={16} className="absolute -left-10 text-green-500" />}
                    {dnsRecord.type}
                  </div>
                </td>
                <td className="px-3 py-2">
                  <div className="flex items-center space-x-1">
                    <span>{dnsRecord.host}</span>
                    <IconButton
                      Icon={<IconCopy size={16} />}
                      srOnly="Copy"
                      color="transparent"
                      className="!p-1"
                      onClick={() => navigator.clipboard.writeText(dnsRecord.host)}
                    />
                  </div>
                </td>
                <td className="px-3 py-2">
                  <div className="flex items-center space-x-1">
                    <span>{dnsRecord.data}</span>
                    <IconButton
                      Icon={<IconCopy size={16} />}
                      srOnly="Copy"
                      color="transparent"
                      className="!p-1"
                      onClick={() => navigator.clipboard.writeText(dnsRecord.data)}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

DnsRecordsTable.propTypes = {
  dnsRecords: PropTypes.object.isRequired,
  validationResults: PropTypes.object,
};

export default DnsRecordsTable;
