import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { setError, setSuccess, getTags, createTag, renameTag, deleteTag } from './actions';

const initialState = {
  loading: false,
  error: false,
  success: false,
  tags: [],
};

const tagsStore = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(getTags.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.tags = payload;
    });

    builder.addCase(createTag.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = 'Tag successfully created!';
      state.tags.push(payload);
    });

    builder.addCase(renameTag.fulfilled, (state) => {
      state.loading = false;
      state.success = 'Tag successfully updated!';
    });

    builder.addCase(deleteTag.fulfilled, (state) => {
      state.loading = false;
      state.success = 'Tag successfully deleted!';
    });

    builder.addMatcher(isAnyOf(getTags.pending, createTag.pending, renameTag.pending, deleteTag.pending), (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addMatcher(
      isAnyOf(getTags.rejected, createTag.rejected, renameTag.rejected, deleteTag.rejected),
      (state, { error }) => {
        state.loading = false;
        state.error = error.message || true;
      },
    );
  },
});

export default tagsStore.reducer;
