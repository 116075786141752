import { useState, useEffect, useCallback } from 'react';
import { Collapse } from '@mui/material';
import { IconChevronDown, IconArrowRight, IconArrowLeft } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { AlertMessage, Button, ConnectionStatusLabel, Loader } from 'ui';
import { getNetworkError } from 'common/utils';

import { getIntegrations as getIntegrationsApi } from '../api';
import { ReactComponent as ZapierLogo } from '../assets/integrations/zapier-logo.svg';
import { ReactComponent as DonorSpringLogo } from '../assets/integrations/donorspring-logo.svg';

const features = [
  {
    id: 'feat1',
    dsName: 'New Donation Created',
    zapierName: 'Donation Created Trigger',
    status: 'available',
    dataFlow: 'outbound',
  },
  {
    id: 'feat2',
    dsName: 'New DonorSpring Contact Added',
    zapierName: 'Contact Added Trigger',
    status: 'available',
    dataFlow: 'outbound',
  },
  {
    id: 'feat3',
    dsName: 'Create DonorSpring Contacts',
    zapierName: 'Transfer Existing Contacts',
    status: 'available',
    dataFlow: 'inbound',
  },
  {
    id: 'feat4',
    dsName: 'Create DonorSpring Contact',
    zapierName: 'Contact Created Action',
    status: 'available',
    dataFlow: 'inbound',
  },
  {
    id: 'feat5',
    dsName: 'Create DonorSpring Donation',
    zapierName: 'Donation Created Action',
    status: 'available',
    dataFlow: 'inbound',
  },
];

const IntegrationStatus = (props) => {
  const { status } = props;
  if (status === 'pending') {
    return (
      <div className="w-fit rounded border border-orange-200 bg-orange-50 px-1.5 py-0.5">
        <p className="text-caption1 font-semibold uppercase text-orange-700">Coming Soon</p>
      </div>
    );
  }
  return (
    <div className="w-fit rounded border border-green-100 bg-green-50 px-1.5 py-0.5">
      <p className="text-caption1 font-semibold uppercase text-green-500">Available</p>
    </div>
  );
};

IntegrationStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

const Integrations = () => {
  const [open, setOpen] = useState(false);
  const [connected, setConnected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const getIntegrations = useCallback(async () => {
    setLoading(true);
    try {
      const integrations = await getIntegrationsApi();
      setConnected(!!integrations.find((integration) => integration.type === 'zapier'));
    } catch (error) {
      Sentry.captureException(error);
      setError(getNetworkError(error));
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getIntegrations();
  }, [getIntegrations]);

  return (
    <>
      <div className="w-full space-y-6">
        <h1 className="text-h3">Integrations</h1>

        <div className="relative max-w-5xl space-y-4 rounded-xl bg-white-100 p-8 shadow-md">
          <div className="flex items-center justify-between space-x-6">
            <div className="flex items-center space-x-6">
              <ZapierLogo className="h-auto w-[140px]" />

              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <h3 className="text-h5 font-semibold">Zapier</h3>

                  <ConnectionStatusLabel connected={connected} />
                </div>

                {connected ? (
                  <p className="text-base">Manage your Zapier integration in the Zapier dashboard.</p>
                ) : (
                  <p className="text-base">
                    Enable DonorSpring in your Zapier dashboard to start connecting to other apps.
                  </p>
                )}
              </div>
            </div>

            {connected ? (
              <Button
                title="Go to Zapier"
                color="primary"
                as="link"
                href="https://zapier.com/app/home"
                target="_blank"
              />
            ) : (
              <Button
                title="Accept Zapier Invite"
                color="primary"
                as="link"
                // Invite link for the 1.0.0 version of the Zapier integration
                href="https://zapier.com/developer/public-invite/208240/422423/074f53ed94cc28a144c7155bdefd862a/"
                target="_blank"
              />
            )}
          </div>

          {!loading && !connected && (
            <>
              <p>
                Zapier is a workflow automation tool that enables the creation of automated workflows (&quot;Zaps&quot;)
                that send data from one app to another. Start creating automations in the Zapier app after accepting the
                invitation to use DonorSpring&apos;s Zapier integration. Zapier connects with most nonprofit CRMs
                including <span className="font-bold">Salesforce</span>, <span className="font-bold">Neon CRM</span>,{' '}
                <span className="font-bold">Bloomerang</span>, <span className="font-bold">Bonterra</span>, and more.
              </p>

              <Collapse in={open} className="relative !mt-0" collapsedSize={110}>
                <div className="flex w-full justify-center">
                  <table className="w-full max-w-2xl">
                    <thead>
                      <tr className="h-[56px]">
                        <th className="w-[260px] p-4">
                          <DonorSpringLogo className="h-auto w-[150px]" />
                        </th>
                        <th className="w-[52px] p-4"></th>
                        <th className="w-[220px] p-4">
                          <ZapierLogo className="h-auto w-[120px]" />
                        </th>
                        <th className="w-[120px] p-4 pr-2.5"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {features.map((feature) => (
                        <tr className="h-[52px] border-b border-gray-50 text-base" key={feature.id}>
                          <td className="w-[260px] p-4">{feature.dsName}</td>
                          <td className="w-[52px] p-4">
                            {feature.dataFlow === 'outbound' ? (
                              <IconArrowRight size={20} className="text-gray-600" />
                            ) : (
                              <IconArrowLeft size={20} className="text-gray-600" />
                            )}
                          </td>
                          <td className="w-[220px] p-4">{feature.zapierName}</td>
                          <td className="w-[120px] p-4 pr-2.5">
                            <IntegrationStatus status={feature.status} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {!open && (
                  <div className="absolute inset-x-0 top-0 h-full">
                    <div className="relative size-full">
                      <div className="absolute inset-0 size-full bg-gradient-to-t from-white-100 to-white-32"></div>
                    </div>
                    <div className="absolute inset-0 flex size-full items-end justify-center pb-1">
                      <Button title="Learn More" onClick={() => setOpen(true)} LeftIcon={IconChevronDown} />
                    </div>
                  </div>
                )}
              </Collapse>
            </>
          )}

          {loading && <Loader />}
        </div>
      </div>

      <AlertMessage open={!!error} message={error} onClose={() => setError('')} severity="error" />
    </>
  );
};

export default Integrations;
