import { useState } from 'react';
import { Menu } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { endOfDay, startOfDay, subYears } from 'date-fns';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import DatePicker from '../inputs/DatePicker';

const DateFilter = ({ initialStartDate, initialEndDate, onSelect, active }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(initialStartDate ? new Date(initialStartDate) : null);
  const [endDate, setEndDate] = useState(initialEndDate ? new Date(initialEndDate) : null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    onSelect({ startDate: '', endDate: '' });
    setStartDate(null);
    setEndDate(null);
    handleClose();
  };

  const handleApply = () => {
    onSelect({ startDate: startDate?.toISOString() || '', endDate: endDate?.toISOString() || '' });
    handleClose();
  };

  return (
    <>
      <div className="flex w-fit">
        <Button
          title="Date"
          className={`w-[110px] justify-between rounded-lg ${active ? 'border-primary-200 !bg-primary-50' : ''}`}
          RightIcon={open ? IconChevronUp : IconChevronDown}
          onClick={handleClick}
        />

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          classes={{ paper: 'w-[324px] !rounded-lg mt-1', list: '!p-0 divide-y divide-gray-50' }}
        >
          <li className="flex items-center justify-between p-4">
            <DatePicker
              id="start-date"
              label="Start Date"
              value={startDate}
              onChange={(date) => setStartDate(startOfDay(date))}
              inputSize="md"
              containerClassName="w-[140px]"
              fromDate={subYears(new Date(), 2)}
              toDate={new Date()}
            />

            <DatePicker
              id="end-date"
              label="End Date"
              value={endDate}
              onChange={(date) => setEndDate(endOfDay(date))}
              inputSize="md"
              containerClassName="w-[140px]"
              fromDate={subYears(new Date(), 2)}
              toDate={new Date()}
            />
          </li>

          <li className="flex items-center justify-between p-4">
            <Button title="Reset" onClick={handleReset} />

            <Button title="Apply" color="primary" onClick={handleApply} />
          </li>
        </Menu>
      </div>
    </>
  );
};

DateFilter.propTypes = {
  initialStartDate: PropTypes.string,
  initialEndDate: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

DateFilter.defaultProps = {
  active: false,
};

export default DateFilter;
