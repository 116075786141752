import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import { getNetworkError } from '../../core/utils';
import appSettings from '../../app-settings';

const DONATIONS_URL = `${appSettings.baseUrl}/donations`;

const SET_ERROR = 'paymentOptions/SET_ERROR';
const SET_SUCCESS = 'paymentOptions/SET_SUCCESS';
const GET_STRIPE_ACCOUNT = 'paymentOptions/GET_STRIPE_ACCOUNT';
const DISCONNECT_STRIPE_ACCOUNT = 'paymentOptions/DISCONNECT_STRIPE_ACCOUNT';

export const setError = createAction(SET_ERROR);

export const setSuccess = createAction(SET_SUCCESS);

export const getStripeAccount = createAsyncThunk(GET_STRIPE_ACCOUNT, async () => {
  try {
    const response = await axios.get(`${DONATIONS_URL}/stripe-details`);
    return response.data;
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const disconnectStripeAccount = createAsyncThunk(DISCONNECT_STRIPE_ACCOUNT, async () => {
  try {
    await axios.get(`${DONATIONS_URL}/disconnect-stripe`);
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});
