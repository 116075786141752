import { customAlphabet } from 'nanoid';

export const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 7);

export const noop = () => {};

export const waitFor = (timeMs) => new Promise((resolve) => setTimeout(() => resolve(), timeMs));

export const getTimeInSeconds = (date) => {
  return Math.floor(new Date(date).getTime() / 1000);
};

export const removeFalsyValues = (obj) => {
  return Object.keys(obj).reduce((acc, val) => {
    if (obj[val]) acc[val] = obj[val];
    return acc;
  }, {});
};

export const getPercentage = (value, total) => {
  return `${(Math.min((value || 0) / (total || 1), 1) * 100).toFixed(0)}%`;
};

export const getErrorMessage = (key, value) => {
  if (!value) return `${key} is required.`;
  return `Invalid ${key.toLowerCase()}`;
};

export const getMedian = (numbers) => {
  if (numbers.length < 2) return 0;
  const sorted = Array.from(numbers).sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
};

export const removeEmptyValues = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (Array.isArray(value) && value.length === 0) return acc;
    if (value && typeof value === 'object' && Object.keys(value).length === 0) return acc;
    if (typeof value === 'boolean' || value) acc[key] = value;
    return acc;
  }, {});
};
