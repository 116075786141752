import { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';

import useActionCreator from '../../hooks/useActionCreator';
import { markRecommendedTasksAsSeen as markRecommendedTasksAsSeenApi } from '../../api';
import {
  getPendingRecommendedTasks,
  getRecommendedTasks,
  updateReviewDraftTaskStatus,
  updateScheduleCampaignTaskStatus,
} from './actions';

const RECOMMENDED_TASKS_STORAGE_KEY = '@DS/seen-recommended-tasks';

export const useRecommendedTasks = () => {
  const recommendedTasksState = useSelector((state) => state.recommendedTasks, shallowEqual);

  const markRecommendedTasksAsSeen = useCallback(async (taskIds) => {
    try {
      const cachedTaskIds = sessionStorage.getItem(RECOMMENDED_TASKS_STORAGE_KEY);
      const newTaskIds = taskIds.join(',');
      if (cachedTaskIds === newTaskIds) {
        // Tasks have already been marked as seen
        return;
      }
      await markRecommendedTasksAsSeenApi(taskIds);
      sessionStorage.setItem(RECOMMENDED_TASKS_STORAGE_KEY, newTaskIds);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  return {
    ...recommendedTasksState,
    getRecommendedTasks: useActionCreator(getRecommendedTasks),
    getPendingRecommendedTasks: useActionCreator(getPendingRecommendedTasks),
    updateReviewDraftTaskStatus: useActionCreator(updateReviewDraftTaskStatus),
    updateScheduleCampaignTaskStatus: useActionCreator(updateScheduleCampaignTaskStatus),
    markRecommendedTasksAsSeen,
  };
};
