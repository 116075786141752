import { useEffect, useMemo, useState } from 'react';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';

import { useAccount } from '../../store/account/hooks';
import { DEBOUNCE_DELAY_TIME_MS, donationTypeLabels, donationTypes } from '../../core/constants';
import { emailPreferencesInitialValues } from '../../store/account/reducers';
import Input from '../inputs/Input';
import ColorInput from '../inputs/ColorInput';
import SelectInput from '../inputs/SelectInput';

const formValidation = yup.object({
  buttonText: yup.string().min(2, 'Text is invalid.').required('Button Text is required.'),
  backgroundColor: yup.string().required('Background Color is required.'),
  textColor: yup.string().required('Text Color is required.'),
  amount: yup.string().required('Amount is required.'),
  type: yup.string().required('Type is required.'),
});

const DonateButtonSettings = ({ onChange }) => {
  const { account, updateEmailPreferences } = useAccount();
  const [initialValues] = useState(
    account.emailPreferences?.donateButton ?? emailPreferencesInitialValues.donateButton,
  );
  const form = useForm({
    initialValues: {
      ...initialValues,
    },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });

  const hasPendingChanges = form.isDirty() && form.isValid();

  useEffect(() => {
    onChange('donateButton', hasPendingChanges);
  }, [hasPendingChanges, onChange]);

  const debouncedUpdateEmailPreferences = useMemo(
    () => debounce(updateEmailPreferences, DEBOUNCE_DELAY_TIME_MS),
    [updateEmailPreferences],
  );

  const handleChange = (field, value) => {
    form.setFieldValue(field, value);
    debouncedUpdateEmailPreferences({ donateButton: { [field]: value } });
  };

  const handleCancel = (field) => {
    let initialValue = initialValues[field];
    form.setFieldValue(field, initialValue);
    debouncedUpdateEmailPreferences({ donateButton: { [field]: initialValue } });
    setTimeout(() => form.clearFieldError(field), 0);
  };

  return (
    <>
      <div className="bg-gray-10 px-4 py-3">
        <h3 className="text-lg font-semibold dark:text-white-100">Donate Button Configuration</h3>
        <p className="text-base">Configure the default appearance when you add a new Donate button to an email</p>
      </div>

      <div className="py-6">
        <form noValidate className="space-y-6 px-4">
          <Input
            id="button-text"
            label="Button Text"
            placeholder="Donate"
            {...form.getInputProps('buttonText')}
            required
            error={!!form.errors.buttonText}
            helperText={form.errors.buttonText}
            labelClassName="w-[125px] mr-[5%]"
            containerClassName={`flex ${form.errors.buttonText ? 'items-start' : 'items-center'}`}
            onChange={(e) => handleChange('buttonText', e.target.value)}
          />

          <ColorInput
            id="backgroundColor"
            label="Background Color"
            placeholder="#061C6C"
            value={form.values.backgroundColor}
            containerClassName="flex items-center"
            labelClassName="w-[125px] mr-[5%] whitespace-nowrap"
            inputContainerClassName="flex-1"
            onChange={(value) => handleChange('backgroundColor', value)}
            error={form.errors.backgroundColor}
            onCancel={() => handleCancel('backgroundColor')}
            required
          />

          <ColorInput
            id="textColor"
            label="Text Color"
            placeholder="#FFFFFF"
            value={form.values.textColor}
            containerClassName="flex items-center"
            labelClassName="w-[125px] mr-[5%]"
            inputContainerClassName="flex-1"
            onChange={(value) => handleChange('textColor', value)}
            error={form.errors.textColor}
            onCancel={() => handleCancel('textColor')}
            required
          />

          <Input
            id="amount"
            type="number"
            label="Amount"
            placeholder="50"
            {...form.getInputProps('amount')}
            required
            error={!!form.errors.amount}
            helperText={form.errors.amount}
            labelClassName="w-[125px] mr-[5%]"
            containerClassName={`flex ${form.errors.amount ? 'items-start' : 'items-center'}`}
            onChange={(e) => handleChange('amount', e.target.value)}
          />

          <SelectInput
            label="Type"
            {...form.getInputProps('type')}
            options={[
              { value: donationTypes.recurring, label: donationTypeLabels.recurring },
              { value: donationTypes.oneTime, label: donationTypeLabels[donationTypes.oneTime] },
            ]}
            required
            error={!!form.errors.type}
            helperText={form.errors.type}
            labelClassName="w-[125px] mr-[5%]"
            containerClassName={`flex ${form.errors.type ? 'items-start' : 'items-center'}`}
            onChange={(e) => handleChange('type', e.target.value)}
          />
        </form>
      </div>
    </>
  );
};

DonateButtonSettings.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default DonateButtonSettings;
