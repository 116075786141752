import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { IconInfoCircle, IconLock, IconDownload } from '@tabler/icons-react';

import csvTemplate from '../../assets/csv/DonorSpring Contacts Columns.csv?url';
import Button from '../buttons/Button';
import FileInfo from '../file-info/FileInfo';
import FullScreenUpload from '../imports/FullScreenUpload';
import { useImports } from '../../store/imports/hooks';
import { importJobStatusToFileStatus } from '../../core/utils';
import { IMPORT_JOB_STATUS } from '../../core/constants';
import useInterval from '../../hooks/useInterval';
import StepNavBottom from './StepNavBottom';

const POLLING_INTERVAL_IN_MS = 15000;

const ImportContactsStep = ({ onPrev, onNext }) => {
  const [uploadOpen, setUploadOpen] = useState(false);
  const { imports, setImportOnboarding, getImports } = useImports();

  useEffect(() => {
    setImportOnboarding(true);
  }, [setImportOnboarding]);

  const isInProgress = useMemo(() => {
    return imports.some(({ status }) =>
      [IMPORT_JOB_STATUS.notStarted, IMPORT_JOB_STATUS.validating, IMPORT_JOB_STATUS.processing].includes(status),
    );
  }, [imports]);

  useInterval(getImports, isInProgress ? POLLING_INTERVAL_IN_MS : null);

  return (
    <div className="m-auto max-w-2xl space-y-12 pb-[60px] 2xl:max-w-5xl">
      <div className="space-y-4">
        <h1 className="text-h1 font-semibold">Import Warm-up List</h1>
        <p className="text-lg">Import to establish sender reputation.</p>
      </div>

      <div className="space-y-6">
        <div className="flex space-x-3">
          <div className="flex size-10 items-center justify-center rounded-full bg-primary-50">
            <IconInfoCircle size={20} className="text-primary-700" />
          </div>

          <div className="flex-1 space-y-6">
            <p className="text-base">
              Internet Service Providers (ISPs) like Google and Outlook have established rules that prevent senders from
              emailing too many new contacts at once.
            </p>

            <p className="text-base">
              To stay in compliance with these rules, DonorSpring can only add new supporters totaling 5% of your
              existing email audience per month. By importing a list of your existing contacts, DonorSpring can grow
              your database faster while staying in compliance and preventing your emails from being blocked by ISPs.
            </p>

            <p className="text-base">
              Importing a list of your existing contacts also ensures that donations from these contacts will not be
              charged the 18% fee for donations from new donors.
            </p>
          </div>
        </div>

        <div className="flex space-x-3">
          <div className="flex size-10 items-center justify-center rounded-full bg-green-50">
            <IconLock size={20} className="text-green-500" />
          </div>

          <div className="flex-1">
            <p className="text-base">
              Your data is secure and is never shared with any third parties or other organizations that DonorSpring
              works with.
            </p>
          </div>
        </div>

        <div>
          <Button
            title="Download CSV template"
            size="sm"
            LeftIcon={IconDownload}
            className="!h-5 border-none !p-0 text-primary-700 !shadow-none focus:ring-0"
            href={csvTemplate}
            download="DonorSpring Contacts Columns.csv"
            iconProps={{ size: 16 }}
          />
        </div>

        <Button title="Import CSV" color="primary" onClick={() => setUploadOpen(true)} />

        <div className="space-y-2">
          {imports.map((importJob) => (
            <FileInfo
              key={`${importJob.filename}-${importJob.createdAt}`}
              name={importJob.filename}
              status={importJobStatusToFileStatus(importJob.status)}
              withAction={false}
            />
          ))}
        </div>
      </div>

      <StepNavBottom onPrev={onPrev} onNext={onNext} nextDisabled={!imports.length} />

      <FullScreenUpload open={uploadOpen} onClose={() => setUploadOpen(false)} />
    </div>
  );
};

ImportContactsStep.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default ImportContactsStep;
