import { useState, useMemo, useEffect } from 'react';
import { IconPlus } from '@tabler/icons-react';

import { sortDataWithDirection } from '../core/utils';
import AlertMessage from '../components/alerts/AlertMessage';
import { sortDirections } from '../core/constants';
import Loader from '../components/loader/Loader';
import { useTags } from '../store/tags/hook';
import TagsTable from '../components/tags/TagsTable';
import CreateTagModal from '../components/tags/CreateTagModal';
import Button from '../components/buttons/Button';
import Sorters from '../components/tables/Sorters';

const sortByOptions = [
  { label: 'Name', value: 'name' },
  { label: 'Date', value: 'created_at' },
];

const Tags = () => {
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState(sortDirections.desc);
  const [createOpen, setCreateOpen] = useState(false);
  const { loading, error, success, tags, getTags, setSuccess, setError, createTag } = useTags();

  useEffect(() => {
    getTags();
  }, [getTags]);

  const onSave = async (name) => {
    try {
      await createTag(name).unwrap();
      setCreateOpen(false);
    } catch (err) {
      // Error handled by redux
    }
  };

  const data = useMemo(() => {
    let filteredData = tags;
    if (sortBy) {
      filteredData = sortDataWithDirection(filteredData, sortBy, sortDirection);
    }
    return filteredData;
  }, [tags, sortBy, sortDirection]);

  return (
    <div className="w-full space-y-6">
      <div className="flex justify-between space-x-3">
        <h1 className="mb-auto text-h3 dark:text-white-100">Tags</h1>

        <div className="flex items-center space-x-3">
          <Sorters
            options={sortByOptions}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
          />

          <Button title="Create Tag" color="primary" LeftIcon={IconPlus} onClick={() => setCreateOpen(true)} />
        </div>
      </div>

      <div className="relative">
        <TagsTable data={data} />

        {loading && <Loader />}
      </div>

      <CreateTagModal open={createOpen} onClose={() => setCreateOpen(false)} onSave={onSave} loading={loading} />

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => setSuccess(false)}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </div>
  );
};

export default Tags;
