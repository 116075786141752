import PropTypes from 'prop-types';

import ConnectStripe from '../payment-options/ConnectStripe';

const ConnectStripeStep = ({ onPrev, onNext }) => {
  return (
    <div className="relative m-auto min-h-[300px] max-w-2xl space-y-12 pb-[60px] 2xl:max-w-5xl">
      <div className="space-y-4">
        <h1 className="text-h1 font-semibold">Connect Stripe account</h1>
        <p className="text-lg">
          Stripe is a leading payment processor used by thousands of major companies and nonprofit organizations. Once
          you connect your Stripe account you will be able to process donations.
        </p>
      </div>

      <ConnectStripe onboarding onPrev={onPrev} onNext={onNext} />
    </div>
  );
};

ConnectStripeStep.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default ConnectStripeStep;
