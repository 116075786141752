import { useEffect, useState } from 'react';
import { Autocomplete, createFilterOptions } from '@mui/material';

import Button from '../buttons/Button';
import { useTags } from '../../store/tags/hook';
import Spinner from '../loader/Spinner';
import Chip from '../chip/Chip';
import { useImports } from '../../store/imports/hooks';
import Input from '../inputs/Input';

const filter = createFilterOptions();

const TagStep = () => {
  const [inputKey, setInputKey] = useState(Date.now().toString());
  const { loading, tags, createTag, setError, setSuccess } = useTags();
  const {
    newImport: { selectedTags },
    updateNewImport,
    goBack,
    goNext,
  } = useImports();

  useEffect(() => {
    // Clear any error or success message on tags
    return () => {
      setError(false);
      setSuccess(false);
    };
  }, [setError, setSuccess]);

  const handleChange = async (event, newValue) => {
    if (!newValue) return;

    if (typeof newValue === 'string' || newValue?.inputValue) {
      try {
        const tagName = newValue?.inputValue || newValue;
        const newTag = await createTag(tagName).unwrap();
        updateNewImport({ selectedTags: [...selectedTags, newTag] });
        setInputKey(Date.now().toString());
      } catch (error) {
        // Error handled by redux
      }
      return;
    }

    if (!selectedTags.find((sT) => sT.name === newValue.name)) {
      updateNewImport({ selectedTags: [...selectedTags, newValue] });
    }

    setInputKey(Date.now().toString());
  };

  return (
    <div className="w-full max-w-xl space-y-6 py-3">
      <h1 className="text-3xl font-medium">Tag your contacts</h1>

      <div className="w-fit max-w-md space-y-2">
        <p className="text-lg font-medium text-gray-900 dark:text-gray-300">Search for or create tags</p>

        <div className="flex flex-wrap">
          {selectedTags.map((cTag) => (
            <Chip
              key={cTag.name}
              label={cTag.name}
              onDelete={() => updateNewImport({ selectedTags: selectedTags.filter((tag) => tag.id !== cTag.id) })}
            />
          ))}
        </div>

        <Autocomplete
          key={inputKey}
          id="import-tag-input"
          onChange={handleChange}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                name: `Create tag "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={tags}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.name;
          }}
          sx={{ width: 280, marginTop: '1rem' }}
          freeSolo
          renderInput={(params) => {
            const { className, ...inputProps } = params.inputProps;

            return (
              <div className="relative w-full" ref={params.InputProps.ref}>
                <Input {...inputProps} />

                {loading && <Spinner className="absolute right-0 top-1.5 size-7" />}
              </div>
            );
          }}
        />
      </div>

      <div className="flex items-center space-x-3">
        <Button title="Go Back" onClick={goBack} disabled={loading} />
        <Button title="Continue" color="primary" onClick={goNext} disabled={loading} />
      </div>
    </div>
  );
};

export default TagStep;
