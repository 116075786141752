import axios from 'axios';

import appSettings from '../app-settings';

const EXPORTS_URL = `${appSettings.baseUrl}/exports`;

export const getExports = async () => {
  const response = await axios.get(EXPORTS_URL);
  return response.data;
};

export const createExportDownloadUrl = async (bucketName, documentKey) => {
  const response = await axios.post(`${EXPORTS_URL}/download-url`, { bucketName, documentKey });
  return response.data.url;
};

export const getExport = async (jobId) => {
  const response = await axios.get(`${EXPORTS_URL}/${jobId}`);
  return response.data;
};
