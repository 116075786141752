import axios from 'axios';
import { startOfHour, subHours } from 'date-fns';

import appSettings from '../app-settings';

import contacts from '../data/contacts/index.json';
import count from '../data/contacts/count.json';
import countDonorspring from '../data/contacts/count-donorspring.json';
import countDonorspringSubscribed from '../data/contacts/count-donorspring-subscribed.json';
import donations from '../data/donations.json';
import reports from '../data/reports.json';
import stripeDetails from '../data/donations/stripe-details.json';

const mocks = {};

function addMock(url, data) {
  mocks[appSettings.baseUrl + url] = data;
}

const isUrlMocked = (url) => url in mocks;

const getMockError = (config) => {
  const mockError = new Error();
  mockError.mockData = mocks[config.url];
  mockError.config = config;
  return Promise.reject(mockError);
};

const isMockError = (error) => !!error.mockData;

const getMockResponse = (mockError) => {
  const { mockData, config } = mockError;

  // Handle mocked success
  return Promise.resolve({
    data: mockData,
    status: 200,
    statusText: 'OK',
    headers: {},
    config,
    isMock: true,
  });
};

axios.interceptors.request.use((config) => {
  if (isUrlMocked(config.url)) {
    // return an error to prevent the request from actually being made
    return getMockError(config);
  }

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (isMockError(error)) {
      return getMockResponse(error);
    }
    return Promise.reject(error);
  },
);

export function enableMocking() {
  const mockingEnabled = Object.keys(mocks).length;
  if (mockingEnabled) return;

  // Add recent createdAt timestamps to donations
  let updatedDonations = donations.map((donation) => {
    donation.createdAt = subHours(new Date(), donation.hoursAgo).getTime() / 1000;
    return donation;
  });

  // Add recent sendAt timestamps to repots
  const updatedReports = reports.map((report) => {
    report.sendAt = startOfHour(subHours(new Date(), report.hoursAgo).getTime()) / 1000;
    return report;
  });

  addMock('/contacts?page=1&limit=10&sort_by=updated_at&sort_direction=desc', contacts);
  addMock('/contacts/count?unsubscribed=false', count);
  addMock('/contacts/count?import_filename=donorspringupload_', countDonorspring);
  addMock('/contacts/count?unsubscribed=false&import_filename=donorspringupload_', countDonorspringSubscribed);
  addMock('/reports', updatedReports);
  addMock('/donations?dashboard=true', updatedDonations);
  addMock('/donations', updatedDonations);
  addMock('/donations/stripe-details', stripeDetails);
}
