import PropTypes from 'prop-types';

export const CONNECTION_STATUS = {
  connected: 'connected',
  pending: 'pending',
  notConnected: 'not-connected',
};

const ConnectionStatus = (props) => {
  const { status } = props;

  if (status === CONNECTION_STATUS.connected) {
    return (
      <div className="rounded border border-green-100 bg-green-50 px-1.5 py-0.5">
        <p className="text-caption1 font-semibold uppercase text-green-500">Connected</p>
      </div>
    );
  }

  if (status === CONNECTION_STATUS.pending) {
    return (
      <div className="rounded border border-orange-200 bg-orange-50 px-1.5 py-0.5">
        <p className="text-caption1 font-semibold uppercase text-orange-700">Incomplete Connection</p>
      </div>
    );
  }

  return (
    <div className="rounded border border-gray-100 bg-gray-10 px-1.5 py-0.5">
      <p className="text-caption1 font-semibold uppercase text-gray-400">Not connected</p>
    </div>
  );
};

ConnectionStatus.propTypes = {
  status: PropTypes.oneOf(['connected', 'pending', 'not-connected']),
};

ConnectionStatus.defaultProps = {
  status: CONNECTION_STATUS.notConnected,
};

export default ConnectionStatus;
