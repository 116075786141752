import { useState } from 'react';
import PropTypes from 'prop-types';

import HelperText from './HelperText';
import Label from './Label';

const Textarea = ({
  id,
  label,
  placeholder,
  value,
  onChange,
  containerClassName,
  labelClassName,
  inputClassName,
  disabled,
  error,
  rows,
  helperText,
  required,
  labelTooltip,
  readOnly,
  size,
  onFocus,
  onBlur,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = (e) => {
    setFocused(true);
    if (onFocus) onFocus(e);
  };

  const handleBlur = (e) => {
    setFocused(false);
    if (onBlur) onBlur(e);
  };

  return (
    <div className={containerClassName}>
      {!!label && size !== 'lg' && (
        <Label id={id} label={label} labelTooltip={labelTooltip} labelClassName={labelClassName} required={required} />
      )}
      <div className="w-full">
        <div
          className={`
          relative
          ${disabled || readOnly ? 'cursor-not-allowed' : 'cursor-auto'}
        `}
        >
          <textarea
            id={id}
            rows={rows}
            className={`
            peer
            block
            w-full
            rounded-lg
            border
            border-gray-100
            bg-white-100
            text-gray-950
            placeholder:text-transparent
            read-only:bg-gray-10
            focus:outline-none
            active:ring-0
            disabled:bg-gray-10
            ${size === 'lg' ? 'text-lg' : 'text-base'}
            ${size === 'lg' ? 'px-4 py-3.5' : 'p-2.5'}
            ${error ? 'border-red-500' : 'border-gray-100'}
            ${error && value ? 'border-2' : ''}
            ${
              disabled || readOnly
                ? 'pointer-events-none'
                : `
                  pointer-events-auto 
                  hover:border
                  hover:border-gray-200
                  focus:ml-[-1px]
                  focus:mt-[-1px]
                  focus:border-2
                  focus:border-primary-700
                  focus:ring-2
                  focus:ring-primary-300
                  focus:ring-offset-1
                `
            }
            ${inputClassName}
          `}
            placeholder={!placeholder && size === 'lg' ? label : placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            required={required}
            readOnly={readOnly}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...rest}
          ></textarea>
          {!!label && size === 'lg' && (
            <Label
              id={id}
              label={label}
              labelTooltip={labelTooltip}
              labelClassName={`
                absolute
                duration-300
                transform
                -translate-y-[18px]
                scale-75
                top-2
                px-1
                z-10
                origin-[0]
                bg-white-100
                peer-focus:px-1 
                peer-focus:!text-primary-700 
                peer-focus:font-semibold
                peer-placeholder-shown:scale-100 
                peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-[20%]
                peer-focus:top-2 
                peer-focus:scale-75 
                peer-focus:-translate-y-[18px] 
                peer-disabled:pointer-events-none
                peer-read-only:pointer-events-none
                peer-disabled:bg-gray-10
                peer-read-only:bg-gray-10
                left-2.5
                ${value ? 'font-semibold !text-gray-950' : '!text-gray-600'}
                ${(disabled || readOnly) && value ? '!text-gray-950' : '!text-gray-400'}
                ${error && value ? '!text-red-500' : ''}
                ${labelClassName}
                ${
                  focused || value
                    ? `
                      before:content-['']
                      before:absolute
                      before:top-0
                      before:left-0
                      before:h-1/2
                      before:w-full
                      before:bg-white-100
                    `
                    : ''
                }
              `}
              required={required}
              requiredClassName={`
                ${focused ? '!text-primary-700' : ''} 
                ${(disabled || readOnly) && !value ? '!text-gray-400' : ''}
              `}
            />
          )}
        </div>
        {!!helperText && <HelperText message={helperText} error={error} containerClassName="mt-1.5" />}
      </div>
    </div>
  );
};

Textarea.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  rows: PropTypes.number,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  labelTooltip: PropTypes.string,
  readOnly: PropTypes.bool,
  size: PropTypes.oneOf(['md', 'lg']),
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

Textarea.defaultProps = {
  containerClassName: '',
  labelClassName: '',
  inputClassName: '',
  disabled: false,
  rows: 4,
  required: false,
  readOnly: false,
  size: 'md',
};

export default Textarea;
