import { useState } from 'react';
import PropTypes from 'prop-types';

import Loader from '../loader/Loader';

const EmbeddedPage = (props) => {
  const { pageUrl, scrolling = true, className, interactive = true } = props;
  const [loading, setLoading] = useState(true);
  // helps mitigate cache issues
  const [name] = useState(Date.now().toString());

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div className="relative size-full bg-white-100">
      <iframe
        name={name}
        src={`${pageUrl}?name=${name}`}
        width="100%"
        height="100%"
        className={className}
        onLoad={handleLoad}
        scrolling={scrolling ? 'yes' : 'no'}
      />
      {!interactive && <div className="absolute inset-0" />}
      {loading && <Loader />}
    </div>
  );
};

EmbeddedPage.propTypes = {
  pageUrl: PropTypes.string.isRequired,
  scrolling: PropTypes.bool,
  className: PropTypes.string,
  interactive: PropTypes.bool,
};

export default EmbeddedPage;
