import {
  RECOMMENDED_TASK_SORT_BY,
  RECOMMENDED_TASK_STATUS_LABELS,
  campaignSendStatusLabels,
  campaignSortBy,
  sortDirections,
} from '../constants';

/**
 * Sorts array data and returns a new array. Does NOT mutate original array.
 * @param {Object[]} campaigns
 * @param {String} sortKey
 * @param {'asc'|'desc'} sortDirection
 * @returns {Object[]}
 */
export const sortCampaigns = (campaigns, sortKey, sortDirection) => {
  return [...campaigns].sort((a, b) => {
    let aVal = a[sortKey];
    let bVal = b[sortKey];
    const descSort = sortDirection === sortDirections.desc;

    if (sortKey === campaignSortBy.sendStatus) {
      aVal = campaignSendStatusLabels[aVal];
      bVal = campaignSendStatusLabels[bVal];
    }

    if (aVal < bVal) {
      if (sortKey === campaignSortBy.name) {
        return descSort ? -1 : 1;
      }

      return descSort ? 1 : -1;
    } else if (aVal > bVal) {
      if (sortKey === campaignSortBy.name) {
        return descSort ? 1 : -1;
      }

      return descSort ? -1 : 1;
    }

    return 0;
  });
};

/**
 * Sorts array data and returns a new array. Does NOT mutate original array.
 * @param {Object[]} reports
 * @param {String} sortKey
 * @param {'asc'|'desc'} sortDirection
 * @returns {Object[]}
 */
export const sortReports = (reports, sortKey, sortDirection) => {
  return [...reports].sort((a, b) => {
    let aVal = a[sortKey] ?? 0;
    let bVal = b[sortKey] ?? 0;
    const descSort = sortDirection === sortDirections.desc;
    if (aVal < bVal) {
      return descSort ? 1 : -1;
    }
    if (aVal > bVal) {
      return descSort ? -1 : 1;
    }
    return 0;
  });
};

/**
 * Sorts array data and returns a new array. Does NOT mutate original array.
 * @param {Object[]} data
 * @param {String} sortKey
 * @param {'asc'|'desc'} sortDirection
 * @returns {Object[]}
 */
export const sortDataWithDirection = (data, sortKey, sortDirection) => {
  return [...data].sort((a, b) => {
    const aVal = a[sortKey] ?? 0;
    const bVal = b[sortKey] ?? 0;
    const descSort = sortDirection === sortDirections.desc;
    if (aVal < bVal) {
      return descSort ? 1 : -1;
    }
    if (aVal > bVal) {
      return descSort ? -1 : 1;
    }
    return 0;
  });
};

/**
 * Sorts array data and returns a new array. Does NOT mutate original array.
 * @param {Object[]} recommendedTasks
 * @param {String} sortKey
 * @param {'asc'|'desc'} sortDirection
 * @returns {Object[]}
 */
export const sortRecommendedTasks = (recommendedTasks, sortKey, sortDirection) => {
  return [...recommendedTasks].sort((a, b) => {
    let aVal = a[sortKey];
    let bVal = b[sortKey];

    const descSort = sortDirection === sortDirections.desc;

    if (sortKey === RECOMMENDED_TASK_SORT_BY.status) {
      aVal = RECOMMENDED_TASK_STATUS_LABELS[aVal];
      bVal = RECOMMENDED_TASK_STATUS_LABELS[bVal];
    }

    if (aVal < bVal) {
      if (sortKey === RECOMMENDED_TASK_SORT_BY.name) {
        return descSort ? -1 : 1;
      }

      return descSort ? 1 : -1;
    } else if (aVal > bVal) {
      if (sortKey === RECOMMENDED_TASK_SORT_BY.name) {
        return descSort ? 1 : -1;
      }

      return descSort ? -1 : 1;
    }

    return 0;
  });
};
