import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import PropTypes from 'prop-types';

const StepNavBottom = ({ prevDisabled, nextDisabled, onNext, onPrev }) => {
  return (
    <div
      className="
        fixed 
        bottom-0 
        left-[264px] 
        z-[10]
        flex 
        h-[60px] 
        w-[calc(100%-264px)] 
        items-center 
        justify-center 
        bg-white-100 
        shadow-custom 
        md:left-[324px] 
        md:w-[calc(100%-324px)] 
        lg:left-[384px] 
        lg:w-[calc(100%-348px)]
      "
    >
      {!!onPrev && (
        <button
          className="
            flex 
            flex-1 
            items-center 
            justify-center 
            space-x-2 
            self-stretch 
            text-gray-800
            disabled:cursor-not-allowed
            disabled:opacity-60
          "
          onClick={onPrev}
          disabled={prevDisabled}
        >
          <IconChevronLeft size={18} />
          <span className="text-lg font-medium">Previous</span>
        </button>
      )}
      <button
        className="
          flex 
          flex-1 
          items-center 
          justify-center 
          space-x-2 
          self-stretch 
          text-primary-700
          disabled:cursor-not-allowed
          disabled:opacity-60
        "
        onClick={onNext}
        disabled={nextDisabled}
      >
        <span className="text-lg font-medium">Next</span>
        <IconChevronRight size={18} />
      </button>
    </div>
  );
};

StepNavBottom.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func,
  nextDisabled: PropTypes.bool,
  prevDisabled: PropTypes.bool,
};

StepNavBottom.defaultProps = {
  nextDisabled: false,
  prevDisabled: false,
};

export default StepNavBottom;
