import {
  startOfWeek,
  endOfWeek,
  startOfDay,
  differenceInMonths,
  eachDayOfInterval,
  format,
  subDays,
  isSameDay,
  differenceInDays,
  isWithinInterval,
  endOfDay,
} from 'date-fns';

import { sortDirections, AMOUNT_CATEGORIES, donationTypes, TREND_DIRECTIONS } from '../constants';
import { sortDataWithDirection } from './sorters';
import { parseAmount, parseDonationAmount } from './parsers';

const getStartAndEndDatesInMs = (data, sortKey, startTime, endTime) => {
  const dataCopy = [...data];
  const sortedData = sortDataWithDirection(dataCopy, sortKey, sortDirections.asc);

  let startDateInMs;
  let endDateInMs;

  if (startTime && endTime) {
    startDateInMs = startTime * 1000;
    endDateInMs = endTime * 1000;
  } else if (sortedData.length === 0) {
    const today = new Date();
    startDateInMs = startOfWeek(today).getTime();
    endDateInMs = endOfWeek(today).getTime();
  } else {
    startDateInMs = sortedData[0][sortKey] * 1000;
    endDateInMs = sortedData.slice(-1)[0][sortKey] * 1000;
  }

  return { sortedData, startDateInMs, endDateInMs };
};

const getDateRangeAndFormat = (startDate, endDate) => {
  const start = startOfDay(new Date(startDate));
  const end = startOfDay(new Date(endDate));
  const monthsDiff = Math.abs(differenceInMonths(start, end));
  let dateFormat = 'yyyy-MM-dd';

  if (monthsDiff > 3) dateFormat = 'yyyy-MM';

  const startToEndRange = eachDayOfInterval({ start, end });

  const dateRange = startToEndRange.reduce((acc, val) => {
    acc[format(val, dateFormat)] = 0;
    return acc;
  }, {});

  return { dateFormat, dateRange };
};

const groupDataByDate = (data, dateFormat, dateRange, sortKey, keyAccessor) => {
  return data.reduce(
    (acc, val) => {
      if (!val[sortKey]) return acc;
      const date = format(new Date(val[sortKey] * 1000), dateFormat);
      const incValue = keyAccessor ? val[keyAccessor] || 0 : 1;
      if (typeof acc[date] === 'number') acc[date] += incValue;
      return acc;
    },
    { ...dateRange },
  );
};

/**
 *
 * @param {Array} data - Data we want to format
 * @param {String} sortKey - Key of the property we want to use for sorting, it has to be a date in seconds.
 * @param {String} [keyAccessor] - Key of the property we want to aggregate
 * @param {Number} [startTime] - Start date in seconds
 * @param {Number} [endTime] - End date in seconds
 * @returns {Array}
 */
export const formatGraphData = (data, sortKey, keyAccessor, startTime, endTime) => {
  const { sortedData, startDateInMs, endDateInMs } = getStartAndEndDatesInMs(data, sortKey, startTime, endTime);

  const { dateFormat, dateRange } = getDateRangeAndFormat(startDateInMs, endDateInMs);

  const dataByDate = groupDataByDate(sortedData, dateFormat, dateRange, sortKey, keyAccessor);

  const formattedData = Object.keys(dataByDate).map((date) => {
    let count = dataByDate[date];
    if (keyAccessor === 'netAmount') count /= 100; // netAmount is in pennies
    return { date, count };
  });

  return formattedData;
};

const getAmountSpreadCategory = (amount) => {
  if (amount > 1001) return AMOUNT_CATEGORIES.fiveThousandPlus;
  if (amount > 501) return AMOUNT_CATEGORIES.oneThousand;
  if (amount > 101) return AMOUNT_CATEGORIES.fiveHundred;
  if (amount > 51) return AMOUNT_CATEGORIES.oneHundred;
  return AMOUNT_CATEGORIES.fifty;
};

export const formatDonationsAmountSpread = (donations) => {
  const spreadCategoriesData = {
    [AMOUNT_CATEGORIES.fifty]: 0,
    [AMOUNT_CATEGORIES.oneHundred]: 0,
    [AMOUNT_CATEGORIES.fiveHundred]: 0,
    [AMOUNT_CATEGORIES.oneThousand]: 0,
    [AMOUNT_CATEGORIES.fiveThousandPlus]: 0,
  };

  donations.forEach((donation) => {
    const netAmount = donation.netAmount / 100; // netAmount is in pennies
    spreadCategoriesData[getAmountSpreadCategory(netAmount)] += 1;
  });

  return Object.keys(spreadCategoriesData).map((amountCategory) => ({
    amountCategory,
    count: spreadCategoriesData[amountCategory],
  }));
};

export const formatDonationsByHour = (donations) => {
  const hoursData = Array.from({ length: 24 }, (_, i) => i + 1).reduce((acc, val) => {
    acc[format(new Date().setHours(val), 'h a')] = 0;
    return acc;
  }, {});

  donations.forEach((donation) => {
    const hour = format(new Date(donation.createdAt * 1000), 'h a');
    hoursData[hour] += 1;
  });

  return Object.keys(hoursData).map((hour) => ({ hour, count: hoursData[hour] }));
};

export const formatDonationsByDay = (donations) => {
  const daysData = { Mon: 0, Tue: 0, Wed: 0, Thu: 0, Fri: 0, Sat: 0, Sun: 0 };

  donations.forEach((donation) => {
    const day = format(new Date(donation.createdAt * 1000), 'E');
    daysData[day] += 1;
  });

  return Object.keys(daysData).map((day) => ({ day, count: daysData[day] }));
};

const getPeriodsIntervals = (startDate, endDate) => {
  const currentStart = startOfDay(startDate);
  const currentEnd = endOfDay(endDate);

  const currentPeriodInterval = { start: currentStart, end: currentEnd };

  // Does not include the start date
  const periodDays = Math.abs(differenceInDays(currentStart, currentEnd));
  const prevEndPeriod = endOfDay(subDays(currentStart, 1));
  const prevStartPeriod = startOfDay(subDays(prevEndPeriod, periodDays));

  const prevPeriodInterval = { start: prevStartPeriod, end: prevEndPeriod };

  // Period days correction, add one to include the start date
  return { prevPeriodInterval, currentPeriodInterval, periodDays: periodDays + 1 };
};

const getTrendDirection = (periodAmountDiff) => {
  if (periodAmountDiff > 0) return TREND_DIRECTIONS.up;
  if (periodAmountDiff < 0) return TREND_DIRECTIONS.down;
  return TREND_DIRECTIONS.equal;
};

export const getDonationAmountStats = (donations, startTime, endTime) => {
  const { sortedData, startDateInMs, endDateInMs } = getStartAndEndDatesInMs(
    donations,
    'createdAt',
    startTime,
    endTime,
  );

  const { prevPeriodInterval, currentPeriodInterval, periodDays } = getPeriodsIntervals(startDateInMs, endDateInMs);

  const { count, prevTotalAmount, totalAmount, oneTimeAmount, recurringAmount } = sortedData.reduce(
    (acc, val) => {
      const donationDate = new Date(val.createdAt * 1000);
      if (isWithinInterval(donationDate, prevPeriodInterval)) {
        acc.prevTotalAmount += val.netAmount;
      }
      if (isWithinInterval(donationDate, currentPeriodInterval)) {
        acc.count += 1;
        acc.totalAmount += val.netAmount;
        if (val.type === donationTypes.oneTime) acc.oneTimeAmount += val.netAmount;
        if (val.type === donationTypes.recurring) acc.recurringAmount += val.netAmount;
      }
      return acc;
    },
    { count: 0, prevTotalAmount: 0, totalAmount: 0, oneTimeAmount: 0, recurringAmount: 0 },
  );

  const periodAmountDiff = totalAmount - prevTotalAmount;

  return {
    title: 'Donations, USD',
    totalAmount: parseDonationAmount(totalAmount / 100),
    // Change in amount -> current period - previous period
    trendAmount: parseDonationAmount(Math.abs(periodAmountDiff) / 100),
    trendDirection: getTrendDirection(periodAmountDiff),
    trendLabel: periodDays === 1 ? 'Than yesterday' : `Previous ${periodDays} days`,
    dataSections: [
      { label: 'Count', value: parseAmount(count, 0) },
      { label: 'One-time', value: parseDonationAmount(oneTimeAmount / 100) },
      { label: 'Recurring', value: parseDonationAmount(recurringAmount / 100) },
    ],
  };
};

export const getDonationCountStats = (donations, startTime, endTime) => {
  const { sortedData, startDateInMs, endDateInMs } = getStartAndEndDatesInMs(
    donations,
    'createdAt',
    startTime,
    endTime,
  );

  const { prevPeriodInterval, currentPeriodInterval, periodDays } = getPeriodsIntervals(startDateInMs, endDateInMs);

  const { prevCount, count, oneTimeCount, recurringCount } = sortedData.reduce(
    (acc, val) => {
      const donationDate = new Date(val.createdAt * 1000);
      if (isWithinInterval(donationDate, prevPeriodInterval)) {
        acc.prevCount += 1;
      }
      if (isWithinInterval(donationDate, currentPeriodInterval)) {
        acc.count += 1;
        if (val.type === donationTypes.oneTime) acc.oneTimeCount += 1;
        if (val.type === donationTypes.recurring) acc.recurringCount += 1;
      }
      return acc;
    },
    { prevCount: 0, count: 0, oneTimeCount: 0, recurringCount: 0 },
  );

  const periodCountDiff = count - prevCount;

  return {
    title: 'Donations, #',
    totalAmount: parseAmount(count, 0),
    // Change in amount -> current period - previous period
    trendAmount: parseAmount(Math.abs(periodCountDiff), 0),
    trendDirection: getTrendDirection(periodCountDiff),
    trendLabel: periodDays === 1 ? 'Than yesterday' : `Previous ${periodDays} days`,
    dataSections: [
      { label: 'One-time', value: parseAmount(oneTimeCount, 0) },
      { label: 'Recurring', value: parseAmount(recurringCount, 0) },
    ],
  };
};

export const getTodayDonationStats = (donations) => {
  const today = startOfDay(new Date());
  const yesterday = subDays(new Date(), 1);

  const { count, prevTotalAmount, totalAmount, maxAmount } = donations.reduce(
    (acc, val) => {
      const donationDate = new Date(val.createdAt * 1000);
      if (isSameDay(yesterday, donationDate)) {
        acc.prevTotalAmount += val.netAmount;
      }
      if (isSameDay(today, donationDate)) {
        acc.count += 1;
        acc.totalAmount += val.netAmount;
        if (val.netAmount > acc.maxAmount) acc.maxAmount = val.netAmount;
      }
      return acc;
    },
    { count: 0, prevTotalAmount: 0, totalAmount: 0, maxAmount: 0 },
  );

  const periodAmountDiff = totalAmount - prevTotalAmount;

  return {
    title: 'Donation Today, USD',
    totalAmount: parseDonationAmount(totalAmount / 100),
    // Change in amount -> current period - previous period
    trendAmount: parseDonationAmount(Math.abs(periodAmountDiff) / 100),
    trendDirection: getTrendDirection(periodAmountDiff),
    trendLabel: 'Than yesterday',
    dataSections: [
      { label: 'Count', value: parseAmount(count, 0) },
      { label: 'Maximum', value: parseDonationAmount(maxAmount / 100) },
      { label: 'Average', value: parseDonationAmount(totalAmount / count / 100) },
    ],
  };
};

const getClickThroughRate = (clicks, opens) => {
  if (!clicks || !opens) return 0;
  return Math.round((clicks / opens) * 100);
};

/**
 * @debt Approximation for email events stats using reports data.
 * This assumes that clicks/opens/unsubscribes occurred on the
 * same day of the email/campaign "sendAt" field which might not
 * be true in all cases.
 * @param {Array} reports List of reports
 * @param {Number} startTime Start date in seconds
 * @param {Number} endTime End date in seconds
 * @returns {Object} Email report stats
 */
export const getEmailReportStats = (reports, startTime, endTime) => {
  const { sortedData, startDateInMs, endDateInMs } = getStartAndEndDatesInMs(
    reports.filter((report) => !!report.sendAt),
    'sendAt',
    startTime,
    endTime,
  );

  const { prevPeriodInterval, currentPeriodInterval, periodDays } = getPeriodsIntervals(startDateInMs, endDateInMs);

  const { prevTotalContacts, totalContacts, clickCount, openCount, unsubscribeCount } = sortedData.reduce(
    (acc, val) => {
      const emailSendAt = new Date(val.sendAt * 1000);
      if (isWithinInterval(emailSendAt, prevPeriodInterval)) {
        acc.prevTotalContacts += parseInt(val.totalContacts || 0, 10);
      }
      if (isWithinInterval(emailSendAt, currentPeriodInterval)) {
        acc.totalContacts += parseInt(val.totalContacts || 0, 10);
        acc.clickCount += val.click || 0;
        acc.openCount += val.open || 0;
        acc.unsubscribeCount += val.unsubscribe || 0;
      }
      return acc;
    },
    { prevTotalContacts: 0, totalContacts: 0, clickCount: 0, openCount: 0, unsubscribeCount: 0 },
  );

  const periodAmountDiff = totalContacts - prevTotalContacts;

  return {
    title: 'Email Recipients',
    totalAmount: parseAmount(totalContacts, 0),
    // Change in amount -> current period - previous period
    trendAmount: parseAmount(Math.abs(periodAmountDiff), 0),
    trendDirection: getTrendDirection(periodAmountDiff),
    trendLabel: periodDays === 1 ? 'Than yesterday' : `Previous ${periodDays} days`,
    dataSections: [
      { label: 'Clicks', value: parseAmount(clickCount, 0) },
      { label: 'Click Through', value: `${getClickThroughRate(clickCount, openCount)}%` },
      { label: 'Unsubscribes', value: parseAmount(unsubscribeCount, 0) },
    ],
  };
};

export const getFormatString = (dateString) => {
  if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    return 'yyyy-MM-dd';
  } else if (/^\d{4}-\d{2}$/.test(dateString)) {
    return 'yyyy-MM';
  } else {
    return 'yyyy';
  }
};
