import isEmpty from 'lodash.isempty';

import appSettings from '../../app-settings';
import { IMAGE_KEYS_WITH_DEFAULT_BASE64_DATA } from '../constants';

const isImageAccountLogo = (imageUrl) => imageUrl.includes('/logo/');

const getPageImageUrl = ({ tenantId, slug, imageKey }) => {
  return `${appSettings.pagesImagesBucketUrl}/${tenantId}/${slug}/images/${imageKey}`;
};

export const getImageKeysToUpload = (images) => {
  const imageKeys = Object.keys(images);

  if (!imageKeys.length) return [];

  return imageKeys.filter((key) => {
    const image = images[key];
    if (!IMAGE_KEYS_WITH_DEFAULT_BASE64_DATA.includes(key)) {
      return !!image.data;
    }
    // When an image has been uploaded the image key is included in the url
    const hasBeenUploadedBefore = !!image.url.includes(key);
    if (key === 'headerImage') {
      return !!image.data || (!hasBeenUploadedBefore && !isImageAccountLogo(image.url));
    }
    return !!image.data || !hasBeenUploadedBefore;
  });
};

export const getPageImagesUrls = ({ images, tenantId, slug }) => {
  const imageKeys = Object.keys(images);

  if (!imageKeys.length) return null;

  return imageKeys.reduce((imgs, imageKey) => {
    if (imageKey === 'headerImage') {
      imgs[imageKey] = {
        url: isImageAccountLogo(images.headerImage.url)
          ? images.headerImage.url
          : getPageImageUrl({ tenantId, slug, imageKey: 'headerImage' }),
      };
    } else {
      imgs[imageKey] = { url: getPageImageUrl({ tenantId, slug, imageKey }) };
    }
    return imgs;
  }, {});
};

export const getPageUrl = ({ account, slug = '', params = {} }) => {
  // Use hardcoded 'sandbox' subdomain for sandbox accounts to get the iframe preview working
  // Sandbox accounts may not have a donationPageSubdomain set since they didn't go through onboarding
  const subdomain = account?.sandbox ? 'sandbox' : account?.donationPageSubdomain;
  // "default" is a special slug that is used for the default donation page
  const slugPath = slug === 'default' ? '' : slug;
  let url = `https://${subdomain}.${appSettings.donationPageDomain}/${slugPath}`;
  if (!isEmpty(params)) {
    url += `?${new URLSearchParams(params).toString()}`;
  }
  return url;
};

export const nameToSlug = (name) => {
  return name.toLowerCase().replace(/\s/g, '-');
};
