import axios from 'axios';
import fileDownload from 'js-file-download';

import { createExportDownloadUrl } from '../../api';

export const getContactName = (contact) => {
  let name = '';
  if (contact?.first_name) {
    name += contact.first_name;
  }
  if (contact?.last_name) {
    name += ` ${contact.last_name}`;
  }
  return name.trim();
};

export const getContactAddress = (contact) => {
  const addressParts = [];

  if (contact?.address_line_1) {
    addressParts.push(contact.address_line_1);
  }
  if (contact?.address_line_2) {
    addressParts.push(contact.address_line_2);
  }

  const cityStateParts = [];

  if (contact?.city) {
    cityStateParts.push(contact.city);
  }
  if (contact?.state_province_region) {
    cityStateParts.push(contact.state_province_region);
  }

  let address = addressParts.join('\n');
  if (addressParts.length > 0 && cityStateParts.length > 0) {
    address += '\n';
  }
  address += cityStateParts.join(', ');

  if (contact?.postal_code) {
    address += ` ${contact.postal_code}`;
  }

  return address.trim();
};

export const downloadContactsFile = async (bucketName, documentKey, filename) => {
  const downloadUrl = await createExportDownloadUrl(bucketName, documentKey);
  const res = await axios.get(downloadUrl, { responseType: 'blob', headers: { Authorization: '' } });
  fileDownload(res.data, filename);
};

export const shouldObscureContacts = (account) => {
  return typeof account.obscureContacts !== 'boolean' || account.obscureContacts;
};

export const isDsContact = (importFilename) => {
  return /^donorspringupload_.*$/.test(importFilename);
};

export const isFieldObscured = (field) => {
  return ['first_name', 'last_name', 'email', 'address_line_1', 'address_line_2', 'phone_number'].includes(field);
};
