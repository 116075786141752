import { createTheme } from '@mui/material/styles';

import { PRIMARY_BASE_COLOR } from './constants';

export const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_BASE_COLOR,
    },
    secondary: {
      main: '#6EADE1',
    },
    warning: {
      main: '#333000',
    },
    save: {
      main: '#006600',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& input:focus': {
            boxShadow: 'none !important',
          },
          '& input.MuiAutocomplete-input': {
            padding: '0px 4px !important',
          },
          '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.23) !important',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: ['"Inter Tight"', 'sans-serif'].join(','),
  },
});
