import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { setError, setSuccess, getStripeAccount, disconnectStripeAccount } from './actions';

const initialState = {
  loading: false,
  error: false,
  success: false,
  stripeAccount: null,
};

const paymentOptionsStore = createSlice({
  name: 'paymentOptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(getStripeAccount.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.stripeAccount = payload;
    });

    builder.addCase(disconnectStripeAccount.fulfilled, (state) => {
      state.loading = false;
      state.stripeAccount = null;
      state.success = 'Account successfully disconnected!';
    });

    builder.addMatcher(isAnyOf(getStripeAccount.pending, disconnectStripeAccount.pending), (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    });

    builder.addMatcher(isAnyOf(getStripeAccount.rejected, disconnectStripeAccount.rejected), (state, { error }) => {
      state.loading = false;
      state.error = error.message || true;
    });
  },
});

export default paymentOptionsStore.reducer;
