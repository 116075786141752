import { useState, useRef } from 'react';
import { ClickAwayListener, Paper, Popper, Grow, MenuList, MenuItem } from '@mui/material';
import { IconClockHour4 } from '@tabler/icons-react';
import { format, endOfDay, addMinutes, isToday, startOfDay, isValid } from 'date-fns';
import PropTypes from 'prop-types';

import Input from '../inputs/Input';

const generateTimeOptions = (intervalMinutes, startDate) => {
  let currentDate = new Date();

  if (!isToday(startDate)) {
    currentDate = startOfDay(currentDate);
  }

  const roundedMinutes = Math.ceil(currentDate.getMinutes() / intervalMinutes) * intervalMinutes;

  const startTime = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    currentDate.getHours(),
    roundedMinutes,
  );
  const endTime = endOfDay(startTime);

  const timeOptions = [];
  let currentTimeOption = startTime;

  while (currentTimeOption.getTime() <= endTime.getTime()) {
    timeOptions.push({ label: format(currentTimeOption, 'h:mm a'), value: currentTimeOption });
    currentTimeOption = addMinutes(currentTimeOption, intervalMinutes);
  }

  return timeOptions;
};

const TimePicker = ({ value, onChange, required, disabled }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(format(value, 'h:mm a'));
  const anchorRef = useRef(null);

  const handleClose = (event) => {
    if (!event) return;

    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleChange = (date) => {
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const clonedDate = new Date(value.getTime());
    clonedDate.setHours(hour);
    clonedDate.setMinutes(minutes);
    clonedDate.setSeconds(0);
    clonedDate.setMilliseconds(0);
    onChange(clonedDate);
    setInputValue(format(clonedDate, 'h:mm a'));
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const timeString = e.target.value;

    setInputValue(timeString.toUpperCase());

    let [hours, minutes, period] = timeString.match(/(\d+):(\d+)(\s?[AaPp][Mm])/)?.slice(1) ?? [];
    period = period?.trim().toLowerCase();

    if (hours?.length > 2 || minutes?.length > 2 || !['am', 'pm'].includes(period)) {
      return;
    }

    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    if (period === 'pm') {
      hours += 12;
    }

    const dateValue = new Date(value.getTime());
    dateValue.setHours(hours);
    dateValue.setMinutes(minutes);
    dateValue.setSeconds(0);
    dateValue.setMilliseconds(0);

    if (isValid(dateValue)) {
      onChange(dateValue);
    }
  };

  return (
    <>
      <Input
        id="time"
        label="Time"
        value={inputValue}
        size="xl"
        containerClassName="w-[140px]"
        LeftIcon={IconClockHour4}
        iconProps={{ size: 20 }}
        onChange={handleInputChange}
        onFocus={() => setOpen(true)}
        onBlur={() => setInputValue(format(value, 'h:mm a'))}
        required={required}
        disabled={disabled}
        ref={anchorRef}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        className="z-[100]"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper classes={{ root: '!rounded-lg my-0.5 overflow-hidden !shadow-custom' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList classes={{ root: 'max-h-[232px] overflow-y-auto' }}>
                  {generateTimeOptions(30, value).map((time) => (
                    <MenuItem
                      key={time.label}
                      classes={{ root: '!py-2 !px-3.5', selected: '!bg-white-100 !text-primary-700' }}
                      onClick={() => handleChange(time.value)}
                      selected={format(time.value, 'h:mm a') === format(value, 'h:mm a')}
                    >
                      <span className="text-base">{time.label}</span>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

TimePicker.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

TimePicker.defaultProps = {
  required: false,
  disabled: false,
};

export default TimePicker;
