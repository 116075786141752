import { useState, useCallback } from 'react';
import { Menu } from '@mui/material';
import { IconTableExport } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import { Button, Input } from 'ui';
import { getNetworkError } from 'common/utils';

import { useContacts } from '../../store/contacts/hooks';
import appSettings from '../../app-settings';
import { EXPORT_FILENAME_REGEX, EXPORT_JOB_STATUS, INVALID_EXPORT_FILENAME_MESSAGE } from '../../core/constants';
import { downloadContactsFile, getExportJobFilename, removeEmptyValues } from '../../core/utils';
import { getExport as getExportApi } from '../../api';
import useInterval from '../../hooks/useInterval';

const CONTACTS_URL = `${appSettings.baseUrl}/contacts`;

const POLLING_INTERVAL_IN_MS = 5000;

const formValidation = yup.object({
  exportFilename: yup
    .string()
    .required('Export name is required.')
    .min(2, 'Export name must be at least 2 characters long.')
    .matches(EXPORT_FILENAME_REGEX, INVALID_EXPORT_FILENAME_MESSAGE),
});

const ExportContactsDropdown = (props) => {
  const { totalContacts, columns, filters, searchTerm } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [jobInProgress, setJobInProgress] = useState(null);
  const { setError, setSuccess } = useContacts();
  const form = useForm({
    initialValues: { exportFilename: '' },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(
    (force = false) => {
      if (!force && (loading || jobInProgress)) return;
      setAnchorEl(null);
      form.reset();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobInProgress, loading],
  );

  const getExport = useCallback(async () => {
    if (!jobInProgress?.jobId) return;
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      const job = await getExportApi(jobInProgress.jobId);
      if ([EXPORT_JOB_STATUS.done, EXPORT_JOB_STATUS.failed].includes(job.status)) {
        setJobInProgress(null);

        if (job.status === EXPORT_JOB_STATUS.failed) {
          throw new Error('Export failed!');
        }

        const filename = getExportJobFilename(job);
        await downloadContactsFile(job.s3.bucket, job.s3.key, filename);
        setSuccess('Export completed successfully!');
        handleClose(true);
      }
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }
    setLoading(false);
  }, [handleClose, jobInProgress?.jobId, setError, setSuccess]);

  useInterval(getExport, jobInProgress ? POLLING_INTERVAL_IN_MS : null);

  const handleExport = async (values) => {
    setError('');
    setLoading(true);
    try {
      const payload = { exportFilename: values.exportFilename, columns };
      const payloadFilters = removeEmptyValues({
        ...filters,
        listIds: filters.tagIds,
        match:
          filters.segmentIds.length > 0 || filters.tagIds.length > 0 || filters.contactType || filters.unsubscribed
            ? filters.match
            : undefined,
        searchTerm,
      });
      if (Object.keys(payloadFilters).length > 0) {
        payload.filters = payloadFilters;
      }
      const response = await axios.post(`${CONTACTS_URL}/export`, payload);
      setJobInProgress(response.data);
      setSuccess('Export started successfully!');
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }
    setLoading(false);
  };

  const isFormValid = form.isValid();
  const isFormDirty = form.isDirty();

  return (
    <>
      <Button
        title="Export Contacts"
        className={open ? 'text-primary-600' : undefined}
        LeftIcon={IconTableExport}
        onClick={handleClick}
        disabled={loading || totalContacts === 0}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        classes={{ paper: 'w-[320px] !rounded-lg mt-0.5 !overflow-hidden ml-[-155px]', list: '!p-0' }}
        PaperProps={{ component: 'form', noValidate: true, onSubmit: form.onSubmit(handleExport) }}
      >
        <li className="p-4 text-base">
          <span className="font-semibold">{totalContacts}</span> contacts will be exported in CSV format based on your
          selected columns, filters, and search query.
        </li>

        <li className="px-4 text-base">Past exports can be viewed on the &quot;Exports&quot; page.</li>

        <li className="p-4 pt-3">
          <Input
            id="export-name"
            label="Export name"
            {...form.getInputProps('exportFilename')}
            required
            size="xl"
            error={!!form.errors.exportFilename}
            helperText={form.errors.exportFilename}
            onKeyDown={(e) => {
              // stops event bubbling to avoid focusing an item in the list
              e.stopPropagation();
            }}
          />
        </li>

        <li className="flex items-center justify-between border-t border-gray-50 p-4">
          <Button
            title={jobInProgress ? 'Exporting...' : 'Export Contacts'}
            color="primary"
            className="w-full"
            type="submit"
            loading={loading || !!jobInProgress}
            disabled={!isFormValid || !isFormDirty}
          />
        </li>
      </Menu>
    </>
  );
};

ExportContactsDropdown.propTypes = {
  totalContacts: PropTypes.number.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  filters: PropTypes.shape({
    segmentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    tagIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    contactType: PropTypes.string,
    unsubscribed: PropTypes.string,
    match: PropTypes.string,
  }).isRequired,
  searchTerm: PropTypes.string.isRequired,
};

export default ExportContactsDropdown;
