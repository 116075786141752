import axios from 'axios';
import axiosRetry from 'axios-retry';
import * as Sentry from '@sentry/react';

import { AUTH_SESSION_KEY } from './constants';
import { removeFalsyValues } from './utils';

axiosRetry(axios, {
  retries: 2,
  retryCondition: (error) => {
    if (error.config.url.includes('accounts/current')) {
      // We're currently only retrying get current account requests
      return true;
    }
    return false;
  },
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      sessionStorage.clear();
      Sentry.setUser(null);
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

axios.interceptors.request.use((req) => {
  const authToken = sessionStorage.getItem(AUTH_SESSION_KEY);

  if (!authToken) return req;

  req.headers = removeFalsyValues({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`,
    ...req.headers,
  });

  return req;
});
