import * as yup from 'yup';

const domainRegEx = /^(?!www\.)[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]$/;

export const formValidation = yup.object({
  domainName: yup
    .string()
    .matches(domainRegEx, { message: 'Invalid domain', excludeEmptyString: true })
    .required('Domain name is required.'),
});

export const initialValues = {
  domainName: '',
};
