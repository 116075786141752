import PropTypes from 'prop-types';
import { CONTACT_STATUS_LABELS } from '../../core/constants';

const getContainerColorClassNames = (status) => {
  switch (status) {
    case 'subscribed':
      return 'border-green-100 bg-green-50 text-green-500';
    case 'unsubscribed':
      return 'border-red-100 bg-red-50 text-red-500';
    case 'archived':
      return 'border-gray-100 bg-gray-10 text-gray-500';
    default:
      return '';
  }
};

const ContactStatusLabel = (props) => {
  const { status, label = '', containerClassName = '' } = props;

  return (
    <div className={`w-fit rounded border px-1.5 py-0.5 ${getContainerColorClassNames(status)} ${containerClassName}`}>
      <p className="truncate text-caption1 font-semibold uppercase">{label || CONTACT_STATUS_LABELS[status]}</p>
    </div>
  );
};

ContactStatusLabel.propTypes = {
  status: PropTypes.string.isRequired,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default ContactStatusLabel;
