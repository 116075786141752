import { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { IconChevronDown, IconLogout, IconSettings } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

import { useAccount } from '../../store/account/hooks';
import { signOut } from '../../core/utils';

const AccountDropdown = () => {
  const { account } = useAccount();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToSettings = () => {
    handleClose();
    navigate('/settings');
  };

  return (
    <>
      <button className="flex items-center space-x-2 text-base font-semibold" onClick={handleClick}>
        <span>{account.organizationName}</span>
        <IconChevronDown size={16} className="m-1" />
      </button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        classes={{ paper: '!rounded-lg mt-2 !shadow-custom' }}
      >
        <MenuItem onClick={goToSettings} className="space-x-2 !py-2">
          <IconSettings size={18} />
          <span className="text-base">Settings</span>
        </MenuItem>
        <MenuItem onClick={signOut} className="space-x-2 !py-2">
          <IconLogout size={18} />
          <span className="text-base">Sign Out</span>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountDropdown;
