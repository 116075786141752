import { useState } from 'react';
import PropTypes from 'prop-types';
import { IconChevronDown } from '@tabler/icons-react';

import Label from './Label';
import HelperText from './HelperText';

const getLeftPaddingClassName = (size, icon) => {
  if (!icon) {
    if (size !== 'md') return 'pl-4';
    return 'pl-3.5';
  }
  if (size !== 'md') return 'pl-[46px]';
  return 'pl-11';
};

const SelectInput = ({
  value,
  onChange,
  options,
  label,
  containerClassName,
  className,
  labelClassName,
  labelTooltip,
  disabled,
  required,
  error,
  helperText,
  readOnly,
  LeftIcon,
  size,
  onBlur,
  onFocus,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = (e) => {
    setFocused(true);
    if (onFocus) onFocus(e);
  };

  const handleBlur = (e) => {
    setFocused(false);
    if (onBlur) onBlur(e);
  };

  return (
    <div className={containerClassName}>
      {!!label && size !== 'lg' && (
        <Label label={label} labelClassName={labelClassName} labelTooltip={labelTooltip} required={required} />
      )}
      <div
        className={`
          relative 
          flex 
          items-center 
          ${disabled || readOnly ? 'cursor-not-allowed' : 'cursor-auto'}
          ${size === 'md' ? 'h-10' : 'h-[52px]'}
        `}
      >
        <select
          className={`
          peer
          block 
          size-full
          cursor-auto
          rounded-lg
          border
          bg-white-100
          p-2.5
          leading-[1rem]
          hover:border
          hover:border-gray-200
          focus:border-2
          focus:border-primary-700
          focus:outline-none
          focus:ring-2
          focus:ring-primary-300
          focus:ring-offset-1
          active:ring-0
          disabled:bg-gray-10
          disabled:opacity-100
          ${size === 'lg' ? 'text-lg' : 'text-base'}
          ${size === 'md' ? 'pr-7' : 'pr-9'}
          ${getLeftPaddingClassName(size, !!LeftIcon)}
          ${value ? 'text-gray-950' : 'text-gray-600'}
          ${error ? 'border-red-500' : 'border-gray-100'}
          ${error && value ? 'border-2' : ''}
          ${disabled || readOnly ? 'pointer-events-none' : 'pointer-events-auto'}
          ${readOnly ? '!bg-gray-10' : ''}
          ${className}
        `}
          onChange={onChange}
          value={value}
          disabled={disabled}
          required={required}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...rest}
        >
          {options.map((option, index) => (
            <option key={`${option.value}-${index}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {!!label && size === 'lg' && (
          <Label
            label={label}
            labelClassName={`
              absolute
              duration-300
              transform
              px-1
              z-10
              origin-[0]
              bg-white-100
              !text-gray-600
              peer-focus:px-1 
              peer-focus:!text-primary-700 
              peer-focus:font-semibold
              scale-100 
              -translate-y-1/2 
              top-1/2 
              pointer-events-none
              peer-focus:top-2 
              peer-focus:scale-75 
              peer-focus:-translate-y-[18px] 
              peer-disabled:bg-gray-10
              peer-disabled:scale-75
              peer-disabled:-translate-y-[18px] 
              peer-disabled:top-2
              ${LeftIcon && !focused && !value ? 'left-[46px]' : 'left-2.5'}
              ${(disabled || readOnly) && value ? 'font-semibold !text-gray-950' : '!text-gray-400'}
              ${labelClassName}
              ${
                focused || value
                  ? `
                    before:content-['']
                    before:absolute
                    before:top-0
                    before:left-0
                    before:h-1/2
                    before:w-full
                    before:bg-white-100
                  `
                  : ''
              }
            `}
            labelTooltip={labelTooltip}
            required={required}
            requiredClassName={`
              ${focused ? '!text-primary-700' : ''} 
              ${(disabled || readOnly) && !value ? '!text-gray-400' : ''}
            `}
          />
        )}
        {LeftIcon && (
          <LeftIcon
            sx={{ fontSize: 18 }}
            className={`
              absolute 
              ${size === 'md' ? 'left-3.5' : 'left-4'} 
              ${!value ? 'text-gray-600' : 'text-gray-950'} 
              ${readOnly && value ? 'text-gray-800' : ''} 
              ${disabled ? '!text-gray-400' : ''}
              peer-valid:text-gray-950 
              peer-focus:text-gray-950 
            `}
          />
        )}
        <IconChevronDown
          size={16}
          className={`
            absolute
            ${size === 'md' ? 'right-2' : 'right-3'}
            ${disabled && !value ? 'text-gray-400' : 'text-gray-600'}
            pointer-events-none	
            peer-focus:rotate-180
          `}
        />
      </div>
      {!!helperText && <HelperText message={helperText} error={error} containerClassName="mt-1.5" />}
    </div>
  );
};

SelectInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.any.isRequired }))
    .isRequired,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  labelTooltip: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  readOnly: PropTypes.bool,
  LeftIcon: PropTypes.object,
  size: PropTypes.oneOf(['md', 'lg']),
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

SelectInput.defaultProps = {
  containerClassName: '',
  className: '',
  labelClassName: '',
  disabled: false,
  required: false,
  error: false,
  readOnly: false,
  size: 'md',
};

export default SelectInput;
