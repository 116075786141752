import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { IconArrowLeft, IconFileCheck, IconFilePencil } from '@tabler/icons-react';
import debounce from 'lodash.debounce';
import isEmpty from 'lodash.isempty';
import { TestEmailModal } from 'ui';

import appSettings from '../app-settings';
import { DEBOUNCE_DELAY_TIME_MS, PREVIEW_HTML_KEY, templateTypes, STATUS_STATES } from '../core/constants';
import AlertMessage from '../components/alerts/AlertMessage';
import { useCampaigns } from '../store/campaigns/hooks';
import { useCallbackPrompt } from '../hooks/useCallbackPrompt';
import ConfirmExitPageModal from '../components/modals/ConfirmExitPageModal';
import Loader from '../components/loader/Loader';
import { useAccount } from '../store/account/hooks';
import Button from '../components/buttons/Button';
import MergeTagBuilderModal from '../components/modals/MergeTagBuilderModal';
import { useEmailTemplates } from '../store/email-templates/hooks';
import ContentGeneratorModal from '../components/modals/ContentGeneratorModal';
import { getNetworkError, injectTemplateDesignSections, getLinkTypes, getEditorOptions } from '../core/utils';
import IconButton from '../components/buttons/IconButton';
import { getDefaultSectionTemplates, sendTestEmail } from '../api';
import { usePages } from '../store/pages/hooks';
import { getPageUrl } from '../core/utils/pages';

const EMAIL_TEMPLATES_URL = `${appSettings.baseUrl}/email-templates`;

const CAMPAIGNS_URL = `${appSettings.baseUrl}/campaigns`;

const updateCampaign = async (campaignId, data) => {
  await axios.patch(`${CAMPAIGNS_URL}/${campaignId}`, data);
};

const createTemplate = async (design, { order, type }, emailConfig) => {
  const res = await axios.post(EMAIL_TEMPLATES_URL, { design: JSON.stringify(design), order, type, emailConfig });
  return res.data;
};

const uploadHtml = async (uploadUrl, html) => {
  await axios.put(uploadUrl, html, { headers: { 'Content-Type': 'text/html', Authorization: '' } });
};

const updateTemplate = async (templateId, version, design, emailConfig) => {
  const res = await axios.put(`${EMAIL_TEMPLATES_URL}/${templateId}/${version}`, {
    design: JSON.stringify(design),
    emailConfig,
  });
  return res.data;
};

const bodyValues = {
  fontFamily: {
    label: 'Lato',
    value: "'Lato', Tahoma, Verdana, sans-serif",
  },
};

const EmailEdits = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [testOpen, setTestOpen] = useState(false);
  const [blockExit, setBlockExit] = useState(true);
  const [mergeTagBuilderOpen, setMergeTagBuilderOpen] = useState(false);
  const [contentGeneratorOpen, setContentGeneratorOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editorLoading, setEditorLoading] = useState(true);
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const { campaignDetails, campaignTemplate, setCampaignTemplate } = useCampaigns();
  const location = useLocation();
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(blockExit);
  const { account, loading: accountLoading } = useAccount();
  const { template } = useEmailTemplates();
  const [selectedTemplate, setSelectedTemplate] = useState(location.state?.selectedTemplate);
  const [loadingTemplate, setLoadingTemplate] = useState(true);
  const isRecurringTemplate = selectedTemplate?.type === templateTypes.recurring;
  const { pageList, status } = usePages();

  const exportHtml = useCallback(async () => {
    return new Promise((resolve, reject) => {
      if (!editorRef.current) reject();
      else editorRef.current.exportHtml((data) => resolve(data.html));
    });
  }, []);

  const exportDesign = useCallback(async () => {
    return new Promise((resolve, reject) => {
      if (!editorRef.current) reject();
      else editorRef.current.saveDesign((design) => resolve(design));
    });
  }, []);

  const saveTemplate = useCallback(async () => {
    const html = await exportHtml();
    const design = await exportDesign();

    if (!html || isEmpty(design)) {
      throw new Error('Email template cannot be empty, please try again.');
    }

    const { templateId, templateVersion } = isRecurringTemplate ? selectedTemplate : campaignTemplate;

    const { uploadUrl, ...savedTemplate } = await (templateId && templateVersion
      ? updateTemplate(templateId, templateVersion, design, template.emailConfig)
      : createTemplate(design, selectedTemplate, template.emailConfig));

    await uploadHtml(uploadUrl, html);

    if (isRecurringTemplate) {
      setSelectedTemplate({ ...savedTemplate, ...selectedTemplate, html });
    } else {
      setCampaignTemplate({ ...savedTemplate, html });
    }

    return savedTemplate;
  }, [
    exportHtml,
    exportDesign,
    selectedTemplate,
    campaignTemplate,
    template.emailConfig,
    setCampaignTemplate,
    isRecurringTemplate,
  ]);

  const handleDesignUpdated = useCallback(async () => {
    if (editorLoading || saving) {
      return;
    }

    setSaving(true);

    try {
      await saveTemplate();
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }

    setSaving(false);
  }, [editorLoading, saveTemplate, saving]);

  const debouncedHandleDesignUpdated = useMemo(
    () => debounce(handleDesignUpdated, DEBOUNCE_DELAY_TIME_MS),
    [handleDesignUpdated],
  );

  const getSectionTemplates = useCallback(async () => {
    if (
      !selectedTemplate ||
      selectedTemplate?.templateId ||
      selectedTemplate?.design ||
      !account.emailPreferences.sections
    ) {
      setLoadingTemplate(false);
      return;
    }

    const sectionTemplates = await getDefaultSectionTemplates(account.emailPreferences.sections);
    const design = JSON.stringify(await import(`../components/email-edits/templates/${selectedTemplate?.source}`));

    setSelectedTemplate({ ...selectedTemplate, design: injectTemplateDesignSections(design, sectionTemplates) });
    setLoadingTemplate(false);
  }, [account.emailPreferences.sections, selectedTemplate]);

  useEffect(() => {
    void getSectionTemplates();
  }, [getSectionTemplates, selectedTemplate]);

  useEffect(() => {
    const editor = editorRef.current?.editor;
    editor?.addEventListener('design:updated', debouncedHandleDesignUpdated);

    return () => {
      editor?.removeEventListener('design:updated', debouncedHandleDesignUpdated);
    };
  }, [debouncedHandleDesignUpdated]);

  const handleGoBack = () => {
    navigate(-1, { replace: true });
  };

  const onLoad = async () => {
    let design = !isRecurringTemplate && campaignTemplate.design ? campaignTemplate.design : selectedTemplate?.design;

    const campaignId = campaignDetails.campaignId;
    const donationPageUrl = getPageUrl({ account, params: { campaignId } });
    design = design.replace(/yourdonationlink/g, donationPageUrl);
    editorRef.current?.loadDesign(JSON.parse(design));
    editorRef.current?.editor.setBodyValues(bodyValues);
    editorRef.current?.editor.setLinkTypes(getLinkTypes({ donationPageUrl, pageList, account }));

    setEditorLoading(false);
  };

  const previewTemplate = async () => {
    try {
      const html = await exportHtml();
      localStorage.setItem(PREVIEW_HTML_KEY, html);
      window.open('/template-preview', '_blank');
    } catch (err) {
      Sentry.captureException(err);
      setError(err?.message || true);
    }
  };

  const exitPage = () => {
    let to;
    let state;
    if (isRecurringTemplate) {
      to = '/email-templates';
      state = { type: templateTypes.recurring };
    } else {
      to = campaignDetails.campaignId ? `/edit-campaign/${campaignDetails.campaignId}` : '/new-campaign';
    }
    navigate(to, { replace: true, state });
  };

  const updateCampaignTemplate = async (templateId, version) => {
    const { campaignId, tempId, segmentId, ...data } = campaignDetails;
    const update = { ...data, template: { templateId, version } };
    await updateCampaign(campaignDetails.campaignId, update);
  };

  const saveAndExit = async () => {
    setError(false);
    setLoading(true);
    try {
      const { templateId, templateVersion } = await saveTemplate();
      if (!isRecurringTemplate) {
        await updateCampaignTemplate(templateId, templateVersion);
      }
      setBlockExit(false);
      setLoading(false);
      exitPage();
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
      setLoading(false);
    }
  };

  const handleSendTestEmail = async (email, html) => {
    await sendTestEmail({
      to: email,
      html,
      subject: isRecurringTemplate ? template?.emailConfig?.subject : campaignDetails.emailConfig.subject,
      previewText: isRecurringTemplate ? template?.emailConfig?.previewText : campaignDetails.emailConfig.previewText,
      campaignId: campaignDetails.campaignId,
    });
  };

  if (status === STATUS_STATES.loading) {
    // pages data is needed to render the editor donation page link type
    return (
      <div className="relative size-full">
        <Loader />
      </div>
    );
  }

  return (
    <div className="relative w-full space-y-6">
      <div className="flex items-center justify-between space-x-3">
        <div className="flex items-center space-x-1">
          <IconButton
            Icon={<IconArrowLeft size={20} />}
            color="transparent"
            className="-ml-2"
            onClick={handleGoBack}
            srOnly="Go back"
          />
          <h1 className="text-h3">{isRecurringTemplate ? selectedTemplate?.title : 'Design Email'}</h1>
        </div>
        <div className="flex flex-1 flex-wrap justify-end gap-3">
          <Button title="Generate AI Content" onClick={() => setContentGeneratorOpen(true)} />
          <Button title="Merge Tags" onClick={() => setMergeTagBuilderOpen(true)} />
          <Button title="Preview" onClick={previewTemplate} />
          <Button title="Test" onClick={() => setTestOpen(true)} />
          <Button title="Save & Exit" color="primary" onClick={saveAndExit} />
        </div>
      </div>

      <div className="rounded-xl bg-white-100 px-12 pb-24 pt-8">
        <div className="mx-auto min-w-max max-w-7xl">
          {!editorLoading && (
            <div className="mb-1 inline-block self-start">
              <div
                className={`
                  ${saving ? 'bg-gray-400' : 'bg-green-500'}
                  flex
                  items-center
                  rounded
                  px-2
                  py-0.5
                  text-base
                  font-semibold
                  text-white-100
                `}
              >
                {saving ? (
                  <>
                    <IconFilePencil size={16} className="mr-1" /> Saving...
                  </>
                ) : (
                  <>
                    <IconFileCheck size={16} className="mr-1" /> Saved
                  </>
                )}
              </div>
            </div>
          )}
          <div className="border-4 border-gray-50">
            {accountLoading || loadingTemplate ? (
              <div className="relative min-h-[550px]">
                <Loader />
              </div>
            ) : (
              <EmailEditor
                ref={editorRef}
                onReady={onLoad}
                options={getEditorOptions({ account, campaignDetails })}
                minHeight={550}
              />
            )}
          </div>
        </div>
      </div>

      {loading && <Loader />}

      <MergeTagBuilderModal open={mergeTagBuilderOpen} onClose={() => setMergeTagBuilderOpen(false)} />

      <ContentGeneratorModal open={contentGeneratorOpen} onClose={() => setContentGeneratorOpen(false)} />

      <TestEmailModal
        open={testOpen}
        onCancel={() => {
          setTestOpen(false);
        }}
        exportHtml={exportHtml}
        sendEmail={handleSendTestEmail}
        onError={(err) => {
          setError(err ?? '');
        }}
        onSuccess={() => {
          setSuccess('Test email sent successfully.');
        }}
      />

      <ConfirmExitPageModal open={showPrompt} onCancel={cancelNavigation} onConfirm={confirmNavigation} />

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => setSuccess(false)}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </div>
  );
};

export default EmailEdits;
