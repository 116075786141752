import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useActionCreator = (action) => {
  const dispatch = useDispatch();

  const actionCall = useCallback(
    (...args) => {
      return dispatch(action(args[0]));
    },
    [action, dispatch],
  );

  return actionCall;
};

export default useActionCreator;
