import { useEffect, useMemo, useState } from 'react';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import Collapse from '@mui/material/Collapse';
import { useAccount } from '../../store/account/hooks';
import { emailPreferencesInitialValues } from '../../store/account/reducers';
import { DEBOUNCE_DELAY_TIME_MS, EMAIL_BUTTON_KEY_NAMES, EMAIL_BUTTON_KEY_NAME_TO_LABEL } from '../../core/constants';
import Input from '../inputs/Input';
import ColorInput from '../inputs/ColorInput';
import SwitchInput from '../inputs/SwitchInput';

const formValidation = yup.object({
  enabled: yup.boolean().required('Enabled is required.'),
  buttonText: yup.string().min(2, 'Text is invalid.').required('Button Text is required.'),
  backgroundColor: yup.string().required('Background Color is required.'),
  textColor: yup.string().required('Text Color is required.'),
});

const FooterButtonSettings = ({ title, subtitle, buttonKeyName, onChange, isActive, setActiveButton }) => {
  const { account, updateEmailPreferences } = useAccount();
  const [initialValues] = useState(
    account.emailPreferences?.[buttonKeyName] ?? emailPreferencesInitialValues[buttonKeyName],
  );
  const form = useForm({
    initialValues: { ...initialValues },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });

  const hasPendingChanges = form.isDirty() && form.isValid();

  useEffect(() => {
    onChange(buttonKeyName, hasPendingChanges);
  }, [buttonKeyName, hasPendingChanges, onChange]);

  const debouncedUpdateEmailPreferences = useMemo(
    () => debounce(updateEmailPreferences, DEBOUNCE_DELAY_TIME_MS),
    [updateEmailPreferences],
  );

  const handleChange = (field, value) => {
    form.setFieldValue(field, value);
    debouncedUpdateEmailPreferences({ [buttonKeyName]: { [field]: value } });
  };

  const handleCancel = (field) => {
    const initialValue = initialValues[field];
    form.setFieldValue(field, initialValue);
    debouncedUpdateEmailPreferences({ [buttonKeyName]: { [field]: initialValue } });
    setTimeout(() => form.clearFieldError(field), 0);
  };

  return (
    <>
      <div
        className="flex cursor-pointer items-center justify-between bg-gray-10 px-4 py-3"
        role="button"
        tabIndex="0"
        onClick={() => setActiveButton(buttonKeyName)}
      >
        <div>
          <h3 className="text-lg font-semibold dark:text-white-100">{title}</h3>
          {isActive && !!subtitle && <p className="text-base">{subtitle}</p>}
        </div>

        {isActive ? <IconChevronUp size={20} /> : <IconChevronDown size={20} />}
      </div>

      <Collapse in={isActive}>
        <div className="py-6">
          <form noValidate className="space-y-6 px-4">
            <Input
              id={buttonKeyName + 'buttonText'}
              label="Button Text"
              placeholder={EMAIL_BUTTON_KEY_NAME_TO_LABEL[buttonKeyName]}
              {...form.getInputProps('buttonText')}
              required
              error={!!form.errors.buttonText}
              helperText={form.errors.buttonText}
              labelClassName="w-[125px] mr-[5%]"
              containerClassName={`flex ${form.errors.buttonText ? 'items-start' : 'items-center'}`}
              onChange={(e) => handleChange('buttonText', e.target.value)}
            />

            <ColorInput
              id={buttonKeyName + 'backgroundColor'}
              label="Background Color"
              placeholder="#061C6C"
              value={form.values.backgroundColor}
              containerClassName="flex items-center"
              labelClassName="w-[125px] mr-[5%] whitespace-nowrap"
              inputContainerClassName="flex-1"
              onChange={(value) => handleChange('backgroundColor', value)}
              error={form.errors.backgroundColor}
              onCancel={() => handleCancel('backgroundColor')}
              required
            />

            <ColorInput
              id={buttonKeyName + 'textColor'}
              label="Text Color"
              placeholder="#FFFFFF"
              value={form.values.textColor}
              containerClassName="flex items-center"
              labelClassName="w-[125px] mr-[5%]"
              inputContainerClassName="flex-1"
              onChange={(value) => handleChange('textColor', value)}
              error={form.errors.textColor}
              onCancel={() => handleCancel('textColor')}
              required
            />

            <SwitchInput
              id={buttonKeyName + 'enabled'}
              label={form.values.enabled ? 'Enabled' : 'Disabled'}
              checked={form.values.enabled}
              onChange={(enabled) => handleChange('enabled', enabled)}
            />
          </form>
        </div>
      </Collapse>
    </>
  );
};

FooterButtonSettings.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  buttonKeyName: PropTypes.oneOf(Object.values(EMAIL_BUTTON_KEY_NAMES)).isRequired,
  onChange: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  setActiveButton: PropTypes.func.isRequired,
};

export default FooterButtonSettings;
