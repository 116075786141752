import { useCallback, useEffect, useRef, useState } from 'react';
import { IconExternalLink, IconPencil } from '@tabler/icons-react';
import * as Sentry from '@sentry/react';
import mergeWith from 'lodash.mergewith';
import postRobot from 'post-robot';
import { Button, AlertMessage } from 'ui';
import { getNetworkError } from 'common/utils';

import appSettings from '../app-settings';
import { getPage, upsertPage } from '../api';
import { getPageUrl, waitFor } from '../core/utils';
import { STATUS_STATES } from '../core/constants';
import { useAccount } from '../store/account/hooks';
import EmbeddedPage from '../components/embedded-page/EmbeddedPage';
import PageEditor from '../components/page-editor/PageEditor';
import CopyBox from '../components/donation-page/CopyBox';

const OptInPage = () => {
  const [editorOpen, setEditorOpen] = useState(false);
  const [status, setStatus] = useState({ state: STATUS_STATES.idle, message: '' });
  const [pageContent, setPageContent] = useState(null);
  const editorRef = useRef(null);
  const { account, getAccount } = useAccount();

  const getPageContent = useCallback(async () => {
    try {
      setStatus({ state: STATUS_STATES.loading, message: '' });
      const data = await getPage('opt-in');
      setPageContent(data?.content || null);
      setStatus({ state: STATUS_STATES.idle, message: '' });
    } catch (error) {
      Sentry.captureException(error);
      setStatus({ state: STATUS_STATES.error, message: getNetworkError(error) });
    }
  }, []);

  useEffect(() => {
    getPageContent();
  }, [getPageContent]);

  const pageUrl = getPageUrl({ account, slug: 'opt-in' });

  const handleEditorLoad = async () => {
    try {
      const initialState = account?.logoUrl
        ? mergeWith({}, { images: { headerImage: { url: account.logoUrl } } }, pageContent || {})
        : pageContent;

      await postRobot.send(editorRef.current.contentWindow, 'initEditor', {
        initialState,
        organization: { name: account?.legalName, address: account?.legalAddress?.replace(/[\r\n]/g, ', ') },
      });
    } catch (error) {
      Sentry.captureException(error);
      setStatus({ state: STATUS_STATES.error, message: 'Something went wrong while initializing the editor.' });
    }
  };

  const handleSave = async () => {
    try {
      if (!account) return;

      setStatus({ state: STATUS_STATES.loading, message: '' });

      const response = await postRobot.send(editorRef.current.contentWindow, 'saveAndExit');

      await upsertPage({
        tenantId: account.tenantId,
        slug: 'opt-in',
        content: response.data,
        action: pageContent ? 'update' : 'create',
      });

      if (!account.donationPageSubdomain) getAccount();

      // Empiric value - page needs some extra time to reflect changes
      await waitFor(2000);

      // Re-fetch page state for reflecting updated state on the editor
      await getPageContent();

      setStatus({ state: STATUS_STATES.success, message: 'Opt-In page launched successfully!' });

      setEditorOpen(false);
    } catch (err) {
      Sentry.captureException(err);
      setStatus({ state: STATUS_STATES.error, message: getNetworkError(err) });
    }
  };

  const loading = status.state === STATUS_STATES.loading;
  const error = status.state === STATUS_STATES.error;
  const success = status.state === STATUS_STATES.success;

  return (
    <>
      <main className="flex size-full flex-col space-y-6">
        <h1 className="text-h3">Opt-In Page</h1>

        <div className="flex flex-1 flex-col overflow-hidden rounded-xl bg-white-100 shadow-md">
          <div className="flex items-center justify-between border-b border-b-gray-50 p-8 pb-3.5">
            <CopyBox pageUrl={pageUrl} />

            {editorOpen ? (
              <div className="flex items-center space-x-2">
                <Button title="Save & Exit" color="primary" onClick={handleSave} size="sm" />

                <Button title="Cancel" onClick={() => setEditorOpen(false)} size="sm" />
              </div>
            ) : (
              <div className="flex items-center space-x-2">
                <Button title="Edit" onClick={() => setEditorOpen(true)} LeftIcon={IconPencil} size="sm" />

                <Button
                  as="link"
                  title="Preview"
                  href={pageUrl}
                  target="_blank"
                  rel="noreferrer"
                  LeftIcon={IconExternalLink}
                  size="sm"
                />
              </div>
            )}
          </div>

          <div className="flex-1">
            {editorOpen ? (
              <PageEditor
                ref={editorRef}
                pageUrl={`${appSettings.donationPageEditorUrl}/opt-in`}
                loading={loading}
                onLoad={handleEditorLoad}
              />
            ) : (
              <EmbeddedPage pageUrl={pageUrl} />
            )}
          </div>
        </div>
      </main>

      <AlertMessage
        open={success}
        message={status.message}
        onClose={() => setStatus({ state: STATUS_STATES.idle, message: '' })}
        severity="success"
      />

      <AlertMessage
        open={error}
        message={status.message}
        onClose={() => setStatus({ state: STATUS_STATES.idle, message: '' })}
        severity="error"
      />
    </>
  );
};

export default OptInPage;
