import { useEffect } from 'react';

import ConnectStripe from '../components/payment-options/ConnectStripe';
import { usePaymentOptions } from '../store/payment-options/hooks';

const PaymentOptions = () => {
  const { getStripeAccount } = usePaymentOptions();

  useEffect(() => {
    getStripeAccount();
  }, [getStripeAccount]);

  return (
    <div className="relative min-h-[300px] w-full space-y-6">
      <h1 className="text-h3 dark:text-white-100">Payment Options</h1>

      <ConnectStripe />
    </div>
  );
};

export default PaymentOptions;
