import { Dialog } from '@mui/material';
import { IconX, IconExclamationCircle } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { CampaignPreview } from 'ui';

import { useCampaigns } from '../../store/campaigns/hooks';
import Button from '../buttons/Button';
import IconButton from '../buttons/IconButton';

const PREVIEW_CONTAINER_VERTICAL_PADDING = 12;

const ConfirmLaunchModal = ({ open, onClose, onConfirm, message }) => {
  const { campaignTemplate } = useCampaigns();

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={onClose}
      sx={{
        '.MuiPaper-root': {
          padding: 0,
          borderRadius: '12px',
          width: '100%',
          overflow: 'hidden',
        },
      }}
    >
      <div className="flex size-full flex-col space-y-6 overflow-y-auto overflow-x-hidden p-6">
        <div className="relative flex">
          <h3 className="w-full text-center text-h6 font-semibold">Are You Sure?</h3>

          <IconButton
            Icon={<IconX size={20} className="text-gray-400" />}
            srOnly="Close"
            onClick={onClose}
            color="transparent"
            className="absolute -right-1 -top-1 !p-1"
          />
        </div>

        <div className="flex items-center justify-center">
          <IconExclamationCircle size={56} className="text-gray-400" />
        </div>

        <p className="text-center text-base">
          {message || 'Launched campaigns cannot be edited and can only be cancelled.'}
        </p>

        <div
          className="border-2 border-gray-50"
          style={{
            padding: `${PREVIEW_CONTAINER_VERTICAL_PADDING}px 0px`,
          }}
        >
          <CampaignPreview html={campaignTemplate.html} />
        </div>

        <div className="flex justify-end space-x-3">
          <Button title="Close" onClick={onClose} />

          <Button title="Confirm" color="primary" onClick={onConfirm} />
        </div>
      </div>
    </Dialog>
  );
};

ConfirmLaunchModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.string,
};

export default ConfirmLaunchModal;
