import { useState } from 'react';
import { Menu } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import Checkbox from '../inputs/Checkbox';

const CampaignsFilter = ({ initialCampaignIds, campaignOptions, onSelect, active }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [campaignIds, setCampaignIds] = useState(initialCampaignIds);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (campaignId) => {
    setCampaignIds((prev) => {
      const alreadySelected = prev.find((cId) => cId === campaignId);
      if (alreadySelected) {
        return prev.filter((cId) => cId !== campaignId);
      }
      return [...prev, campaignId];
    });
  };

  const handleReset = () => {
    onSelect([]);
    setCampaignIds([]);
    handleClose();
  };

  const handleApply = () => {
    onSelect(campaignIds);
    handleClose();
  };

  return (
    <>
      <div className="flex w-fit">
        <Button
          title="Campaign"
          className={`w-[130px] justify-between rounded-lg ${active ? 'border-primary-200 !bg-primary-50' : ''}`}
          RightIcon={open ? IconChevronUp : IconChevronDown}
          onClick={handleClick}
        />

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          classes={{ paper: 'w-[320px] !rounded-lg mt-1', list: '!p-0 divide-y divide-gray-50' }}
        >
          <li className="max-h-[292px] space-y-3 overflow-y-auto p-4">
            {campaignOptions.map((campaign) => (
              <Checkbox
                key={campaign.value}
                id={campaign.value}
                name={campaign.label}
                label={campaign.label}
                value={campaign.value}
                onChange={() => handleSelect(campaign.value)}
                checked={!!campaignIds.find((campaignId) => campaignId === campaign.value)}
              />
            ))}
          </li>

          <li className="flex items-center justify-between p-4">
            <Button title="Reset" onClick={handleReset} />

            <Button title="Apply" color="primary" onClick={handleApply} />
          </li>
        </Menu>
      </div>
    </>
  );
};

CampaignsFilter.propTypes = {
  initialCampaignIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  campaignOptions: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

CampaignsFilter.defaultProps = {
  initialCampaignIds: [],
  active: false,
};

export default CampaignsFilter;
