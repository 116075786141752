import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTags } from '../../store/tags/hook';
import { isDsContact, isFieldObscured, shouldObscureContacts } from '../../core/utils/contacts';
import { useAccount } from '../../store/account/hooks';
import CustomDialog from '../modals/CustomDialog';
import Button from '../buttons/Button';
import Checkbox from '../inputs/Checkbox';
import Input from '../inputs/Input';
import { DEFAULT_VISIBLE_FIELDS, MAP_FIELD_TO_LABEL } from './constants';

const EditContactModal = ({ open, onClose, data, onSave, loading }) => {
  const [contactTags, setContactTags] = useState([]);
  const [contact, setContact] = useState({});
  const { tags } = useTags();
  const { account } = useAccount();

  const obscuredContact = isDsContact(contact.import_filename) && shouldObscureContacts(account);

  useEffect(() => {
    if (data && open) {
      setContact(data);
      setContactTags(data.tags?.map((tag) => tag.id) ?? []);
    }
  }, [data, open]);

  const handleInputChange = ({ target: { name, value } }) => {
    setContact((prevContact) => ({ ...prevContact, [name]: value }));
  };

  const handleUpdateTags = (event) => {
    if (event.target.checked) {
      setContactTags((prevTags) => [...prevTags, event.target.value]);
      return;
    }
    setContactTags((prevTags) => prevTags.filter((tag) => tag !== event.target.value));
  };

  const handleClose = () => {
    if (loading) return;
    onClose();
    setContactTags([]);
    setContact({});
  };

  const handleSave = () => {
    const dataTagIds = data?.tags?.map((tag) => tag.id) ?? [];
    const tagIdsToAdd = data ? contactTags.filter((tag) => !dataTagIds.includes(tag)) : contactTags;
    const tagIdsToRemove = dataTagIds.filter((tag) => !contactTags.includes(tag));
    const { created_at, updated_at, custom_fields, unsubscribed, tags, import_filename, ...rest } = contact;

    let contactData = { ...rest };

    if (obscuredContact) {
      contactData = Object.entries(contactData).reduce((acc, [key, value]) => {
        if (!isFieldObscured(key)) {
          acc[key] = value;
        }
        return acc;
      }, {});
    }

    onSave({ contact: contactData, tagIdsToAdd, tagIdsToRemove });
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      title={`${data ? 'Edit' : 'Add'} a contact`}
      containerClassName="md:min-w-[640px]"
      titleClassName="!text-left !text-xl"
    >
      <div className="mt-2">
        <form className="flex flex-row">
          <div className="w-3/4 space-y-4">
            {Object.keys(DEFAULT_VISIBLE_FIELDS).map((field) => {
              if (['unsubscribed', 'tags', 'created_at', 'updated_at'].includes(field)) return null;
              if (field === 'import_filename' && !contact.import_filename) return null;
              const disabled = field === 'import_filename' || (obscuredContact && isFieldObscured(field));
              return (
                <Input
                  key={field}
                  id={field}
                  label={MAP_FIELD_TO_LABEL[field]}
                  value={contact[field] || ''}
                  onChange={handleInputChange}
                  disabled={disabled}
                />
              );
            })}
          </div>

          <div className="flex-3 mx-6 mt-3 md:mx-10">
            <h3 className="mb-4 text-lg">{data ? 'Edit' : 'Add'} tags</h3>
            <div className="flex flex-col flex-nowrap space-y-4">
              {[...tags]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((tag) => {
                  return (
                    <Checkbox
                      key={tag.id}
                      id={tag.id}
                      name={tag.id}
                      label={tag.name}
                      value={tag.id}
                      checked={contactTags.includes(tag.id)}
                      onChange={handleUpdateTags}
                    />
                  );
                })}
            </div>
          </div>
        </form>
      </div>

      <div className="mt-8 flex items-center justify-end space-x-4">
        <Button title="Cancel" onClick={onClose} disabled={loading} />

        <Button title="Save" color="primary" onClick={handleSave} disabled={loading} loading={loading} />
      </div>
    </CustomDialog>
  );
};

EditContactModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object,
};

export default EditContactModal;
