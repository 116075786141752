import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { startOfWeek, format } from 'date-fns';
import { getNetworkError, replaceItemAtIndex } from 'common/utils';
import { SORT_DIRECTIONS } from 'common/constants';

import { getRecommendedTasks as getRecommendedTasksApi } from '../../api';
import { RECOMMENDED_TASK_STATUS, RECOMMENDED_TASK_TYPES } from '../../core/constants';
import { getTaskStatusAndCampaignCountByDelta, sortRecommendedTasks } from '../../core/utils';

const PENDING_TASKS_LIMIT = 4;

const GET_RECOMMENDED_TASKS = 'campaigns/GET_RECOMMENDED_TASKS';
const GET_PENDING_RECOMMENDED_TASKS = 'campaigns/GET_PENDING_RECOMMENDED_TASKS';
const UPDATE_RECOMMENDED_TASKS = 'campaigns/UPDATE_RECOMMENDED_TASKS';
const UPDATE_PENDING_RECOMMENDED_TASKS = 'campaigns/UPDATE_PENDING_RECOMMENDED_TASKS';

export const updateRecommendedTasks = createAction(UPDATE_RECOMMENDED_TASKS);

export const updatePendingRecommendedTasks = createAction(UPDATE_PENDING_RECOMMENDED_TASKS);

export const getRecommendedTasks = createAsyncThunk(GET_RECOMMENDED_TASKS, async () => {
  try {
    const tasks = await getRecommendedTasksApi();
    return tasks;
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const getPendingRecommendedTasks = createAsyncThunk(GET_PENDING_RECOMMENDED_TASKS, async () => {
  try {
    const queryParams = new URLSearchParams({ status: RECOMMENDED_TASK_STATUS.pending, limit: PENDING_TASKS_LIMIT });
    const tasks = await getRecommendedTasksApi(queryParams.toString());
    return tasks;
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const updateReviewDraftTaskStatus = (campaignId) => (dispatch, getState) => {
  const { recommendedTasks } = getState();

  let tasks = [...recommendedTasks.tasks];
  let pendingTasks = [...recommendedTasks.pendingTasks];

  const taskIdx = tasks.findIndex((task) => task.suggestedCampaignId === campaignId);
  const task = tasks[taskIdx];
  const pendingTask = pendingTasks.find((task) => task.suggestedCampaignId === campaignId);

  if (task) {
    tasks = replaceItemAtIndex(tasks, taskIdx, { ...task, status: RECOMMENDED_TASK_STATUS.completed });
    dispatch(updateRecommendedTasks(tasks));
  }

  if (pendingTask) {
    pendingTasks = pendingTasks.filter((task) => task.taskId !== pendingTask.taskId);
    dispatch(updatePendingRecommendedTasks(pendingTasks));
  }
};

export const updateScheduleCampaignTaskStatus =
  ({ sendTime, delta = 1 }) =>
  (dispatch, getState) => {
    const { recommendedTasks } = getState();

    let tasks = [...recommendedTasks.tasks];
    let pendingTasks = [...recommendedTasks.pendingTasks];

    const startOfWeekDate = startOfWeek(new Date(sendTime), { weekStartsOn: 1 });
    const date = format(startOfWeekDate, 'yyyy-MM-dd');
    const taskId = `${RECOMMENDED_TASK_TYPES.weeklyCampaignScheduling}#${date}`;

    const taskIdx = tasks.findIndex((task) => task.taskId === taskId);
    const task = tasks[taskIdx];

    const pendingTaskIdx = pendingTasks.findIndex((task) => task.taskId === taskId);
    const pendingTask = pendingTasks[pendingTaskIdx];

    if (task) {
      const { status, campaignsCount } = getTaskStatusAndCampaignCountByDelta(task, delta);
      tasks = replaceItemAtIndex(tasks, taskIdx, { ...task, status, campaignsCount });
      dispatch(updateRecommendedTasks(tasks));

      if (status === RECOMMENDED_TASK_STATUS.pending && !pendingTask && pendingTasks.length < PENDING_TASKS_LIMIT) {
        // Recommended task is not currently present in the pending tasks list, and we have less than the pending tasks limit
        pendingTasks = sortRecommendedTasks(
          [...pendingTasks, { ...task, status, campaignsCount }],
          'createdAt',
          SORT_DIRECTIONS.desc,
        );
        dispatch(updatePendingRecommendedTasks(pendingTasks));
      }
    }

    if (pendingTask) {
      const { status, campaignsCount } = getTaskStatusAndCampaignCountByDelta(pendingTask, delta);

      if (status === RECOMMENDED_TASK_STATUS.completed) {
        pendingTasks = pendingTasks.filter((task) => task.taskId !== pendingTask.taskId);
        dispatch(updatePendingRecommendedTasks(pendingTasks));
      } else if (status === RECOMMENDED_TASK_STATUS.pending) {
        pendingTasks = replaceItemAtIndex(pendingTasks, pendingTaskIdx, { ...pendingTask, status, campaignsCount });
        dispatch(updatePendingRecommendedTasks(pendingTasks));
      }
    }
  };
