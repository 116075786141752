import { useState, useRef } from 'react';
import * as Sentry from '@sentry/react';

import { uploadFile } from '../../core/utils';
import FileDrop from '../file-drop/FileDrop';
import { allowedFileTypes, createPresignedUploadUrl, updateAccountDocument } from './utils';
import FileInfo from '../file-info/FileInfo';
import { useAccount } from '../../store/account/hooks';
import { FILE_STATUS } from '../../core/constants';

const UploadFileInput = () => {
  const [document, setDocument] = useState(null);
  const [fileStatus, setFileStatus] = useState(FILE_STATUS.pending);
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const uploadControllerRef = useRef();
  const { account, setAccountDocument } = useAccount();

  const handleUploadProgress = (progressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setProgress(percentCompleted);
  };

  const handleUploadFile = async (file) => {
    setErrorMessage('');
    setFileStatus(FILE_STATUS.pending);
    try {
      const { url: uploadUrl, fileKey: documentKey } = await createPresignedUploadUrl(file.name);
      const arrayBuffer = await file.arrayBuffer();
      uploadControllerRef.current = new AbortController();
      await uploadFile(uploadUrl, arrayBuffer, file.type, {
        onUploadProgress: handleUploadProgress,
        signal: uploadControllerRef.current.signal,
      });
      await updateAccountDocument(documentKey);
      setFileStatus(FILE_STATUS.saved);
    } catch (err) {
      Sentry.captureException(err);
      setFileStatus(FILE_STATUS.failed);
      setErrorMessage('Uploading failed');
    }
  };

  const handleDeleteFile = async () => {
    setErrorMessage('');
    setLoading(true);
    try {
      await updateAccountDocument('');
      if (document) setDocument(null);
      else setAccountDocument('');
    } catch (err) {
      Sentry.captureException(err);
      setFileStatus(FILE_STATUS.failed);
      setErrorMessage('Delete failed');
    }
    setLoading(false);
  };

  const handleCancelUpload = async () => {
    setLoading(true);
    try {
      uploadControllerRef.current?.abort();
      uploadControllerRef.current = undefined;
      await updateAccountDocument('');
      setDocument(null);
      setProgress(0);
    } catch (err) {
      Sentry.captureException(err);
      setFileStatus(FILE_STATUS.failed);
      setErrorMessage('Cancel upload failed');
    }
    setLoading(false);
  };

  const handleFileChange = (file) => {
    setErrorMessage('');
    setDocument(file);
    handleUploadFile(file);
  };

  return (
    <div className="space-y-4">
      <FileDrop
        onChange={handleFileChange}
        allowedFileTypes={allowedFileTypes}
        acceptedFileExtension=".pdf"
        containerClassName="!w-full h-[122px]"
        shortDescription="Only PDF file, max size 50MB."
        maxFileSize={50 * 10 ** 6} // 50MB -> in bytes
        multiple
        disabled={document && fileStatus === FILE_STATUS.pending}
      />

      {document && (
        <FileInfo
          name={document.name}
          size={document.size || 0}
          onDelete={handleDeleteFile}
          onCancel={handleCancelUpload}
          status={fileStatus}
          progress={progress}
          loading={loading}
          errorMessage={errorMessage}
        />
      )}

      {account?.taxDocumentKey && (
        <FileInfo
          name={account.taxDocumentKey.split('/').slice(-1)[0]}
          onDelete={handleDeleteFile}
          status={FILE_STATUS.saved}
          loading={loading}
        />
      )}
    </div>
  );
};

export default UploadFileInput;
