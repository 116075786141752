import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import appSettings from '../../app-settings';
import { getNetworkError } from '../../core/utils';

const TAGS_URL = `${appSettings.baseUrl}/tags`;

const SET_ERROR = 'tags/SET_ERROR';
const SET_SUCCESS = 'tags/SET_SUCCESS';
const GET_TAGS = 'tags/GET_TAGS';
const CREATE_TAG = 'tags/CREATE_TAG';
const RENAME_TAG = 'tags/RENAME_TAG';
const DELETE_TAG = 'tags/DELETE_TAG';

export const setError = createAction(SET_ERROR);

export const setSuccess = createAction(SET_SUCCESS);

export const getTags = createAsyncThunk(GET_TAGS, async () => {
  const res = await axios.get(TAGS_URL);
  return res.data.tags;
});

export const createTag = createAsyncThunk(CREATE_TAG, async (name) => {
  try {
    const res = await axios.post(TAGS_URL, { name });
    return res.data;
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const renameTag = createAsyncThunk(RENAME_TAG, async ({ tagId, name }) => {
  try {
    await axios.patch(`${TAGS_URL}/${tagId}`, { name });
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const deleteTag = createAsyncThunk(DELETE_TAG, async (tagId) => {
  try {
    await axios.delete(`${TAGS_URL}/${tagId}`);
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});
