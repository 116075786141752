import { Dialog } from '@mui/material';
import { IconX, IconAlertCircleFilled } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { Button, IconButton } from 'ui';

const ConfirmArchiveModal = ({ open, onClose, onArchive, loading, contactsNumber }) => {
  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={onClose}
      sx={{ '.MuiPaper-root': { padding: 0, margin: 0, borderRadius: '12px', width: '100%' } }}
    >
      <div className="space-y-6 p-6">
        <div className="relative flex">
          <div className="flex items-center space-x-3">
            <IconAlertCircleFilled size={20} className="text-red-500" />
            <h3 className="text-h6 font-semibold">Archive Contacts?</h3>
          </div>

          <IconButton
            Icon={<IconX size={20} className="text-gray-400" />}
            srOnly="Close"
            onClick={onClose}
            color="transparent"
            className="absolute -right-1 -top-1 !p-1"
          />
        </div>

        <p className="text-base">
          Are you sure you want to archive <span className="font-semibold">{contactsNumber}</span> contacts? Archived
          contacts will not receive campaign emails and cannot be unarchived for 1 year.
        </p>

        <div className="flex justify-end space-x-3">
          <Button title="No, cancel" onClick={onClose} disabled={loading} />

          <Button
            title={loading ? 'Loading...' : "Yes, I'm sure"}
            color="destructive"
            disabled={loading}
            onClick={onArchive}
            loading={loading}
          />
        </div>
      </div>
    </Dialog>
  );
};

ConfirmArchiveModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  contactsNumber: PropTypes.number.isRequired,
};

export default ConfirmArchiveModal;
