import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import { AlertMessage, Button, Loader } from 'ui';
import { getNetworkError } from 'common/utils';

import SubscriptionLayout from './SubscriptionLayout';
import { ONBOARDING_STEPS } from '../../core/constants';
import appSettings from '../../app-settings';
import InvoicesTable from '../subscription/InvoicesTable';

const INVOICES_URL = `${appSettings.baseUrl}/accounts/subscription/invoices`;

const SubscribeStep = ({ setStep }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [invoices, setInvoices] = useState([]);

  const getInvoices = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(INVOICES_URL);
      setInvoices(response.data);
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  return (
    <SubscriptionLayout>
      <div className="relative flex size-full flex-col items-center justify-center">
        <div className="max-w-[700px] space-y-8">
          <div className="space-y-4">
            <h1 className="text-h2 font-semibold">Subscription</h1>
            <p className="text-lg">
              Your DonorSpring subscription is active! You can continue with account setup or view and pay your
              subscription invoice below.
            </p>
          </div>

          <div className="relative flex-1 overflow-x-auto overflow-y-hidden">
            <InvoicesTable data={invoices} />
            {loading && <Loader />}
          </div>

          <div className="mx-auto max-w-[336px]">
            <Button
              title="Continue"
              color="primary"
              size="xl"
              className="w-full"
              onClick={() => setStep(ONBOARDING_STEPS.scheduleCall)}
            />
          </div>
        </div>
      </div>

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </SubscriptionLayout>
  );
};

SubscribeStep.propTypes = {
  setStep: PropTypes.func.isRequired,
};

export default SubscribeStep;
