import { useState } from 'react';
import * as Sentry from '@sentry/react';
import axios from 'axios';

import Button from '../components/buttons/Button';
import EnableTOTP2FAModal from '../components/settings/EnableTOTP2FAModal';
import EnableSMS2FAModal from '../components/settings/EnableSMS2FAModal';
import { getNetworkError } from '../core/utils';
import AlertMessage from '../components/alerts/AlertMessage';
import { useAccount } from '../store/account/hooks';
import { MFA_SETTINGS, MFA_SETTING_LABELS } from '../core/constants';
import appSettings from '../app-settings';

const AUTH_URL = `${appSettings.baseUrl}/auth`;

const Settings = () => {
  const { account, setAccountMfa } = useAccount();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [enableTOTP2FAOpen, setEnableTOTP2FAOpen] = useState(false);
  const [enableSMS2FAOpen, setEnableSMS2FAOpen] = useState(false);

  const handleDisable2FA = async () => {
    setLoading(true);
    try {
      const urlSuffix = account.mfaSetting === MFA_SETTINGS.TOTP ? '/totp/deactivate' : '/sms/disable';
      await axios.patch(`${AUTH_URL}${urlSuffix}`);
      setAccountMfa('');
      setSuccess(`${MFA_SETTING_LABELS[account.mfaSetting]} 2FA successfully disabled!`);
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }
    setLoading(false);
  };

  const renderStatus = () => {
    if (account.mfaSetting) {
      return (
        <div className="rounded border border-green-100 bg-green-50 px-1.5 py-0.5">
          <p className="text-caption1 font-semibold uppercase text-green-500">
            Enabled - {MFA_SETTING_LABELS[account.mfaSetting]}
          </p>
        </div>
      );
    }

    return (
      <div className="rounded border border-gray-100 bg-gray-10 px-1.5 py-0.5">
        <p className="text-caption1 font-semibold uppercase text-gray-400">Not enabled</p>
      </div>
    );
  };

  return (
    <div className="w-full space-y-6">
      <h1 className="text-h3">Settings</h1>

      <div className="space-y-4 rounded-xl bg-white-100 p-8">
        <div className="flex items-center space-x-4">
          <h3 className="text-h5 font-semibold">Two-Factor Authentication (2FA)</h3>
          {renderStatus()}
        </div>

        <p className="text-lg">
          Add an extra layer of security to your account with Two-Factor Authentication. Any app that supports standard
          TOTP will work - Authy, Google Authenticator, 1Password, LastPass, and more.
        </p>

        {account.mfaSetting ? (
          <Button
            title="Disable Two-Factor Authentication"
            onClick={handleDisable2FA}
            loading={loading}
            disabled={loading}
          />
        ) : (
          <div className="flex items-center space-x-3">
            <Button title="Enable TOTP 2FA (Recommended)" color="primary" onClick={() => setEnableTOTP2FAOpen(true)} />
            <Button title="Enable SMS 2FA" color="primary" onClick={() => setEnableSMS2FAOpen(true)} />
          </div>
        )}
      </div>

      <EnableTOTP2FAModal
        open={enableTOTP2FAOpen}
        onClose={() => setEnableTOTP2FAOpen(false)}
        onConfirm={() => {
          setAccountMfa(MFA_SETTINGS.totp);
          setSuccess('TOTP 2FA verification successful!');
        }}
        onError={(message) => setError(message)}
      />

      <EnableSMS2FAModal
        open={enableSMS2FAOpen}
        onClose={() => setEnableSMS2FAOpen(false)}
        onConfirm={() => {
          setAccountMfa(MFA_SETTINGS.sms);
          setSuccess('SMS 2FA verification successful!');
        }}
        onError={(message) => setError(message)}
        onSuccess={(message) => setSuccess(message)}
      />

      <AlertMessage
        open={!!success}
        message={success || 'Operation completed successfully!'}
        onClose={() => setSuccess('')}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={error || 'Oops, something went wrong!'}
        onClose={() => setError('')}
        severity="error"
      />
    </div>
  );
};

export default Settings;
