import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import appSettings from '../../app-settings';
import { getNetworkError } from '../../core/utils';

const SEGMENTS_URL = `${appSettings.baseUrl}/segments`;

const SET_ERROR = 'segments/SET_ERROR';
const SET_SUCCESS = 'segments/SET_SUCCESS';
const RESET_SEGMENT_DETAILS = 'segments/RESET_SEGMENT_DETAILS';
const UPDATE_SEGMENT_DETAILS = 'segments/UPDATE_SEGMENT_DETAILS';
const GET_SEGMENTS = 'segments/GET_SEGMENTS';
const CREATE_SEGMENT = 'segments/CREATE_SEGMENT';
const DELETE_SEGMENT = 'segments/DELETE_SEGMENT';
const UPDATE_SEGMENT = 'segments/UPDATE_SEGMENT';

export const setError = createAction(SET_ERROR);

export const setSuccess = createAction(SET_SUCCESS);

export const resetSegmentDetails = createAction(RESET_SEGMENT_DETAILS);

export const updateSegmentDetails = createAction(UPDATE_SEGMENT_DETAILS);

export const getSegments = createAsyncThunk(GET_SEGMENTS, async () => {
  const res = await axios.get(SEGMENTS_URL);
  return res.data;
});

export const createSegment = createAsyncThunk(CREATE_SEGMENT, async (_, { getState }) => {
  const {
    segments: { segmentDetails },
  } = getState();
  try {
    const res = await axios.post(SEGMENTS_URL, segmentDetails);
    return res.data;
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const deleteSegment = createAsyncThunk(DELETE_SEGMENT, async (segmentId, { getState }) => {
  await axios.delete(`${SEGMENTS_URL}/${segmentId}`);
  const {
    segments: { segments },
  } = getState();
  return segments.filter((segment) => segment.segmentId !== segmentId);
});

export const updateSegment = createAsyncThunk(UPDATE_SEGMENT, async ({ segmentId, updateData }, { getState }) => {
  try {
    const res = await axios.put(`${SEGMENTS_URL}/${segmentId}`, updateData);
    const updatedSegment = res.data;
    const {
      segments: { segments },
    } = getState();
    const segmentIndex = segments.findIndex((segment) => segment.segmentId === segmentId);
    if (segmentIndex < 0) return null;
    const updatedSegments = [...segments.slice(0, segmentIndex), updatedSegment, ...segments.slice(segmentIndex + 1)];
    return updatedSegments;
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});
