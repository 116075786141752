import { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import {
  IconEye,
  IconPencil,
  IconDotsVertical,
  IconReport,
  IconFiles,
  IconMailCancel,
  IconTrash,
  IconDownload,
  IconArrowBack,
  IconMailPlus,
  IconEdit,
} from '@tabler/icons-react';
import PropTypes from 'prop-types';

import { CAMPAIGN_ACTIONS, IMPORT_JOB_ACTIONS, SEGMENT_ACTIONS, TAG_ACTIONS } from '../../core/constants';
import IconButton from '../buttons/IconButton';

const getActionIcon = (action) => {
  switch (action) {
    case CAMPAIGN_ACTIONS.edit:
      return <IconPencil size={20} />;
    case SEGMENT_ACTIONS.rename:
    case TAG_ACTIONS.rename:
      return <IconEdit size={20} />;
    case CAMPAIGN_ACTIONS.view:
    case IMPORT_JOB_ACTIONS.view:
    case TAG_ACTIONS.view:
      return <IconEye size={20} />;
    case CAMPAIGN_ACTIONS.viewReport:
      return <IconReport size={20} />;
    case CAMPAIGN_ACTIONS.viewReportDraft:
    case CAMPAIGN_ACTIONS.duplicate:
      return <IconFiles size={20} />;
    case CAMPAIGN_ACTIONS.cancel:
      return <IconMailCancel size={20} />;
    case CAMPAIGN_ACTIONS.delete:
    case SEGMENT_ACTIONS.delete:
    case TAG_ACTIONS.delete:
      return <IconTrash size={20} className="text-red-500" />;
    case CAMPAIGN_ACTIONS.restore:
      return <IconArrowBack size={20} />;
    case SEGMENT_ACTIONS.createCampaign:
    case TAG_ACTIONS.createCampaign:
      return <IconMailPlus size={20} />;
    case IMPORT_JOB_ACTIONS.downloadInvalid:
      return <IconDownload size={20} />;
    default:
      return null;
  }
};

const isDeleteAction = (action) => [CAMPAIGN_ACTIONS.delete, SEGMENT_ACTIONS.delete].includes(action);

const RowActions = ({ actions, visibleActions, onSelectAction }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAction = (action) => {
    onSelectAction(action);
    handleClose();
  };

  if (actions.length <= visibleActions) {
    return (
      <div className="flex items-center">
        {actions.map((action) => (
          <IconButton
            key={action}
            Icon={getActionIcon(action)}
            srOnly={action}
            color="transparent"
            circle
            onClick={() => handleSelectAction(action)}
          />
        ))}
      </div>
    );
  }

  const visibleActionButtons = actions.slice(0, visibleActions);
  const menuActions = actions.slice(visibleActions);

  return (
    <div className="flex items-center">
      {visibleActionButtons.map((action) => (
        <IconButton
          key={action}
          Icon={getActionIcon(action)}
          srOnly="Main action"
          color="transparent"
          circle
          onClick={() => handleSelectAction(action)}
        />
      ))}

      <IconButton
        Icon={<IconDotsVertical size={18} className="text-gray-950" />}
        srOnly="More actions"
        color="transparent"
        circle
        onClick={handleClick}
      />

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} classes={{ paper: '!rounded-lg mt-0.5' }}>
        {menuActions.map((action) => (
          <MenuItem key={action} classes={{ root: '!py-2 !px-3.5' }} onClick={() => handleSelectAction(action)}>
            {getActionIcon(action)}
            <span className={`ml-2.5 text-base ${isDeleteAction(action) ? 'text-red-500' : ''}`}>{action}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

RowActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.string).isRequired,
  visibleActions: PropTypes.number,
  onSelectAction: PropTypes.func.isRequired,
};

RowActions.defaultProps = {
  visibleActions: 1,
};

export default RowActions;
