import { useState } from 'react';
import { Menu } from '@mui/material';
import { IconTableExport } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import { Button, Input } from 'ui';
import { getNetworkError } from 'common/utils';

import { useDonations } from '../../store/donations/hook';
import { EXPORT_FILENAME_REGEX, INVALID_EXPORT_FILENAME_MESSAGE } from '../../core/constants';
import { createDonationsExportJob } from '../../api';
import { removeEmptyValues, downloadDonationsFile } from '../../core/utils';

const formValidation = yup.object({
  exportFilename: yup
    .string()
    .required('Export name is required.')
    .min(2, 'Export name must be at least 2 characters long.')
    .matches(EXPORT_FILENAME_REGEX, INVALID_EXPORT_FILENAME_MESSAGE),
});

const ExportDonationsDropdown = (props) => {
  const { donations } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setError } = useDonations();
  const form = useForm({
    initialValues: { exportFilename: '' },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });
  const { filters, setSuccess } = useDonations();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (force = false) => {
    if (!force && loading) return;
    setAnchorEl(null);
    form.reset();
  };

  const handleExport = async (values) => {
    setLoading(true);
    try {
      const payload = { exportFilename: values.exportFilename };
      const payloadFilters = removeEmptyValues(filters);
      if (Object.keys(payloadFilters).length > 0) {
        payload.filters = payloadFilters;
      }
      await createDonationsExportJob(payload);
      downloadDonationsFile(donations, `${values.exportFilename}.csv`);
      handleClose();
      setSuccess('Donations exported successfully!');
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }
    setLoading(false);
  };

  const totalDonations = donations.length;
  const isFormValid = form.isValid();
  const isFormDirty = form.isDirty();

  return (
    <>
      <Button
        title="Export Donations"
        className={open ? 'text-primary-600' : undefined}
        LeftIcon={IconTableExport}
        onClick={handleClick}
        disabled={totalDonations === 0}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: 'w-[320px] !rounded-lg mt-0.5 !overflow-hidden -ml-4', list: '!p-0' }}
        PaperProps={{ component: 'form', noValidate: true, onSubmit: form.onSubmit(handleExport) }}
      >
        <li className="p-4 text-base">
          <span className="font-semibold">{totalDonations}</span> donations will be exported in CSV format based on your
          selected filters and search query.
        </li>

        <li className="px-4 text-base">Past exports can be viewed on the &quot;Exports&quot; page.</li>

        <li className="p-4 pt-3">
          <Input
            id="export-name"
            label="Export name"
            {...form.getInputProps('exportFilename')}
            required
            size="xl"
            error={!!form.errors.exportFilename}
            helperText={form.errors.exportFilename}
            onKeyDown={(e) => {
              // stops event bubbling to avoid focusing an item in the list
              e.stopPropagation();
            }}
          />
        </li>

        <li className="flex items-center justify-between border-t border-gray-50 p-4">
          <Button
            title="Export Donations"
            color="primary"
            className="w-full"
            type="submit"
            loading={loading}
            disabled={!isFormValid || !isFormDirty}
          />
        </li>
      </Menu>
    </>
  );
};

ExportDonationsDropdown.propTypes = {
  donations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ExportDonationsDropdown;
