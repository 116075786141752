import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { getNetworkError } from 'common/utils';

import { getPages as getPagesApi } from '../../api';

const SET_STATUS = 'pages/SET_STATUS';
const GET_PAGES = 'pages/GET_PAGES';

export const setStatus = createAction(SET_STATUS);

export const getPages = createAsyncThunk(GET_PAGES, async () => {
  try {
    const pages = await getPagesApi();
    return pages.filter((page) => page.slug !== 'opt-in');
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});
