import { Dialog } from '@mui/material';
import { IconX, IconAlertCircleFilled } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import IconButton from '../buttons/IconButton';

const ConfirmExitPageModal = ({ open, onCancel, onConfirm }) => {
  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={onCancel}
      sx={{ '.MuiPaper-root': { padding: 0, margin: 0, borderRadius: '12px', width: '100%' } }}
    >
      <div className="space-y-6 p-6">
        <div className="relative flex">
          <div className="flex items-center space-x-3">
            <IconAlertCircleFilled size={20} className="text-red-500" />
            <h3 className="text-h6 font-semibold">Exit Page?</h3>
          </div>

          <IconButton
            Icon={<IconX size={20} className="text-gray-400" />}
            srOnly="Close"
            onClick={onCancel}
            color="transparent"
            className="absolute -right-1 -top-1 !p-1"
          />
        </div>

        <p className="text-base">Are you sure you want to exit this page? Changes you made will be lost.</p>

        <div className="flex justify-end space-x-3">
          <Button title="No, cancel" onClick={onCancel} />

          <Button title="Yes, I'm sure" color="destructive" onClick={onConfirm} />
        </div>
      </div>
    </Dialog>
  );
};

ConfirmExitPageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmExitPageModal;
