import { useEffect, useState } from 'react';
import { useForm, yupResolver } from '@mantine/form';
import PropTypes from 'prop-types';
import { IconAlertCircleFilled } from '@tabler/icons-react';

import Input from '../inputs/Input';
import Button from '../buttons/Button';
import { formValidation, initialValues } from './utils';
import Checkbox from '../inputs/Checkbox';
import { useDomains } from '../../store/domains/hooks';
import AlertMessage from '../alerts/AlertMessage';
import StepNavBottom from '../onboarding/StepNavBottom';
import EmailDNSRecordsModal from './EmailDNSRecordsModal';
import DnsRecordsTable from './DnsRecordsTable';
import HeaderSection from './HeaderSection';

const getDefaultDmarcRecord = (domain) => ({
  host: `_dmarc.${domain}`,
  valid: false,
  data: 'v=DMARC1; p=none;',
  type: 'TXT',
});

const DomainAuthForm = ({ onboarding, onPrev, onNext, onDelete }) => {
  const [recordsAdded, setRecordsAdded] = useState(false);
  const [emailDnsOpen, setEmailDnsOpen] = useState(false);
  const {
    domain,
    validationResults,
    loading,
    success,
    error,
    setSuccess,
    setError,
    addDomain,
    validateDomain,
    getDomains,
  } = useDomains();
  const { errors, setValues, getInputProps, onSubmit, isValid } = useForm({
    initialValues: domain.domain ? { domainName: domain.domain } : initialValues,
    validate: yupResolver(formValidation),
    validateInputOnBlur: true,
  });

  useEffect(() => {
    if (!domain.domain) return;
    setValues({ domainName: domain.domain });
  }, [domain.domain, setValues]);

  const handleAddDomain = ({ domainName }) => {
    addDomain(domainName);
  };

  const handleVerifyDomain = async (refresh = false) => {
    if (!domain.id) return;
    await validateDomain(domain.id);
    if (refresh) {
      await getDomains();
    }
  };

  const handleDeleteDomain = () => {
    if (!domain.id) return;
    onDelete(domain);
  };

  return (
    <div
      className={`space-y-8 border border-gray-100 bg-white-100 ${
        onboarding ? 'rounded-lg p-6' : 'max-w-6xl rounded-xl p-8'
      }`}
    >
      {!onboarding && (
        <p className="text-lg">
          Prevent being marked as spam and improve deliverability by authenticating your domain.
        </p>
      )}

      {domain.valid ? (
        <div className="space-y-6">
          <div className="flex items-center justify-between">
            <div className="space-y-2">
              <h2 className="text-h5 font-semibold">Domain</h2>

              <div className="flex items-center space-x-2">
                <p className="text-base">{domain.domain}</p>
                <div className="rounded border border-green-100 bg-green-50 px-1.5 py-0.5">
                  <p className="text-caption1 font-semibold uppercase text-green-500">Verified</p>
                </div>
              </div>
            </div>

            {!onboarding && <Button title="Delete domain" onClick={handleDeleteDomain} />}
          </div>

          {(!domain.dns.dmarc || !domain.validationResults?.dmarc?.valid) && (
            <>
              <hr className="border-gray-50" />

              <p>
                We strongly suggest adding a DMARC record. DMARC is a standard that can help prevent spammers from
                trying to send email that appears to be from your domain. The DMARC record is not specific to
                DonorSpring, but some email services (like Gmail and Yahoo) require bulk senders to have it.
              </p>

              <DnsRecordsTable
                dnsRecords={{ dmarc: domain.dns.dmarc || getDefaultDmarcRecord(domain.domain) }}
                validationResults={validationResults}
              />

              <div className="flex items-center justify-between">
                <Checkbox
                  id="dns-record"
                  name="dns-record"
                  label="I've added this record"
                  checked={recordsAdded}
                  onChange={() => setRecordsAdded((prev) => !prev)}
                />

                <Button title="Email DNS Record to a Coworker" onClick={() => setEmailDnsOpen(true)} />
              </div>

              {recordsAdded && (
                <div className="flex justify-end">
                  <Button title="Verify" color="primary" loading={loading} onClick={() => handleVerifyDomain(true)} />
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <>
          <div className="flex justify-between">
            <h2 className="text-h5 font-semibold">Domain</h2>
            {domain.id && <Button title="Delete domain" onClick={handleDeleteDomain} />}
          </div>

          <form className="space-y-4" onSubmit={onSubmit(handleAddDomain)}>
            <HeaderSection number={1} title="Enter Your Domain" />

            <Input
              id="domain"
              label="Domain Name"
              placeholder="Domain Name"
              {...getInputProps('domainName')}
              error={!!errors.domainName}
              helperText={errors.domainName}
              required
              size="xl"
              disabled={!!domain.id}
            />

            {!domain.dns && (
              <div className="flex justify-end">
                <Button title="Next" color="primary" type="submit" disabled={!isValid()} loading={loading} />
              </div>
            )}
          </form>

          {domain.id && !domain.hasValidSpfRecord && (
            <div className="!mt-6 flex items-center space-x-2 rounded-md bg-red-50 px-3 py-2">
              <IconAlertCircleFilled size={20} className="size-5 text-red-500" />

              <div className="flex-1 space-y-5">
                <h3 className="text-base font-semibold">Prerequisite Missing</h3>

                <p className="text-base">DonorSpring recommends setting up DMARC.</p>

                <p className="text-base">
                  A prerequisite for DMARC is that you also have SPF and/or DKIM set up for your domain. Please reach
                  out to your current email service or IT provider to set these up before proceeding with
                  DonorSpring&apos;s Domain Authentication setup. If you are adding SPF and DKIM to your domain for the
                  first time it is also recommended to wait 48 hours after adding them before setting up DMARC.
                </p>
              </div>
            </div>
          )}

          {!!domain.dns && domain.hasValidSpfRecord && (
            <div className="space-y-4">
              <HeaderSection number={2} title="Install DNS Records" />

              <DnsRecordsTable dnsRecords={domain.dns} validationResults={validationResults} />

              <div className="flex items-center justify-between">
                <Checkbox
                  id="dns-records"
                  name="dns-records"
                  label="I've added these records"
                  checked={recordsAdded}
                  onChange={() => setRecordsAdded((prev) => !prev)}
                />

                <Button title="Email DNS Records to a Coworker" onClick={() => setEmailDnsOpen(true)} />
              </div>
            </div>
          )}

          {recordsAdded && (
            <div className="space-y-4">
              <HeaderSection number={3} title="Verify Domain" />

              <div className="flex items-center justify-between">
                <p>Press the button to verify your domain</p>

                <Button title="Verify" color="primary" loading={loading} onClick={handleVerifyDomain} />
              </div>
            </div>
          )}
        </>
      )}

      {onboarding && <StepNavBottom onPrev={onPrev} onNext={onNext} nextDisabled={!recordsAdded && !domain.valid} />}

      <EmailDNSRecordsModal
        open={emailDnsOpen}
        onClose={() => setEmailDnsOpen(false)}
        setError={setError}
        setSuccess={setSuccess}
      />

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => setSuccess(false)}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </div>
  );
};

DomainAuthForm.propTypes = {
  onboarding: PropTypes.bool,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  onDelete: PropTypes.func,
};

DomainAuthForm.defaultProps = {
  onboarding: false,
  onPrev: () => {},
  onNext: () => {},
  onDelete: () => {},
};

export default DomainAuthForm;
