import { createSlice } from '@reduxjs/toolkit';

import { STATUS_STATES } from '../../core/constants';
import { setStatus, getPages } from './actions';

const initialState = {
  status: STATUS_STATES.idle,
  statusDescription: '',
  pageList: [],
};

const pagesStore = createSlice({
  name: 'pages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setStatus, (state, { payload }) => {
      state.status = payload.status;
      state.statusDescription = payload.statusDescription || '';
    });

    builder.addCase(getPages.fulfilled, (state, { payload }) => {
      state.status = STATUS_STATES.idle;
      state.statusDescription = '';
      state.pageList = payload;
    });

    builder.addCase(getPages.pending, (state) => {
      state.status = STATUS_STATES.loading;
      state.statusDescription = '';
    });

    builder.addCase(getPages.rejected, (state, { error }) => {
      state.status = STATUS_STATES.error;
      state.statusDescription = error.message;
    });
  },
});

export default pagesStore.reducer;
