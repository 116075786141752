import format from 'date-fns/format';
import { unparse } from 'papaparse';
import fileDownload from 'js-file-download';
import { parseDonationAmount } from 'common/utils';

import { donationStatusLabels, donationTypeLabels } from '../constants';

export const filterDonations = (donations, filters = {}) => {
  const { startDate, endDate, minAmount, maxAmount, type, status, campaignIds, searchTerm } = filters;

  let filteredDonations = [...donations];

  if (startDate) {
    filteredDonations = filteredDonations.filter((don) => {
      const startTime = Math.round(new Date(startDate).getTime() / 1000);
      const createdAt = parseInt(don.createdAt, 10);
      return createdAt >= startTime;
    });
  }

  if (endDate) {
    filteredDonations = filteredDonations.filter((don) => {
      const endTime = Math.round(new Date(endDate).getTime() / 1000);
      const createdAt = parseInt(don.createdAt, 10);
      return createdAt <= endTime;
    });
  }

  if (campaignIds && campaignIds.length > 0) {
    filteredDonations = filteredDonations.filter((don) => campaignIds.includes(don.campaignId));
  }
  if (minAmount) {
    filteredDonations = filteredDonations.filter((don) => don.netAmount / 100 >= minAmount);
  }
  if (maxAmount) {
    filteredDonations = filteredDonations.filter((don) => don.netAmount / 100 <= maxAmount);
  }
  if (searchTerm) {
    filteredDonations = filteredDonations.filter((don) => don.donorName.match(new RegExp(searchTerm, 'i')));
  }
  if (status) {
    filteredDonations = filteredDonations.filter((don) => don.status === status);
  }
  if (type) {
    filteredDonations = filteredDonations.filter((don) => don.type === type);
  }

  return filteredDonations;
};

const DONATION_COLUMNS = {
  donationId: 'Donation ID',
  donorId: 'Donor ID',
  createdAt: 'Date',
  netAmount: 'Amount',
  type: 'Type',
  status: 'Status',
  donorName: 'Donor',
  donorEmail: 'Donor Email',
  campaignName: 'Campaign',
};

export const downloadDonationsFile = (donations, filename) => {
  const data = donations.map((donation) => {
    const donationData = {};
    Object.keys(DONATION_COLUMNS).forEach((column) => {
      const objKey = DONATION_COLUMNS[column];
      const objValue = donation[column];
      if (column === 'createdAt') {
        donationData[objKey] = objValue ? format(new Date(objValue * 1000), 'MM.dd.yyyy') : '';
        return;
      }
      if (column === 'netAmount') {
        donationData[objKey] = objValue ? parseDonationAmount(objValue / 100) : '';
        return;
      }
      if (column === 'status') {
        donationData[objKey] = donationStatusLabels[objValue] || '';
        return;
      }
      if (column === 'type') {
        let label = donationTypeLabels[objValue];
        if (donation.paymentInfo?.subscription?.status) {
          label += ` - ${donation.paymentInfo.subscription.status.toUpperCase()}`;
        }
        donationData[objKey] = label;
        return;
      }
      donationData[objKey] = objValue;
    });
    return donationData;
  });
  const csvData = unparse(data);
  const blob = new Blob([csvData], { type: 'text/csv' });
  fileDownload(blob, filename);
};
