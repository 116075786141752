import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const getClassName = ({ isActive }) => {
  const base = `
    flex
    items-center
    capitalize
    py-2
    -ml-3
    transition-colors
    font-medium
    text-base
    text-gray-800
    rounded-lg
    hover:bg-gray-10
    before:block
    before:transition-colors
    before:h-5
    before:w-1
    before:rounded
    before:mr-2
  `;

  if (!isActive) return base;

  return `${base} bg-primary-50 text-primary-700 hover:bg-primary-50 before:bg-primary-700`;
};

const NavbarListItem = ({ Icon, title, to }) => (
  <li>
    <NavLink className={getClassName} to={to}>
      <Icon className="mr-3" size={20} />
      {title}
    </NavLink>
  </li>
);

NavbarListItem.propTypes = {
  Icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default NavbarListItem;
