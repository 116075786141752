import { useCallback, useState } from 'react';
import { EMAIL_BUTTON_KEY_NAMES, EMAIL_SECTION_KEY_NAMES } from '../../core/constants';
import { useAccount } from '../../store/account/hooks';
import Button from '../buttons/Button';
import Loader from '../loader/Loader';
import DonateButtonSettings from './DonateButtonSettings';
import FooterButtonSettings from './FooterButtonSettings';
import EmailSectionEditor from './EmailSectionEditor';

const pendingChangesInitialValue = {
  [EMAIL_BUTTON_KEY_NAMES.shareMissionButton]: false,
  [EMAIL_BUTTON_KEY_NAMES.subscribeButton]: false,
};

const EmailToolSettings = () => {
  const [pendingChanges, setPendingChanges] = useState(pendingChangesInitialValue);
  const [activeButton, setActiveButton] = useState('');
  const [renderKey, setRenderKey] = useState(Date.now().toString());
  const { loading, saveEmailPreferences } = useAccount();

  const handlePendingChanges = useCallback((buttonKeyName, hasPendingChanges) => {
    setPendingChanges((prev) => ({ ...prev, [buttonKeyName]: hasPendingChanges }));
  }, []);

  const handleSetActiveButton = useCallback((buttonKeyName) => {
    setActiveButton((prev) => {
      if (prev === buttonKeyName) return '';
      return buttonKeyName;
    });
  }, []);

  const handleSaveChanges = async () => {
    await saveEmailPreferences();
    setRenderKey(Date.now().toString());
  };

  const hasPendingChanges = Object.values(pendingChanges).some((value) => value);

  return (
    <div className="space-y-6">
      <div className="relative rounded-lg bg-white-100 shadow-md">
        <div className="px-4 py-3">
          <h2 className="text-xl dark:text-white-100">Email Sections</h2>
          <p className="text-base">
            Configure default sections that are automatically inserted into new Campaigns and Suggested Drafts
          </p>
        </div>
        <EmailSectionEditor sectionKeyName={EMAIL_SECTION_KEY_NAMES.header} />
        <EmailSectionEditor sectionKeyName={EMAIL_SECTION_KEY_NAMES.footer} />
      </div>
      <div className="relative rounded-lg bg-white-100 shadow-md">
        <div className="space-y-2 px-4 py-3">
          <h2 className="text-xl dark:text-white-100">Email Buttons</h2>
        </div>

        <DonateButtonSettings key={`${renderKey}-donate`} onChange={handlePendingChanges} />

        <FooterButtonSettings
          key={`${renderKey}-shareMission`}
          title="Share Our Mission Button Configuration"
          subtitle={`Configure the "Share our mission" button present in your campaign emails' footer`}
          buttonKeyName={EMAIL_BUTTON_KEY_NAMES.shareMissionButton}
          onChange={handlePendingChanges}
          isActive={activeButton === EMAIL_BUTTON_KEY_NAMES.shareMissionButton}
          setActiveButton={handleSetActiveButton}
        />

        <FooterButtonSettings
          key={`${renderKey}-subscribe`}
          title="Subscribe Button Configuration"
          subtitle={`Configure the "Subscribe" button present in your campaign emails' footer`}
          buttonKeyName={EMAIL_BUTTON_KEY_NAMES.subscribeButton}
          onChange={handlePendingChanges}
          isActive={activeButton === EMAIL_BUTTON_KEY_NAMES.subscribeButton}
          setActiveButton={handleSetActiveButton}
        />

        <div className="bg-primary-50 px-4 py-6">
          <Button title="Save Changes" color="primary" onClick={handleSaveChanges} disabled={!hasPendingChanges} />
        </div>

        {loading && <Loader />}
      </div>
    </div>
  );
};

export default EmailToolSettings;
