import { useEffect, useState } from 'react';
import format from 'date-fns/format';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import { useAccount } from '../store/account/hooks';
import { getNetworkError, parseDonationAmount } from '../core/utils';
import Button from '../components/buttons/Button';
import appSettings from '../app-settings';
import AlertMessage from '../components/alerts/AlertMessage';
import ChangePlanModal from '../components/subscription/ChangePlanModal';
import Loader from '../components/loader/Loader';

const PAYMENT_UPDATE_SESSION_URL = `${appSettings.baseUrl}/accounts/payment-update`;

const Subscription = () => {
  const {
    account: { subscription },
    loading: loadingAccount,
  } = useAccount();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [openPlansModal, setOpenPlansModal] = useState(false);

  useEffect(() => {
    const updatePaymentSucceeded = new URLSearchParams(window.location.search).get('success') === 'true';
    if (updatePaymentSucceeded) {
      setSuccess('Payment method successfully updated!');
    }
    // Remove query params
    window.history.replaceState({}, document.title, '/subscription');
  }, []);

  const handleUpdatePaymentMethod = async () => {
    setLoading(true);
    try {
      const response = await axios.post(PAYMENT_UPDATE_SESSION_URL);
      window.location.href = response.data.url;
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }
    setLoading(false);
  };

  return (
    <div className="w-full space-y-6">
      <h1 className="text-h3">Subscription</h1>

      <div className="relative w-[456px] space-y-3 rounded-lg bg-white-100 px-6 py-[18px]">
        <div className="flex items-center justify-between">
          <p className="text-base">Current Plan</p>
          <p className="text-base font-semibold">{subscription?.plan.nickname}</p>
        </div>

        <div className="flex items-center justify-between">
          <p className="text-base">Current Plan Price</p>
          <p className="text-base font-semibold">
            {parseDonationAmount((subscription?.plan.amount || 0) / 100, 0)}/{subscription?.plan.interval}
          </p>
        </div>

        <div className="flex items-center justify-between">
          <p className="text-base">Plan Start Date</p>
          <p className="text-base font-semibold">
            {subscription?.startDate ? format(subscription.startDate * 1000, 'dd MMM yyyy') : ''}
          </p>
        </div>

        <div className="flex items-center justify-between">
          <p className="text-base">Next Automatic Renewal</p>
          <p className="text-base font-semibold">
            {subscription?.currentPeriodEnd ? format(subscription.currentPeriodEnd * 1000, 'dd MMM yyyy') : ''}
          </p>
        </div>

        <div className="!mt-8 flex items-center justify-between">
          <Button
            title="Update Payment Method"
            color="primary"
            onClick={handleUpdatePaymentMethod}
            loading={loading}
            disabled={loading || loadingAccount}
          />

          <Button
            title="Change Plan"
            color="primary"
            onClick={() => setOpenPlansModal(true)}
            disabled={loading || loadingAccount}
          />
        </div>

        {loadingAccount && <Loader />}
      </div>

      <ChangePlanModal
        open={openPlansModal}
        onClose={() => setOpenPlansModal(false)}
        onError={setError}
        onSuccess={setSuccess}
      />

      <AlertMessage open={!!success} message={success} onClose={() => setSuccess('')} severity="success" />

      <AlertMessage open={!!error} message={error} onClose={() => setError(false)} severity="error" />
    </div>
  );
};

export default Subscription;
