import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  setError,
  setSuccess,
  getImports,
  downloadFile,
  updateNewImport,
  resetNewImport,
  updateFieldMappings,
  setImportOnboarding,
} from './actions';

const initialNewImportState = {
  importType: '',
  fileName: '',
  skipUpdate: true,
  sendRecurringEmails: false,
  optInContacts: true,
  fieldMappings: {},
  selectedTags: [],
  activeStep: 0,
  csvData: [],
  csvHeaders: [],
};

const initialState = {
  loading: false,
  error: false,
  success: false,
  imports: [],
  newImport: initialNewImportState,
  onboarding: false,
};

const importsStore = createSlice({
  name: 'imports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(updateNewImport, (state, { payload = {} }) => {
      state.newImport = { ...state.newImport, ...payload };
    });

    builder.addCase(resetNewImport, (state) => {
      state.newImport = initialNewImportState;
    });

    builder.addCase(setImportOnboarding, (state, { payload }) => {
      state.onboarding = payload;
    });

    builder.addCase(updateFieldMappings, (state, { payload }) => {
      state.newImport.fieldMappings = { ...state.newImport.fieldMappings, ...payload };
    });

    builder.addCase(getImports.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.imports = payload ?? [];
    });

    builder.addCase(downloadFile.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addMatcher(isAnyOf(getImports.pending, downloadFile.pending), (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    });

    builder.addMatcher(isAnyOf(getImports.rejected, downloadFile.rejected), (state, { error }) => {
      state.loading = false;
      state.error = error.message || true;
    });
  },
});

export default importsStore.reducer;
