import axios from 'axios';

import appSettings from '../app-settings';
import { PAGES_IMAGE_KEYS, DEFAULT_HEADER_IMG_BASE64, DEFAULT_HERO_IMG_BASE64 } from '../core/constants';
import { uploadImage, getImageKeysToUpload, getPageImagesUrls } from '../core/utils';

const API_URL = `${appSettings.baseUrl}/pages`;

const IMG_KEYS_TO_DEFAULT_IMG_BASE64 = {
  [PAGES_IMAGE_KEYS.headerImage]: DEFAULT_HEADER_IMG_BASE64,
  [PAGES_IMAGE_KEYS.heroImage]: DEFAULT_HERO_IMG_BASE64,
};

export const getPages = async () => {
  const res = await axios.get(API_URL);
  return res.data;
};

export const getPage = async (slug) => {
  const res = await axios.get(`${API_URL}/${slug}`);
  return res.data;
};

export const uploadPageImages = async ({ slug, images }) => {
  const imageKeysToUpload = getImageKeysToUpload(images);

  if (!imageKeysToUpload.length) return;

  const uploadResponse = await axios.post(`${API_URL}/${slug}/images`, {
    imageKeys: imageKeysToUpload,
  });

  const imageUploadUrls = uploadResponse.data;

  await Promise.all(
    imageKeysToUpload.map(async (key) => {
      const image = images[key];
      const uploadUrl = imageUploadUrls[key];
      const data = image.data || IMG_KEYS_TO_DEFAULT_IMG_BASE64[key];
      // Let's be cautious here
      if (!uploadUrl || !data) return;
      await uploadImage(uploadUrl, data);
    }),
  );
};

export const upsertPage = async ({ tenantId, slug, content, action = 'update' }) => {
  const { images, metadata: { name } = {}, ...rest } = content;

  await uploadPageImages({ slug, images });

  const optInPageContent = { ...rest };
  const imagesContent = getPageImagesUrls({ images, tenantId, slug });

  if (imagesContent) optInPageContent.images = imagesContent;

  if (action === 'create') {
    await axios.post(API_URL, { slug, name, content: optInPageContent });
    return;
  }

  await axios.patch(`${API_URL}/${slug}`, { name, content: optInPageContent });
};

export const setDefaultPage = async ({ slug, defaultNewSlug }) => {
  await axios.patch(`${API_URL}/set-default`, { slug, defaultNewSlug });
};
