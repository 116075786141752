import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import mergeWith from 'lodash.mergewith';
import isArray from 'lodash.isarray';

import { nanoid } from '../../core/utils';
import {
  setError,
  setSuccess,
  resetSegmentDetails,
  updateSegmentDetails,
  getSegments,
  createSegment,
  deleteSegment,
  updateSegment,
} from './actions';

const cutomizedMerge = (objValue, srcValue) => {
  if (srcValue === '') {
    return srcValue;
  }
  if (isArray(objValue)) {
    return srcValue;
  }
};

export const initialFieldCondition = { field: '', condition: '', value: '', key: nanoid() };

const initialSegmentDetails = {
  name: 'Untitled',
  match: 'any',
  rules: [{ ...initialFieldCondition }],
};

const initialState = {
  loading: false,
  error: false,
  success: false,
  segments: [],
  segmentDetails: initialSegmentDetails,
};

const segmentsStore = createSlice({
  name: 'segments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(resetSegmentDetails, (state) => {
      state.segmentDetails = initialSegmentDetails;
    });

    builder.addCase(updateSegmentDetails, (state, { payload }) => {
      state.segmentDetails = mergeWith({}, state.segmentDetails, payload, cutomizedMerge);
    });

    builder.addCase(getSegments.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.segments = payload;
    });

    builder.addCase(createSegment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.segments.unshift(payload);
      state.success = 'The segment was successfully created!';
    });

    builder.addCase(deleteSegment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.segments = payload;
      state.success = 'The segment was successfully deleted!';
    });

    builder.addCase(updateSegment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = 'The segment was successfully updated!';
      if (payload) state.segments = payload;
    });

    builder.addMatcher(
      isAnyOf(getSegments.pending, createSegment.pending, deleteSegment.pending, updateSegment.pending),
      (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      },
    );

    builder.addMatcher(
      isAnyOf(getSegments.rejected, createSegment.rejected, deleteSegment.rejected, updateSegment.rejected),
      (state, { error }) => {
        state.loading = false;
        state.error = error.message || true;
      },
    );
  },
});

export default segmentsStore.reducer;
