import PropTypes from 'prop-types';

import { INVOICE_STATUS } from '../../core/constants';

const getContainerColorClassNames = (status, isPastDue) => {
  if (isPastDue) return 'border-red-100 bg-red-50 text-red-500';

  switch (status) {
    case INVOICE_STATUS.paid:
      return 'border-green-100 bg-green-50 text-green-500';
    default:
      // INVOICE_STATUS.void
      // INVOICE_STATUS.draft
      // INVOICE_STATUS.open
      // INVOICE_STATUS.uncollectible
      return 'border-gray-100 bg-gray-10 text-gray-500';
  }
};

const InvoiceStatusLabel = ({ dueDate, status }) => {
  const isPastDue = dueDate * 1000 < Date.now();
  return (
    <div className={`w-fit rounded border px-1.5 py-0.5 ${getContainerColorClassNames(status, isPastDue)}`}>
      <p className="text-caption1 font-semibold uppercase">{isPastDue ? 'Past Due' : status}</p>
    </div>
  );
};

InvoiceStatusLabel.propTypes = {
  dueDate: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

export default InvoiceStatusLabel;
