import { useSelector, shallowEqual } from 'react-redux';

import useActionCreator from '../../hooks/useActionCreator';
import { setError, setSuccess, getDomains, addDomain, validateDomain, deleteDomain } from './actions';

export const useDomains = () => {
  const { domains, ...domainsState } = useSelector((state) => state.domains, shallowEqual);

  const domain = domains[0] ?? {};

  const authenticatedDomain = domain.valid ? domain.domain : '';

  return {
    ...domainsState,
    domain,
    authenticatedDomain,
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    getDomains: useActionCreator(getDomains),
    addDomain: useActionCreator(addDomain),
    validateDomain: useActionCreator(validateDomain),
    deleteDomain: useActionCreator(deleteDomain),
  };
};
