import axios from 'axios';

import appSettings from '../app-settings';

const INTEGRATIONS_URL = `${appSettings.baseUrl}/integrations`;

export const getIntegrations = async () => {
  const response = await axios.get(INTEGRATIONS_URL);
  return response.data;
};
