import { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { IconDotsVertical, IconTrash } from '@tabler/icons-react';

import IconButton from '../buttons/IconButton';
import { useCampaigns } from '../../store/campaigns/hooks';

const MoreMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { setViewTrashCan, getCampaigns } = useCampaigns();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewTrashCan = () => {
    setViewTrashCan(true);
    getCampaigns();
    handleClose();
  };

  return (
    <>
      <IconButton
        Icon={<IconDotsVertical size={18} className="text-gray-950" />}
        srOnly="More Actions"
        className="
          size-10 
          border 
          border-gray-150 
          bg-white-100 
          enabled:hover:bg-white-100 
          enabled:focus:bg-white-100
          enabled:active:bg-white-100
        "
        onClick={handleClick}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: '!rounded-lg mt-0.5', list: '!p-0' }}
      >
        <MenuItem classes={{ root: 'space-x-2.5 !py-4 !px-3.5' }} onClick={handleViewTrashCan}>
          <IconTrash size={18} className="text-gray-800" />
          <p className="text-base text-gray-950">Campaigns Trash Can</p>
        </MenuItem>
      </Menu>
    </>
  );
};

export default MoreMenu;
