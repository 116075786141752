import { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell, CartesianGrid } from 'recharts';
import PropTypes from 'prop-types';
import { parse, format } from 'date-fns';

import { AMOUNT_CATEGORIES_INTERVAL, PRIMARY_BASE_COLOR } from '../../core/constants';
import { getFormatString, parseDonationAmount } from '../../core/utils';

const BAR_COLOR = '#8AD3FF';

const dayToTooltipLabel = {
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday',
};

const formatXAxis = (tickItem) => {
  const parsedDate = parse(tickItem, getFormatString(tickItem), new Date());
  return format(parsedDate, 'MMM d');
};

const CustomTooltip = ({ active, payload, currency, xDataKey }) => {
  if (!active || !payload || !payload.length) return null;

  const { payload: data } = payload[0];

  const getLabel = () => {
    if (xDataKey === 'date') {
      const parsedDate = parse(data[xDataKey], getFormatString(data[xDataKey]), new Date());
      return format(parsedDate, 'MMM d, yyyy');
    }
    if (xDataKey === 'day') {
      return dayToTooltipLabel[data[xDataKey]];
    }
    if (xDataKey === 'amountCategory') {
      return AMOUNT_CATEGORIES_INTERVAL[data[xDataKey]];
    }
    return data[xDataKey];
  };

  return (
    <div className="space-y-1 rounded-lg bg-white-100 px-3 py-2 shadow-custom">
      <p className="text-sm text-gray-800">{getLabel()}</p>
      <p className="text-lg text-primary-700" style={{ color: PRIMARY_BASE_COLOR }}>
        {currency ? parseDonationAmount(data.count) : data.count}
      </p>
    </div>
  );
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  currency: PropTypes.bool,
  xDataKey: PropTypes.string,
};

const margin = {
  top: 12,
  right: 0,
  left: -12,
  bottom: 0,
};

const CustomBarChart = ({ data, currency, xDataKey, interval, XAxisTick, XAxisTicks, XAxisTickCount }) => {
  const [focusBarIndex, setFocusBarIndex] = useState(null);

  const handleMouseInteraction = (state) => {
    if (state.isTooltipActive) {
      setFocusBarIndex(state.activeTooltipIndex);
    } else {
      setFocusBarIndex(null);
    }
  };

  return (
    <ResponsiveContainer width="100%" height={153}>
      <BarChart
        width={312}
        height={153}
        data={data}
        margin={margin}
        barCategoryGap="5%"
        onMouseEnter={handleMouseInteraction}
        onMouseLeave={handleMouseInteraction}
        onMouseMove={handleMouseInteraction}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey={xDataKey}
          tickFormatter={xDataKey === 'date' ? formatXAxis : undefined}
          interval={interval}
          axisLine={false}
          tickLine={false}
          tickMargin={12}
          tick={XAxisTick}
          ticks={XAxisTicks}
          fontSize={12}
          tickCount={XAxisTickCount}
        />
        <YAxis axisLine={false} tickLine={false} tickMargin={12} fontSize={12} tickCount={4} />
        <Tooltip content={<CustomTooltip currency={currency} xDataKey={xDataKey} />} cursor={false} />
        <Bar dataKey="count">
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                focusBarIndex !== null ? (focusBarIndex === index ? PRIMARY_BASE_COLOR : BAR_COLOR) : PRIMARY_BASE_COLOR
              }
              radius={[2, 2, 0, 0]}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

CustomBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  currency: PropTypes.bool,
  xDataKey: PropTypes.string,
  interval: PropTypes.any,
  XAxisTickCount: PropTypes.number,
  XAxisTick: PropTypes.func,
  XAxisTicks: PropTypes.arrayOf(PropTypes.any),
};

CustomBarChart.defaultProps = {
  xDataKey: 'date',
  interval: 'preserveEnd',
};

export default CustomBarChart;
