import PropTypes from 'prop-types';

const ImportedContacts = ({
  createdContacts,
  updatedContacts,
  ignoredContacts,
  invalidContacts,
  suppressedContacts,
}) => {
  const onlyAdded = !updatedContacts && !ignoredContacts && !invalidContacts;
  const onlySuppressed = !ignoredContacts && !invalidContacts;
  return (
    <>
      {!!createdContacts && (
        <p className="text-base">
          {createdContacts}
          {onlyAdded ? ' contacts' : ' contacts added'}
        </p>
      )}
      {!!suppressedContacts && (
        <p className="text-base">
          {suppressedContacts}
          {onlySuppressed ? ' suppressed' : ' contacts suppressed'}
        </p>
      )}
      {!!updatedContacts && <p className="text-base">{updatedContacts} contacts updated</p>}
      {!!ignoredContacts && <p className="text-base">{ignoredContacts} contacts ignored</p>}
      {!!invalidContacts && <p className="text-base">{invalidContacts} contacts were invalid</p>}
    </>
  );
};

ImportedContacts.propTypes = {
  createdContacts: PropTypes.number,
  updatedContacts: PropTypes.number,
  ignoredContacts: PropTypes.number,
  invalidContacts: PropTypes.number,
  suppressedContacts: PropTypes.number,
};

export default ImportedContacts;
