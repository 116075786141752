import { useState } from 'react';
import PropTypes from 'prop-types';

import CustomDialog from '../modals/CustomDialog';
import Button from '../buttons/Button';
import Input from '../inputs/Input';

const CreateTagModal = ({ open, onClose, onSave, loading }) => {
  const [name, setName] = useState('');

  const handleClose = () => {
    if (loading) return;
    onClose();
    setName('');
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      title="Create New Tag"
      containerClassName="sm:min-w-[448px]"
      titleClassName="!text-2xl"
    >
      <Input
        id="new-tag-name"
        label="Tag name"
        value={name}
        onChange={(event) => setName(event.target.value)}
        required
        containerClassName="mt-6 mb-8"
      />

      <div className="flex justify-center space-x-16 pt-1.5">
        <Button title="Cancel" onClick={handleClose} disabled={loading} />

        <Button title="Create" color="primary" onClick={() => onSave(name)} disabled={loading} loading={loading} />
      </div>
    </CustomDialog>
  );
};

CreateTagModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CreateTagModal;
