import axios from 'axios';
import * as yup from 'yup';

import appSettings from '../../app-settings';
import { TAX_ID_REGEX } from '../../core/constants';

const ACCOUNTS_URL = `${appSettings.baseUrl}/accounts`;

export const DOCUMENT_TYPES = {
  document: 'document',
  image: 'image',
};

export const DOCUMENT_CATEGORY_TYPES = {
  tax: 'tax',
  logo: 'logo',
};

export const createPresignedUploadUrl = async (
  filename,
  type = DOCUMENT_CATEGORY_TYPES.tax,
  documentType = DOCUMENT_TYPES.document,
) => {
  const response = await axios.post(`${ACCOUNTS_URL}/upload-url`, { type, filename, documentType });
  return response.data;
};

export const createPresignedDownloadUrl = async (documentKey) => {
  const response = await axios.post(`${ACCOUNTS_URL}/download-url`, { documentKey });
  return response.data;
};

export const updateAccount = async (account) => {
  const response = await axios.post(`${ACCOUNTS_URL}/current`, account);
  return response.data;
};

export const updateAccountDocument = async (taxDocumentKey) => {
  await axios.post(`${ACCOUNTS_URL}/current/document`, { taxDocumentKey });
};

export const getDocumentData = async (downloadUrl) => {
  const response = await axios.get(downloadUrl, { responseType: 'blob', headers: { Authorization: '' } });
  return response.data;
};

export const allowedFileTypes = { 'application/pdf': [] };

export const urlRegMatch = /^(https?:\/\/)?(?!www\.)[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]$/;

export const formValidation = yup.object({
  logo: yup.mixed(),
  organizationName: yup.string().min(2, 'Invalid name.').required('Organization Name is required.'),
  legalName: yup.string().min(2, 'Invalid name.').required('Legal Name is required.'),
  customerSupportEmail: yup.string().email('Invalid email.').required('Customer Support Email is required.'),
  websiteUrl: yup.string().matches(urlRegMatch, 'Invalid URL.').required('Website URL is required.'),
  taxId: yup.string().matches(TAX_ID_REGEX, 'Invalid tax ID.').required('Tax ID is required.'),
  legalAddress: yup.string().min(2, 'Invalid address.').required('Legal Address is required.'),
  organizationDescription: yup.string().min(2, 'Invalid description.').required('Organization Mission is required.'),
  missionGeographicFocus: yup.string().required('Geographic Focus of Mission is required.'),
  taxDocumentKey: yup.string(),
});

export const getFormValues = (account) => ({
  logo: account?.logoUrl,
  organizationName: account?.organizationName ?? '',
  legalName: account?.legalName ?? '',
  customerSupportEmail: account?.customerSupportEmail ?? '',
  websiteUrl: account?.websiteUrl ?? '',
  taxId: account?.taxId ?? '',
  legalAddress: account?.legalAddress ?? '',
  organizationDescription: account?.organizationDescription ?? '',
  missionGeographicFocus: account?.missionGeographicFocus ?? '',
  taxDocumentKey: account?.taxDocumentKey ?? '',
});
