import { useState, useEffect } from 'react';
import { useForm, yupResolver } from '@mantine/form';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

import { useAccount } from '../../store/account/hooks';
import { getNetworkError, removeFalsyValues, uploadFile } from '../../core/utils';
import { MISSION_GEOGRAPHIC_FOCUS } from '../../core/constants';
import Button from '../buttons/Button';
import Input from '../inputs/Input';
import Textarea from '../inputs/Textarea';
import SelectInput from '../inputs/SelectInput';
import Loader from '../loader/Loader';
import AlertMessage from '../alerts/AlertMessage';
import {
  DOCUMENT_CATEGORY_TYPES,
  DOCUMENT_TYPES,
  createPresignedUploadUrl,
  formValidation,
  getFormValues,
  updateAccount,
} from './utils';
import UploadFileInput from './UploadFileInput';
import StepNavBottom from '../onboarding/StepNavBottom';
import ImageInput from './ImageInput';

const AccountInfoForm = ({ onboarding, onNext }) => {
  const [loading, setLoading] = useState(false);
  const [localError, setLocalError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { loading: accountLoading, error: accountError, account, updateAccountInfo, setError } = useAccount();
  const form = useForm({
    initialValues: getFormValues(account),
    validate: yupResolver(formValidation),
    validateInputOnBlur: true,
  });

  useEffect(() => {
    if (!account) return;
    form.setValues(getFormValues(account));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const handleCancel = () => {
    form.reset();
  };

  const handleSave = async (values) => {
    setLoading(true);
    try {
      const { logo, ...rest } = values;
      let logoUrl = account?.logoUrl;

      if (typeof logo === 'object' && logo.name) {
        const { url: uploadUrl, fileUrl } = await createPresignedUploadUrl(
          logo.name,
          DOCUMENT_CATEGORY_TYPES.logo,
          DOCUMENT_TYPES.image,
        );

        logoUrl = fileUrl;
        const arrayBuffer = await logo.arrayBuffer();
        await uploadFile(uploadUrl, arrayBuffer, logo.type);
      }

      const data = removeFalsyValues({ ...rest, logoUrl });
      const resData = await updateAccount(data);
      const updatedData = getFormValues(resData);

      updateAccountInfo({ ...updatedData, logoUrl, logo: undefined });
      form.setValues(updatedData);
      form.resetDirty();
      if (onboarding) onNext();
    } catch (err) {
      Sentry.captureException(err);
      setLocalError(getNetworkError(err));
      if (onboarding) setLoading(false);
    }
    if (!onboarding) setLoading(false);
  };

  const clearError = () => {
    setLocalError(false);
    setError(false);
  };

  const isFormValid = form.isValid();

  const isFormDirty = form.isDirty();

  const error = localError || accountError;

  return (
    <div className="relative w-full">
      <div className={onboarding ? 'w-full' : 'max-w-4xl rounded-xl bg-white-100 p-8'}>
        <form noValidate onSubmit={form.onSubmit(handleSave)} className="space-y-8">
          <div className="space-y-6">
            {!onboarding && <p className="mb-8 text-h5 font-semibold">Edit information</p>}

            <h3 className="text-h6">Upload your logo</h3>

            <ImageInput
              name="Logo"
              onChange={(file) => form.setFieldValue('logo', file)}
              onDelete={() => form.setFieldValue('logo', undefined)}
              error={form.errors.logo}
              setError={(err) => form.setFieldError('logo', err)}
              imageUrl={account?.logoUrl}
            />

            <h3 className="text-h6">General information</h3>

            <Input
              id="organization-name"
              label="Organization Name"
              placeholder="DonorSpring"
              {...form.getInputProps('organizationName')}
              required
              error={!!form.errors.organizationName}
              helperText={form.errors.organizationName}
              size="xl"
            />

            <Input
              id="legal-name"
              label="Legal Name"
              placeholder="DonorSpring, LLC"
              {...form.getInputProps('legalName')}
              required
              error={!!form.errors.legalName}
              helperText={form.errors.legalName}
              size="xl"
            />

            <Input
              id="customer-support-email"
              label="Customer Support Email"
              type="email"
              placeholder="help@donorspring.org"
              {...form.getInputProps('customerSupportEmail')}
              required
              error={!!form.errors.customerSupportEmail}
              helperText={form.errors.customerSupportEmail}
              size="xl"
            />

            <Input
              id="website-url"
              label="Website URL"
              placeholder="donorspring.org"
              {...form.getInputProps('websiteUrl')}
              onChange={(e) => form.setFieldValue('websiteUrl', e.target.value?.toLowerCase())}
              required
              error={!!form.errors.websiteUrl}
              helperText={form.errors.websiteUrl}
              size="xl"
            />

            <Input
              id="tax-id"
              label="Tax ID"
              placeholder="xx-xxxxxxx"
              {...form.getInputProps('taxId')}
              required
              error={!!form.errors.taxId}
              helperText={form.errors.taxId}
              size="xl"
              containerClassName="lg:w-1/2"
            />

            <Textarea
              id="legal-address"
              label="Legal Address"
              placeholder="Legal Address"
              {...form.getInputProps('legalAddress')}
              required
              error={!!form.errors.legalAddress}
              helperText={form.errors.legalAddress}
              size="lg"
            />

            <Textarea
              id="organization-description"
              label="Organization Mission"
              placeholder="Helping those in need"
              {...form.getInputProps('organizationDescription')}
              required
              error={!!form.errors.organizationDescription}
              helperText={form.errors.organizationDescription || 'Our organization focuses on...'}
              size="lg"
            />

            <SelectInput
              label="Geographic Focus of Mission"
              {...form.getInputProps('missionGeographicFocus')}
              options={MISSION_GEOGRAPHIC_FOCUS}
              required
              error={!!form.errors.missionGeographicFocus}
              helperText={form.errors.missionGeographicFocus}
              size="lg"
              containerClassName="lg:w-1/2"
            />
          </div>

          <div className="flex flex-col space-y-6">
            <div className="space-y-2">
              <h3 className="text-h6">Tax exemption status</h3>

              <p className="text-base">Document upload for proof of tax-exempt status</p>
            </div>

            <UploadFileInput />
          </div>

          {!onboarding && (
            <div className="flex space-x-3">
              <Button
                title="Save changes"
                color="primary"
                type="submit"
                loading={loading}
                disabled={loading || !isFormValid || !isFormDirty}
              />

              <Button title="Cancel" onClick={handleCancel} disabled={loading || !isFormDirty} />
            </div>
          )}
        </form>
      </div>

      {(accountLoading || loading) && <Loader />}

      {onboarding && <StepNavBottom onNext={form.onSubmit(handleSave)} nextDisabled={!isFormValid} />}

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => setSuccess(false)}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={clearError}
        severity="error"
      />
    </div>
  );
};

AccountInfoForm.propTypes = {
  onboarding: PropTypes.bool,
  onNext: PropTypes.func,
};

AccountInfoForm.defaultProps = {
  onboarding: false,
  onNext: () => {},
};

export default AccountInfoForm;
