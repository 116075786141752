import { useState } from 'react';
import PropTypes from 'prop-types';

import SearchBar from '../filters/SearchBar';

const SearchFilter = (props) => {
  const { initialValue, onChange } = props;
  const [value, setValue] = useState(initialValue);

  const handleChange = () => {
    const val = event.target.value;
    setValue(val);
    onChange({ searchTerm: val });
  };

  return <SearchBar value={value} onChange={handleChange} containerClassName="w-[300px]" />;
};

SearchFilter.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

SearchFilter.defaultProps = {
  initialValue: '',
};

export default SearchFilter;
