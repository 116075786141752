import { useMemo, useState, useEffect } from 'react';
import { format } from 'date-fns';
import debounce from 'lodash.debounce';

import DonationsTable from '../components/donations/DonationsTable';
import {
  DEBOUNCE_DELAY_TIME_MS,
  DONATIONS_SORT_BY,
  donationStatusLabels,
  donationTypeLabels,
  sortDirections,
} from '../core/constants';
import { useDonations } from '../store/donations/hook';
import AlertMessage from '../components/alerts/AlertMessage';
import Loader from '../components/loader/Loader';
import { parseDonationAmount, sortDataWithDirection } from '../core/utils';
import Chip from '../components/chip/Chip';
import Sorters from '../components/tables/Sorters';
import DateFilter from '../components/donations/DateFilter';
import AmountFilter from '../components/donations/AmountFilter';
import TypeFilter from '../components/donations/TypeFilter';
import StatusFilter from '../components/donations/StatusFilter';
import CampaignsFilter from '../components/donations/CampaignsFilter';
import { useNavigate, useParams } from 'react-router-dom';
import { useContacts } from '../store/contacts/hooks';
import ContactDrawer from '../components/contact-drawer/ContactDrawer';
import ExportDonationsDropdown from '../components/donations/ExportDonationsDropdown';
import { filterDonations } from '../core/utils/donations';
import SearchFilter from '../components/donations/SearchFilter';

const sortingOptions = [
  { label: 'Date', value: DONATIONS_SORT_BY.createdAt },
  { label: 'Amount', value: DONATIONS_SORT_BY.netAmount },
];

const Donations = () => {
  const [sortBy, setSortBy] = useState(DONATIONS_SORT_BY.createdAt);
  const [sortDirection, setSortDirection] = useState(sortDirections.desc);
  const [filtersRenderKey, setFiltersRenderKey] = useState(Date.now().toString());
  const {
    loading,
    donations,
    filters,
    getDonations,
    error,
    success,
    setError,
    setSuccess,
    updateFilters,
    resetFilters,
  } = useDonations();
  const {
    data: { contacts },
  } = useContacts();
  const { contactId } = useParams();
  const navigate = useNavigate();

  const selectedContact = contactId ? contacts.find((contact) => contact.id === contactId) ?? { id: contactId } : null;

  useEffect(() => {
    getDonations();
  }, [getDonations]);

  const handleResetAllFilters = () => {
    resetFilters();
    setFiltersRenderKey(Date.now().toString());
  };

  const debouncedUpdateFilters = useMemo(() => debounce(updateFilters, DEBOUNCE_DELAY_TIME_MS), [updateFilters]);

  const campaignOptions = useMemo(() => {
    const filtered = Array.from(new Set(donations.map((obj) => obj.campaignId))).map((id) =>
      donations.find((a) => a.campaignId === id),
    );
    return filtered.map((donation) => {
      if (donation.tenantId === donation.campaignId) {
        // Default campaign - Renamed to have a consistent campaign option name.
        // The default campaign name corresponds to the user's legal name, which can change over time.
        return { label: 'Default Campaign', value: donation.campaignId };
      }
      return { label: donation.campaignName, value: donation.campaignId };
    });
  }, [donations]);

  const selectedCampaigns = campaignOptions.reduce((acc, campaign) => {
    if (filters.campaignIds.includes(campaign.value)) {
      acc.push(campaign);
    }
    return acc;
  }, []);

  const data = useMemo(() => {
    return filterDonations(sortDataWithDirection(donations, sortBy, sortDirection), filters);
  }, [donations, filters, sortBy, sortDirection]);

  const activeDateFilter = !!filters.startDate || !!filters.endDate;

  const activeAmountFilter = !!filters.minAmount || !!filters.maxAmount;

  const activeTypeFilter = !!filters.type;

  const activeStatusFilter = !!filters.status;

  const activeCampaignsFilter = !!filters.campaignIds.length;

  const activeFilters =
    activeDateFilter || activeAmountFilter || activeTypeFilter || activeStatusFilter || activeCampaignsFilter;

  return (
    <div className="w-full space-y-6">
      <div className="flex justify-between space-x-3">
        <h1 className="text-h3">Donations</h1>

        <div className="flex items-center space-x-3">
          <Sorters
            options={sortingOptions}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
          />

          <ExportDonationsDropdown donations={data} />
        </div>
      </div>

      <div className="space-y-4">
        <div className="flex flex-wrap-reverse items-center gap-2">
          <div className="flex flex-wrap items-center gap-2">
            <DateFilter
              key={`date-${filtersRenderKey}`}
              initialStartDate={filters.startDate}
              initialEndDate={filters.endDate}
              onSelect={(range) => updateFilters(range)}
              active={activeDateFilter}
            />

            <AmountFilter
              key={`amount-${filtersRenderKey}`}
              initialMinAmount={filters.minAmount}
              initialMaxAmount={filters.maxAmount}
              onSelect={(interval) => updateFilters(interval)}
              active={activeAmountFilter}
            />

            <TypeFilter
              key={`type-${filtersRenderKey}`}
              initialType={filters.type}
              onSelect={(type) => updateFilters({ type })}
              active={activeTypeFilter}
            />

            <StatusFilter
              key={`status-${filtersRenderKey}`}
              initialStatus={filters.status}
              onSelect={(status) => updateFilters({ status })}
              active={activeStatusFilter}
            />

            <CampaignsFilter
              key={`campaigns-${filtersRenderKey}`}
              initialCampaigns={filters.campaignIds}
              campaignOptions={campaignOptions}
              onSelect={(campaignIds) => updateFilters({ campaignIds })}
              active={activeCampaignsFilter}
            />
          </div>

          <div className="flex flex-1 justify-end">
            <SearchFilter initialValue={filters.searchTerm} onChange={debouncedUpdateFilters} />
          </div>
        </div>

        {activeFilters && (
          <div className="flex flex-wrap items-center space-x-2">
            {activeDateFilter && (
              <Chip
                label={`${filters.startDate ? format(new Date(filters.startDate), 'MM.dd.yyyy') : ''} - ${filters.endDate ? format(new Date(filters.endDate), 'MM.dd.yyyy') : ''}`}
                onDelete={() => updateFilters({ startDate: '', endDate: '' })}
                className="border-0 bg-white-100 px-[10px] py-[5px] !text-gray-800"
                iconClassName="text-gray-400"
              />
            )}

            {activeAmountFilter && (
              <Chip
                label={`${parseDonationAmount(filters.minAmount, 0)} - ${parseDonationAmount(filters.maxAmount, 0)}`}
                onDelete={() => updateFilters({ minAmount: 0, maxAmount: 0 })}
                className="border-0 bg-white-100 px-[10px] py-[5px] !text-gray-800"
                iconClassName="text-gray-400"
              />
            )}

            {activeTypeFilter && (
              <Chip
                label={donationTypeLabels[filters.type]}
                onDelete={() => updateFilters({ type: '' })}
                className="border-0 bg-white-100 px-[10px] py-[5px] !text-gray-800"
                iconClassName="text-gray-400"
              />
            )}

            {activeStatusFilter && (
              <Chip
                label={donationStatusLabels[filters.status]}
                onDelete={() => updateFilters({ status: '' })}
                className="border-0 bg-white-100 px-[10px] py-[5px] !text-gray-800"
                iconClassName="text-gray-400"
              />
            )}

            {activeCampaignsFilter &&
              selectedCampaigns.map((campaign) => (
                <Chip
                  key={campaign.value}
                  label={campaign.label}
                  onDelete={() =>
                    updateFilters({ campaignIds: filters.campaignIds.filter((id) => id !== campaign.value) })
                  }
                  className="border-0 bg-white-100 px-[10px] py-[5px] !text-gray-800"
                  iconClassName="text-gray-400"
                />
              ))}

            <button className="px-[10px] py-[5px] text-sm text-gray-600" onClick={handleResetAllFilters}>
              Reset all
            </button>
          </div>
        )}
      </div>

      <div className="relative">
        <DonationsTable data={data} />

        {loading && <Loader />}
      </div>

      <ContactDrawer open={!!selectedContact} onClose={() => navigate('/donations')} contactDetails={selectedContact} />

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => setSuccess(false)}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </div>
  );
};

export default Donations;
