import { useState } from 'react';

import RadioButton from '../inputs/RadioButton';
import { IMPORT_TYPES } from '../../core/constants';
import { useImports } from '../../store/imports/hooks';
import Button from '../buttons/Button';

const ImportTypeStep = () => {
  const { newImport, goNext, updateNewImport } = useImports();
  const [importType, setImportType] = useState(newImport.importType);
  const [validationError, setValidationError] = useState('');

  const handleSelect = (value) => {
    setImportType(value);
    setValidationError('');
  };

  const handleNext = async () => {
    if (!importType) {
      setValidationError('Please select your upload type.');
      return;
    }
    updateNewImport({ importType });
    goNext();
  };

  return (
    <div className="w-full max-w-xl space-y-6 py-3">
      <h1 className="text-3xl font-medium">Choose your import type</h1>

      <div className="space-y-3">
        <p className="text-base font-medium text-gray-900 dark:text-gray-300">What would you like to do?</p>

        <div className="space-y-2">
          <RadioButton
            id="import-contacts"
            label={
              <>
                Import <span className="font-semibold">contacts</span>
              </>
            }
            value={IMPORT_TYPES.contacts}
            checked={importType === IMPORT_TYPES.contacts}
            onChange={() => handleSelect(IMPORT_TYPES.contacts)}
          />

          <RadioButton
            id="import-suppression-list"
            label={
              <>
                Import <span className="font-semibold">suppression list</span>
              </>
            }
            value={IMPORT_TYPES.suppressionList}
            checked={importType === IMPORT_TYPES.suppressionList}
            onChange={() => handleSelect(IMPORT_TYPES.suppressionList)}
          />

          {!!validationError && <p className="mt-1 text-sm italic text-red-500">{validationError}</p>}
        </div>
      </div>

      <Button title="Continue" color="primary" onClick={handleNext} />
    </div>
  );
};

export default ImportTypeStep;
