/* eslint-disable react/prop-types */
import { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { useReactTable, getCoreRowModel, flexRender, getSortedRowModel } from '@tanstack/react-table';
import {
  IconPlus,
  IconPencil,
  IconArrowNarrowDown,
  IconArrowNarrowUp,
  IconEye,
  IconArrowsJoin,
  IconArrowsRight,
  IconArchive,
  IconArrowLeft,
  IconTrash,
  IconArchiveOff,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import subYears from 'date-fns/subYears';
import {
  TablePagination,
  TableRowActions,
  IndeterminateCheckbox,
  Chip,
  Button,
  IconButton,
  ConfirmationModal,
} from 'ui';

import { formatDate } from '../../core/utils';
import CustomTooltip from './CustomTooltip';
import AlertMessage from '../alerts/AlertMessage';
import Loader from '../loader/Loader';
import { useContacts } from '../../store/contacts/hooks';
import ContactDrawer from '../contact-drawer/ContactDrawer';
import { CONTACT_ACTIONS, DEBOUNCE_DELAY_TIME_MS } from '../../core/constants';
import SearchBar from '../filters/SearchBar';
import { useSegments } from '../../store/segments/hook';
import { useTags } from '../../store/tags/hook';
import { CONTACT_TYPE_LABELS, DEFAULT_VISIBLE_FIELDS } from './constants';
import EditContactModal from './EditContactModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import UnsubscribeContactModal from './UnsubscribeContactModal';
import ColumnsDropdown from './ColumnsDropdown';
import SegmentsFilter from './SegmentsFilter';
import TagsFilter from './TagsFilter';
import ContactTypeFilter from './ContactTypeFilter';
import UnsubscribedFilter from './UnsubscribedFilter';
import SegmentControl from '../segment-control/SegmentControl';
import ExportContactsDropdown from './ExportContactsDropdown';
import ConfirmArchiveModal from './ConfirmArchiveModal';
import MoreMenu from './MoreMenu';

const INITIAL_FILTERS_STATE = {
  segmentIds: [],
  tagIds: [],
  contactType: '',
  unsubscribed: '',
  match: 'all',
};

const FILTERS_MATCH_SEGMENTS = [
  { segment: 'all', Icon: IconArrowsJoin, label: 'Match ALL' },
  { segment: 'any', Icon: IconArrowsRight, label: 'Match ANY' },
];

const VIEW_MODE = {
  contacts: 'contacts',
  archived: 'archived',
};

const getActionIcon = (action) => {
  switch (action) {
    case CONTACT_ACTIONS.edit:
      return <IconPencil size={20} />;
    case CONTACT_ACTIONS.view:
      return <IconEye size={20} />;
    default:
      return null;
  }
};

const Table = ({ filter, selectedContact }) => {
  const [upsertContact, setUpsertContact] = useState({ open: false, contact: null });
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [unsubscribeOpen, setUnsubscribeOpen] = useState(false);
  const [archiveOpen, setArchiveOpen] = useState(false);
  const [unarchiveOpen, setUnarchiveOpen] = useState(false);
  const [tableFields, setTableFields] = useState({ ...DEFAULT_VISIBLE_FIELDS });
  const [{ sortBy, sortDirection }, setTableSort] = useState({ sortBy: 'updated_at', sortDirection: 'desc' });
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState([{ id: 'updated_at', desc: true }]);
  const [rowSelection, setRowSelection] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState(() => {
    if (filter?.type === 'tag') {
      return {
        ...INITIAL_FILTERS_STATE,
        tagIds: [filter.id],
      };
    }
    if (filter?.type === 'segment') {
      return {
        ...INITIAL_FILTERS_STATE,
        segmentIds: [filter.id],
      };
    }
    return INITIAL_FILTERS_STATE;
  });
  const [viewMode, setViewMode] = useState(VIEW_MODE.contacts);
  const {
    loading,
    error,
    success,
    data,
    totalCount,
    getContacts,
    setError,
    setSuccess,
    deleteContacts,
    createContact,
    updateContact,
    unsubscribeContact,
    archiveContacts: archiveContactsAction,
    unarchiveContacts: unarchiveContactsAction,
  } = useContacts();
  const navigate = useNavigate();
  const { segments } = useSegments();
  const { tags } = useTags();

  const filterRef = useRef(filter);
  useEffect(() => {
    // Clear location state so filter prop is reset on refresh which is
    // the default behavior of all the other filters. We're storing the
    // original filter object in a ref so we can avoid re-triggering a
    // contacts query because of this change.
    navigate(window.location.pathname, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const sortItem = (sorting ?? [])[0];
    setTableSort({
      sortBy: sortItem?.id ?? 'updated_at',
      sortDirection: sortItem ? (sortItem.desc ? 'desc' : 'asc') : 'desc',
    });
  }, [sorting]);

  const viewArchived = viewMode === VIEW_MODE.archived;

  const contactsQuery = useMemo(() => {
    if (viewArchived) {
      return {
        pageIndex,
        pageSize,
        sortBy,
        sortDirection,
        archived: true,
      };
    }

    return {
      pageIndex,
      pageSize,
      sortBy,
      sortDirection,
      filter: filterRef.current,
      segmentIds: filters.segmentIds,
      tagIds: filters.tagIds,
      contactType: filters.contactType,
      unsubscribed: filters.unsubscribed,
      match: filters.match,
    };
  }, [
    viewArchived,
    pageIndex,
    pageSize,
    sortBy,
    sortDirection,
    filters.segmentIds,
    filters.tagIds,
    filters.contactType,
    filters.unsubscribed,
    filters.match,
  ]);

  const debouncedGetContacts = useRef(debounce(getContacts, DEBOUNCE_DELAY_TIME_MS));

  useEffect(() => {
    if (searchTerm) {
      if (contactsQuery.pageIndex === 0) {
        // User is updating the search term
        debouncedGetContacts.current({ ...contactsQuery, searchTerm });
        return;
      }

      // User is paginating with the search term
      getContacts({ ...contactsQuery, searchTerm });
      return;
    }

    /**
     * Cancel any pending debounced function calls.
     * This is particularly useful when the user clears the search input,
     * preventing any pending debounced search with the last previous search term
     * from executing after calling the "getContacts" function with no search term.
     */
    debouncedGetContacts.current.cancel();
    getContacts(contactsQuery);
  }, [contactsQuery, getContacts, searchTerm]);

  const handleSelectAction = useCallback(
    (action, contact) => {
      if (action === CONTACT_ACTIONS.edit) {
        setUpsertContact({ open: true, contact });
        return;
      }
      navigate(`/contacts/${contact.id}`);
    },
    [navigate],
  );

  const columns = useMemo(
    () =>
      [
        {
          id: 'select',
          header: ({ table }) => (
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          ),
          cell: ({ row }) => (
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          ),
        },
        {
          accessorKey: 'email',
          header: () => 'Email',
          footer: (props) => props.column.id,
          minSize: 240,
        },
        {
          accessorKey: 'first_name',
          header: () => 'First Name',
          footer: (props) => props.column.id,
          minSize: 140,
        },
        {
          accessorKey: 'last_name',
          header: () => 'Last Name',
          footer: (props) => props.column.id,
          minSize: 140,
        },
        {
          accessorKey: 'address_line_1',
          header: () => 'Address Line 1',
          footer: (props) => props.column.id,
          minSize: 200,
        },
        {
          accessorKey: 'address_line_2',
          header: () => 'Address Line 2',
          footer: (props) => props.column.id,
          minSize: 200,
        },
        {
          accessorKey: 'city',
          header: () => 'City',
          footer: (props) => props.column.id,
          minSize: 140,
        },
        {
          accessorKey: 'state_province_region',
          header: () => 'State/Province/Region',
          footer: (props) => props.column.id,
          minSize: 200,
        },
        {
          accessorKey: 'country',
          header: () => 'Country',
          footer: (props) => props.column.id,
          minSize: 140,
        },
        {
          accessorKey: 'postal_code',
          header: () => 'Postal Code',
          footer: (props) => props.column.id,
          minSize: 140,
        },
        {
          accessorKey: 'phone_number',
          header: () => 'Phone Number',
          footer: (props) => props.column.id,
          minSize: 160,
        },
        {
          accessorKey: 'import_filename',
          header: () => 'Upload Type',
          footer: (props) => props.column.id,
          minSize: 200,
        },
        {
          accessorKey: 'created_at',
          header: () => 'Created At',
          footer: (props) => props.column.id,
          cell: (info) => formatDate(info.getValue()),
          minSize: 200,
        },
        {
          accessorKey: 'updated_at',
          header: () => 'Updated At',
          footer: (props) => props.column.id,
          cell: (info) => formatDate(info.getValue()),
          minSize: 200,
        },
        {
          accessorKey: 'tags',
          header: () => 'Tags',
          footer: (props) => props.column.id,
          cell: (info) => <CustomTooltip tags={[...(info.getValue() || [])]} />,
          minSize: 200,
        },
        {
          accessorKey: 'unsubscribed',
          header: () => 'Unsubscribed',
          footer: (props) => props.column.id,
          cell: (info) => (info.getValue() ? 'Yes' : 'No'),
          minSize: 140,
        },
        ...(viewArchived
          ? [
              {
                accessorKey: 'archived_at',
                header: () => 'Archived At',
                footer: (props) => props.column.id,
                cell: (info) => formatDate(info.getValue()),
                minSize: 200,
              },
            ]
          : []),
        {
          accessorKey: 'id',
          header: () => '',
          footer: (props) => props.column.id,
          cell: (info) => (
            <TableRowActions
              actions={[CONTACT_ACTIONS.edit, CONTACT_ACTIONS.view]}
              visibleActions={2}
              onSelectAction={(action) => handleSelectAction(action, info.row.original)}
              getActionIcon={getActionIcon}
            />
          ),
          size: 52,
        },
      ].filter((col) => {
        if (!col.accessorKey) return true;
        // Actions column is always visible
        if (col.accessorKey === 'id') return true;
        return tableFields[col.accessorKey];
      }),
    [handleSelectAction, tableFields, viewArchived],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data: data.contacts,
    columns,
    pageCount: data.totalPages,
    state: {
      pagination,
      sorting,
      rowSelection,
    },
    defaultColumn: {
      size: 'fit-content',
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
  });

  const selectedContacts = table.getSelectedRowModel().flatRows.map((fR) => fR.original);

  const selectedRows = selectedContacts.length;

  const onDelete = async () => {
    try {
      await deleteContacts(selectedContacts.map((contact) => contact.id)).unwrap();
      setRowSelection({});
      setDeleteOpen(false);
      getContacts(contactsQuery);
    } catch (err) {
      // Error handled by redux
    }
  };

  // `archived_at` column is not allowed for create and update contact actions
  const onSave = async ({ contact: { archived_at, ...contact }, tagIdsToAdd, tagIdsToRemove }) => {
    if (contact.id) {
      await updateContact({ contact, tagIdsToAdd, tagIdsToRemove });
    } else {
      await createContact({ contact, tagIds: tagIdsToAdd });
      await getContacts(contactsQuery);
    }
    setUpsertContact({ open: false, contact: null });
  };

  const onUnsubscribe = async (email) => {
    try {
      await unsubscribeContact(email).unwrap();
      setUnsubscribeOpen(false);
      getContacts(contactsQuery);
    } catch (error) {
      // Error handled by redux
    }
  };

  const handleSearch = (event) => {
    const searchText = event.target.value;
    setSearchTerm(searchText);
    if (pageIndex > 0) {
      // If search is active, reset pagination to the first page
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    }
  };

  const handleResetAllFilters = () => {
    setFilters(INITIAL_FILTERS_STATE);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  const handleSetSelectedFilter = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  const handleDeleteSelectedFilter = (key, value) => {
    if (['contactType', 'unsubscribed'].includes(key)) {
      setFilters((prev) => ({ ...prev, [key]: value }));
    } else {
      setFilters((prev) => ({ ...prev, [key]: (prev[key] ?? []).filter((val) => val !== value) }));
    }
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  const archiveContacts = async () => {
    try {
      await archiveContactsAction(selectedContacts.map((contact) => contact.id)).unwrap();
      setRowSelection({});
      setArchiveOpen(false);
      getContacts(contactsQuery);
    } catch (error) {
      // Error handled by redux
    }
  };

  const unarchiveContacts = async () => {
    try {
      await unarchiveContactsAction(selectedContacts.map((contact) => contact.id)).unwrap();
      setRowSelection({});
      setUnarchiveOpen(false);
      getContacts(contactsQuery);
    } catch (error) {
      // Error handled by redux
    }
  };

  const handleViewArchived = () => {
    setViewMode(VIEW_MODE.archived);
    setTableFields((prev) => ({ ...prev, archived_at: true }));
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  const handleViewContacts = () => {
    setViewMode(VIEW_MODE.contacts);
    setTableFields((prev) => {
      const nextState = { ...prev };
      delete nextState.archived_at;
      return nextState;
    });
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  const renderActions = () => {
    if (viewArchived) {
      if (selectedRows) {
        const unarchivedDisabled = selectedContacts.some(
          (contact) => new Date(contact.archived_at).getTime() > subYears(new Date(), 1).getTime(),
        );
        return (
          <div className="flex items-center space-x-3">
            <Button
              title="Unarchive Selected"
              LeftIcon={IconArchiveOff}
              onClick={() => setUnarchiveOpen(true)}
              disabled={unarchivedDisabled}
            />

            <Button
              title="Delete Selected"
              color="destructive"
              variant="outlined"
              LeftIcon={IconTrash}
              onClick={() => setDeleteOpen(true)}
            />
          </div>
        );
      }
      return null;
    }
    if (selectedRows) {
      return (
        <Button
          title="Archive Selected"
          color="destructive"
          variant="outlined"
          LeftIcon={IconArchive}
          onClick={() => setArchiveOpen(true)}
        />
      );
    }
    return (
      <div className="flex items-center space-x-3">
        <ExportContactsDropdown
          totalContacts={totalCount}
          columns={Object.keys(tableFields).filter((key) => tableFields[key])}
          filters={filters}
          searchTerm={searchTerm}
        />

        <Button
          title="New Contact"
          color="primary"
          LeftIcon={IconPlus}
          onClick={() => setUpsertContact({ open: true, contact: null })}
        />

        <MoreMenu onUnsubscribe={() => setUnsubscribeOpen(true)} onArchived={handleViewArchived} />
      </div>
    );
  };

  const emptyRows = data.limit - data.count;

  const activeSegmentsFilter = filters.segmentIds.length > 0;
  const activeTagsFilter = filters.tagIds.length > 0;
  const activeContactTypeFilter = filters.contactType !== '';
  const activeUnsubscribedFilter = filters.unsubscribed !== '';
  const activeFilters = activeSegmentsFilter || activeTagsFilter || activeContactTypeFilter || activeUnsubscribedFilter;

  return (
    <div className="relative space-y-6">
      <div className="flex min-h-10 items-center justify-between">
        <div className="flex items-center space-x-1">
          {!selectedRows && viewArchived && (
            <IconButton
              Icon={<IconArrowLeft size={20} />}
              color="transparent"
              className="-ml-2"
              onClick={handleViewContacts}
              srOnly="Go back to campaigns"
            />
          )}
          <h1 className="text-h3 dark:text-white-100">
            {selectedRows ? `${selectedRows} selected` : viewArchived ? 'Archived Contacts' : 'Contacts'}
          </h1>
        </div>

        {renderActions()}
      </div>

      {!viewArchived && (
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <ColumnsDropdown fields={tableFields} setFields={setTableFields} />

              <SegmentsFilter
                selectedSegmentIds={filters.segmentIds}
                onSelect={(segmentIds) => handleSetSelectedFilter('segmentIds', segmentIds)}
              />

              <TagsFilter
                selectedTagIds={filters.tagIds}
                onSelect={(tagIds) => handleSetSelectedFilter('tagIds', tagIds)}
              />

              <ContactTypeFilter
                selectedContactType={filters.contactType}
                onSelect={(contactType) => handleSetSelectedFilter('contactType', contactType)}
              />

              <UnsubscribedFilter
                selectedValue={filters.unsubscribed}
                onSelect={(unsubscribed) => handleSetSelectedFilter('unsubscribed', unsubscribed)}
              />
            </div>

            <SearchBar
              value={searchTerm}
              onChange={handleSearch}
              containerClassName="w-[300px]"
              placeholder="Search by name or email"
            />
          </div>

          {activeFilters > 0 && (
            <div className="flex flex-wrap items-center space-x-2">
              <SegmentControl
                segments={FILTERS_MATCH_SEGMENTS}
                selectedSegment={filters.match}
                onSelect={(segment) => handleSetSelectedFilter('match', segment)}
                segmentWidth={128}
                segmentHeight={32}
                buttonSize="sm"
                containerClassName="bg-gray-50"
              />

              {activeSegmentsFilter > 0 &&
                filters.segmentIds.map((segmentId) => (
                  <Chip
                    key={segmentId}
                    label={segments.find((segment) => segment.segmentId === segmentId)?.name}
                    onDelete={() => handleDeleteSelectedFilter('segmentIds', segmentId)}
                    className="border-0 bg-white-100 px-[10px] py-[5px] !text-gray-800"
                    iconClassName="text-gray-400"
                  />
                ))}

              {activeTagsFilter > 0 &&
                filters.tagIds.map((tagId) => (
                  <Chip
                    key={tagId}
                    label={tags.find((tag) => tag.id === tagId)?.name}
                    onDelete={() => handleDeleteSelectedFilter('tagIds', tagId)}
                    className="border-0 bg-white-100 px-[10px] py-[5px] !text-gray-800"
                    iconClassName="text-gray-400"
                  />
                ))}

              {activeContactTypeFilter && (
                <Chip
                  label={CONTACT_TYPE_LABELS[filters.contactType]}
                  onDelete={() => handleDeleteSelectedFilter('contactType', '')}
                  className="border-0 bg-white-100 px-[10px] py-[5px] !text-gray-800"
                  iconClassName="text-gray-400"
                />
              )}

              {activeUnsubscribedFilter && (
                <Chip
                  label={filters.unsubscribed === 'true' ? 'Unsubscribed' : 'Subscribed'}
                  onDelete={() => handleDeleteSelectedFilter('unsubscribed', '')}
                  className="border-0 bg-white-100 px-[10px] py-[5px] !text-gray-800"
                  iconClassName="text-gray-400"
                />
              )}

              <button className="px-[10px] py-[5px] text-sm text-gray-600" onClick={handleResetAllFilters}>
                Reset all
              </button>
            </div>
          )}
        </div>
      )}

      <div className="space-y-3">
        {filterRef.current?.type === 'file' && (
          <h3 className="font-semibold">
            <span className="capitalize">{filterRef.current?.type}: </span>
            {filterRef.current?.name}
          </h3>
        )}

        <div className="relative overflow-x-auto sm:rounded-lg">
          <table className="w-full text-left text-base text-gray-950 dark:text-gray-400">
            <thead className="bg-white-100 text-sm uppercase text-gray-400 dark:bg-gray-700 dark:text-gray-400">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="bg-inherit">
                  {headerGroup.headers.map((header, index) => {
                    const stickyCol = index === headerGroup.headers.length - 1 && data.contacts.length > 0;
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className={`bg-inherit px-4 py-3 ${stickyCol ? 'sticky right-0' : ''}`}
                        style={{ width: header.column.columnDef.size, minWidth: header.column.columnDef.minSize }}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'flex items-center cursor-pointer select-none'
                                : 'flex items-center',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: <IconArrowNarrowUp size={16} className="ml-2 text-gray-400" aria-hidden="true" />,
                              desc: <IconArrowNarrowDown size={16} className="ml-2 text-gray-400" aria-hidden="true" />,
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr
                    key={row.id}
                    className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                  >
                    {row.getVisibleCells().map((cell, index) => {
                      const sentryMasked = !['created_at', 'updated_at', 'tags', 'unsubscribed'].includes(
                        cell.column.id,
                      );
                      const stickyCol = index === row.getVisibleCells().length - 1;
                      return (
                        <td
                          key={cell.id}
                          className={`bg-inherit ${sentryMasked ? 'sentry-mask' : ''} ${stickyCol ? 'sticky right-0 p-2' : 'px-4 py-2'}`}
                          style={{ width: cell.column.columnDef.size, minWidth: cell.column.columnDef.minSize }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              {emptyRows > 0 &&
                Array(emptyRows)
                  .fill('')
                  .map((row, index) => (
                    <tr
                      key={`emptyRow-${index}`}
                      className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                    >
                      <td
                        className="px-4 py-2"
                        // plus 2 because of the sticky column - "id" is not included in the editable visible columns
                        colSpan={Object.keys(tableFields).length + 2}
                        style={{ minWidth: 110 }}
                      ></td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination table={table} total={totalCount} />

      {loading && <Loader />}

      <EditContactModal
        key={String(upsertContact.contact?.id)}
        open={upsertContact.open}
        onClose={() => {
          if (loading) return;
          setUpsertContact({ open: false, contact: null });
        }}
        data={upsertContact.contact}
        onSave={onSave}
        loading={loading}
      />

      <ConfirmDeleteModal
        open={deleteOpen}
        onClose={() => {
          if (loading) return;
          setDeleteOpen(false);
        }}
        onDelete={onDelete}
        loading={loading}
      />

      <UnsubscribeContactModal
        open={unsubscribeOpen}
        onClose={() => {
          if (loading) return;
          setUnsubscribeOpen(false);
        }}
        onConfirm={onUnsubscribe}
        loading={loading}
      />

      <ConfirmArchiveModal
        open={archiveOpen}
        onClose={() => {
          if (loading) return;
          setArchiveOpen(false);
        }}
        onArchive={archiveContacts}
        loading={loading}
        contactsNumber={selectedContacts?.length}
      />

      <ConfirmationModal
        open={unarchiveOpen}
        onClose={() => {
          if (loading) return;
          setUnarchiveOpen(false);
        }}
        onConfirm={unarchiveContacts}
        message={
          <span>
            Are you sure you want to unarchive <span className="font-semibold">{selectedContacts?.length}</span>
            &nbsp;contacts?
          </span>
        }
        loading={loading}
      />

      <ContactDrawer
        open={!!selectedContact}
        onClose={() => navigate('/contacts')}
        contactDetails={selectedContact}
        archived={viewArchived}
      />

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => setSuccess(false)}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </div>
  );
};

Table.propTypes = {
  filter: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
  }),
  selectedContact: PropTypes.object,
};

export default Table;
