import PropTypes from 'prop-types';

const HeaderSection = ({ number, title }) => {
  return (
    <div className="flex items-center space-x-2.5">
      <div className="flex size-[22px] items-center justify-center rounded-full bg-primary-50">
        <span className="text-sm font-semibold text-primary-700">{number}</span>
      </div>
      <h3 className="text-h6">{title}</h3>
    </div>
  );
};

HeaderSection.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default HeaderSection;
