import { useEffect, useRef } from 'react';

const useInterval = (cb, delay) => {
  const savedCallback = useRef(cb);

  useEffect(() => {
    savedCallback.current = cb;
  }, [cb]);

  useEffect(() => {
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(id);
    };
  }, [delay]);
};

export default useInterval;
