import { Component } from 'react';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';

export class TemplatesSection extends Component {
  constructor(props) {
    super(props);

    let sections = [];
    let currSection = [];

    for (let i = 0; i < this.props.templates.length; i++) {
      currSection.push(this.props.templates[i]);
      if (i % 8 === 7) {
        sections.push(currSection);
        currSection = [];
      }
    }
    sections.push(currSection);

    this.state = {
      index: 0,
      fullTemplates: sections,
    };
  }

  render() {
    if (!this.props.templates) {
      return (
        <div className="justify-content w-full">
          <p className="py-10 text-2xl font-bold dark:text-white-100">{this.props.title}</p>
          <div className="flex flex-row overflow-x-auto">
            <div className={'grid grid-cols-4 gap-x-16 gap-y-10 p-1'}>
              <p>Loading data...</p>
            </div>
          </div>
        </div>
      );
    }

    if (this.props.templates.length <= 8) {
      const display = this.state.fullTemplates[this.state.index];

      return (
        <div className="mx-auto max-w-6xl">
          <div className="grid grid-cols-[repeat(auto-fill,minmax(262px,262px))] justify-center gap-6">
            {display.map((d, i) => (
              <button
                key={`${d.title}-${i}`}
                onClick={() => this.props.selectTemplate(d)}
                className={`
                flex 
                size-[264px] 
                flex-col 
                items-center 
                justify-center
                space-y-6 
                rounded-xl 
                bg-white-100 
                p-6 
                text-center 
                text-base 
                font-semibold 
                text-gray-600 
                ${
                  this.props.selectedTemplate === d
                    ? `
                        text-primary-700 
                        ring-2 
                        ring-primary-700 
                        ring-offset-1
                      `
                    : ''
                }
              `}
              >
                <div className="h-[172px] w-[216px] overflow-hidden">
                  {d.downloadUrl ? (
                    <div className="h-[396px]">
                      <iframe
                        className="pointer-events-none origin-top-left scale-50"
                        width="480"
                        height="396"
                        src={d.downloadUrl}
                        frameBorder="0"
                        scrolling="no"
                      />
                    </div>
                  ) : (
                    <img width={216} height={172} src={`/templates/${d.image}`} alt={`template ${d.title}`} />
                  )}
                </div>
                <span>{d.title}</span>
              </button>
            ))}
          </div>
        </div>
      );
    }

    const display = this.state.fullTemplates[this.state.index];

    return (
      <div className="mx-auto max-w-6xl space-y-6">
        <p className="text-2xl font-semibold">{this.props.title}</p>
        <div className="grid grid-cols-[repeat(auto-fill,minmax(262px,262px))] justify-center gap-6">
          {display.map((d, i) => (
            <button
              key={`${d.title}-${i}`}
              onClick={() => this.props.selectTemplate(d)}
              className={`
                flex 
                size-[264px] 
                flex-col 
                items-center 
                justify-center
                space-y-6 
                rounded-xl 
                bg-white-100 
                p-6 
                text-center 
                text-base 
                font-semibold 
                text-gray-600 
                ${
                  this.props.selectedTemplate === d
                    ? `
                        text-primary-700 
                        ring-2 
                        ring-primary-700 
                        ring-offset-1
                      `
                    : ''
                }
              `}
            >
              <div className="h-[172px] w-[216px] overflow-hidden">
                {d.downloadUrl ? (
                  <div className="h-[396px]">
                    <iframe
                      className="pointer-events-none origin-top-left scale-50"
                      width="480"
                      height="396"
                      src={d.downloadUrl}
                      frameBorder="0"
                      scrolling="no"
                    />
                  </div>
                ) : (
                  <img width={216} height={172} src={`/templates/${d.image}`} alt={`template ${d.title}`} />
                )}
              </div>
              <span>{d.title}</span>
            </button>
          ))}
        </div>

        <div className="flex items-center justify-center space-x-6">
          <Button
            title="Previous"
            disabled={this.state.index <= 0}
            onClick={() => {
              this.setState({ index: this.state.index - 1 });
            }}
            LeftIcon={IconArrowLeft}
          />

          <Button
            title="Next"
            disabled={this.state.index >= this.state.fullTemplates.length - 1}
            onClick={() => {
              this.setState({ index: this.state.index + 1 });
            }}
            RightIcon={IconArrowRight}
          />
        </div>
      </div>
    );
  }
}

TemplatesSection.propTypes = {
  title: PropTypes.string.isRequired,
  templates: PropTypes.array.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedTemplate: PropTypes.object,
};

export default TemplatesSection;
