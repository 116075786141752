import { useMemo } from 'react';
import debounce from 'lodash.debounce';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { useCampaigns } from '../../../store/campaigns/hooks';
import { DEBOUNCE_DELAY_TIME_MS, formStoreTargets } from '../../../core/constants';
import { useDomains } from '../../../store/domains/hooks';
import Loader from '../../loader/Loader';
import { useEmailTemplates } from '../../../store/email-templates/hooks';
import Input from '../../inputs/Input';
import EmailWithDomainInput from '../../inputs/EmailWithDomainInput';

// eslint-disable-next-line react-refresh/only-export-components
export const fromFormValidationSchema = yup.object({
  senderName: yup.string().required('Sender name is required.').min(2, 'Sender name is invalid.'),
  senderEmail: yup
    .string()
    .required('Email address is required.')
    .test('is-email', 'Email address is invalid.', (val) => {
      const username = val?.split('@')?.[0] ?? '';
      if (username.length < 2) return false;
      return yup.string().email().isValidSync(val);
    }),
  returnEmail: yup.string(),
});

const formValidation = yup.object({
  senderName: yup.string().min(2, 'Sender name is invalid.').required('Sender name is required.'),
  senderEmail: yup
    .string()
    .required('Email address is required.')
    .test('email-prefix', 'Email address is invalid.', (val) => {
      if (val?.length < 2) return false;
      return !val.includes('@');
    }),
  returnEmail: yup.string(),
});

const FromForm = ({ storeTarget, disabled }) => {
  const { template, updateTemplate } = useEmailTemplates();
  const { campaignDetails, updateCampaignDetails } = useCampaigns();
  const { loading, authenticatedDomain } = useDomains();

  const getTarget = () => (storeTarget === formStoreTargets.template ? template : campaignDetails);

  const updater = useMemo(
    () => (storeTarget === formStoreTargets.template ? updateTemplate : updateCampaignDetails),
    [storeTarget, updateTemplate, updateCampaignDetails],
  );

  const form = useForm({
    initialValues: {
      senderName: getTarget().emailConfig.senderName,
      senderEmail: getTarget().emailConfig.senderEmail?.split('@')[0] || '',
      returnEmail: getTarget().emailConfig.returnEmail?.split('@')[0] || '',
    },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });

  const debouncedUpdateTarget = useMemo(() => debounce(updater, DEBOUNCE_DELAY_TIME_MS), [updater]);

  const handleEmailEdit = (e) => {
    const senderEmail = e.target.value;
    form.setFieldValue('senderEmail', senderEmail);
    const email = senderEmail ? `${senderEmail}@${authenticatedDomain}` : '';
    debouncedUpdateTarget({ emailConfig: { senderEmail: email, returnEmail: email } });
  };

  const handleNameEdit = (e) => {
    const senderName = e.target.value;
    form.setFieldValue('senderName', senderName);
    debouncedUpdateTarget({ emailConfig: { senderName } });
  };

  return (
    <form className="space-y-6">
      <div className="space-y-4">
        <p className="text-base font-semibold">The name subscribers will see as the sender.</p>
        <Input
          id="name"
          label="Name"
          value={form.values.senderName}
          onChange={handleNameEdit}
          placeholder="Insert Name"
          required
          disabled={disabled}
          error={!!form.errors.senderName}
          helperText={form.errors.senderName || ''}
          size="xl"
        />
      </div>
      <div className="space-y-4">
        <p className="text-base font-semibold">
          The address the subscribers will see as the sender. Must be from your domain
        </p>
        <EmailWithDomainInput
          id="youremail"
          label="Email Address"
          value={form.values.senderEmail}
          onChange={handleEmailEdit}
          authenticatedDomain={authenticatedDomain}
          placeholder="youremail"
          required
          disabled={!authenticatedDomain || disabled}
          error={!!form.errors.senderEmail}
          helperText={form.errors.senderEmail || ''}
          size="xl"
        />
        {!authenticatedDomain && (
          <p className="mt-1.5 text-sm text-red-500">Please authenticate your domain on Settings &gt; Domain Auth.</p>
        )}
        {loading && <Loader />}
      </div>
    </form>
  );
};

FromForm.propTypes = {
  storeTarget: PropTypes.string,
  disabled: PropTypes.bool,
};

FromForm.defaultProps = {
  disabled: false,
};

export default FromForm;
