import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconPencil, IconCirclePlus } from '@tabler/icons-react';
import { useForm, yupResolver } from '@mantine/form';
import debounce from 'lodash.debounce';

import yup from '../components/new-segment/yup-extended';
import FieldCondition from '../components/new-segment/FieldCondition';
import SegmentNameModal, { nameFormValidation } from '../components/new-segment/SegmentNameModal';
import { nanoid } from '../core/utils';
import { DEBOUNCE_DELAY_TIME_MS, SEGMENT_MATCH_VALUES } from '../core/constants';
import { useSegments } from '../store/segments/hook';
import { initialFieldCondition } from '../store/segments/reducers';
import { useContactFields } from '../store/contacts/hooks';
import Loader from '../components/loader/Loader';
import Button from '../components/buttons/Button';
import IconButton from '../components/buttons/IconButton';
import SelectInput from '../components/inputs/SelectInput';

const matchOptions = SEGMENT_MATCH_VALUES.map((mv) => ({ label: mv.toUpperCase(), value: mv }));

const formValidationSchema = yup.object({
  name: nameFormValidation,
  match: yup.string().oneOf(SEGMENT_MATCH_VALUES, 'Match is invalid.').required('Match is required.'),
  rules: yup
    .array(
      yup.object({
        field: yup.string().required('Field is required.'),
        condition: yup.string().required('Condition is required.'),
        value: yup.string().required('Value is required.'),
      }),
    )
    .min(1, 'At least one rule condition is required.')
    .uniqueField('Duplicate rule field issue.'),
});

const NewSegment = () => {
  const [nameOpen, setNameOpen] = useState(false);
  const navigate = useNavigate();
  const { segmentDetails, updateSegmentDetails } = useSegments();
  const { loading, error, data: contactFields } = useContactFields();

  const form = useForm({
    initialValues: { ...segmentDetails },
    validate: yupResolver(formValidationSchema),
    validateInputOnChange: ['name', 'match', 'rules'],
  });

  const toggleNameModal = () => {
    setNameOpen((prevState) => !prevState);
  };

  const goToPreview = () => {
    const validationResults = form.validate();
    if (validationResults.hasErrors) return;
    updateSegmentDetails(form.values);
    navigate('/preview-segment');
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleMatchChange = (e) => {
    form.setFieldValue('match', e.target.value);
  };

  const handleNameUpdate = (name) => {
    form.setFieldValue('name', name);
  };

  const debouncedSetFieldValue = useMemo(
    () => debounce(form.setFieldValue, DEBOUNCE_DELAY_TIME_MS),
    [form.setFieldValue],
  );

  return (
    <div className="w-full">
      <div className="flex flex-col">
        {/* Header Bar */}
        <div className="flex flex-row justify-between pb-6">
          <h1 className="text-h3 dark:text-white-100">
            {segmentDetails.segmentId ? 'Edit Segment' : 'Create a New Segment'}
          </h1>
          <div>
            <Button title="Cancel" className="mr-4" onClick={goBack} />
            <Button title="Continue to Preview" color="primary" onClick={goToPreview} />
          </div>
        </div>
        {/* Name */}
        <div className="w-full">
          <div className="justify-left">
            <div className="justify-left flex items-center">
              <p className="text-2xl font-bold dark:text-white-100">{form.values.name}</p>
              <IconButton
                srOnly="Change segment name"
                onClick={toggleNameModal}
                color="transparent"
                Icon={<IconPencil />}
                circle
                className="-mt-0.5 ml-2"
              />
            </div>
            {!nameOpen && !!form.errors.name && <p className="mt-1 text-sm italic text-red-500">{form.errors.name}</p>}
          </div>
          {/* Details */}
          <div className="relative mt-6 rounded-lg border border-gray-50 bg-white-100 px-4 py-6">
            <div className="flex items-center pb-6">
              <p className="text-lg">Contacts match</p>
              <div className="px-4">
                <div className="relative mt-1">
                  <SelectInput onChange={handleMatchChange} value={form.values.match} options={matchOptions} />
                </div>
              </div>
              <p className="text-lg">of the following:</p>
            </div>

            {loading && contactFields.length === 0 && <Loader style={{ top: '55%' }} />}

            {form.values.rules.map((item, index) => {
              const errors = {
                field: form.errors[`rules.${index}.field`],
                condition: form.errors[`rules.${index}.condition`],
                value: form.errors[`rules.${index}.value`],
              };
              return (
                <div key={item.key}>
                  <hr />
                  <FieldCondition
                    index={index}
                    setFieldValue={debouncedSetFieldValue}
                    initialValues={segmentDetails.rules[index] ?? initialFieldCondition}
                    onDelete={() => form.removeListItem('rules', index)}
                    errors={errors}
                    contactFields={contactFields}
                  />
                </div>
              );
            })}
            {form.values.rules.length === 0 && !!form.errors.rules && (
              <p className="mt-1 text-base italic text-red-500">{form.errors.rules}</p>
            )}
            {!!error &&
              (typeof error === 'string' ? (
                <p className="mt-1 text-base italic text-red-500">{error}</p>
              ) : (
                <p className="mt-1 text-base italic text-red-500">
                  Something went wrong while retrieving contact fields!
                </p>
              ))}
            <button
              type="button"
              onClick={() => form.insertListItem('rules', { ...initialFieldCondition, key: nanoid() })}
              className="mt-6
                inline-flex 
              items-center 
                space-x-2
                rounded-lg 
                p-1.5
                text-center 
                font-medium
                text-green-600 
                decoration-solid
                outline-0
                focus:ring-4
                focus:ring-green-300"
            >
              <IconCirclePlus />
              <span>Add</span>
            </button>
          </div>
        </div>
      </div>

      <SegmentNameModal
        open={nameOpen}
        onClose={toggleNameModal}
        initialName={form.values.name}
        initialError={form.errors.name}
        updateName={handleNameUpdate}
      />
    </div>
  );
};

export default NewSegment;
