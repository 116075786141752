import { useState, useRef } from 'react';
import { Menu, MenuItem, ClickAwayListener, Popper, Paper, Grow } from '@mui/material';
import { IconCalendar, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  subMonths,
  subWeeks,
  subYears,
  format,
  subDays,
} from 'date-fns';
import { DayPicker } from 'react-day-picker';
import styles from 'react-day-picker/dist/style.module.css';

import { useDashboard } from '../../store/dashboard/hooks';
import Button from '../buttons/Button';
import { getTimeInSeconds } from '../../core/utils';

const dayPickerClassNames = {
  ...styles,
  root: `px-6 py-3`,
  caption: `flex items-center justify-center relative`,
  caption_label: `text-base font-semibold text-gray-950 absolute`,
  nav: `w-full flex items-center justify-between`,
  nav_button: `h-9 w-9 rounded-full flex items-center justify-center`,
  nav_button_previous: `!-ml-2`,
  nav_button_next: `!-mr-2`,
  nav_icon: `text-gray-600`,
  head_cell: `text-sm font-semibold text-gray-600 h-7`,
  day_selected: `!bg-primary-700 !text-white-100 rounded-lg`,
  cell: `h-9 w-9 p-0.25`,
  day: `w-8 h-8 rounded-lg !text-base`,
  day_today: `!font-semibold`,
  button: `enabled:hover:bg-primary-50 enabled:cursor-pointer disabled:opacity-50`,
  day_range_middle: ``,
};

const dateRangeKeys = {
  today: 'Today',
  last7Days: 'Last 7 Days',
  lastWeek: 'Last Week',
  last30Days: 'Last 30 Days',
  lastMonth: 'Last Month',
  last6Months: 'Last 6 Months',
  lastYear: 'Last Year',
  custom: 'Custom range',
};

const DateRangePicker = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedRangeKey, setSelectedRangeKey] = useState(dateRangeKeys.last7Days);
  const { setDateRange } = useDashboard();
  const [pickerRange, setPickerRange] = useState();

  const menuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseDatePicker = () => {
    setDatePickerOpen(false);
  };

  const updateDateRange = (range) => {
    switch (range) {
      case dateRangeKeys.today:
        setDateRange({
          startDate: getTimeInSeconds(startOfDay(new Date())),
          endDate: getTimeInSeconds(endOfDay(new Date())),
        });
        break;
      case dateRangeKeys.last7Days: {
        const sevenDaysAgo = subDays(new Date(), 7);
        const yesterday = subDays(new Date(), 1);
        setDateRange({
          startDate: getTimeInSeconds(startOfDay(sevenDaysAgo)),
          endDate: getTimeInSeconds(endOfDay(yesterday)),
        });
        break;
      }
      case dateRangeKeys.lastWeek: {
        const lastWeek = subWeeks(new Date(), 1);
        setDateRange({
          startDate: getTimeInSeconds(startOfWeek(lastWeek)),
          endDate: getTimeInSeconds(endOfWeek(lastWeek)),
        });
        break;
      }
      case dateRangeKeys.last30Days: {
        const thirtyDaysAgo = subDays(new Date(), 30);
        const yesterday = subDays(new Date(), 1);
        setDateRange({
          startDate: getTimeInSeconds(startOfDay(thirtyDaysAgo)),
          endDate: getTimeInSeconds(endOfDay(yesterday)),
        });
        break;
      }
      case dateRangeKeys.lastMonth: {
        const lastMonth = subMonths(new Date(), 1);
        setDateRange({
          startDate: getTimeInSeconds(startOfMonth(lastMonth)),
          endDate: getTimeInSeconds(endOfMonth(lastMonth)),
        });
        break;
      }
      case dateRangeKeys.last6Months: {
        const sixMonthAgo = subMonths(new Date(), 6);
        const lastMonth = subMonths(new Date(), 1);
        setDateRange({
          startDate: getTimeInSeconds(startOfMonth(sixMonthAgo)),
          endDate: getTimeInSeconds(endOfMonth(lastMonth)),
        });
        break;
      }
      case dateRangeKeys.lastYear: {
        const lastYear = subYears(new Date(), 1);
        setDateRange({
          startDate: getTimeInSeconds(startOfYear(lastYear)),
          endDate: getTimeInSeconds(endOfYear(lastYear)),
        });
        break;
      }
      default:
        setDatePickerOpen(true);
        break;
    }
  };

  const handleChange = (rangeKey) => {
    if (selectedRangeKey === dateRangeKeys.custom && rangeKey !== dateRangeKeys.custom) {
      // Reset range date picker when selecting any option but "Custom range"
      setPickerRange(undefined);
    }
    setSelectedRangeKey(rangeKey);
    updateDateRange(rangeKey);
    handleCloseMenu();
  };

  const handleSelectDateRange = (range, selectedDay) => {
    if (!range || (pickerRange?.from && pickerRange?.to)) {
      setPickerRange({ from: selectedDay, to: undefined });
      return;
    }
    setPickerRange(range);
    if (range?.from && range?.to) {
      setDateRange({ startDate: getTimeInSeconds(range.from), endDate: getTimeInSeconds(range.to) });
      setTimeout(() => setDatePickerOpen(false), 500);
    }
  };

  return (
    <>
      <Button
        title={
          selectedRangeKey === dateRangeKeys.custom && pickerRange && pickerRange.to && pickerRange.from
            ? `${format(pickerRange.from, 'MM/dd/yyyy')} - ${format(pickerRange.to, 'MM/dd/yyyy')}`
            : selectedRangeKey
        }
        LeftIcon={IconCalendar}
        RightIcon={menuOpen ? IconChevronUp : IconChevronDown}
        onClick={handleClick}
        className="min-w-[180px] !justify-start !text-left"
        titleClassName="flex-1"
        ref={anchorRef}
      />

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        classes={{ paper: 'w-[180px] !rounded-lg mt-0.5 !shadow-custom' }}
      >
        {Object.values(dateRangeKeys).map((range) => (
          <MenuItem
            key={range}
            classes={{ root: '!py-2 !px-3.5', selected: '!bg-white-100 !text-primary-700' }}
            onClick={() => handleChange(range)}
            selected={selectedRangeKey === range}
          >
            <span className="text-base">{range}</span>
          </MenuItem>
        ))}
      </Menu>

      <Popper
        open={datePickerOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        className="z-[100]"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper classes={{ root: '!rounded-lg my-0.5 !shadow-custom' }}>
              <ClickAwayListener onClickAway={handleCloseDatePicker}>
                <div>
                  <DayPicker
                    mode="range"
                    selected={pickerRange}
                    onSelect={handleSelectDateRange}
                    classNames={dayPickerClassNames}
                    toDate={new Date()}
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DateRangePicker;
