import { useState } from 'react';
import { Menu } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import RangeNumberInput from '../inputs/RangeNumberInput';
import { parseDonationAmount } from '../../core/utils';

const AmountFilter = ({ initialMinAmount, initialMaxAmount, onSelect, active }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [minAmount, setMinAmount] = useState(initialMinAmount || 0);
  const [maxAmount, setMaxAmount] = useState(initialMaxAmount || 0);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    onSelect({ minAmount: 0, maxAmount: 0 });
    setMinAmount(0);
    setMaxAmount(0);
    handleClose();
  };

  const handleApply = () => {
    onSelect({ minAmount, maxAmount });
    handleClose();
  };

  return (
    <>
      <div className="flex w-fit">
        <Button
          title="Amount"
          className={`w-[110px] justify-between rounded-lg ${active ? 'border-primary-200 !bg-primary-50' : ''}`}
          RightIcon={open ? IconChevronUp : IconChevronDown}
          onClick={handleClick}
        />

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          classes={{ paper: 'w-[244px] !rounded-lg mt-1', list: '!p-0 divide-y divide-gray-50' }}
        >
          <li className="flex items-center justify-between p-4">
            <RangeNumberInput
              minValue={minAmount}
              maxValue={maxAmount}
              onChangeMinValue={setMinAmount}
              onChangeMaxValue={setMaxAmount}
              formatter={(value) => parseDonationAmount(value, 0)}
              className="w-[100px]"
            />
          </li>

          <li className="flex items-center justify-between p-4">
            <Button title="Reset" onClick={handleReset} />

            <Button title="Apply" color="primary" onClick={handleApply} />
          </li>
        </Menu>
      </div>
    </>
  );
};

AmountFilter.propTypes = {
  initialMinAmount: PropTypes.number,
  initialMaxAmount: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

AmountFilter.defaultProps = {
  active: false,
};

export default AmountFilter;
