import { Outlet } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Navbar from '../components/navbar/Navbar';
import Header from '../components/header/Header';

const Layout = () => {
  const { bannerMessage } = useFlags();

  return (
    <>
      <Header />
      {bannerMessage?.value && (
        <div className="bg-primary-700 px-6 py-2.5 text-center text-sm font-semibold text-white-100">
          {bannerMessage.value}
        </div>
      )}
      <div className="flex grow overflow-y-hidden">
        <Navbar />
        <div className={`grow overflow-auto px-8 py-6`}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
