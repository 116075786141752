import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import Input from '../inputs/Input';
import ColorInput from '../inputs/ColorInput';

const FormInput = ({ id, label, value, onChange, onReset, type, error, placeholder, required }) => {
  const [editable, setEditable] = useState(false);

  const handleEdit = () => {
    setEditable(true);
  };

  const handleCancelEdit = () => {
    setEditable(false);
    onReset();
  };

  const renderInput = () => {
    if (type === 'color') {
      return (
        <ColorInput
          label={label}
          placeholder={placeholder}
          value={value}
          containerClassName="flex items-center"
          labelClassName="w-[125px] mr-[5%]"
          inputContainerClassName="flex-1"
          onChange={onChange}
          disabled={!editable}
          error={error}
        />
      );
    }

    return (
      <Input
        id={id}
        label={label}
        placeholder={placeholder}
        containerClassName={`flex ${error ? 'items-start' : 'items-center'}`}
        labelClassName="w-[125px] mr-[5%]"
        inputContainerClassName="flex-1"
        value={value}
        onChange={onChange}
        type={type}
        disabled={!editable}
        error={!!error}
        helperText={error}
        required={required}
      />
    );
  };

  return (
    <div className="flex">
      <div className="w-3/4">{renderInput()}</div>
      <div className="flex w-1/4 flex-col">
        <Button
          title={editable ? 'Cancel' : 'Edit'}
          className="self-end"
          onClick={editable ? handleCancelEdit : handleEdit}
        />
      </div>
    </div>
  );
};

FormInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  type: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

FormInput.defaultProps = {
  type: 'text',
  error: '',
  onReset: () => {},
  placeholder: '',
  required: false,
};

export default FormInput;
