import { IconCloudUpload, IconRefresh, IconCircleCheckFilled, IconCircleXFilled } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import { IMPORT_JOB_STATUS, MAP_IMPORT_JOB_STATUS_TO_LABEL } from '../../core/constants';

const getJobIcon = (status) => {
  switch (status) {
    case IMPORT_JOB_STATUS.notStarted:
      return <IconCloudUpload size={24} className="text-gray-500" />;
    case IMPORT_JOB_STATUS.validating:
    case IMPORT_JOB_STATUS.processing:
    case IMPORT_JOB_STATUS.validated:
      return <IconRefresh size={24} className="text-orange-300" />;
    case IMPORT_JOB_STATUS.done:
      return <IconCircleCheckFilled size={24} className="text-green-400" />;
    case IMPORT_JOB_STATUS.failed:
      return <IconCircleXFilled size={24} className="text-red-500" />;
    default:
      return null;
  }
};

const ImportStatus = ({ status }) => {
  return (
    <div className="flex items-center space-x-3">
      {getJobIcon(status)}
      <p>{MAP_IMPORT_JOB_STATUS_TO_LABEL[status]}</p>
    </div>
  );
};

ImportStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default ImportStatus;
