import { useSelector, shallowEqual } from 'react-redux';

import useActionCreator from '../../hooks/useActionCreator';
import { setStatus, getPages } from './actions';

export const usePages = () => {
  const pagesState = useSelector((state) => state.pages, shallowEqual);

  return {
    ...pagesState,
    setStatus: useActionCreator(setStatus),
    getPages: useActionCreator(getPages),
  };
};
