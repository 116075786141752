import { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { IconChevronDown } from '@tabler/icons-react';
import { CampaignStatusLabel } from 'ui';

import { sortDataWithDirection } from '../../core/utils';
import { useDashboard } from '../../store/dashboard/hooks';
import { useReports } from '../../store/reports/hooks';
import { reportTypes, sortDirections } from '../../core/constants';

const SelectCampaign = () => {
  const { campaignId, selectCampaignId } = useDashboard();
  const { reports } = useReports();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (campaignId) => {
    selectCampaignId(campaignId);
    handleClose(null);
  };

  const campaignReports = sortDataWithDirection(reports, 'sendAt', sortDirections.desc).filter(
    (report) => report.type === reportTypes.single,
  );

  const selectedCampaign = campaignReports.find((cReport) => cReport.campaignId === campaignId);

  const allCampaignsLabel = `All Campaigns (${campaignReports.length})`;

  return (
    <>
      <h1 className="text-h3 text-primary-700">
        <button
          className="
            flex 
            items-center 
            space-x-1 
            p-1
            enabled:hover:text-primary-600
            enabled:focus:text-primary-600
            enabled:active:text-primary-800
          "
          onClick={handleClick}
        >
          <span>{selectedCampaign?.name || allCampaignsLabel}</span>
          {selectedCampaign?.sendStatus && <CampaignStatusLabel status={selectedCampaign.sendStatus} />}
          <IconChevronDown size={20} className={open ? 'rotate-180' : ''} />
        </button>
      </h1>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: 'w-[365px] !rounded-lg mt-0.5 !max-h-96 !shadow-custom' }}
      >
        {[{ name: allCampaignsLabel }].concat(campaignReports).map(({ name, campaignId, sendStatus }) => (
          <MenuItem
            key={campaignId ?? 'all-campaigns'}
            onClick={() => handleSelect(campaignId ?? '')}
            classes={{ root: 'h-10 !py-2.5 space-x-3', selected: '!bg-white-100 !text-primary-700' }}
            selected={selectedCampaign?.campaignId === campaignId}
          >
            <span className="w-[236px] max-w-[236px] overflow-hidden text-ellipsis text-base">{name}</span>
            {sendStatus && (
              <div className="flex flex-1 justify-end">
                <CampaignStatusLabel status={sendStatus} />
              </div>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SelectCampaign;
