export const DEFAULT_VISIBLE_FIELDS = {
  email: true,
  first_name: true,
  last_name: true,
  address_line_1: true,
  address_line_2: true,
  city: true,
  state_province_region: true,
  country: true,
  postal_code: true,
  phone_number: true,
  import_filename: true,
  created_at: true,
  updated_at: true,
  tags: true,
  unsubscribed: true,
};

export const MAP_FIELD_TO_LABEL = {
  email: 'Email',
  first_name: 'First Name',
  last_name: 'Last Name',
  address_line_1: 'Address Line 1',
  address_line_2: 'Address Line 2',
  city: 'City',
  state_province_region: 'State/Province/Region',
  country: 'Country',
  postal_code: 'Postal Code',
  phone_number: 'Phone Number',
  import_filename: 'Upload Type',
  created_at: 'Created At',
  updated_at: 'Updated At',
  tags: 'Tags',
  unsubscribed: 'Unsubscribed',
  archived_at: 'Archived At',
};

export const CONTACT_TYPES = {
  dsContacts: 'ds-contacts',
  notDsContacts: 'not-ds-contacts',
};

export const CONTACT_TYPE_LABELS = {
  [CONTACT_TYPES.dsContacts]: 'From DonorSpring',
  [CONTACT_TYPES.notDsContacts]: 'Not From DonorSpring',
};
