import { forwardRef, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Slide } from '@mui/material';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { parse } from 'papaparse';

import AlertMessage from '../alerts/AlertMessage';
import appSettings from '../../app-settings';
import { getNetworkError, uploadFile } from '../../core/utils';
import Button from '../buttons/Button';
import Stepper from '../stepper/Stepper';
import ImportStep from './ImportStep';
import CategorizeStep from './CategorizeStep';
import TagStep from './TagStep';
import { useTags } from '../../store/tags/hook';
import MatchStep from './MatchStep';
import OnboardingStep from './OnboardingStep';
import CompleteStep from './CompleteStep';
import SuccessStep from './SuccessStep';
import { useContactFields } from '../../store/contacts/hooks';
import { useImports } from '../../store/imports/hooks';
import { useAccount } from '../../store/account/hooks';
import { IMPORT_TYPES } from '../../core/constants';
import ImportTypeStep from './ImportTypeStep';

const CONTACTS_URL = `${appSettings.baseUrl}/contacts`;

const GENERAL_UPLOAD_STEPS = ['Type', 'Import'];

const IMPORT_CONTACTS_STEPS = ['Type', 'Import', 'Categorize', 'Tag', 'Match', 'Onboard', 'Complete'];

const IMPORT_SUPPRESSION_LIST_STEPS = ['Type', 'Import', 'Match', 'Complete'];

const parseCsvFile = (file) =>
  new Promise((resolve, reject) => {
    parse(file, {
      header: true,
      complete: (results) => {
        resolve({ headers: results.meta.fields, data: results.data });
      },
      error: (error) => {
        reject(error);
      },
    });
  });

const createPresignedUploadUrl = async ({
  filename,
  skipUpdate,
  sendRecurringEmails,
  optInContacts,
  tagIds,
  fieldMappings,
  importType,
}) => {
  const response = await axios.post(`${CONTACTS_URL}/import`, {
    filename,
    tag_ids: tagIds,
    skip_update: skipUpdate,
    send_recurring_emails: sendRecurringEmails,
    opt_in_contacts: optInContacts,
    field_mappings: fieldMappings,
    import_type: importType,
  });
  return response.data.url;
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenUpload = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [localError, setLocalError] = useState(false);
  const [importFile, setImportFile] = useState(null);
  const { error: tagError, setError: setTagError, tags } = useTags();
  const { getContactFields } = useContactFields();
  const { newImport, updateNewImport, goNext, getImports, resetNewImport } = useImports();
  const { account } = useAccount();

  useEffect(() => {
    getContactFields();
  }, [getContactFields]);

  const hasBeenOnboarded = !!account?.onboarded;

  const setDefaultTag = useCallback(() => {
    if (!open || hasBeenOnboarded) return;
    // We changed the default tag name from "Seed List" to "Warm-up List"
    // Match either for backwards compatibility
    const defaultTag = tags.find((tag) => /(seed list|warm-up list)/i.test(tag.name));
    if (!defaultTag) return;
    updateNewImport({ selectedTags: [defaultTag] });
  }, [open, hasBeenOnboarded, tags, updateNewImport]);

  useEffect(() => {
    setDefaultTag();
  }, [setDefaultTag]);

  const handleFileChange = useCallback(
    async (file) => {
      setImportFile(file);
      const { headers, data } = await parseCsvFile(file);
      updateNewImport({ fileName: file.name, csvData: data, csvHeaders: headers });
    },
    [updateNewImport],
  );

  const handleCompleteImport = async () => {
    if (!importFile) return;
    setLocalError(false);
    setLoading(true);
    try {
      const payload = {
        filename: importFile.name,
        fieldMappings: newImport.fieldMappings,
        importType: newImport.importType,
      };
      if (newImport.importType === IMPORT_TYPES.contacts) {
        payload.skipUpdate = newImport.skipUpdate;
        payload.sendRecurringEmails = newImport.sendRecurringEmails;
        payload.optInContacts = newImport.optInContacts;
        payload.tagIds = newImport.selectedTags.map((tag) => tag.id);
      }
      const uploadUrl = await createPresignedUploadUrl(payload);
      const arrayBuffer = await importFile.arrayBuffer();
      await uploadFile(uploadUrl, arrayBuffer, importFile.type);
      await getImports();
      goNext();
    } catch (err) {
      Sentry.captureException(err);
      setLocalError(getNetworkError(err));
    }
    setLoading(false);
  };

  const resetError = () => {
    if (localError) setLocalError(false);
    if (tagError) setTagError(false);
  };

  const handleClose = () => {
    setImportFile(null);
    resetNewImport();
    onClose();
  };

  const renderContent = () => {
    if (newImport.importType === IMPORT_TYPES.suppressionList) {
      switch (newImport.activeStep) {
        case 0:
          return <ImportTypeStep />;
        case 1:
          return <ImportStep importFile={importFile} onUploadFile={handleFileChange} />;
        case 2:
          return <MatchStep />;
        case 3:
          return <CompleteStep onComplete={handleCompleteImport} onCancel={handleClose} loading={loading} />;
        case 4:
          return <SuccessStep onClose={handleClose} />;
        default:
          return null;
      }
    }

    switch (newImport.activeStep) {
      case 0:
        return <ImportTypeStep />;
      case 1:
        return <ImportStep importFile={importFile} onUploadFile={handleFileChange} />;
      case 2:
        return <CategorizeStep />;
      case 3:
        return <TagStep />;
      case 4:
        return <MatchStep />;
      case 5:
        return <OnboardingStep />;
      case 6:
        return <CompleteStep onComplete={handleCompleteImport} onCancel={handleClose} loading={loading} />;
      case 7:
        return <SuccessStep onClose={handleClose} />;
      default:
        return null;
    }
  };

  const getTitle = () => {
    if (newImport.activeStep > 0) {
      if (newImport.importType === IMPORT_TYPES.contacts) {
        return 'Import contacts';
      }
      if (newImport.importType === IMPORT_TYPES.suppressionList) {
        return 'Import suppression list';
      }
    }
    return 'Import contacts or suppression List';
  };

  const getUploadSteps = () => {
    if (newImport.importType === IMPORT_TYPES.contacts) {
      return IMPORT_CONTACTS_STEPS;
    }
    if (newImport.importType === IMPORT_TYPES.suppressionList) {
      return IMPORT_SUPPRESSION_LIST_STEPS;
    }
    return GENERAL_UPLOAD_STEPS;
  };

  const error = localError || tagError;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      sx={{ '.MuiPaper-root': { padding: 0 } }}
    >
      <div className="flex items-center justify-between border-b border-gray-50 px-6 py-3">
        <img alt="DonorSpring" src="dn-logo-64x64.png" className="mr-4 h-10" />

        <div className="flex-1">
          <h2 className="text-2xl font-semibold dark:text-white-100">{getTitle()}</h2>

          {newImport.activeStep < 6 && <Stepper steps={getUploadSteps()} activeStep={newImport.activeStep} />}
        </div>

        <Button title="Exit" onClick={handleClose} disabled={loading} />
      </div>

      <div className="relative m-10 flex-1">{renderContent()}</div>

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={resetError}
        severity="error"
      />
    </Dialog>
  );
};

FullScreenUpload.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FullScreenUpload;
