/* eslint-disable react/prop-types */
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender } from '@tanstack/react-table';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { format } from 'date-fns';
import { getNetworkError } from 'common/utils';
import { Loader, TablePagination } from 'ui';

import { getDonorDonations } from '../../api/donations';
import { parseDonationAmount } from '../../core/utils';
import DonationType from '../donations/DonationType';
import StatusLabel from '../donations/StatusLabel';

const DonationHistory = (props) => {
  const { donorId } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [donations, setDonations] = useState([]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const getContactDonations = useCallback(async (id) => {
    setLoading(true);
    try {
      const donorDonations = await getDonorDonations(id);
      setDonations(donorDonations);
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getContactDonations(donorId);
  }, [donorId, getContactDonations]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'createdAt',
        header: () => 'Date',
        footer: (props) => props.column.id,
        cell: (info) => format(new Date(info.getValue() * 1000), 'MM.dd.yyyy'), // createdAt is in seconds,
        minSize: 110,
      },
      {
        accessorKey: 'netAmount',
        header: () => 'Amount',
        footer: (props) => props.column.id,
        cell: (info) => parseDonationAmount(info.getValue() / 100), // netAmount is in cents,
        minSize: 110,
      },
      {
        accessorKey: 'type',
        header: () => 'Type',
        footer: (props) => props.column.id,
        cell: (info) => <DonationType {...info.row.original} />,
        minSize: 185,
      },
      {
        accessorKey: 'campaignName',
        header: () => 'Campaign',
        footer: (props) => props.column.id,
        minSize: 280,
      },
      {
        accessorKey: 'status',
        header: () => 'Status',
        footer: (props) => props.column.id,
        cell: (info) => <StatusLabel status={info.getValue()} />,
        minSize: 110,
      },
    ],
    [],
  );

  const data = useMemo(
    () => donations.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize),
    [donations, pageIndex, pageSize],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    pageCount: Math.ceil(donations.length / pageSize),
    state: { pagination },
    defaultColumn: {
      size: 'fit-content',
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  return (
    <div className="space-y-2">
      <h3 className="text-lg font-semibold">Donation History</h3>

      <div className="relative space-y-2 overflow-x-auto sm:rounded-lg">
        <table className="w-full text-left text-base text-gray-950 dark:text-gray-400">
          <thead className="bg-white-100 text-sm uppercase text-gray-400 dark:bg-gray-700 dark:text-gray-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="px-4 py-3"
                      style={{ width: header.column.columnDef.size, minWidth: header.column.columnDef.minSize }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                >
                  {row.getVisibleCells().map((cell) => {
                    const sentryMasked = cell.column.id === 'donorName';
                    return (
                      <td
                        key={cell.id}
                        className={`px-4 py-2 ${sentryMasked ? 'sentry-mask' : ''}`}
                        style={{ width: cell.column.columnDef.size, minWidth: cell.column.columnDef.minSize }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        {!!error && <p className="m-6 py-2 text-center text-base text-red-500">{error}</p>}

        {loading && <Loader />}
      </div>

      <TablePagination table={table} total={donations.length} />
    </div>
  );
};

DonationHistory.propTypes = {
  donorId: PropTypes.string.isRequired,
};

export default DonationHistory;
