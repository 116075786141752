import { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconPlus } from '@tabler/icons-react';

import { sortDataWithDirection } from '../core/utils';
import AlertMessage from '../components/alerts/AlertMessage';
import Table from '../components/segments/Table';
import { useSegments } from '../store/segments/hook';
import { useContactFields } from '../store/contacts/hooks';
import { sortDirections } from '../core/constants';
import Loader from '../components/loader/Loader';
import Button from '../components/buttons/Button';
import Sorters from '../components/tables/Sorters';

const sortByOptions = [
  { label: 'Name', value: 'name' },
  { label: 'Date', value: 'createdAt' },
];

const Segments = () => {
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState(sortDirections.desc);
  const navigate = useNavigate();
  const { loading, error, segments, success, resetSegmentDetails, getSegments, setError, setSuccess } = useSegments();
  const { getContactFields } = useContactFields();

  useEffect(() => {
    getSegments();
    getContactFields();
  }, [getContactFields, getSegments]);

  const goToNewSegment = () => {
    resetSegmentDetails();
    navigate('/new-segment');
  };

  const data = useMemo(() => {
    let filteredData = segments;
    if (sortBy) {
      filteredData = sortDataWithDirection(filteredData, sortBy, sortDirection);
    }
    return filteredData;
  }, [segments, sortBy, sortDirection]);

  return (
    <div className="w-full space-y-6">
      <div className="flex justify-between space-x-3">
        <h1 className="mb-auto text-h3 dark:text-white-100">Segments</h1>

        <div className="flex items-center space-x-3">
          <Sorters
            options={sortByOptions}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
          />

          <Button title="Create Segment" color="primary" LeftIcon={IconPlus} onClick={goToNewSegment} />
        </div>
      </div>

      <div className="relative">
        <Table data={data} />

        {loading && <Loader />}
      </div>

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => setSuccess(false)}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </div>
  );
};

export default Segments;
