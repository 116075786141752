import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../buttons/Button';
import Input from '../inputs/Input';

const EmailWithDomainInput = ({
  id,
  label,
  value,
  onChange,
  authenticatedDomain,
  onReset,
  error,
  placeholder,
  required,
}) => {
  const [editable, setEditable] = useState(false);

  const handleEdit = () => {
    setEditable(true);
  };

  const handleCancelEdit = () => {
    setEditable(false);
    onReset();
  };

  return (
    <div className="flex">
      <div className={`flex w-3/4 ${error ? 'items-start' : 'items-center'}`}>
        <label htmlFor={id} className="mr-[5%] block w-[125px] text-base font-medium text-gray-900">
          <span>{label}</span>
          {required && <span className="ml-1 text-base text-red-500">*</span>}
        </label>
        <div className="w-full flex-1">
          <div className="flex">
            <Input
              type="email"
              id={id}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              disabled={!authenticatedDomain || !editable}
              error={!!error}
              helperText={error}
              containerClassName="flex-1"
              inputClassName="rounded-none rounded-l-lg"
              required={required}
            />
            <span className="inline-flex h-10 items-center rounded-r-md border border-l-0 border-gray-100 bg-gray-10 px-3 text-base text-gray-950">
              @{authenticatedDomain ? authenticatedDomain : 'yourverifieddomain.com'}
            </span>
          </div>
        </div>
      </div>
      <div className="flex w-1/4 flex-col">
        <Button
          title={editable ? 'Cancel' : 'Edit'}
          className="self-end"
          onClick={editable ? handleCancelEdit : handleEdit}
        />
      </div>
    </div>
  );
};

EmailWithDomainInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  authenticatedDomain: PropTypes.string.isRequired,
  onReset: PropTypes.func,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

EmailWithDomainInput.defaultProps = {
  error: '',
  onReset: () => {},
  placeholder: '',
  required: false,
};

export default EmailWithDomainInput;
