import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAccount } from '../store/account/hooks';

const UserRoleProtectedRoute = ({ children, allowedRoles }) => {
  const { account } = useAccount();

  if (!allowedRoles.includes(account.userRole)) {
    return <Navigate to="/" />;
  }

  return children;
};

UserRoleProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default UserRoleProtectedRoute;
