import { useState, useEffect, useRef, useCallback } from 'react';
import postRobot from 'post-robot';
import * as Sentry from '@sentry/react';
import { IconExternalLink, IconPencil, IconArrowLeft } from '@tabler/icons-react';
import mergeWith from 'lodash.mergewith';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IconButton } from 'ui';

import appSettings from '../app-settings';
import { STATUS_STATES } from '../core/constants';
import { getNetworkError, waitFor, getPageUrl } from '../core/utils';
import { getPage, upsertPage } from '../api';
import { useAccount } from '../store/account/hooks';
import Button from '../components/buttons/Button';
import PageEditor from '../components/page-editor/PageEditor';
import EmbeddedPage from '../components/embedded-page/EmbeddedPage';
import AlertMessage from '../components/alerts/AlertMessage';
import CopyBox from '../components/donation-page/CopyBox';
import { usePages } from '../store/pages/hooks';

const DonationPage = () => {
  const params = useParams();
  const [editorOpen, setEditorOpen] = useState(['new', 'new-default'].includes(params.slug));
  const [status, setStatus] = useState({ state: STATUS_STATES.idle, message: '' });
  const [pageData, setPageData] = useState(null);
  const editorRef = useRef(null);
  const { account, getAccount } = useAccount();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getPages } = usePages();

  const isNewPage = ['new', 'new-default'].includes(params.slug);
  const isNewDefaultPage = params.slug === 'new-default';
  const slug = isNewPage ? '' : params.slug;

  const deploymentSuccess = searchParams.get('success') === 'true';

  const getPageData = useCallback(async () => {
    if (!slug) return;
    try {
      setStatus({ state: STATUS_STATES.loading, message: '' });
      const data = await getPage(slug);
      setPageData(data || null);
      setStatus({
        state: deploymentSuccess ? STATUS_STATES.success : STATUS_STATES.idle,
        message: deploymentSuccess ? 'Donation page launched successfully!' : '',
      });
      if (deploymentSuccess) setSearchParams({});
    } catch (error) {
      Sentry.captureException(error);
      setStatus({ state: STATUS_STATES.error, message: getNetworkError(error) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    getPageData();
  }, [getPageData]);

  const pageUrl = getPageUrl({ account, slug: params.slug });

  const handleEditorLoad = async () => {
    try {
      const baseUrl = pageUrl.replace(params.slug, '');

      const content = pageData?.content
        ? {
            ...pageData.content,
            metadata: { name: pageData.name || '', slug, baseUrl },
          }
        : { metadata: { baseUrl, ...(isNewDefaultPage && { name: 'Default Donation Page', slug: 'default' }) } };

      const initialState = account?.logoUrl
        ? mergeWith({}, { images: { headerImage: { url: account.logoUrl } } }, content)
        : content;

      await postRobot.send(editorRef.current.contentWindow, 'initEditor', {
        initialState,
        isOnboarding: isNewDefaultPage,
      });
    } catch (error) {
      Sentry.captureException(error);
      setStatus({ state: STATUS_STATES.error, message: 'Something went wrong while initializing the editor.' });
    }
  };

  const handleSave = async () => {
    try {
      if (!account) return;

      const response = await postRobot.send(editorRef.current.contentWindow, 'saveAndExit');

      if (!response.data) return;

      setStatus({ state: STATUS_STATES.loading, message: '' });

      const pageSlug = slug || response.data.metadata.slug || (isNewDefaultPage && 'default');

      await upsertPage({
        tenantId: account.tenantId,
        slug: pageSlug,
        content: response.data,
        action: pageData ? 'update' : 'create',
      });

      if (!account.donationPageSubdomain) getAccount();

      // Empiric value - page needs some extra time to reflect changes
      await waitFor(2000);
      await getPages(); // Update local page list

      if (isNewPage) {
        // Re-render page with new slug and include query param to indicate successful deployment
        setEditorOpen(false);
        navigate(`/donation-pages/${pageSlug}?success=true`, { replace: true });
        return;
      }

      // Re-fetch page state for reflecting updated state on the editor
      await getPageData();
      setStatus({ state: STATUS_STATES.success, message: 'Donation page launched successfully!' });
      setEditorOpen(false);
    } catch (err) {
      Sentry.captureException(err);
      setStatus({ state: STATUS_STATES.error, message: getNetworkError(err) });
    }
  };

  const handleCancel = () => {
    if (isNewPage) {
      navigate('/donation-pages', { replace: true });
      return;
    }

    setEditorOpen(false);
  };

  const loading = status.state === STATUS_STATES.loading;
  const error = status.state === STATUS_STATES.error;
  const success = status.state === STATUS_STATES.success;

  return (
    <>
      <main className="flex size-full flex-col">
        <div className="flex items-center space-x-1">
          <IconButton
            Icon={<IconArrowLeft size={20} />}
            color="transparent"
            className="-ml-2"
            onClick={() => navigate('/donation-pages', { replace: true })}
            srOnly="Go back to campaigns"
          />

          <h1 className="text-h3 dark:text-white-100">Donation Page</h1>
        </div>

        <div className="mb-2 mt-6 flex h-full">
          <div className="flex flex-1 flex-col overflow-hidden rounded-xl bg-white-100 shadow-md">
            <div className="flex items-center justify-between border-b border-gray-50 p-8 pb-3.5">
              <div className="space-y-2">
                <h3 className="text-h5 font-semibold">{pageData?.name || 'New Donation Page'}</h3>

                {!isNewPage && <CopyBox pageUrl={pageUrl} />}
              </div>

              {editorOpen ? (
                <div className="flex items-center space-x-2">
                  <Button title="Save & Exit" color="primary" onClick={handleSave} size="sm" />

                  <Button title="Cancel" onClick={handleCancel} size="sm" />
                </div>
              ) : (
                <div className="flex items-center space-x-2">
                  <Button title="Edit" onClick={() => setEditorOpen(true)} LeftIcon={IconPencil} size="sm" />

                  <Button
                    title="Preview"
                    href={pageUrl}
                    target="_blank"
                    rel="noreferrer"
                    LeftIcon={IconExternalLink}
                    size="sm"
                  />
                </div>
              )}
            </div>

            <div className="flex-1">
              {editorOpen ? (
                <PageEditor
                  ref={editorRef}
                  pageUrl={`${appSettings.donationPageEditorUrl}/?customerSupportEmail=${account?.customerSupportEmail}`}
                  loading={loading}
                  onLoad={handleEditorLoad}
                />
              ) : (
                <EmbeddedPage pageUrl={pageUrl} />
              )}
            </div>
          </div>
        </div>
      </main>

      <AlertMessage
        open={success}
        message={status.message}
        onClose={() => setStatus({ state: STATUS_STATES.idle, message: '' })}
        severity="success"
      />

      <AlertMessage
        open={error}
        message={status.message}
        onClose={() => setStatus({ state: STATUS_STATES.idle, message: '' })}
        severity="error"
      />
    </>
  );
};

export default DonationPage;
