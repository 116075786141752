import PropTypes from 'prop-types';

import { donationStatus, donationStatusLabels } from '../../core/constants';

const getContainerColorClassNames = (status) => {
  switch (status) {
    case donationStatus.succeeded:
      return 'border-green-100 bg-green-50 text-green-500';
    case donationStatus.refunded:
      return 'border-purple-100 bg-purple-50 text-purple-500';
    case donationStatus.failure:
      return 'border-red-100 bg-red-50 text-red-500';
    default:
      // donationStatus.pending
      return 'border-orange-200 bg-orange-50 text-orange-700';
  }
};

const StatusLabel = ({ status }) => {
  return (
    <div className={`w-fit rounded border px-1.5 py-0.5 ${getContainerColorClassNames(status)}`}>
      <p className="text-caption1 font-semibold uppercase">{donationStatusLabels[status]}</p>
    </div>
  );
};

StatusLabel.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusLabel;
