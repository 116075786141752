import PropTypes from 'prop-types';

import { USER_STATUS, USER_STATUS_TO_LABEL } from '../../core/constants';

const getContainerColorClassNames = (status) => {
  switch (status) {
    case USER_STATUS.confirmed:
      return 'border-green-100 bg-green-50 text-green-500';
    default:
      // USER_STATUS.forceChangePassword
      return 'border-orange-200 bg-orange-50 text-orange-700';
  }
};

const StatusLabel = ({ status }) => {
  return (
    <div className={`w-fit rounded border px-1.5 py-0.5 ${getContainerColorClassNames(status)}`}>
      <p className="text-caption1 font-semibold uppercase">{USER_STATUS_TO_LABEL[status]}</p>
    </div>
  );
};

StatusLabel.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusLabel;
