import { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { IconArrowNarrowDown, IconChevronDown, IconArrowNarrowUp } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import IconButton from '../buttons/IconButton';
import { sortDirections } from '../../core/constants';

const Sorters = ({ options, sortBy, setSortBy, sortDirection, setSortDirection }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSortDirection = () => {
    setSortDirection((prevState) => (prevState === sortDirections.desc ? sortDirections.asc : sortDirections.desc));
  };

  const handleChangeSortBy = (sortKey) => {
    setSortBy(sortKey);
    handleClose();
  };

  return (
    <>
      <div className="flex w-fit rounded-lg">
        <Button
          title={options.find((options) => options.value === sortBy)?.label}
          className="flex-1 rounded-none rounded-l-lg border-r-0"
          LeftIcon={sortDirection === sortDirections.desc ? IconArrowNarrowDown : IconArrowNarrowUp}
          onClick={handleChangeSortDirection}
        />

        <IconButton
          Icon={
            <IconChevronDown
              size={20}
              className="
              text-gray-600 
              hover:text-primary-600
              focus:text-primary-600
              active:text-primary-800"
            />
          }
          srOnly="Select action"
          onClick={handleClick}
          className="
          size-10 
          rounded-none
          rounded-r-lg 
          border 
          border-l-0 
          border-gray-150 
          bg-white-100
          p-2.5
          enabled:hover:bg-white-100
          enabled:focus:bg-white-100
          enabled:active:bg-white-100
        "
        />
      </div>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: '!min-w-[120px] !rounded-lg mt-0.5' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 86,
        }}
      >
        {options.map(({ label, value }) => (
          <MenuItem
            key={`${label}-${value}`}
            classes={{ root: '!py-2 !px-3.5', selected: '!bg-primary-50' }}
            onClick={() => handleChangeSortBy(value)}
            selected={value === sortBy}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

Sorters.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired }),
  ).isRequired,
  sortBy: PropTypes.string.isRequired,
  setSortBy: PropTypes.func.isRequired,
  sortDirection: PropTypes.string.isRequired,
  setSortDirection: PropTypes.func.isRequired,
};

export default Sorters;
