import { Select, FormControl, MenuItem, InputLabel, OutlinedInput, ListSubheader, Box } from '@mui/material';
import { IconChevronDown } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import Chip from '../chip/Chip';
import Checkbox from './Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultipleSelectInput = ({
  id,
  label,
  options,
  sections,
  value,
  onChange,
  containerClassName,
  disabled = false,
}) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const renderOptions = () => {
    if (options.length) {
      return options.map((option) => (
        <MenuItem key={option.id} value={option.id} classes={{ selected: '!bg-primary-100' }}>
          <Checkbox
            label={option.name}
            labelClassName="text-lg"
            value=""
            checked={value.indexOf(option.id) > -1}
            className="mr-2"
            onChange={() => {}} // removes console warning
          />
        </MenuItem>
      ));
    }

    const components = [];

    sections.forEach((section) => {
      components.push(<ListSubheader key={section.title}>{section.title}</ListSubheader>);
      section.options.forEach((option) =>
        components.push(
          <MenuItem key={option.id} value={option.id} classes={{ selected: '!bg-primary-100' }}>
            <Checkbox
              label={option.name}
              labelClassName="text-lg"
              value=""
              checked={value.indexOf(option.id) > -1}
              className="mr-2"
              onChange={() => {}} // removes console warning
            />
          </MenuItem>,
        ),
      );
    });
    return components;
  };

  const values = [...options, ...sections.flatMap((s) => s.options)];

  return (
    <div className={containerClassName}>
      <FormControl fullWidth>
        <InputLabel id={`${id}-${label}`} className="!-top-1">
          {label}
        </InputLabel>
        <Select
          labelId={`${id}-${label}`}
          id={id}
          multiple
          value={value}
          onChange={handleChange}
          input={
            <OutlinedInput
              label={label}
              sx={{ '& .MuiOutlinedInput-input': { padding: '12px !important' } }}
              className="!rounded-lg !border-0"
            />
          }
          MenuProps={MenuProps}
          className="block
          w-full
          cursor-auto
          border
          border-gray-200
          bg-white-100
          text-base
          text-gray-950
          focus:border-2
          focus:border-primary-700
          focus:outline-none
          focus:ring-2
          focus:ring-primary-300
          focus:ring-offset-1
          disabled:cursor-not-allowed
          disabled:bg-gray-10
          dark:text-white-100
          dark:focus:border-primary-500
          dark:focus:ring-primary-500"
          IconComponent={(props) => (
            // eslint-disable-next-line react/prop-types
            <IconChevronDown size={16} className={`${props.className} !right-3 inline-block`} />
          )}
          disabled={disabled}
          renderValue={(optionIds) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {optionIds.map((optionId) => (
                <Chip
                  key={optionId}
                  label={values.find((v) => v.id === optionId)?.name ?? ''}
                  onDelete={() =>
                    onChange((typeof value === 'string' ? value.split(',') : value).filter((val) => val !== optionId))
                  }
                />
              ))}
            </Box>
          )}
        >
          {renderOptions()}
        </Select>
      </FormControl>
    </div>
  );
};

MultipleSelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

MultipleSelectInput.defaultProps = {
  options: [],
  sections: [],
};

export default MultipleSelectInput;
