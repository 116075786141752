import Collapse from '@mui/material/Collapse';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import { ReactComponent as UnCheckIcon } from '../../../assets/icons/uncheck.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/success-check.svg';
import IconButton from '../../buttons/IconButton';

const FormCard = ({ isExpanded, handleClick, title, subtitle, checked, form, containerClassName }) => {
  return (
    <div className={`p-8 ${containerClassName}`}>
      <div className="flex cursor-pointer items-center justify-between" onClick={handleClick}>
        <div className="space-y-2">
          <h3 className="text-h4 font-semibold">{title}</h3>

          <p className="text-lg">{subtitle}</p>
        </div>

        <div className="flex items-center space-x-3">
          {checked ? <CheckIcon className="size-6" /> : <UnCheckIcon className="size-6" />}

          <IconButton
            Icon={
              isExpanded ? (
                <IconChevronUp size={20} className="text-gray-600" />
              ) : (
                <IconChevronDown size={20} className="text-gray-600" />
              )
            }
            color="transparent"
            className="!p-1"
            circle
            srOnly="Toggle content"
          />
        </div>
      </div>

      <Collapse in={isExpanded}>
        <div className="mt-6">{form}</div>
      </Collapse>
    </div>
  );
};

FormCard.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  form: PropTypes.any.isRequired,
  checked: PropTypes.bool.isRequired,
  containerClassName: PropTypes.string,
};

FormCard.defaultProps = {
  containerClassName: '',
};

export default FormCard;
