import PropTypes from 'prop-types';

import { DASHBOARD_ACTIVE_CHART } from '../../core/constants';
import { useDashboard } from '../../store/dashboard/hooks';
import Button from '../buttons/Button';

const ButtonItem = ({ title, active, onClick }) => {
  return (
    <Button
      title={title}
      onClick={onClick}
      className={`
        h-fit 
        border-0 
        !p-0 
        text-lg 
        !shadow-none 
        focus:!ring-0 
        focus:!ring-offset-0 
        enabled:hover:!text-primary-600 
        enabled:focus:!text-primary-600 
        enabled:active:!text-primary-800 
        ${active ? 'font-semibold text-primary-700' : 'font-normal !text-gray-800'}
      `}
    />
  );
};

ButtonItem.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const ButtonGroup = () => {
  const { activeChart, setActiveChart } = useDashboard();

  return (
    <div className="inline-flex space-x-6">
      <ButtonItem
        title="Donations, USD"
        active={activeChart === DASHBOARD_ACTIVE_CHART.donations_usd}
        onClick={() => setActiveChart(DASHBOARD_ACTIVE_CHART.donations_usd)}
      />
      <ButtonItem
        title="Donations, #"
        active={activeChart === DASHBOARD_ACTIVE_CHART.donations_number}
        onClick={() => setActiveChart(DASHBOARD_ACTIVE_CHART.donations_number)}
      />
      <ButtonItem
        title="Emails Sent"
        active={activeChart === DASHBOARD_ACTIVE_CHART.emails_sent}
        onClick={() => setActiveChart(DASHBOARD_ACTIVE_CHART.emails_sent)}
      />
    </div>
  );
};

export default ButtonGroup;
