import PropTypes from 'prop-types';

import Input from './Input';
import HelperText from './HelperText';

const getHeightClassName = (size) => {
  if (size === 'xl') return 'h-[52px]';
  if (size === 'lg') return 'h-12';
  return 'h-10';
};

const EmailWithDomainInput = ({
  id,
  label,
  value,
  onChange,
  authenticatedDomain,
  error,
  placeholder,
  disabled,
  required,
  containerClassName,
  helperText,
  size,
}) => {
  return (
    <div className={`${containerClassName}`}>
      <div className="flex items-end">
        <Input
          type="email"
          id={id}
          label={label}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={!authenticatedDomain || disabled}
          error={!!error}
          containerClassName="flex-1"
          inputClassName="rounded-none rounded-l-lg"
          required={required}
          size={size}
        />
        <span
          className={`
            ${getHeightClassName(size)} 
            inline-flex 
            items-center 
            rounded-r-md 
            border 
            border-l-0 
            border-gray-100 
            bg-gray-10 
            px-3 
            text-base 
            text-gray-950
          `}
        >
          @{authenticatedDomain ? authenticatedDomain : 'yourverifieddomain.com'}
        </span>
      </div>
      {!!helperText && <HelperText message={helperText} error={error} containerClassName="mt-1.5" />}
    </div>
  );
};

EmailWithDomainInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  authenticatedDomain: PropTypes.string.isRequired,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  containerClassName: PropTypes.string,
  helperText: PropTypes.string,
  size: PropTypes.oneOf(['md', 'lg', 'xl']),
};

EmailWithDomainInput.defaultProps = {
  containerClassName: '',
  required: false,
  size: 'md',
};

export default EmailWithDomainInput;
