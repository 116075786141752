import PropTypes from 'prop-types';

import Button from '../components/buttons/Button';

const FallbackComponent = ({ resetError }) => {
  return (
    <div className="relative flex min-h-screen w-full flex-col items-center justify-center">
      <h1 className="m-2 text-3xl font-bold dark:text-white-100">An error has occurred!</h1>
      <p className="text-base text-gray-700">
        We are currently having some issues, please get in touch with support if this keeps happening.
      </p>
      <Button title="Reload Page" color="primary" onClick={resetError} className="mt-10" />
    </div>
  );
};

FallbackComponent.propTypes = {
  resetError: PropTypes.func.isRequired,
};

export default FallbackComponent;
