import { useSelector, shallowEqual } from 'react-redux';

import useActionCreator from '../../hooks/useActionCreator';
import { setError, setSuccess, getReports } from './actions';

export const useReports = () => {
  const reportsState = useSelector((state) => state.reports, shallowEqual);

  return {
    ...reportsState,
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    getReports: useActionCreator(getReports),
  };
};
