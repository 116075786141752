import { IconCopy } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { Button } from 'ui';

const CopyBox = ({ pageUrl }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(pageUrl);
  };

  return (
    <div
      className="
        flex
        w-fit
        items-center
        justify-between
        space-x-6
        rounded-lg
        bg-gray-10
        px-4
        py-2.5
        text-base 
      "
    >
      <p className="flex-1 text-base text-gray-950">{pageUrl}</p>

      <Button
        title="Copy"
        size="sm"
        onClick={handleCopy}
        className="
          !border-0 
          !bg-gray-10 
          !p-0 
          text-sm 
          !text-gray-800 
          !shadow-none
          focus:!ring-0 
          focus:!ring-offset-0
          enabled:hover:!text-gray-600
          enabled:focus:!text-gray-600
          enabled:active:!text-gray-800
        "
        LeftIcon={IconCopy}
        iconProps={{ size: 16 }}
      />
    </div>
  );
};

CopyBox.propTypes = {
  pageUrl: PropTypes.string.isRequired,
};

export default CopyBox;
