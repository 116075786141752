import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import appSettings from '../../app-settings';
import { getNetworkError } from '../../core/utils';

const DOMAINS_URL = `${appSettings.baseUrl}/domains`;

const SET_ERROR = 'domains/SET_ERROR';
const SET_SUCCESS = 'domains/SET_SUCCESS';
const GET_DOMAINS = 'domains/GET_DOMAINS';
const ADD_DOMAIN = 'domains/ADD_DOMAIN';
const VALIDATE_DOMAIN = 'domains/VALIDATE_DOMAIN';
const DELETE_DOMAIN = 'domains/DELETE_DOMAIN';

export const setError = createAction(SET_ERROR);

export const setSuccess = createAction(SET_SUCCESS);

export const getDomains = createAsyncThunk(GET_DOMAINS, async () => {
  try {
    const res = await axios.get(DOMAINS_URL);
    return res.data ?? [];
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const addDomain = createAsyncThunk(ADD_DOMAIN, async (domainName) => {
  try {
    const response = await axios.post(DOMAINS_URL, { domain: domainName });
    return response.data;
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const validateDomain = createAsyncThunk(VALIDATE_DOMAIN, async (domainId) => {
  try {
    const response = await axios.post(`${DOMAINS_URL}/${domainId}/validate`);
    return response.data;
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const deleteDomain = createAsyncThunk(DELETE_DOMAIN, async (domainId) => {
  try {
    await axios.delete(`${DOMAINS_URL}/${domainId}/delete`);
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});
