import axios from 'axios';
import { Buffer } from 'buffer';

export const getNetworkError = (error) => {
  return error?.response?.data?.message ?? error?.message ?? 'Something went wrong!';
};

export const uploadImage = async (presignedUrl, base64Image) => {
  const mimeType = base64Image.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];

  const buffer = Buffer.from(base64Image.replace(/^data:image\/\w+;base64,/, ''), 'base64');

  await axios.put(presignedUrl, buffer, {
    headers: {
      'Content-Type': mimeType,
      'Content-Encoding': 'base64',
      Authorization: '',
    },
  });
};

export const uploadFile = async (presignedUrl, arrayBuffer, type, config = {}) => {
  await axios.put(presignedUrl, arrayBuffer, {
    headers: {
      'Content-Type': type,
      Authorization: '',
    },
    ...config,
  });
};
