import PropTypes from 'prop-types';

import { RECOMMENDED_TASK_STATUS, RECOMMENDED_TASK_STATUS_LABELS } from '../../core/constants';

const getContainerColorClassNames = (status) => {
  switch (status) {
    case RECOMMENDED_TASK_STATUS.completed:
      return 'border-green-100 bg-green-50 text-green-500';
    case RECOMMENDED_TASK_STATUS.expired:
      return 'border-red-100 bg-red-50 text-red-500';
    default:
      // pending
      return 'border-orange-200 bg-orange-50 text-orange-700';
  }
};

const StatusLabel = (props) => {
  const { status, label, containerClassName } = props;

  return (
    <div className={`w-fit rounded border px-1.5 py-0.5 ${getContainerColorClassNames(status)} ${containerClassName}`}>
      <p className="truncate text-caption1 font-semibold uppercase">
        {label || RECOMMENDED_TASK_STATUS_LABELS[status]}
      </p>
    </div>
  );
};

StatusLabel.propTypes = {
  status: PropTypes.string.isRequired,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
};

StatusLabel.defaultProps = {
  label: '',
  containerClassName: '',
};

export default StatusLabel;
