import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storageSession,
  whitelist: ['campaigns', 'account'],
  stateReconciler: autoMergeLevel2,
  migrate: (state) => {
    // console.log('Migration Running!');
    return Promise.resolve(state);
  },
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureAppStore = (preloadedState) => {
  const devMiddlewares = [];

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(devMiddlewares),
    preloadedState,
    enhancers: [],
  });

  const persistor = persistStore(store);

  return { store, persistor };
};

export const { store, persistor } = configureAppStore();
