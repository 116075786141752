import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  setError,
  setSuccess,
  getDonations,
  refundDonation,
  resendReceipt,
  cancelSubscription,
  updateDonations,
  updateFilters,
  resetFilters,
} from './actions';

const initialState = {
  loading: false,
  error: false,
  success: false,
  donations: [],
  filters: {
    startDate: '',
    endDate: '',
    minAmount: 0,
    maxAmount: 0,
    type: '',
    status: '',
    campaignIds: [],
    searchTerm: '',
  },
};

const donationsStore = createSlice({
  name: 'donations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(updateDonations, (state, { payload }) => {
      state.donations = payload;
    });

    builder.addCase(updateFilters, (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    });

    builder.addCase(resetFilters, (state) => {
      state.filters = initialState.filters;
    });

    builder.addCase(getDonations.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.donations = payload;
    });

    builder.addMatcher(
      isAnyOf(refundDonation.fulfilled, resendReceipt.fulfilled, cancelSubscription.fulfilled),
      (state) => {
        state.loading = false;
      },
    );

    builder.addMatcher(
      isAnyOf(getDonations.pending, refundDonation.pending, resendReceipt.pending, cancelSubscription.pending),
      (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      },
    );

    builder.addMatcher(
      isAnyOf(getDonations.rejected, refundDonation.rejected, resendReceipt.rejected, cancelSubscription.rejected),
      (state, { error }) => {
        state.loading = false;
        state.error = error.message || true;
      },
    );
  },
});

export default donationsStore.reducer;
