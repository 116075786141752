import axios from 'axios';

import appSettings from '../app-settings';

const API_URL = `${appSettings.baseUrl}/recommended-tasks`;

export const getRecommendedTasks = async (queryParams) => {
  let url = API_URL;
  if (queryParams) {
    url += `?${queryParams}`;
  }
  const response = await axios.get(url);
  return response.data;
};

export const markRecommendedTasksAsSeen = async (taskIds) => {
  await axios.patch(`${API_URL}/seen`, { taskIds });
};
