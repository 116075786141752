import { IconTrendingUp, IconTrendingDown, IconLineDashed } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import { TREND_DIRECTIONS } from '../../core/constants';

const StatsWidget = ({ title, totalAmount, trendDirection, trendAmount, trendLabel, dataSections }) => {
  const renderTrendIcon = () => {
    if (trendDirection === 'up') {
      return <IconTrendingUp size={16} className="text-green-500" />;
    }
    if (trendDirection === 'down') {
      return <IconTrendingDown size={16} className="text-red-500" />;
    }
    return <IconLineDashed size={16} className="text-gray-500" />;
  };

  const getTrendLabelClassColor = () => {
    if (trendDirection === 'up') {
      return 'text-green-500';
    }
    if (trendDirection === 'down') {
      return 'text-red-500';
    }
    return 'text-gray-500';
  };

  return (
    <div className="space-y-3 rounded-xl bg-white-100 px-6 py-5">
      <div className="space-y-2">
        <p className="text-sm uppercase">{title}</p>
        <h3 className="text-h3 font-semibold">{totalAmount}</h3>
        <div className="flex items-center space-x-1.5">
          <div className="flex items-center space-x-0.5">
            {renderTrendIcon()}
            <p className={`text-sm font-semibold ${getTrendLabelClassColor()}`}>{trendAmount}</p>
          </div>
          <p className="text-sm text-gray-600">{trendLabel}</p>
        </div>
      </div>
      <hr className="border-gray-50" />
      <div className="space-y-0.5">
        {dataSections.map(({ label, value }) => (
          <div key={label} className="flex items-center justify-between">
            <p className="text-sm text-gray-600">{label}</p>
            <p className="text-sm">{value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

StatsWidget.propTypes = {
  title: PropTypes.string.isRequired,
  totalAmount: PropTypes.string.isRequired,
  trendDirection: PropTypes.oneOf(Object.values(TREND_DIRECTIONS)).isRequired,
  trendAmount: PropTypes.string.isRequired,
  trendLabel: PropTypes.string.isRequired,
  dataSections: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.any.isRequired }),
  ).isRequired,
};

export default StatsWidget;
