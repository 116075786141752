import PropTypes from 'prop-types';

const RadioButton = ({ id, label, value, onChange, checked, disabled, containerClassName, inputClassName }) => {
  return (
    <div className={`flex items-center ${containerClassName}`}>
      <input
        id={id}
        name={id}
        type="radio"
        value={value}
        onChange={() => onChange(value)}
        className={`
          size-4
          min-w-[16px]
          cursor-auto
          border 
          border-gray-200 
          bg-white-100
          text-primary-700
          focus:outline-none
          focus:ring-2
          focus:ring-primary-300
          focus:ring-offset-1
          disabled:cursor-not-allowed
          disabled:bg-gray-10
          ${inputClassName}
        `}
        checked={checked}
        disabled={disabled}
      />
      <label htmlFor={id} className="ml-2 text-base text-gray-900 dark:text-gray-300">
        {label}
      </label>
    </div>
  );
};

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  containerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
};

RadioButton.defaultProps = {
  disabled: false,
  containerClassName: '',
  inputClassName: '',
};

export default RadioButton;
