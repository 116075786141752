import PropTypes from 'prop-types';
import { donationTypeLabels } from '../../core/constants';

const DonationType = ({ type, paymentInfo }) => {
  return paymentInfo.subscription ? (
    <p>
      {donationTypeLabels[type]} - <span className="uppercase">{paymentInfo.subscription.status}</span>
    </p>
  ) : (
    <span>{donationTypeLabels[type]}</span>
  );
};

DonationType.propTypes = {
  type: PropTypes.string.isRequired,
  paymentInfo: PropTypes.shape({ subscription: PropTypes.shape({ status: PropTypes.string.isRequired }) }).isRequired,
};

export default DonationType;
