import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { TRANSACTIONAL_EMAILS } from '../../core/constants';
import { getAccount } from '../account/actions';
import {
  setError,
  setSuccess,
  updateTransactionalEmail,
  getTransactionalEmails,
  saveTransactionalEmails,
} from './actions';

const placeholderImage = 'https://via.placeholder.com/200x90/ffffff?text=';

const initialEmailState = {
  send: false,
  subject: 'Thank you for your support!',
  image: placeholderImage,
  heading: 'Thank you for your support!',
  text: '<p>Thank you {{FirstName}},</p><p><br></p><p>All of us at {{OrganizationName}} want to express our deepest appreciation for your recent donation. Our organization relies on the support of donors like you.</p><p><br></p><p>-Your Friends at {{OrganizationName}}</p>',
};

const initialOneTimeFailedState = {
  send: false,
  subject: 'Sorry, your donation did not go through.',
  image: placeholderImage,
  heading: 'Sorry, your donation did not go through.',
  text: '<p>Hi {{FirstName}},</p><p><br></p><p>You attempted to donate ${{DonationAmount}} to us at {{OrganizationName}}. We sincerely appreciate it. Unfortunately, there was an error in processing the donation. Could you please try again?</p><p><br></p><p>Thank you for your support!</p>',
};

const initialRecurringFailedState = {
  send: false,
  subject: "We couldn't process your recurring donation.",
  image: placeholderImage,
  heading: "We couldn't process your recurring donation.",
  text: "<p>Hi {{FirstName}},</p><p><br></p><p>We're reaching out to let you know that your recurring donation did not go through. This typically happens when you card expires or your card number changes for some reason.</p><p><br></p><p>Your contribution of ${{DonationAmount}} is extremely important to us at {{OrganizationName}} and we're hoping that you will restart your recurring gift with updated payment information. To update your information, please click the button below and set up a new monthly donation of ${{DonationAmount}}.</p><p><br></p><p>Thank you for your support!</p>",
};

const initialAbandonedCartState = {
  send: false,
  subject: 'Your kindness can change lives - Complete your donation today',
  image: placeholderImage,
  heading: 'Your kindness can change lives - Complete your donation today',
  text: "<p>Hello {{FirstName | Friend}},</p><p><br></p><p>We noticed that you started making a donation to us at {{OrganizationName}} and we wanted to reach out to you personally. Your support means the world to us, and we're sincerely grateful for your commitment.</p><p><br></p><p><strong>It's individuals like you who make it possible for us to continue our vital work..</strong></p><p><br></p><p>We kindly encourage you to take a moment today to finalize your generous gift. Simply click the link below to return and complete your donation.</p><p><br></p><p>Thank you for your compassion and support.<br>-{{OrganizationName}}</p>",
};

const initialAnnualDonationsReceiptState = {
  send: false,
  subject: 'Your year end giving report from {{OrganizationName}}',
  image: 'https://via.placeholder.com/200x90/ffffff?text=',
  heading: 'Thank you for your generous support this year!',
  text: '<p>Thank you {{FirstName}},</p><p><br></p><p>We at {{OrganizationName}} thank you for your essential donations this year.</p><p>Please find your consolidated annual giving report in the link below.</p><p><br></p><p>-Your Friends at {{OrganizationName}}</p>',
};

const initialRecurringInstallmentCancelledState = {
  send: false,
  subject: 'Recurring Donation Cancelled',
  image: placeholderImage,
  heading: 'Recurring Donation Cancelled',
  text: '<p>Hi {{FirstName}},</p><p><br></p><p>Your recurring donation of ${{DonationAmount}} to us at {{OrganizationName}} has been cancelled. We have greatly appreciate the support you gave.</p><p><br></p><p>-Your Friends at {{OrganizationName}}</p>',
};

const initialStateEmails = {
  [TRANSACTIONAL_EMAILS.oneTimeReceipt]: { ...initialEmailState },
  [TRANSACTIONAL_EMAILS.recurringReceipt]: { ...initialEmailState },
  [TRANSACTIONAL_EMAILS.recurringInstallmentReceipt]: { ...initialEmailState },
  [TRANSACTIONAL_EMAILS.oneTimeFailed]: { ...initialOneTimeFailedState },
  [TRANSACTIONAL_EMAILS.recurringInstallmentFailed]: { ...initialRecurringFailedState },
  [TRANSACTIONAL_EMAILS.recurringInstallmentCancelled]: { ...initialRecurringInstallmentCancelledState },
  [TRANSACTIONAL_EMAILS.abandonedCart]: { ...initialAbandonedCartState },
  [TRANSACTIONAL_EMAILS.annualDonationsReceipt]: { ...initialAnnualDonationsReceiptState },
};

const initialState = {
  loading: false,
  error: false,
  success: false,
  templatesRenderKey: Date.now().toString(),
  preferencesRenderKey: (Date.now() * 2).toString(),
  emails: { ...initialStateEmails },
  edits: { ...initialStateEmails },
};

const transactionalStore = createSlice({
  name: 'transactional',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(updateTransactionalEmail, (state, { payload }) => {
      const { email, ...update } = payload;
      state.edits[email] = { ...state.edits[email], ...update };
    });

    builder.addCase(getTransactionalEmails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.emails = { ...state.emails, ...payload };
      state.edits = { ...state.emails, ...payload };
      state.templatesRenderKey = Date.now().toString();
    });

    builder.addCase(saveTransactionalEmails.fulfilled, (state) => {
      state.loading = false;
      state.success = 'Changes successfully saved!';
    });

    builder.addCase(getAccount.fulfilled, (state) => {
      state.preferencesRenderKey = (Date.now() * 2).toString();
    });

    builder.addMatcher(isAnyOf(getTransactionalEmails.pending, saveTransactionalEmails.pending), (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addMatcher(
      isAnyOf(getTransactionalEmails.rejected, saveTransactionalEmails.rejected),
      (state, { error }) => {
        state.loading = false;
        state.error = error.message || true;
      },
    );
  },
});

export default transactionalStore.reducer;
