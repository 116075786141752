import { IconAlertCircle } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import CustomDialog from '../modals/CustomDialog';
import Button from '../buttons/Button';

const ConfirmDeleteModal = ({ open, onClose, onDelete, loading }) => {
  return (
    <CustomDialog open={open} onClose={onClose} title="Delete Contact(s)" Icon={IconAlertCircle}>
      <div className="flex items-center justify-center">
        <p className="mb-6 w-4/5 text-center text-lg font-normal text-gray-900">
          Are you sure you want to delete the selected contacts?
        </p>
      </div>

      <div className="flex w-full justify-around">
        <Button
          title={loading ? 'Loading...' : "Yes, I'm sure"}
          color="destructive"
          disabled={loading}
          onClick={onDelete}
          loading={loading}
        />

        <Button title="No, cancel" onClick={onClose} disabled={loading} />
      </div>
    </CustomDialog>
  );
};

ConfirmDeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ConfirmDeleteModal;
