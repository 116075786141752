import PropTypes from 'prop-types';
import { Button } from 'ui';

const getWidthClassName = (number) => {
  switch (number) {
    case 3:
      return 'w-1/3';
    case 4:
      return 'w-1/4';
    case 5:
      return 'w-1/5';
    default:
      // 2
      return 'w-1/2';
  }
};

const getTranslateClassName = (index) => {
  switch (index) {
    case 1:
      return 'translate-x-full';
    case 2:
      return 'translate-x-[200%]';
    case 3:
      return 'translate-x-[300%]';
    case 4:
      return 'translate-x-[400%]';
    default:
      // index = 0
      return 'translate-x-0';
  }
};

const getButtonClassName = (selected, color) => {
  if (selected) {
    if (color === 'primary') {
      return `
      text-white-100
      enabled:hover:text-white-88
      enabled:focus:text-white-88
      enabled:active:text-white-72
      `;
    }
    return 'text-primary-700';
  }
  return 'text-gray-600';
};

const SegmentControl = ({
  segments,
  selectedSegment,
  onSelect,
  segmentWidth,
  segmentHeight,
  containerClassName,
  buttonSize,
  segmentColor,
  disabled,
}) => {
  return (
    <div
      className={`w-fit rounded-[10px] bg-gray-10 p-0.5 ${containerClassName}`}
      style={{ height: segmentHeight + 4 }}
    >
      <div className="relative flex items-center">
        {/* white sliding segment block */}
        <div
          className={`
            absolute 
            inset-y-0 
            left-0 
            ${getWidthClassName(segments.length)} 
            ${segmentColor === 'default' ? 'bg-white-100' : 'bg-primary-700'}
            rounded-lg 
            shadow-custom-sm 
            transition-all 
            duration-200 
            ease-in-out
            ${segments
              .map(({ segment }, index) => `${selectedSegment === segment ? getTranslateClassName(index) : ''}`)
              .join(' ')}
          `}
          style={{ width: segmentWidth, height: segmentHeight }}
        />

        {/* clickable buttons */}
        {segments.map(({ segment, Icon, label }) => (
          <div
            key={segment}
            className={`${getWidthClassName(segments.length)}`}
            style={{ width: segmentWidth, height: segmentHeight }}
          >
            <Button
              title={label || segment}
              onClick={() => onSelect(segment)}
              className={`
                relative
                w-full 
                font-semibold 
                capitalize 
                !shadow-none 
                ring-offset-gray-10 
                ${getButtonClassName(selectedSegment === segment, segmentColor)}
              `}
              variant="outlined"
              LeftIcon={Icon}
              disabled={disabled}
              size={buttonSize}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

SegmentControl.propTypes = {
  // Segments: Min 2, Max 5
  segments: PropTypes.arrayOf(
    PropTypes.shape({ segment: PropTypes.string.isRequired, Icon: PropTypes.object, label: PropTypes.string }),
  ).isRequired,
  selectedSegment: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  segmentWidth: PropTypes.number,
  segmentHeight: PropTypes.number,
  containerClassName: PropTypes.string,
  buttonSize: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  segmentColor: PropTypes.oneOf(['default', 'primary']),
  disabled: PropTypes.bool,
};

SegmentControl.defaultProps = {
  segmentWidth: 80,
  segmentHeight: 40,
  containerClassName: '',
  buttonSize: 'md',
  segmentColor: 'default',
  disabled: false,
};

export default SegmentControl;
