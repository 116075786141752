import { RECOMMENDED_TASK_STATUS } from '../constants';

export const getTaskStatusAndCampaignCountByDelta = (task, delta) => {
  let status = task.status;
  let campaignsCount = Math.max(0, (task.campaignsCount || 0) + delta);

  if (campaignsCount >= 2) {
    status = RECOMMENDED_TASK_STATUS.completed;
  } else {
    status = RECOMMENDED_TASK_STATUS.pending;
  }

  return { status, campaignsCount };
};
