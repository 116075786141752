import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import useActionCreator from '../../hooks/useActionCreator';
import {
  setError,
  setSuccess,
  getDonationsData,
  setActiveChart,
  setCampaignId,
  setDateRange,
  resetDateRange,
  selectCampaignId,
} from './actions';

export const useDashboard = () => {
  const loading = useSelector((state) => state.dashboard.loading);
  const error = useSelector((state) => state.dashboard.error);
  const success = useSelector((state) => state.dashboard.success);
  const activeChart = useSelector((state) => state.dashboard.activeChart);
  const campaignId = useSelector((state) => state.dashboard.campaignId);
  const startDate = useSelector((state) => state.dashboard.startDate);
  const endDate = useSelector((state) => state.dashboard.endDate);
  const donationsData = useSelector((state) => state.dashboard.donationsData, shallowEqual);
  const reportsData = useSelector((state) => state.reports.reports, shallowEqual);

  const donations = useMemo(() => {
    if (!campaignId) return donationsData;
    return donationsData.filter((d) => d.campaignId === campaignId);
  }, [donationsData, campaignId]);

  const reports = useMemo(() => {
    if (!campaignId) return reportsData;
    return reportsData.filter((d) => d.campaignId === campaignId);
  }, [reportsData, campaignId]);

  return {
    loading,
    error,
    success,
    activeChart,
    campaignId,
    donations,
    reports,
    startDate,
    endDate,
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    getDonationsData: useActionCreator(getDonationsData),
    setActiveChart: useActionCreator(setActiveChart),
    setCampaignId: useActionCreator(setCampaignId),
    setDateRange: useActionCreator(setDateRange),
    resetDateRange: useActionCreator(resetDateRange),
    selectCampaignId: useActionCreator(selectCampaignId),
  };
};
