import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import appSettings from '../../app-settings';

const REPORTS_URL = `${appSettings.baseUrl}/reports`;

const SET_ERROR = 'reports/SET_ERROR';
const SET_SUCCESS = 'reports/SET_SUCCESS';
const GET_REPORTS = 'reports/GET_REPORTS';

export const setError = createAction(SET_ERROR);

export const setSuccess = createAction(SET_SUCCESS);

export const getReports = createAsyncThunk(GET_REPORTS, async () => {
  const res = await axios.get(REPORTS_URL);
  return res.data;
});
