import { useCallback, useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { IconUpload } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import Loader from '../loader/Loader';

const FileDrop = ({
  loading,
  onChange,
  allowedFileTypes,
  acceptedFileExtension,
  containerClassName,
  disabled,
  fileError,
  importFile,
  shortDescription,
  multiple,
  maxFileSize,
}) => {
  const [error, setError] = useState(false);
  const [file, setFile] = useState(importFile);

  useEffect(() => {
    setError(fileError);
  }, [fileError]);

  const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
    setError(false);
    setFile(acceptedFiles[0] || fileRejections[0] || null);
    const acceptedFile = acceptedFiles[0];
    if (fileRejections.length > 0 || (!!acceptedFileExtension && !acceptedFile?.name.endsWith(acceptedFileExtension))) {
      setError('Invalid file.');
      return;
    }
    onChange(acceptedFile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDescription = () => {
    if (multiple || !file) {
      return (
        <p className="mt-3 text-center text-base">
          Drag and drop your file here or <span className="text-primary-700">browse</span>
        </p>
      );
    }

    return <p className="mt-3 text-center text-base">{file.name}</p>;
  };

  return (
    <Dropzone
      onDrop={(acceptedFiles, fileRejections) => onDrop(acceptedFiles, fileRejections)}
      maxSize={maxFileSize}
      accept={allowedFileTypes}
      disabled={loading || disabled}
      // TODO: make sure you handle multiple files on the parent component before assigning the multiple prop
      multiple={false}
    >
      {({ getRootProps, getInputProps }) => (
        <section className={`relative h-[200px] w-[400px] ${containerClassName}`}>
          <div className="flex size-full flex-col" style={{ opacity: loading ? 0.5 : 1 }}>
            <div
              {...getRootProps()}
              className={`
                border
                ${error ? 'border-red-500' : 'border-gray-100'}
                flex
                size-full
                flex-col
                items-center
                justify-center
                rounded-lg
                border-dashed
                bg-white-100
                ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
              `}
            >
              <div className="flex w-full flex-1 flex-col items-center justify-center">
                <input {...getInputProps()} id="dropDocumentInput" />

                <IconUpload size={18} className="text-gray-800" />

                {renderDescription()}

                {!!shortDescription && <p className="text-base text-gray-600">{shortDescription}</p>}
              </div>
            </div>

            {error && <p className="mt-1 text-sm italic text-red-500">{error}</p>}
          </div>
          {loading && <Loader style={{ top: '50%', left: 2.5, marginTop: -20 }} />}
        </section>
      )}
    </Dropzone>
  );
};

FileDrop.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  allowedFileTypes: PropTypes.object,
  acceptedFileExtension: PropTypes.string,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  fileError: PropTypes.string,
  importFile: PropTypes.object,
  shortDescription: PropTypes.string,
  multiple: PropTypes.bool,
  maxFileSize: PropTypes.number,
};

FileDrop.defaultProps = {
  loading: false,
  onChange: () => {},
  allowedFileTypes: { 'text/csv': ['.csv'], 'application/vnd.ms-excel': [] },
  acceptedFileExtension: '',
  containerClassName: '',
  disabled: false,
  fileError: '',
  importFile: null,
  shortDescription: '',
  multiple: false,
  maxFileSize: 10 * 10 ** 6, // 10MB -> in bytes
};

export default FileDrop;
