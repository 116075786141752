import { useSelector, shallowEqual } from 'react-redux';

import useActionCreator from '../../hooks/useActionCreator';
import { setError, setSuccess, getStripeAccount, disconnectStripeAccount } from './actions';

export const usePaymentOptions = () => {
  const paymentOptionsState = useSelector((state) => state.paymentOptions, shallowEqual);

  return {
    ...paymentOptionsState,
    isStripeAccountConnected: !!paymentOptionsState.stripeAccount?.email,
    isStripeConnectionComplete:
      !!paymentOptionsState.stripeAccount?.detailsSubmitted &&
      !!paymentOptionsState.stripeAccount?.chargesEnabled &&
      !!paymentOptionsState.stripeAccount?.payoutsEnabled,
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    getStripeAccount: useActionCreator(getStripeAccount),
    disconnectStripeAccount: useActionCreator(disconnectStripeAccount),
  };
};
