import { useSelector, shallowEqual } from 'react-redux';

import useActionCreator from '../../hooks/useActionCreator';
import {
  setError,
  setSuccess,
  resetSegmentDetails,
  updateSegmentDetails,
  getSegments,
  createSegment,
  deleteSegment,
  updateSegment,
} from './actions';

export const useSegments = () => {
  const segmentsState = useSelector((state) => state.segments, shallowEqual);

  return {
    ...segmentsState,
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    resetSegmentDetails: useActionCreator(resetSegmentDetails),
    updateSegmentDetails: useActionCreator(updateSegmentDetails),
    getSegments: useActionCreator(getSegments),
    createSegment: useActionCreator(createSegment),
    deleteSegment: useActionCreator(deleteSegment),
    updateSegment: useActionCreator(updateSegment),
  };
};
