import { useState, useEffect } from 'react';

const getStorageValue = (key, defaultValue) => {
  try {
    const saved = sessionStorage.getItem(key);
    return saved ? JSON.parse(saved) : defaultValue;
  } catch (error) {
    console.warn(`Error reading sessionStorage key “${key}”:`, error);
    return defaultValue;
  }
};

const setStorageValue = (key, value) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.warn(`Error setting sessionStorage key “${key}”:`, error);
  }
};

const useSessionStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => getStorageValue(key, defaultValue));

  useEffect(() => {
    setStorageValue(key, value);
  }, [key, value]);

  return [value, setValue];
};

export default useSessionStorage;
