import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import mergeWith from 'lodash.mergewith';

import {
  setError,
  setSuccess,
  getAccount,
  updateAccountInfo,
  updateEmailPreferences,
  saveEmailPreferences,
  setAccountDocument,
  setOnboardingComplete,
  setOnboardingCall,
  setAccountMfa,
  setUserRole,
} from './actions';
import { PRIMARY_BASE_COLOR, donationTypes } from '../../core/constants';

export const emailPreferencesInitialValues = {
  fromName: '',
  replyAddress: '',
  primaryColor: PRIMARY_BASE_COLOR,
  bodyBackground: '#ffffff',
  headerColor: '#212121',
  logo: 'https://via.placeholder.com/200x90/ffffff?text=',
  textColor: '#212121',
  footerBackground: '#ffffff',
  footerTextColor: '#212121',
  donateButton: {
    buttonText: 'Donate',
    backgroundColor: '#061c6c',
    textColor: '#ffffff',
    amount: '50',
    type: donationTypes.recurring,
  },
  shareMissionButton: {
    enabled: true,
    buttonText: 'Share our mission',
    backgroundColor: '#1151a5',
    textColor: '#ffffff',
  },
  subscribeButton: {
    enabled: true,
    buttonText: 'Subscribe',
    backgroundColor: '#1151a5',
    textColor: '#ffffff',
  },
  sections: {
    header: {
      templateId: '',
    },
    footer: {
      templateId: '',
    },
  },
};

const accountInitialValues = {
  organizationName: '',
  legalName: '',
  customerSupportEmail: '',
  websiteUrl: '',
  taxId: '',
  legalAddress: '',
  taxDocumentKey: '',
  donationPageSubdomain: '',
  organizationDescription: '',
  missionGeographicFocus: '',
  emailPreferences: { ...emailPreferencesInitialValues },
  onboarded: false,
  onboardingCall: false,
  sandbox: false,
  subscription: null,
  mfaSetting: '',
  userRole: '',
};

const initialState = {
  loading: false,
  error: false,
  success: false,
  account: accountInitialValues,
};

const accountStore = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(updateAccountInfo, (state, { payload }) => {
      state.account = {
        ...accountInitialValues,
        ...payload,
        donationPageSubdomain: state.account.donationPageSubdomain,
        onboarded: state.account.onboarded,
        userRole: state.account.userRole,
      };
    });

    builder.addCase(updateEmailPreferences, (state, { payload }) => {
      state.account.emailPreferences = mergeWith({}, state.account.emailPreferences, payload);
    });

    builder.addCase(setAccountDocument, (state, { payload }) => {
      state.account.taxDocumentKey = payload;
    });

    builder.addCase(setAccountMfa, (state, { payload }) => {
      state.account.mfaSetting = payload;
    });

    builder.addCase(setUserRole, (state, { payload }) => {
      state.account.userRole = payload;
    });

    builder.addCase(getAccount.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.account = {
        ...accountInitialValues,
        ...payload,
        emailPreferences: { ...accountInitialValues.emailPreferences, ...(payload?.emailPreferences ?? {}) },
        userRole: state.account.userRole,
      };
    });

    builder.addCase(saveEmailPreferences.fulfilled, (state) => {
      state.success = 'Email preferences successfully saved!';
      state.loading = false;
    });

    builder.addCase(setOnboardingComplete.fulfilled, (state) => {
      state.loading = false;
      state.account.onboarded = true;
    });

    builder.addCase(setOnboardingCall.fulfilled, (state) => {
      state.loading = false;
      state.account.onboardingCall = true;
    });

    builder.addMatcher(
      isAnyOf(
        getAccount.pending,
        saveEmailPreferences.pending,
        setOnboardingComplete.pending,
        setOnboardingCall.pending,
      ),
      (state) => {
        state.error = false;
        state.success = false;
        state.loading = true;
      },
    );

    builder.addMatcher(
      isAnyOf(
        getAccount.rejected,
        saveEmailPreferences.rejected,
        setOnboardingComplete.rejected,
        setOnboardingCall.rejected,
      ),
      (state, { error }) => {
        state.loading = false;
        state.error = error.message || true;
      },
    );
  },
});

export default accountStore.reducer;
