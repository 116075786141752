import { Dialog } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import IconButton from '../buttons/IconButton';

const CustomDialog = ({ open, onClose, title, children, Icon, containerClassName, titleClassName, closeDisabled }) => {
  const handleClose = () => {
    if (closeDisabled) return;
    onClose();
  };

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={handleClose}
      sx={{ '.MuiPaper-root': { padding: 0, margin: 0, borderRadius: '1rem' } }}
    >
      <div tabIndex="-1" aria-hidden="true" className="items-center justify-center overflow-y-auto overflow-x-hidden">
        <div
          className={`relative flex w-full max-w-xl flex-col overflow-hidden bg-white-100 p-6 text-left align-middle shadow-xl md:p-8 ${containerClassName}`}
        >
          <div className="relative mb-2">
            <h3 className={`text-center text-3xl font-semibold capitalize ${titleClassName}`}>{title}</h3>

            {!closeDisabled && (
              <IconButton
                Icon={<IconX size={18} className="text-gray-500" />}
                srOnly="Close"
                color="transparent"
                onClick={onClose}
                className="absolute -right-4 -top-4 hidden sm:block md:-right-6 md:-top-6"
              />
            )}
          </div>

          {Icon && (
            <div className="mt-2 flex justify-center">
              <Icon size={56} className="mb-4 text-gray-400" />
            </div>
          )}

          {children}
        </div>
      </div>
    </Dialog>
  );
};

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  Icon: PropTypes.elementType,
  containerClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  closeDisabled: PropTypes.bool,
};

CustomDialog.defaultProps = {
  containerClassName: '',
  titleClassName: '',
  closeDisabled: false,
};

export default CustomDialog;
