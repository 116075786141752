import { useState } from 'react';

import Button from '../buttons/Button';
import Input from '../inputs/Input';
import ChoiceInput from './ChoiceInput';
import { useImports } from '../../store/imports/hooks';

const CategorizeStep = () => {
  const [confirmValue, setConfirmValue] = useState('');
  const [confirmError, setConfirmError] = useState(false);
  const {
    newImport: { skipUpdate, optInContacts },
    updateNewImport,
    goBack,
    goNext,
  } = useImports();

  const handleNext = () => {
    if (!skipUpdate && confirmValue !== 'CONFIRM') {
      setConfirmError(true);
      return;
    }
    goNext();
  };

  return (
    <div className="w-full max-w-xl space-y-6 py-3">
      <h1 className="text-3xl font-medium">Categorize your upload</h1>

      <div className="max-w-sm">
        <ChoiceInput
          id="update-contacts"
          label="Are you uploading new contacts or updating existing ones?"
          yesLabel="Uploading new contacts"
          noLabel="Updating existing contacts"
          selected={skipUpdate}
          onChange={(value) => updateNewImport({ skipUpdate: value })}
        />
        {!skipUpdate && (
          <div className="pl-10">
            <p className="mb-2 text-base font-medium">
              WARNING: Updating contacts may cause existing data in your database to be lost.
            </p>
            <Input
              id="confirm-input"
              label="Type CONFIRM to confirm your selection."
              placeholder="CONFIRM"
              inputContainerClassName="w-4/5"
              labelClassName="!font-bold"
              value={confirmValue}
              onChange={(e) => setConfirmValue(e.target.value)}
              error={confirmError}
              helperText={confirmError ? 'Please confirm your selection.' : undefined}
            />
          </div>
        )}

        <ChoiceInput
          id="opt-in-contacts"
          label="Have these contacts opted into receiving emails?"
          selected={optInContacts}
          onChange={(value) => updateNewImport({ optInContacts: value })}
          containerClassName="mt-4"
        />
      </div>

      <div className="flex items-center space-x-3">
        <Button title="Go Back" onClick={goBack} />
        <Button title="Continue" color="primary" onClick={handleNext} />
      </div>
    </div>
  );
};

export default CategorizeStep;
