import { useSelector, shallowEqual } from 'react-redux';

import useActionCreator from '../../hooks/useActionCreator';
import {
  setError,
  setSuccess,
  getAccount,
  updateAccountInfo,
  updateEmailPreferences,
  saveEmailPreferences,
  setAccountDocument,
  setOnboardingComplete,
  setOnboardingCall,
  setAccountMfa,
  setUserRole,
} from './actions';

export const useAccount = () => {
  const accountState = useSelector((state) => state.account, shallowEqual);

  return {
    ...accountState,
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    getAccount: useActionCreator(getAccount),
    updateAccountInfo: useActionCreator(updateAccountInfo),
    updateEmailPreferences: useActionCreator(updateEmailPreferences),
    saveEmailPreferences: useActionCreator(saveEmailPreferences),
    setAccountDocument: useActionCreator(setAccountDocument),
    setOnboardingComplete: useActionCreator(setOnboardingComplete),
    setOnboardingCall: useActionCreator(setOnboardingCall),
    setAccountMfa: useActionCreator(setAccountMfa),
    setUserRole: useActionCreator(setUserRole),
  };
};
