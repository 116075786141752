import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import SubscriptionLayout from './SubscriptionLayout';
import { ONBOARDING_STEPS } from '../../core/constants';

const StartOnboardingStep = ({ setStep }) => {
  return (
    <SubscriptionLayout>
      <div className="relative flex size-full flex-col items-center justify-center space-y-8">
        <div className="max-w-[336px] space-y-8">
          <div className="space-y-4">
            <h1 className="text-h2 font-semibold">Success!</h1>
            <p className="text-lg">
              Your onboarding call has been scheduled. In the meantime, you can continue setting up your account:
            </p>
          </div>
          <Button
            title="Continue Account Setup"
            color="primary"
            size="xl"
            className="w-full"
            onClick={() => setStep(ONBOARDING_STEPS.accountInfo)}
          />
        </div>
      </div>
    </SubscriptionLayout>
  );
};

StartOnboardingStep.propTypes = {
  setStep: PropTypes.func.isRequired,
};

export default StartOnboardingStep;
