import { useNavigate } from 'react-router-dom';

import { useSegments } from '../store/segments/hook';
import AlertMessage from '../components/alerts/AlertMessage';
import Loader from '../components/loader/Loader';
import { parseSegmentFieldName } from '../core/utils';
import Button from '../components/buttons/Button';

const PreviewSegment = () => {
  const navigate = useNavigate();
  const { segmentDetails, loading, error, createSegment, setError, updateSegment } = useSegments();

  const goBack = () => {
    navigate(-1);
  };

  const onSave = async () => {
    try {
      if (segmentDetails.segmentId) {
        await updateSegment({ segmentId: segmentDetails.segmentId, updateData: segmentDetails }).unwrap();
      } else {
        await createSegment().unwrap();
      }
      navigate('/segments');
    } catch (err) {
      // Error handled by redux
    }
  };

  return (
    <div className="w-full">
      <div className="flex flex-col">
        {/* Header Bar */}
        <div className="flex justify-between">
          <h1 className="text-h3 dark:text-white-100">Preview Segment</h1>
          <div>
            <Button title="Edit Segment" onClick={goBack} className="mr-4" />
            <Button title={segmentDetails.segmentId ? 'Update' : 'Save'} color="primary" onClick={onSave} />
          </div>
        </div>
        {/* Name */}
        <div className="mt-6 w-full">
          <div className="justify-left flex flex-col">
            <p className="text-2xl font-bold dark:text-white-100">{segmentDetails.name}</p>
            <p className="mt-4 text-lg">
              Contacts match <span className="font-semibold">{segmentDetails?.match?.toUpperCase()}</span> of the
              following:
            </p>
          </div>
        </div>
        {/* Details */}
        <div className="relative my-2 overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-left text-base text-gray-950 dark:text-gray-400">
            <thead className="bg-white-100 text-sm uppercase text-gray-400 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-4 py-3">
                  Field
                </th>
                <th scope="col" className="px-4 py-3">
                  Condition
                </th>
                <th scope="col" className="px-4 py-3">
                  Value
                </th>
              </tr>
            </thead>
            <tbody>
              {segmentDetails.rules.map(({ field, condition, value }) => (
                <tr
                  key={`${field}-${condition}-${value}`}
                  className="border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                >
                  <td className="px-6 py-4 capitalize">{parseSegmentFieldName(field)}</td>
                  <td className="px-6 py-4">{condition}</td>
                  <td className="px-6 py-4">{value}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {loading && <Loader />}
        </div>
      </div>

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </div>
  );
};

export default PreviewSegment;
