/* eslint-disable react/prop-types */
import { useMemo } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender } from '@tanstack/react-table';
import PropTypes from 'prop-types';

import CustomPagination from '../tables/CustomPagination';
import { formatDate } from '../../core/utils';
import StatusLabel from './StatusLabel';
import { USER_ROLE_TO_LABEL } from '../../core/constants';

const getCognitoAttribute = (user, name) => {
  return user.UserAttributes.find((attribute) => attribute.Name === name)?.Value || '';
};

const UserSeatsTable = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        id: 'email',
        accessorFn: (row) => getCognitoAttribute(row, 'email'),
        header: () => 'Email',
        footer: (props) => props.column.id,
      },
      {
        id: 'name',
        accessorFn: (row) => `${getCognitoAttribute(row, 'given_name')} ${getCognitoAttribute(row, 'family_name')}`,
        header: () => 'Name',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'UserRole',
        header: () => 'Role',
        footer: (props) => props.column.id,
        cell: (info) => USER_ROLE_TO_LABEL[info.getValue()],
      },
      {
        accessorKey: 'PreferredMfaSetting',
        header: () => 'Authentication',
        footer: (props) => props.column.id,
        cell: (info) => info.getValue() || 'Default',
      },
      {
        accessorKey: 'UserStatus',
        header: () => 'Status',
        footer: (props) => props.column.id,
        cell: (info) => <StatusLabel status={info.getValue()} />,
      },
      {
        accessorKey: 'UserCreateDate',
        header: () => 'Created At',
        footer: (props) => props.column.id,
        cell: (info) => formatDate(info.getValue()),
      },
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    pageCount: data.totalPages,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const emptyRows = 5 - data.length;

  return (
    <div className="space-y-6">
      <div className="relative overflow-x-auto sm:rounded-lg">
        <table className="data-table w-full text-left text-base text-gray-950 dark:text-gray-400">
          <thead className="bg-white-100 text-sm uppercase text-gray-400 dark:bg-gray-700 dark:text-gray-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan} className="px-4 py-3">
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className="px-4 py-2">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {emptyRows > 0 &&
              Array(emptyRows)
                .fill('')
                .map((row, index) => (
                  <tr
                    key={`emptyRow-${index}`}
                    className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                  >
                    <td className="px-4 py-2" colSpan={15}></td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <CustomPagination table={table} total={data.length} />
    </div>
  );
};

UserSeatsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UserSeatsTable;
