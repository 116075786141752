import { useState, useMemo, useCallback, useEffect } from 'react';
import * as Sentry from '@sentry/react';

import { DONATIONS_SORT_BY, sortDirections } from '../core/constants';
import {
  getNetworkError,
  sortDataWithDirection,
  downloadDonationsFile,
  filterDonations,
  downloadContactsFile,
  getExportJobFilename,
} from '../core/utils';
import { useDonations } from '../store/donations/hook';
import Loader from '../components/loader/Loader';
import ExportsTable from '../components/exports/ExportsTable';
import AlertMessage from '../components/alerts/AlertMessage';
import Sorters from '../components/tables/Sorters';
import { getExports as getExportsApi } from '../api';

const sortByOptions = [{ label: 'Date', value: 'createdAt' }];

const Exports = () => {
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState(sortDirections.desc);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState('');
  const [exports, setExports] = useState([]);
  const { getDonations } = useDonations();

  const getExports = useCallback(async () => {
    setError(false);
    setLoading(true);
    try {
      const exportJobs = await getExportsApi();
      setExports(exportJobs);
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getExports();
  }, [getExports]);

  const downloadExportFile = useCallback(
    async (exportJob) => {
      try {
        const filename = getExportJobFilename(exportJob);
        if (exportJob.type === 'donations') {
          const donations = await getDonations().unwrap();
          const filteredDonations = filterDonations(
            sortDataWithDirection(donations, DONATIONS_SORT_BY.createdAt, sortDirections.desc),
            {
              ...(exportJob.filters || {}),
              endDate: exportJob.filters?.endDate || new Date(exportJob.createdAt * 1000).toISOString(),
            },
          );
          downloadDonationsFile(filteredDonations, filename);
        } else if (exportJob.type === 'contacts') {
          await downloadContactsFile(exportJob.s3.bucket, exportJob.s3.key, filename);
        }
        setSuccess('Export downloaded successfully!');
      } catch (error) {
        Sentry.captureException(error);
        setError(getNetworkError(error));
      }
    },
    [getDonations],
  );

  const data = useMemo(() => {
    if (sortBy) {
      return sortDataWithDirection(exports, sortBy, sortDirection);
    }
    return exports;
  }, [exports, sortBy, sortDirection]);

  return (
    <div className="w-full space-y-6">
      <div className="flex justify-between space-x-3">
        <h1 className="mb-auto text-h3 dark:text-white-100">Exports</h1>

        <Sorters
          options={sortByOptions}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
        />
      </div>

      <div className="relative">
        <ExportsTable data={data} downloadExportFile={downloadExportFile} />

        {loading && <Loader />}
      </div>

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => setSuccess(false)}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </div>
  );
};

export default Exports;
