import { useState } from 'react';
import { Menu } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import Checkbox from '../inputs/Checkbox';
import { donationStatus } from '../../core/constants';
import StatusLabel from './StatusLabel';

const getButtonClassName = (open, active) => {
  if (open) return 'text-primary-600';
  if (active) return 'text-primary-700 !bg-primary-50 border-primary-200';
  return '';
};

const StatusFilter = ({ initialStatus, onSelect, active }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState(initialStatus);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (selectedStatus) => {
    setStatus((prev) => (prev === selectedStatus ? '' : selectedStatus));
  };

  const handleReset = () => {
    onSelect('');
    setStatus('');
    handleClose();
  };

  const handleApply = () => {
    onSelect(status);
    handleClose();
  };

  return (
    <>
      <div className="flex w-fit">
        <Button
          title="Status"
          RightIcon={open ? IconChevronUp : IconChevronDown}
          onClick={handleClick}
          className={getButtonClassName(open, active)}
        />

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          classes={{ paper: 'w-[180px] !rounded-lg mt-1', list: '!p-0 divide-y divide-gray-50' }}
        >
          <li className="space-y-3 p-4">
            <Checkbox
              id={donationStatus.pending}
              name={donationStatus.pending}
              label={<StatusLabel status={donationStatus.pending} />}
              value={donationStatus.pending}
              onChange={() => handleSelect(donationStatus.pending)}
              checked={status === donationStatus.pending}
            />

            <Checkbox
              id={donationStatus.succeeded}
              name={donationStatus.succeeded}
              label={<StatusLabel status={donationStatus.succeeded} />}
              value={donationStatus.succeeded}
              onChange={() => handleSelect(donationStatus.succeeded)}
              checked={status === donationStatus.succeeded}
            />

            <Checkbox
              id={donationStatus.refunded}
              name={donationStatus.refunded}
              label={<StatusLabel status={donationStatus.refunded} />}
              value={donationStatus.refunded}
              onChange={() => handleSelect(donationStatus.refunded)}
              checked={status === donationStatus.refunded}
            />

            <Checkbox
              id={donationStatus.failure}
              name={donationStatus.failure}
              label={<StatusLabel status={donationStatus.failure} />}
              value={donationStatus.failure}
              onChange={() => handleSelect(donationStatus.failure)}
              checked={status === donationStatus.failure}
            />
          </li>

          <li className="flex items-center justify-between p-4">
            <Button title="Reset" onClick={handleReset} />

            <Button title="Apply" color="primary" onClick={handleApply} />
          </li>
        </Menu>
      </div>
    </>
  );
};

StatusFilter.propTypes = {
  initialStatus: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

StatusFilter.defaultProps = {
  initialStatus: '',
  active: false,
};

export default StatusFilter;
