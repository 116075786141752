import { IconCircleCheck } from '@tabler/icons-react';

import { ReactComponent as Dots } from '../../assets/icons/dots.svg';

const RightContent = () => {
  return (
    <div
      className="relative flex size-full justify-center overflow-hidden bg-primary-700"
      style={{ background: 'linear-gradient(212.62deg, #1151A5 0%, #073479 100%)' }}
    >
      <div className="bg-signup absolute left-0 top-11 h-[711px] w-full" />

      <Dots className="absolute top-4 max-h-[95%] max-w-[85%]" />

      <img
        alt="dashboard preview"
        src="/dashboard-preview.png"
        className="absolute bottom-0 right-0 max-h-[60%] w-[95%] max-w-[790px]"
      />

      <div className="z-[1] mt-[10vh] max-w-[360px] space-y-6 max-[840px]:max-w-[85%]">
        <img className="h-auto w-[150px]" src="/white-logo.svg" alt="logo" />

        <h2 className="text-h2 text-white-100">A comprehensive platform that works with you</h2>

        <ul className="space-y-2 text-lg text-white-100">
          <li className="flex items-center space-x-2.5">
            <IconCircleCheck size={16} className="text-primary-100" />
            <span>Connect with new donors</span>
          </li>
          <li className="flex items-center space-x-2.5">
            <IconCircleCheck size={16} className="text-primary-100" />
            <span>Raise more from existing supporters</span>
          </li>
          <li className="flex items-center space-x-2.5">
            <IconCircleCheck size={16} className="text-primary-100" />
            <span>Personalize outreach to each individual</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default RightContent;
