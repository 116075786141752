import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { subDays, startOfDay, endOfDay } from 'date-fns';

import appSettings from '../../app-settings';
import { getTimeInSeconds, sortDataWithDirection } from '../../core/utils';
import { sortDirections } from '../../core/constants';

const DONATIONS_URL = `${appSettings.baseUrl}/donations`;

const SET_ERROR = 'dashboard/SET_ERROR';
const SET_SUCCESS = 'dashboard/SET_SUCCESS';
const GET_DONATIONS_DATA = 'dashboard/GET_DONATIONS_DATA';
const SET_ACTIVE_CHART = 'dashboard/SET_ACTIVE_CHART';
const SET_CAMPAIGN_ID = 'dashboard/SET_CAMPAIGN_ID';
const SET_DATE_RANGE = 'dashboard/SET_DATE_RANGE';

export const setError = createAction(SET_ERROR);

export const setSuccess = createAction(SET_SUCCESS);

export const getDonationsData = createAsyncThunk(GET_DONATIONS_DATA, async () => {
  const res = await axios.get(`${DONATIONS_URL}?dashboard=true`);
  const orderedData = sortDataWithDirection(res.data ?? [], 'createdAt', sortDirections.desc);
  return orderedData;
});

export const setActiveChart = createAction(SET_ACTIVE_CHART);

export const setCampaignId = createAction(SET_CAMPAIGN_ID);

export const setDateRange = createAction(SET_DATE_RANGE);

export const resetDateRange = () => (dispatch, getState) => {
  const {
    dashboard: { campaignId, donationsData },
  } = getState();

  if (!campaignId) {
    const currentDate = new Date();
    dispatch(
      setDateRange({
        startDate: getTimeInSeconds(startOfDay(subDays(currentDate, 6))),
        endDate: getTimeInSeconds(endOfDay(currentDate)),
      }),
    );
    return;
  }

  const filteredData = donationsData.filter((d) => d.campaignId === campaignId);
  if (!filteredData.length) return;

  const lastDonation = sortDataWithDirection(filteredData, 'createdAt', sortDirections.desc)[0];
  if (!lastDonation) return;

  const lastDate = new Date(lastDonation.createdAt * 1000);
  dispatch(
    setDateRange({
      startDate: getTimeInSeconds(startOfDay(subDays(lastDate, 6))),
      endDate: getTimeInSeconds(endOfDay(lastDate)),
    }),
  );
};

export const selectCampaignId = (campaignId) => (dispatch) => {
  dispatch(setCampaignId(campaignId));
  dispatch(resetDateRange());
};
