import { useSelector, shallowEqual } from 'react-redux';

import useActionCreator from '../../hooks/useActionCreator';
import { setError, setSuccess, getTags, createTag, renameTag, deleteTag } from './actions';

export const useTags = () => {
  const tagsState = useSelector((state) => state.tags, shallowEqual);

  return {
    ...tagsState,
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    getTags: useActionCreator(getTags),
    createTag: useActionCreator(createTag),
    renameTag: useActionCreator(renameTag),
    deleteTag: useActionCreator(deleteTag),
  };
};
