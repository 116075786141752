import { useCallback, useState } from 'react';
import { Dialog } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import * as yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import appSettings from '../../app-settings';
import { getNetworkError } from '../../core/utils';
import Button from '../buttons/Button';
import Input from '../inputs/Input';
import IconButton from '../buttons/IconButton';
import SelectInput from '../inputs/SelectInput';
import { USER_ROLES, USER_ROLE_TO_LABEL } from '../../core/constants';

const USERS_URL = `${appSettings.baseUrl}/accounts/users`;

const formValidation = yup.object({
  email: yup.string().email('Invalid email.').required('Email is required.'),
  firstName: yup.string().required('First name is required.'),
  lastName: yup.string().required('Last name is required.'),
  role: yup.string().required('Role is required.'),
});

const createUser = async (user) => {
  await axios.post(USERS_URL, user);
};

const CreateUserModal = ({ open, onClose, onError, onConfirm }) => {
  const [loading, setLoading] = useState(false);
  const form = useForm({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      role: USER_ROLES.editor,
    },
    validate: yupResolver(formValidation),
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    if (loading) return;
    form.reset();
    onClose();
  }, [form, loading, onClose]);

  const handleCreate = async (values) => {
    onError(false);
    setLoading(true);
    try {
      await createUser(values);
      onConfirm();
      handleClose();
    } catch (err) {
      Sentry.captureException(err);
      onError(getNetworkError(err));
    }
    setLoading(false);
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      sx={{ '.MuiPaper-root': { padding: 0, margin: 0, borderRadius: '12px', width: '100%' } }}
    >
      <div className="space-y-6 p-6">
        <div className="relative flex">
          <h3 className="text-h6 font-semibold">Create User</h3>

          <IconButton
            Icon={<IconX size={20} className="text-gray-400" />}
            srOnly="Close"
            onClick={handleClose}
            color="transparent"
            className="absolute -right-1 -top-1 !p-1"
          />
        </div>

        <p className="text-base">
          An email will be sent to the new user containing a randomly generated temporary password. They will be allowed
          to set their own permanent password after first signing in with the temporary password.
        </p>

        <form noValidate className="space-y-6" onSubmit={form.onSubmit(handleCreate)}>
          <Input
            id="email"
            label="Email Address"
            type="email"
            {...form.getInputProps('email')}
            error={!!form.errors.email}
            helperText={form.errors.email}
            required
            disabled={loading}
            size="xl"
          />

          <Input
            id="firstName"
            label="First Name"
            type="text"
            {...form.getInputProps('firstName')}
            error={!!form.errors.firstName}
            helperText={form.errors.firstName}
            required
            disabled={loading}
            size="xl"
          />

          <Input
            id="lastName"
            label="Last Name"
            type="text"
            {...form.getInputProps('lastName')}
            error={!!form.errors.lastName}
            helperText={form.errors.lastName}
            required
            disabled={loading}
            size="xl"
          />

          <SelectInput
            id="role"
            label="Role"
            {...form.getInputProps('role')}
            options={[
              { value: USER_ROLES.editor, label: USER_ROLE_TO_LABEL[USER_ROLES.editor] },
              { value: USER_ROLES.administrator, label: USER_ROLE_TO_LABEL[USER_ROLES.administrator] },
            ]}
            error={!!form.errors.role}
            helperText={form.errors.role ?? 'Editors cannot make billing changes or add new users.'}
            required
            disabled={loading}
            size="lg"
          />

          <Button
            title="Create User"
            type="submit"
            color="primary"
            className="w-full"
            loading={loading}
            disabled={loading}
          />
        </form>
      </div>
    </Dialog>
  );
};

CreateUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default CreateUserModal;
