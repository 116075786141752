import { useMemo, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import uniqBy from 'lodash.uniqby';

import { PRIMARY_BASE_COLOR, donationTypes } from '../../core/constants';
import { useDashboard } from '../../store/dashboard/hooks';
import { parseAmount, parseDonationAmount } from '../../core/utils';
import useIsMounted from '../../hooks/useIsMounted';
import appSettings from '../../app-settings';
import CustomPieChart from './CustomPieChart';
import Loader from '../loader/Loader';

const CONTACTS_URL = `${appSettings.baseUrl}/contacts`;

const DONOR_SPRING_FILENAME = 'donorspringupload_';

const DonorsWidget = () => {
  const [contactsLoading, setContactsLoading] = useState(false);
  const [contactsCount, setContactsCount] = useState({ total: 0, donorspring: 0, dSProspected: 0 });
  const { donations, loading: donationsLoading, campaignId, reports, setError } = useDashboard();
  const isMounted = useIsMounted();

  const getContactsCount = useCallback(async () => {
    setContactsLoading(true);
    try {
      const [contactsResponse, dsContactsResponse, dSProspectedResponse] = await Promise.all([
        axios.get(`${CONTACTS_URL}/count?unsubscribed=false`),
        axios.get(`${CONTACTS_URL}/count?unsubscribed=false&import_filename=${DONOR_SPRING_FILENAME}`),
        axios.get(`${CONTACTS_URL}/count?import_filename=${DONOR_SPRING_FILENAME}&opted_in=any`),
      ]);
      if (isMounted()) {
        setContactsCount({
          total: contactsResponse.data.count || 0,
          donorspring: dsContactsResponse.data.count || 0,
          dSProspected: dSProspectedResponse.data.count || 0,
        });
      }
    } catch (err) {
      Sentry.captureException(err);
      if (isMounted()) {
        setError('Something went wrong while fetching contacts data!');
      }
    }
    if (isMounted()) {
      setContactsLoading(false);
    }
  }, [isMounted, setError]);

  useEffect(() => {
    getContactsCount();
  }, [getContactsCount]);

  const totalDonationsAmount = useMemo(
    () =>
      donations.reduce((totalAmount, donation) => {
        totalAmount += donation.netAmount; // amount in cents
        return totalAmount;
      }, 0),
    [donations],
  );

  const campaignReport = campaignId ? reports[0] : null;

  const totalContactsCount = (campaignReport ? parseInt(campaignReport.totalContacts, 10) : contactsCount.total) || 0;

  const totalDSContactsCount =
    (campaignReport ? parseInt(campaignReport.donorSpringContacts, 10) : contactsCount.donorspring) || 0;

  const donorsData = useMemo(() => {
    const uniqueDonationsByDonor = uniqBy(donations, 'donorId');

    const recurringDonorsCount = uniqueDonationsByDonor.filter(
      (donation) => donation.type === donationTypes.recurring,
    ).length;

    return [
      { name: 'Recurring Donors', count: recurringDonorsCount, color: PRIMARY_BASE_COLOR },
      { name: 'Non-donors', count: Math.max(totalContactsCount - uniqueDonationsByDonor.length, 0), color: '#B8E6FF' },
      {
        name: 'One-time Donors',
        count: Math.max(uniqueDonationsByDonor.length - recurringDonorsCount, 0),
        color: '#2F95E9',
      },
    ];
  }, [donations, totalContactsCount]);

  const loading = donationsLoading || contactsLoading;

  return (
    <div className="relative flex flex-col space-y-5 rounded-xl bg-white-100 px-6 py-5">
      <div className="space-y-5">
        <h3 className="text-lg font-semibold">Contacts</h3>

        <div className="space-y-3">
          <div className="flex items-center space-x-2 text-base">
            <p className="flex-1">Total Subscribed Contacts</p>
            <p className="font-semibold">{parseAmount(totalContactsCount, 0)}</p>
          </div>

          <div className="flex items-center space-x-2 text-base">
            <p className="flex-1">Subscribed Contacts from DonorSpring</p>
            <p className="font-semibold">{parseAmount(totalDSContactsCount, 0)}</p>
          </div>

          <div className="flex items-center space-x-2 text-base">
            <p className="flex-1">Total Contacts from DonorSpring Prospected</p>
            <p className="font-semibold">{parseAmount(contactsCount.dSProspected, 0)}</p>
          </div>

          <div className="flex items-center space-x-2 text-base">
            <p className="flex-1">Total Raised</p>
            <p className="font-semibold">{parseDonationAmount(totalDonationsAmount / 100)}</p>
          </div>
        </div>
      </div>

      <div className="flex-1 items-center justify-center">
        <div className="grid size-full grid-cols-2">
          <CustomPieChart data={donorsData} totalContacts={totalContactsCount} />

          <div className="flex flex-col items-center justify-center">
            <div className="space-y-4">
              <div className="flex items-center space-x-1.5">
                <div className="size-2 rounded-full bg-primary-700" />
                <p className="text-sm">Recurring Donors</p>
              </div>

              <div className="flex items-center space-x-1.5">
                <div className="size-2 rounded-full bg-primary-500" />
                <p className="text-sm">One-time Donors</p>
              </div>

              <div className="flex items-center space-x-1.5">
                <div className="size-2 rounded-full bg-primary-100" />
                <p className="text-sm">Non-donors</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Loader />}
    </div>
  );
};

export default DonorsWidget;
