import PropTypes from 'prop-types';

import RadioButton from '../inputs/RadioButton';

const ChoiceInput = ({ id, label, selected, onChange, yesLabel, noLabel, containerClassName }) => {
  return (
    <div className={`flex flex-col ${containerClassName}`}>
      <p className="mb-3 text-base font-medium text-gray-900 dark:text-gray-300">{label}</p>

      <div className="space-y-2">
        <RadioButton id={`yes-${id}`} label={yesLabel} value="" checked={selected} onChange={() => onChange(true)} />

        <RadioButton id={`no-${id}`} label={noLabel} value="" checked={!selected} onChange={() => onChange(false)} />
      </div>
    </div>
  );
};

ChoiceInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  containerClassName: PropTypes.string,
};

ChoiceInput.defaultProps = {
  yesLabel: 'Yes',
  noLabel: 'No',
  containerClassName: '',
};

export default ChoiceInput;
