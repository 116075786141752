import { useEffect, useState } from 'react';
import { Menu } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { Button, Checkbox } from 'ui';
import { CONTACT_TYPES, CONTACT_TYPE_LABELS } from './constants';

const ContactTypeFilter = (props) => {
  const { selectedContactType, onSelect } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [contactType, setContactType] = useState(selectedContactType);

  const open = Boolean(anchorEl);

  useEffect(() => {
    // Needed to reconcile the initial state of the dropdown with the current
    // state of the selected contact type ids when the dropdown is closed and reopened.
    // Use case: user toggles local state selection but does not apply the changes.
    if (!open) {
      setContactType(selectedContactType);
    }
  }, [open, selectedContactType]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (type) => {
    setContactType((prev) => (prev === type ? '' : type));
  };

  const handleReset = () => {
    setContactType('');
  };

  const handleApply = () => {
    onSelect(contactType);
    handleClose();
  };

  return (
    <>
      <Button
        title="Contact Type"
        className={selectedContactType ? 'border-primary-200 !bg-primary-50' : ''}
        RightIcon={open ? IconChevronUp : IconChevronDown}
        onClick={handleClick}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: 'w-[209px] !rounded-lg mt-0.5 !overflow-hidden', list: '!p-0' }}
      >
        <ul className="space-y-3 p-4">
          <li>
            <Checkbox
              id={CONTACT_TYPES.dsContacts}
              name={CONTACT_TYPES.dsContacts}
              label={CONTACT_TYPE_LABELS[CONTACT_TYPES.dsContacts]}
              value={CONTACT_TYPES.dsContacts}
              checked={contactType === CONTACT_TYPES.dsContacts}
              onChange={() => handleChange(CONTACT_TYPES.dsContacts)}
            />
          </li>

          <li>
            <Checkbox
              id={CONTACT_TYPES.notDsContacts}
              name={CONTACT_TYPES.notDsContacts}
              label={CONTACT_TYPE_LABELS[CONTACT_TYPES.notDsContacts]}
              value={CONTACT_TYPES.notDsContacts}
              checked={contactType === CONTACT_TYPES.notDsContacts}
              onChange={() => handleChange(CONTACT_TYPES.notDsContacts)}
            />
          </li>
        </ul>

        <li className="flex items-center justify-between border-t border-gray-50 p-4">
          <Button title="Reset" onClick={handleReset} />

          <Button title="Apply" color="primary" onClick={handleApply} />
        </li>
      </Menu>
    </>
  );
};

ContactTypeFilter.propTypes = {
  selectedContactType: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ContactTypeFilter;
