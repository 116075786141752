import { useEffect, useState } from 'react';

import { useTransactional } from '../store/transactional/hooks';
import AlertMessage from '../components/alerts/AlertMessage';
import TransactionalEmails from '../components/transactional/TransactionalEmails';
import EmailPreferences from '../components/transactional/EmailPreferences';
import { useAccount } from '../store/account/hooks';

const Transactional = () => {
  const [activeEmail, setActiveEmail] = useState('');
  const {
    error: transactionalError,
    success: transactionalSuccess,
    templatesRenderKey,
    preferencesRenderKey,
    setError: setTransactionalError,
    setSuccess: setTransactionalSuccess,
    getTransactionalEmails,
  } = useTransactional();
  const {
    error: accountError,
    success: accountSuccess,
    setError: setAccountError,
    setSuccess: setAccountSuccess,
  } = useAccount();

  useEffect(() => {
    getTransactionalEmails();
  }, [getTransactionalEmails]);

  const error = transactionalError || accountError;
  const success = transactionalSuccess || accountSuccess;

  return (
    <div className="w-full flex-auto">
      <div className="mb-6 flex">
        <h1 className="text-h3 dark:text-white-100">Transactional</h1>
      </div>

      <EmailPreferences key={preferencesRenderKey} />

      <TransactionalEmails key={templatesRenderKey} activeEmail={activeEmail} setActiveEmail={setActiveEmail} />

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => {
          setTransactionalSuccess(false);
          setAccountSuccess(false);
        }}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => {
          setTransactionalError(false);
          setAccountError(false);
        }}
        severity="error"
      />
    </div>
  );
};

export default Transactional;
