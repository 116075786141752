import PropTypes from 'prop-types';

import { noop } from '../../core/utils';
import Input from './Input';

const RangeNumberInput = ({ minValue, maxValue, onChangeMinValue, onChangeMaxValue, formatter, className }) => {
  const onMinChange = (event) => {
    const value = Number(event.target.value.replace(/\D/g, ''));
    onChangeMinValue(value);
  };

  const onMaxChange = (event) => {
    const value = Number(event.target.value.replace(/\D/g, ''));
    onChangeMaxValue(value);
  };

  return (
    <>
      <Input
        id="minAmount"
        label="Min amount"
        placeholder="3"
        value={formatter(minValue ?? '')}
        onChange={onMinChange}
        containerClassName={className}
      />

      <Input
        id="maxAmount"
        label="Max amount"
        placeholder="3"
        value={formatter(maxValue ?? '')}
        onChange={onMaxChange}
        containerClassName={className}
      />
    </>
  );
};

RangeNumberInput.propTypes = {
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  onChangeMinValue: PropTypes.func.isRequired,
  onChangeMaxValue: PropTypes.func.isRequired,
  className: PropTypes.string,
  formatter: PropTypes.func,
};

RangeNumberInput.defaultProps = {
  className: '',
  formatter: noop,
};

export default RangeNumberInput;
