import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import Input from '../inputs/Input';
import CustomDialog from '../modals/CustomDialog';

const formValidation = yup.object({
  email: yup.string().email('Invalid email.').required('Email is required.'),
});

const UnsubscribeContactModal = ({ open, onClose, onConfirm, loading }) => {
  const form = useForm({
    initialValues: { email: '' },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });

  const handleClose = () => {
    if (loading) return;
    onClose();
    form.reset();
  };

  const handleConfirm = (values) => {
    onConfirm(values.email);
  };

  return (
    <CustomDialog open={open} onClose={handleClose} title="Unsubscribe Contact" containerClassName="sm:min-w-[448px]">
      <form noValidate className="mt-4 space-y-6" onSubmit={form.onSubmit(handleConfirm)}>
        <Input
          id="contact-email"
          label="Contact email"
          value={form.values.email}
          onChange={(e) => form.setFieldValue('email', e.target.value)}
          error={!!form.errors.email}
          helperText={form.errors.email}
        />

        <div className="mt-8 flex w-full items-center justify-between">
          <Button title="Cancel" onClick={onClose} disabled={loading} />

          <Button title="Confirm" color="primary" type="submit" loading={loading} disabled={loading} />
        </div>
      </form>
    </CustomDialog>
  );
};

UnsubscribeContactModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default UnsubscribeContactModal;
