import PropTypes from 'prop-types';

import CustomBarChart from './CustomBarChart.jsx';

const BarChartCard = ({ name, data, currency, xDataKey, interval, XAxisTick, XAxisTicks, XAxisTickCount }) => {
  return (
    <div className="space-y-4 rounded-xl bg-white-100 p-6">
      <h3 className="text-lg font-semibold">{name}</h3>
      <CustomBarChart
        data={data}
        currency={currency}
        xDataKey={xDataKey}
        interval={interval}
        XAxisTick={XAxisTick}
        XAxisTicks={XAxisTicks}
        XAxisTickCount={XAxisTickCount}
      />
    </div>
  );
};

BarChartCard.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  currency: PropTypes.bool,
  xDataKey: PropTypes.string,
  interval: PropTypes.any,
  XAxisTick: PropTypes.func,
  XAxisTicks: PropTypes.arrayOf(PropTypes.any),
  XAxisTickCount: PropTypes.number,
};

export default BarChartCard;
