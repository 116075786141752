import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import appSettings from '../../app-settings';
import { getNetworkError, uploadFile } from '../../core/utils';
import { enableMocking } from '../../core/mock-axios';

const ACCOUNTS_URL = `${appSettings.baseUrl}/accounts`;

const EMAIL_TEMPLATES_URL = `${appSettings.baseUrl}/email-templates`;

const createEmailImageUploadUrl = async (templateName) => {
  const response = await axios.post(`${EMAIL_TEMPLATES_URL}/images`, { templateName });
  return response.data;
};

const SET_ERROR = 'account/SET_ERROR';
const SET_SUCCESS = 'account/SET_SUCCESS';
const GET_ACCOUNT = 'account/GET_ACCOUNT';
const UPDATE_ACCOUNT_INFO = 'account/UPDATE_ACCOUNT_INFO';
const UPDATE_EMAIL_PREFERENCES = 'account/UPDATE_EMAIL_PREFERENCES';
const SAVE_EMAIL_PREFERENCES = 'account/SAVE_EMAIL_PREFERENCES';
const SET_ACCOUNT_DOCUMENT = 'account/SET_ACCOUNT_DOCUMENT';
const SET_ONBOARDING_COMPLETE = 'account/SET_ONBOARDING_COMPLETE';
const SET_ONBOARDING_CALL = 'account/SET_ONBOARDING_CALL';
const SET_ACCOUNT_MFA = 'account/SET_ACCOUNT_MFA';
const SET_USER_ROLE = 'account/SET_USER_ROLE';

export const setError = createAction(SET_ERROR);

export const setSuccess = createAction(SET_SUCCESS);

export const updateAccountInfo = createAction(UPDATE_ACCOUNT_INFO);

export const updateEmailPreferences = createAction(UPDATE_EMAIL_PREFERENCES);

export const setAccountDocument = createAction(SET_ACCOUNT_DOCUMENT);

export const setAccountMfa = createAction(SET_ACCOUNT_MFA);

export const setUserRole = createAction(SET_USER_ROLE);

export const getAccount = createAsyncThunk(GET_ACCOUNT, async () => {
  try {
    const res = await axios.get(`${ACCOUNTS_URL}/current`);
    const account = res.data;

    if (account?.sandbox) {
      enableMocking();
    }

    return account;
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const saveEmailPreferences = createAsyncThunk(
  SAVE_EMAIL_PREFERENCES,
  async (logoFile, { getState, dispatch }) => {
    try {
      const {
        domains: { authenticatedDomain },
        account: {
          account: { emailPreferences },
        },
      } = getState();

      let logoUrl = emailPreferences?.logo ?? '';

      if (logoFile) {
        const { uploadUrl, s3Key } = await createEmailImageUploadUrl('logo');
        const arrayBuffer = await logoFile.arrayBuffer();
        await uploadFile(uploadUrl, arrayBuffer, logoFile.type);
        logoUrl = `${appSettings.emailImagesDomain}/${s3Key}`;
      }

      const { logo, replyAddress, ...rest } = emailPreferences;
      const replyAddressName = replyAddress.trim();

      const updateData = {
        ...rest,
        replyAddress: replyAddressName
          ? replyAddressName?.includes('@')
            ? replyAddressName
            : `${replyAddressName}@${authenticatedDomain}`
          : '',
      };

      if (logoUrl) updateData.logo = logoUrl;

      await axios.post(`${ACCOUNTS_URL}/current`, {
        emailPreferences: updateData,
      });

      dispatch(getAccount());
    } catch (err) {
      Sentry.captureException(err);
      const errorMessage = getNetworkError(err);
      throw new Error(errorMessage);
    }
  },
);

export const setOnboardingComplete = createAsyncThunk(SET_ONBOARDING_COMPLETE, async () => {
  try {
    await axios.post(`${ACCOUNTS_URL}/onboarding/complete`);
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const setOnboardingCall = createAsyncThunk(SET_ONBOARDING_CALL, async () => {
  try {
    await axios.post(`${ACCOUNTS_URL}/onboarding/call`);
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});
