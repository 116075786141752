import * as yup from 'yup';

yup.addMethod(yup.array, 'uniqueField', function (message, mapper = (a) => a.field) {
  return this.test('uniqueField', message, function (list) {
    const currentList = (list || []).map(mapper);

    const countObj = {};
    for (let index = 0; index < currentList.length; index += 1) {
      const element = currentList[index];

      if (!countObj[element]) countObj[element] = 0;
      countObj[element] += 1;

      if (countObj[element] > 1) {
        return this.createError({ path: `rules.${index}.field`, message });
      }
    }

    return true;
  });
});

export default yup;
