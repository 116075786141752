import PropTypes from 'prop-types';

const EmailStatus = ({ enabled }) => {
  if (enabled) {
    return (
      <div className="w-fit rounded border border-primary-200 bg-primary-50 px-1.5 py-0.5">
        <p className="text-caption1 font-semibold uppercase text-primary-500">Enabled</p>
      </div>
    );
  }
  return (
    <div className="w-fit rounded border border-gray-100 bg-gray-10 px-1.5 py-0.5">
      <p className="text-caption1 font-semibold uppercase text-gray-400">Disabled</p>
    </div>
  );
};

EmailStatus.propTypes = {
  enabled: PropTypes.bool.isRequired,
};

export default EmailStatus;
