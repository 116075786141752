import { lazy, useState, useCallback, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'ui';

import { StepNavBottom } from '../onboarding';
import { usePaymentOptions } from '../../store/payment-options/hooks';
import AlertMessage from '../alerts/AlertMessage';
import AccountStatus from './AccountStatus';

const StripeOnboarding = lazy(() => import('./StripeOnboarding.jsx'));

const ConnectStripe = ({ onboarding, onPrev, onNext }) => {
  const [inProgress, setInProgress] = useState(false);
  const { isStripeAccountConnected, loading, error, success, setError, setSuccess, getStripeAccount } =
    usePaymentOptions();

  const handleExit = useCallback(() => {
    setInProgress(false);
    getStripeAccount();
  }, [getStripeAccount]);

  return (
    <>
      {inProgress ? (
        <Suspense fallback={<Loader />}>
          <StripeOnboarding onExit={handleExit} />
        </Suspense>
      ) : (
        <AccountStatus onboarding={onboarding} onConnect={() => setInProgress(true)} />
      )}

      {onboarding && (
        <StepNavBottom
          onPrev={onPrev}
          onNext={onNext}
          nextDisabled={loading || !isStripeAccountConnected || inProgress}
          prevDisabled={inProgress}
        />
      )}

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => {
          // Only show success message once - update path and remove query params
          window.history.replaceState({}, document.title, '/');
          setSuccess(false);
        }}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </>
  );
};

ConnectStripe.propTypes = {
  onboarding: PropTypes.bool,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
};

ConnectStripe.defaultProps = {
  onboarding: false,
  onPrev: () => {},
  onNext: () => {},
};

export default ConnectStripe;
