import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import appSettings from '../app-settings';
import { getNetworkError, getUserRole, sigInWithEmailAndPassword } from '../core/utils';
import RightContent from '../components/signup/RightContent';
import SignupForm from '../components/signup/SignupForm';
import Loader from '../components/loader/Loader';
import AlertMessage from '../components/alerts/AlertMessage';
import { useAccount } from '../store/account/hooks';

const INVITES_API_URL = `${appSettings.baseUrl}/customer-management/invites`;
const ONBOARDING_USER_API_URL = `${appSettings.baseUrl}/onboarding`;

const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [validInvite, setValidInvite] = useState(false);
  const { inviteId } = useParams();
  const navigate = useNavigate();
  const { setUserRole } = useAccount();

  const authenticateInvite = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${INVITES_API_URL}/${inviteId}`);
      const isValid = response.data.status === 'pending';
      setValidInvite(isValid);
      if (!isValid) throw new Error('Invalid invite');
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }
    setLoading(false);
  }, [inviteId]);

  useEffect(() => {
    if (!inviteId) return;

    authenticateInvite();
  }, [inviteId, authenticateInvite]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setError(false);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await axios.post(`${ONBOARDING_USER_API_URL}/user`, {
        name: values.organizationName,
        taxId: values.taxId,
        address: values.legalAddress,
        email: values.email,
        password: values.password,
        inviteId,
      });

      setSuccess('Account created successfully!');

      await sigInWithEmailAndPassword(values.email, values.password);

      const role = getUserRole();
      setUserRole(role);
      navigate('/');
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }
    setLoading(false);
  };

  return (
    <>
      <div className="flex grow overflow-y-hidden bg-white-100">
        <div className="relative flex-1">
          <SignupForm disabled={!validInvite} onSubmit={handleSubmit} />
          {loading && <Loader />}
        </div>

        <div className="flex-1">
          <RightContent />
        </div>
      </div>

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => setSuccess(false)}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        severity="error"
        onClose={handleClose}
        autoHideDuration={null}
      />
    </>
  );
};

export default SignUp;
