import { useState, useEffect } from 'react';
import { Menu } from '@mui/material';
import { IconColumns3 } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { Button, Checkbox, IndeterminateCheckbox } from 'ui';

import { MAP_FIELD_TO_LABEL } from './constants';

const ColumnsDropdown = (props) => {
  const { fields, setFields } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [updatedFields, setUpdatedFields] = useState(fields);

  const open = Boolean(anchorEl);

  useEffect(() => {
    // Needed to reconcile the initial state of the dropdown with the current
    // state of the fields when the dropdown is closed and reopened.
    // Use case: user toggles local state selection but does not apply the changes.
    if (!open) {
      setUpdatedFields(fields);
    }
  }, [open, fields]);

  const totalCount = Object.keys(fields).length;
  const selectedCount = Object.keys(updatedFields).filter((field) => updatedFields[field]).length;
  const allSelected = selectedCount === totalCount;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputChange = (event) => {
    setUpdatedFields((prevFields) => ({ ...prevFields, [event.target.name]: event.target.checked }));
  };

  const handleSelectAll = () => {
    setUpdatedFields((prevFields) =>
      Object.keys(prevFields).reduce((acc, field) => ({ ...acc, [field]: !allSelected }), {}),
    );
  };

  const handleApply = () => {
    setFields(updatedFields);
    handleClose();
  };

  return (
    <>
      <Button
        title="Columns"
        LeftIcon={IconColumns3}
        onClick={handleClick}
        className={open ? 'text-primary-600' : ''}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{
          paper: 'w-[220px] h-[384px] !rounded-lg mt-0.5 !overflow-hidden',
          list: '!p-0',
        }}
      >
        <li className="px-4 pb-2 pt-4">
          <p className="text-base">
            Selected <span className="font-semibold">{selectedCount}</span> of&nbsp;
            <span className="font-semibold">{totalCount}</span>
          </p>
        </li>

        <ul className="h-[268px] space-y-3 overflow-y-auto p-4 pt-2">
          <li>
            <IndeterminateCheckbox
              id="select-all"
              label="Select all"
              value=""
              checked={allSelected}
              indeterminate={selectedCount > 0 && selectedCount < totalCount}
              onChange={handleSelectAll}
            />
          </li>

          {Object.keys(updatedFields).map((field) => {
            return (
              <li key={field}>
                <Checkbox
                  id={field}
                  name={field}
                  label={MAP_FIELD_TO_LABEL[field]}
                  value={field}
                  checked={updatedFields[field]}
                  onChange={handleInputChange}
                />
              </li>
            );
          })}
        </ul>

        <li className="border-t border-gray-50 p-4">
          <Button
            title="Apply"
            color="primary"
            onClick={handleApply}
            className="w-full"
            disabled={selectedCount === 0}
          />
        </li>
      </Menu>
    </>
  );
};

ColumnsDropdown.propTypes = {
  fields: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
};

export default ColumnsDropdown;
