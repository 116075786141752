import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  getRecommendedTasks,
  getPendingRecommendedTasks,
  updateRecommendedTasks,
  updatePendingRecommendedTasks,
} from './actions';

const initialState = {
  tasks: [],
  pendingTasks: [],
  loading: false,
  error: false,
};

const recommendedTasksStore = createSlice({
  name: 'recommendedTasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateRecommendedTasks, (state, { payload }) => {
      state.tasks = payload;
    });

    builder.addCase(updatePendingRecommendedTasks, (state, { payload }) => {
      state.pendingTasks = payload;
    });

    builder.addCase(getRecommendedTasks.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.tasks = payload;
    });

    builder.addCase(getPendingRecommendedTasks.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.pendingTasks = payload;
    });

    builder.addMatcher(isAnyOf(getRecommendedTasks.rejected), (state, { error }) => {
      state.loading = false;
      state.error = error.message || true;
    });

    builder.addMatcher(isAnyOf(getRecommendedTasks.pending), (state) => {
      state.loading = true;
      state.error = false;
    });
  },
});

export default recommendedTasksStore.reducer;
