import { donationTypeLabels, donationTypes } from '../constants';
import { shouldObscureContacts } from './contacts';
import { getPageUrl } from './pages';

const getCustomSubstitutionTagsFromString = (string) => string.match(/{{(.*?)}}/g);

export const getCustomSubstitutionTagDefaultValues = (string) => {
  const tags = getCustomSubstitutionTagsFromString(string) || [];

  if (tags.length === 0) return {};

  return tags.reduce((prev, tag) => {
    const [tagName, defaultValue] = tag
      .replace(/{{|}}/g, '')
      .split('|')
      .map((item) => item.trim());
    // eslint-disable-next-line no-param-reassign
    if (!prev[tagName]) prev[tagName] = defaultValue ?? '';
    return prev;
  }, {});
};

export const replaceCustomTagsWithSendgridTags = (string) => string.replace(/\s?\|(.*?)}}/g, '}}');

export const substituteTagWithValue = (string, tagName, value) =>
  string.replace(new RegExp(`{{${tagName}}}`, 'g'), value);

export const injectTemplateHtmlSections = (html, sectionTemplates) => {
  const templateDocument = new DOMParser().parseFromString(html, 'text/html');

  if (sectionTemplates.header) {
    const headerDocument = new DOMParser().parseFromString(sectionTemplates.header.html, 'text/html');
    const headerRow = headerDocument.querySelector('.u-row-container');
    const templateFirstRow = templateDocument.querySelector('.u-row-container:first-child');
    if (headerRow && templateFirstRow) {
      templateFirstRow.insertAdjacentElement('beforebegin', headerRow);
    }
  }

  if (sectionTemplates.footer) {
    const footerDocument = new DOMParser().parseFromString(sectionTemplates.footer.html, 'text/html');
    const footerRow = footerDocument.querySelector('.u-row-container');
    const templateLastRow = templateDocument.querySelector('.u-row-container:last-child');
    if (footerRow && templateLastRow) {
      templateLastRow.insertAdjacentElement('afterend', footerRow);
    }
  }

  return templateDocument.documentElement.innerHTML;
};

export const injectTemplateDesignSections = (design, sectionTemplates) => {
  const templateDesign = JSON.parse(design);

  if (sectionTemplates?.header) {
    const headerDesign = JSON.parse(sectionTemplates.header.design);
    const headerRowDesign = headerDesign.body.rows[0];
    templateDesign.body.rows.unshift(headerRowDesign);
  }

  if (sectionTemplates?.footer) {
    const footerDesign = JSON.parse(sectionTemplates.footer.design);
    const footerRowDesign = footerDesign.body.rows[0];
    templateDesign.body.rows.push(footerRowDesign);
  }

  return JSON.stringify(templateDesign);
};

export const replaceTemplateHtmlDonateButtonStyles = (html, { backgroundColor, textColor }) => {
  const templateDocument = new DOMParser().parseFromString(html, 'text/html');
  const donateButtons = templateDocument.querySelectorAll('.v-button');
  donateButtons.forEach((button) => {
    button.style.backgroundColor = backgroundColor;
    button.style.color = textColor;
  });
  return templateDocument.documentElement.innerHTML;
};

export const replaceTemplateDesignDonateButtonStyles = (design, { backgroundColor, textColor }) => {
  const templateDesign = JSON.parse(design);
  templateDesign.body.rows.forEach((row) => {
    row.columns.forEach((column) => {
      column.contents.forEach((content) => {
        if (content.type === 'button') {
          content.values.buttonColors.backgroundColor = backgroundColor;
          content.values.buttonColors.color = textColor;
        }
      });
    });
  });
  return JSON.stringify(templateDesign);
};

export const getLinkTypes = ({ donationPageUrl, pageList, account }) => {
  const [pageUrl, queryParams] = donationPageUrl.split('/?');

  const slugOptions = pageList.map((page) => ({
    value: page.slug === 'default' ? '/' : `/${page.slug}`,
    label: page.name,
  }));

  return [
    {
      name: 'web',
      enabled: !shouldObscureContacts(account),
    },
    {
      name: 'phone',
      enabled: false,
    },
    {
      name: 'email',
      enabled: false,
    },
    {
      name: 'sms',
      enabled: false,
    },
    {
      name: 'static_donation_page_link',
      label: 'Go To Donation Page',
      attrs: {
        href: `${pageUrl}{{slug}}?${queryParams}&amount={{amount}}&type={{type}}`,
        target: '_blank',
      },
      fields: [
        {
          name: 'slug',
          label: 'Donation Page',
          defaultValue: '/',
          inputType: null,
          placeholderText: null,
          options: slugOptions,
        },
        {
          name: 'amount',
          label: 'Amount',
          defaultValue: account.emailPreferences.donateButton.amount,
          inputType: 'number',
          placeholderText: null,
        },
        {
          name: 'type',
          label: 'Type',
          defaultValue: account.emailPreferences.donateButton.type,
          inputType: null,
          placeholderText: null,
          options: [
            { value: donationTypes.recurring, label: donationTypeLabels.recurring },
            { value: donationTypes.oneTime, label: donationTypeLabels[donationTypes.oneTime] },
          ],
        },
      ],
    },
  ];
};

export const getEditorOptions = ({ account, campaignDetails }) => ({
  tools: {
    button: {
      properties: {
        buttonColors: {
          value: {
            color: account.emailPreferences.donateButton.textColor,
            backgroundColor: account.emailPreferences.donateButton.backgroundColor,
          },
        },
        borderRadius: {
          value: '0px',
        },
        text: {
          value: account.emailPreferences.donateButton.buttonText,
        },
        href: {
          value: {
            name: 'static_donation_page_link',
            attrs: {
              href: `${getPageUrl({
                account,
                params: {
                  campaignId: campaignDetails.campaignId,
                  amount: account.emailPreferences.donateButton.amount,
                  type: account.emailPreferences.donateButton.type,
                },
              })}`,
              target: '_blank',
            },
          },
        },
      },
    },
  },
  designTagsConfig: {
    // Disables default design tags delimiter '[[]]' which is used for suggested drafts custom prompt tags.
    delimiter: ['|<|', '|>|'],
  },
  version: '1.63.2',
});
