/* eslint-disable react/prop-types */
import { useMemo, useCallback } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

import CustomPagination from '../tables/CustomPagination';
import { IMPORT_JOB_ACTIONS, IMPORT_TYPES } from '../../core/constants';
import { useImports } from '../../store/imports/hooks';
import RowActions from '../tables/RowActions';
import ImportStatus from './ImportStatus';
import ImportedContacts from './ImportedContacts';
import ImportType from './ImportType';

const getImportJobActions = (importType, invalidContacts) => {
  if (importType === IMPORT_TYPES.suppressionList) {
    if (!invalidContacts) return [];
    return [IMPORT_JOB_ACTIONS.downloadInvalid];
  }
  if (!invalidContacts) return [IMPORT_JOB_ACTIONS.view];
  return [IMPORT_JOB_ACTIONS.view, IMPORT_JOB_ACTIONS.downloadInvalid];
};

const ImportsTable = ({ data }) => {
  const { downloadFile } = useImports();
  const navigate = useNavigate();

  const handleAction = useCallback(
    async (importJob, action) => {
      if (action === IMPORT_JOB_ACTIONS.view) {
        navigate('/contacts', { state: { id: importJob.filename, name: importJob.filename, type: 'file' } });
      } else if (action === IMPORT_JOB_ACTIONS.downloadInvalid) {
        // Key format: 'uploads/TENANT_ID/CREATED_AT.csv'
        const [prefix, tenantId, suffix] = importJob.s3.key.split('/');
        const [fileName] = importJob.filename.split('.csv');

        downloadFile({
          bucketName: importJob.s3.bucket,
          documentKey: `${prefix}/${tenantId}/invalid/${suffix}`,
          fileName: `${fileName}_invalid_contacts.csv`,
        });
      }
    },
    [navigate, downloadFile],
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'filename',
        header: () => 'Import from',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'importType',
        header: () => 'Type',
        footer: (props) => props.column.id,
        cell: (info) => <ImportType importType={info.getValue()} />,
      },
      {
        accessorKey: 'createdContacts',
        header: () => 'Quantity',
        footer: (props) => props.column.id,
        cell: (info) => <ImportedContacts {...info.row.original} />,
      },
      {
        accessorKey: 'createdAt',
        header: () => 'Date',
        footer: (props) => props.column.id,
        cell: (info) => <span>{format(new Date(info.getValue()), 'd MMM yyyy, hh:mm a')}</span>,
      },
      {
        accessorKey: 'status',
        header: () => 'Status',
        footer: (props) => props.column.id,
        cell: (info) => <ImportStatus status={info.getValue()} />,
      },
      {
        accessorKey: 'tenantId',
        header: () => '',
        footer: (props) => props.column.id,
        cell: (info) => (
          <RowActions
            actions={getImportJobActions(info.row.original.importType, info.row.original.invalidContacts)}
            visibleActions={2}
            onSelectAction={(action) => handleAction(info.row.original, action)}
          />
        ),
        size: 104,
        minSize: 104,
      },
    ],
    [handleAction],
  );

  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      size: 'fit-content',
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const emptyRows = 5 - data.length;

  return (
    <div className="space-y-6">
      <div className="relative overflow-x-auto rounded-xl">
        <table className="w-full text-left text-base text-gray-950">
          <thead className="bg-white-100 text-sm uppercase text-gray-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="px-4 py-3"
                      style={{ width: header.column.columnDef.size, minWidth: header.column.columnDef.minSize }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id} className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10">
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className="px-4 py-2"
                        style={{ width: cell.column.columnDef.size, minWidth: cell.column.columnDef.minSize }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {emptyRows > 0 &&
              Array(emptyRows)
                .fill('')
                .map((row, index) => (
                  <tr
                    key={`emptyRow-${index}`}
                    className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10"
                  >
                    <td className="px-4 py-2" colSpan={15} style={{ minWidth: 110 }}></td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <CustomPagination table={table} total={data.length} />
    </div>
  );
};

ImportsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ImportsTable;
