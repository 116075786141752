import { IconCheck } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import { parseDonationAmount } from '../../core/utils';
import Button from '../buttons/Button';

const PLAN_INTERVAL_LABELS = {
  month: 'per month',
  year: 'per year',
};

const SubscriptionPlanCard = ({
  name,
  price,
  interval,
  selected = false,
  current = false,
  disabled = false,
  unavailable = false,
  onSelect,
}) => {
  const getButtonTitle = () => {
    if (unavailable) {
      return 'Contact us to downgrade';
    }
    if (current) {
      return 'Current Plan';
    }
    if (selected) {
      return 'Selected';
    }
    return 'Select';
  };

  return (
    <div
      className={`
        w-[284px] 
        space-y-6 
        rounded-lg 
        border-2 
        bg-white-100 
        p-6
        ${selected ? 'border-primary-700' : 'border-white-100'}
      `}
    >
      <h5 className="text-h5 font-semibold">{name}</h5>

      <div className="flex items-center space-x-2">
        <h2 className="text-h2 font-semibold">{parseDonationAmount(price, 0)}</h2>
        <p className="text-sm">{PLAN_INTERVAL_LABELS[interval]}</p>
      </div>

      <Button
        title={getButtonTitle()}
        color="primary"
        className="w-full"
        LeftIcon={selected ? IconCheck : undefined}
        disabled={disabled || current}
        onClick={onSelect}
      />
    </div>
  );
};

SubscriptionPlanCard.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  interval: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  current: PropTypes.bool,
  disabled: PropTypes.bool,
  unavailable: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default SubscriptionPlanCard;
