import { useState, useMemo, useEffect } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender } from '@tanstack/react-table';
import { nanoid } from 'nanoid';
import { IconPencilMinus } from '@tabler/icons-react';
import capitalize from 'lodash.capitalize';

import { useContactFields } from '../../store/contacts/hooks';
import Button from '../buttons/Button';
import CustomPagination from '../tables/CustomPagination';
import EditHeaderModal from './EditHeaderModal';
import { useImports } from '../../store/imports/hooks';
import { EMAIL_REGEX, IMPORT_TYPES } from '../../core/constants';
import AlertMessage from '../alerts/AlertMessage';

const MatchStep = () => {
  const [error, setError] = useState('');
  const [activeHeader, setActiveHeader] = useState('');
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const { data: contactFields } = useContactFields();
  const {
    newImport: { csvHeaders, csvData, fieldMappings, importType },
    updateNewImport,
    goBack,
    goNext,
    updateFieldMappings,
  } = useImports();

  const emailField = csvHeaders.find((header) => /email|e-mail/i.test(header));

  useEffect(() => {
    if (!emailField) {
      setError('A column named "email" is required in the file.');
      return;
    }

    if (!/^email$/i.test(emailField)) {
      updateFieldMappings({ [emailField]: 'Email' });
    }
  }, [emailField, updateFieldMappings]);

  const data = useMemo(() => {
    const { contacts } = csvData.reduce(
      (prev, contact) => {
        const email = contact[emailField];
        if (!email || prev.emailsMap[email] || !EMAIL_REGEX.test(email)) {
          return prev;
        }
        prev.contacts.push(contact);
        prev.emailsMap[email] = email;
        return prev;
      },
      { contacts: [], emailsMap: {} },
    );

    return contacts;
  }, [csvData, emailField]);

  const columns = useMemo(() => {
    let cols = csvHeaders.map((header) => ({
      id: nanoid(),
      accessorKey: header,
      header,
      // eslint-disable-next-line react/prop-types
      footer: (props) => props.column.id,
    }));
    if (importType === IMPORT_TYPES.suppressionList) {
      cols = cols.filter((col) => col.header === emailField);
    }
    return cols;
  }, [csvHeaders, emailField, importType]);

  const tableData = useMemo(
    () => data.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize),
    [data, pageIndex, pageSize],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data: tableData,
    columns,
    pageCount: Math.ceil(data.length / pageSize),
    state: { pagination },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  const handleEditHeaderConfirm = (headerName) => {
    updateFieldMappings({ [activeHeader]: headerName });
  };

  const handleNext = () => {
    if (csvData.length !== data.length) {
      updateNewImport({ csvData: data });
    }
    goNext();
  };

  const fieldOptions = useMemo(() => {
    if (importType === IMPORT_TYPES.suppressionList) {
      return [
        { label: 'New Field', value: '' },
        { label: 'Email', value: 'email' },
      ];
    }
    return [
      { label: 'New Field', value: '' },
      ...contactFields.reduce((options, cField) => {
        if (cField.field === 'id' || cField.field.includes('donor.')) return options;
        const label = cField.custom ? cField.field : capitalize(cField.field.replace(/_/g, ' '));
        options.push({ label, value: cField.field });
        return options;
      }, []),
    ];
  }, [contactFields, importType]);

  const getColumHeaderName = (header) => {
    if (fieldMappings[header] && !fieldOptions.find((option) => option.value === fieldMappings[header])) {
      return fieldMappings[header];
    }
    return capitalize((fieldMappings[header] || header).replace(/_/g, ' '));
  };

  return (
    <div className="w-full space-y-6 py-3">
      <div className="space-y-2">
        <h1 className="text-3xl font-medium">Match column headers to contract information</h1>

        <p className="text-base">
          <span className="font-bold">{data.length.toLocaleString()}</span> unique contacts were found in this file
        </p>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-left text-base text-gray-950 dark:text-gray-400">
          <thead className="bg-white-100 text-sm text-gray-400 dark:bg-gray-700 dark:text-gray-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={`w-[180px] min-w-[180px] max-w-[180px] truncate p-3`}
                    >
                      {header.isPlaceholder ? null : (
                        <div className="flex items-center">
                          <span
                            className="mr-1.5 cursor-pointer p-0.5"
                            onClick={() => setActiveHeader(header.column.columnDef.header)}
                          >
                            <IconPencilMinus size={16} className="text-gray-400" />
                          </span>
                          {flexRender(getColumHeaderName(header.column.columnDef.header), header.getContext())}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="sentry-mask">
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={`h-[56px] min-h-[56px] w-[180px] min-w-[180px] max-w-[180px] truncate px-4 py-2`}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <CustomPagination table={table} total={data.length} />

      <div className="!mt-8 flex items-center space-x-3">
        <Button title="Go Back" onClick={goBack} />
        <Button title="Continue" color="primary" onClick={handleNext} disabled={!data.length} />
      </div>

      <EditHeaderModal
        open={!!activeHeader}
        header={activeHeader}
        onConfirm={handleEditHeaderConfirm}
        onClose={() => setActiveHeader('')}
        fieldMappings={fieldMappings}
        options={fieldOptions}
      />

      <AlertMessage open={!!error} message={error} severity="error" autoHideDuration={null} />
    </div>
  );
};

export default MatchStep;
