import * as yup from 'yup';

export const emailValidation = yup.object({
  send: yup.boolean(),
  subject: yup.string().min(2, 'Invalid subject.').required('Subject is required.'),
  image: yup.string().required('Image is required.'),
  heading: yup.string().min(2, 'Invalid heading.').required('Heading is required.'),
  text: yup.string().min(2, 'Invalid text.').required('Text is required.'),
});

export const formValidation = yup.object({
  oneTimeReceipt: emailValidation,
  recurringReceipt: emailValidation,
  recurringInstallmentReceipt: emailValidation,
});
