import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { IconPlus } from '@tabler/icons-react';

import appSettings from '../app-settings';
import UserSeatsTable from '../components/user-seats/UserSeatsTable';
import AlertMessage from '../components/alerts/AlertMessage';
import Loader from '../components/loader/Loader';
import Button from '../components/buttons/Button';
import CreateUserModal from '../components/user-seats/CreateUserModal';

const USERS_URL = `${appSettings.baseUrl}/accounts/users`;

const UserSeats = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState('');
  const [users, setUsers] = useState([]);
  const [createUserOpen, setCreateUserOpen] = useState(false);

  const getUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(USERS_URL);
      setUsers(response.data);
    } catch (err) {
      Sentry.captureException(err);
      setError(err?.message || true);
    }
    setLoading(false);
  }, []);

  const handleCreateUserClose = useCallback(() => {
    setCreateUserOpen(false);
  }, []);

  const handleUserCreated = useCallback(() => {
    setSuccess('User created successfully.');
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <div className="w-full space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="mb-auto text-h3 dark:text-white-100">User Seats</h1>

        <Button title="New User" color="primary" LeftIcon={IconPlus} onClick={() => setCreateUserOpen(true)} />
      </div>

      <div className="relative flex-1 overflow-x-auto overflow-y-hidden">
        <UserSeatsTable data={users} />
        {loading && <Loader />}
      </div>

      <CreateUserModal
        open={createUserOpen}
        onClose={handleCreateUserClose}
        onError={(message) => setError(message)}
        onConfirm={handleUserCreated}
        loading={loading}
      />

      <AlertMessage
        open={!!success}
        message={success || 'Operation completed successfully!'}
        onClose={() => setSuccess('')}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </div>
  );
};

export default UserSeats;
