import { useSelector, shallowEqual } from 'react-redux';

import useActionCreator from '../../hooks/useActionCreator';
import {
  setError,
  setSuccess,
  getDonations,
  refundDonation,
  resendReceipt,
  cancelSubscription,
  updateFilters,
  resetFilters,
} from './actions';

export const useDonations = () => {
  const donationsState = useSelector((state) => state.donations, shallowEqual);

  return {
    ...donationsState,
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    getDonations: useActionCreator(getDonations),
    refundDonation: useActionCreator(refundDonation),
    resendReceipt: useActionCreator(resendReceipt),
    cancelSubscription: useActionCreator(cancelSubscription),
    updateFilters: useActionCreator(updateFilters),
    resetFilters: useActionCreator(resetFilters),
  };
};
