import { useSelector, shallowEqual } from 'react-redux';

import useActionCreator from '../../hooks/useActionCreator';
import {
  setError,
  setSuccess,
  getContactFields,
  getContacts,
  deleteContacts,
  createContact,
  unsubscribeContact,
  updateContactInList,
  updateContact,
  archiveContacts,
  unarchiveContacts,
} from './actions';

export const useContactFields = () => {
  const contactFieldsState = useSelector((state) => state.contacts.fields, shallowEqual);

  return { ...contactFieldsState, getContactFields: useActionCreator(getContactFields) };
};

export const useContacts = () => {
  const loading = useSelector((state) => state.contacts.loading);
  const error = useSelector((state) => state.contacts.error);
  const success = useSelector((state) => state.contacts.success);
  const data = useSelector((state) => state.contacts.data, shallowEqual);
  const totalCount = useSelector((state) => state.contacts.totalCount);

  return {
    loading,
    error,
    success,
    data,
    totalCount,
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    getContacts: useActionCreator(getContacts),
    deleteContacts: useActionCreator(deleteContacts),
    createContact: useActionCreator(createContact),
    unsubscribeContact: useActionCreator(unsubscribeContact),
    updateContactInList: useActionCreator(updateContactInList),
    updateContact: useActionCreator(updateContact),
    archiveContacts: useActionCreator(archiveContacts),
    unarchiveContacts: useActionCreator(unarchiveContacts),
  };
};
