import Button from '../buttons/Button';
import { useAccount } from '../../store/account/hooks';
import AlertMessage from '../alerts/AlertMessage';

const CompleteStep = () => {
  const { loading, error, setError, setOnboardingComplete } = useAccount();

  return (
    <div className="m-auto max-w-md space-y-12 2xl:max-w-lg">
      <div className="space-y-4">
        <h1 className="text-h1 font-semibold">Congratulations, you have finished setting up your account!</h1>
        <p className="text-lg">
          You have successfully completed the initial setup of your account. You can now connect with new donors, create
          and send campaigns, and process donations
        </p>
      </div>

      <Button
        title="Go to your home page"
        color="primary"
        size="xl"
        className="w-full"
        onClick={() => setOnboardingComplete()}
        loading={loading}
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </div>
  );
};

export default CompleteStep;
