import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getNetworkError } from 'common/utils';

import appSettings from '../../app-settings';
import { nanoid } from '../../core/utils';
import { getSuggestedCampaigns as getSuggestedCampaignsApi } from '../../api';
import { updateScheduleCampaignTaskStatus } from '../recommended-tasks/actions';

const CAMPAIGNS_URL = `${appSettings.baseUrl}/campaigns`;
const EMAIL_TEMPLATES_URL = `${appSettings.baseUrl}/email-templates`;

const SET_CAMPAIGN_TEMPLATE = 'campaigns/SET_CAMPAIGN_TEMPLATE';
const UPDATE_CAMPAIGN_DETAILS = 'campaigns/UPDATE_CAMPAIGN_DETAILS';
const SET_SEND_TO = 'campaigns/SET_SEND_TO';
const RESET_CAMPAIGN_DETAILS = 'campaigns/RESET_CAMPAIGN_DETAILS';
const RESET_CAMPAIGN_TEMPLATE = 'campaigns/RESET_CAMPAIGN_TEMPLATE';
const SET_LOADING = 'campaigns/SET_LOADING';
const SET_ERROR = 'campaigns/SET_ERROR';
const SET_SUCCESS = 'campaigns/SET_SUCCESS';
const GET_CAMPAIGNS = 'campaigns/GET_CAMPAIGNS';
const GET_CAMPAIGN = 'campaigns/GET_CAMPAIGN';
const SET_VIEW_TRASH_CAN = 'campaigns/SET_VIEW_TRASH_CAN';
const SOFT_DELETE_CAMPAIGN = 'campaigns/SOFT_DELETE_CAMPAIGN';
const CANCEL_CAMPAIGN = 'campaigns/CANCEL_CAMPAIGN';
const RESTORE_CAMPAIGN = 'campaigns/RESTORE_CAMPAIGN';
const RESTORE_CAMPAIGNS = 'campaigns/RESTORE_CAMPAIGNS';
const HARD_DELETE_CAMPAIGNS = 'campaigns/HARD_DELETE_CAMPAIGNS';
const DUPLICATE_CAMPAIGN = 'campaigns/DUPLICATE_CAMPAIGN';
const SET_VIEW_MODE = 'campaigns/SET_VIEW_MODE';
const SET_CALENDAR_DATE = 'campaigns/SET_CALENDAR_DATE';
const SET_CALENDAR_VIEW = 'campaigns/SET_CALENDAR_VIEW';
const GET_SUGGESTED_CAMPAIGNS = 'campaigns/GET_SUGGESTED_CAMPAIGNS';

export const setCampaignTemplate = createAction(SET_CAMPAIGN_TEMPLATE);

export const updateCampaignDetails = createAction(UPDATE_CAMPAIGN_DETAILS);

export const setSendTo = createAction(SET_SEND_TO);

export const resetCampaignDetails = createAction(RESET_CAMPAIGN_DETAILS);

export const resetCampaignTemplate = createAction(RESET_CAMPAIGN_TEMPLATE);

export const setLoading = createAction(SET_LOADING);

export const setError = createAction(SET_ERROR);

export const setSuccess = createAction(SET_SUCCESS);

export const setViewTrashCan = createAction(SET_VIEW_TRASH_CAN);

export const setViewMode = createAction(SET_VIEW_MODE);

export const setCalendarDate = createAction(SET_CALENDAR_DATE);

export const setCalendarView = createAction(SET_CALENDAR_VIEW);

export const getCampaigns = createAsyncThunk(GET_CAMPAIGNS, async (_, { getState }) => {
  try {
    const {
      campaigns: { viewTrashCan },
    } = getState();
    const config = viewTrashCan ? { params: { deleted: viewTrashCan } } : undefined;
    const res = await axios.get(CAMPAIGNS_URL, config);
    return res.data;
  } catch (err) {
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const getCampaign = createAsyncThunk(GET_CAMPAIGN, async (id) => {
  try {
    const res = await axios.get(`${CAMPAIGNS_URL}/${id}`);
    return res.data;
  } catch (err) {
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const softDeleteCampaign = createAsyncThunk(SOFT_DELETE_CAMPAIGN, async (id, { getState }) => {
  try {
    const {
      campaigns: { campaigns, suggestedCampaigns },
    } = getState();
    await axios.delete(`${CAMPAIGNS_URL}/${id}`);
    return {
      campaigns: campaigns.filter((campaign) => campaign.campaignId !== id),
      suggestedCampaigns: suggestedCampaigns.filter((suggestedCampaign) => suggestedCampaign.campaignId !== id),
    };
  } catch (err) {
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const cancelCampaign = createAsyncThunk(CANCEL_CAMPAIGN, async (campaign, { dispatch }) => {
  try {
    await axios.post(`${CAMPAIGNS_URL}/cancel/${campaign.campaignId}`);
    dispatch(updateScheduleCampaignTaskStatus({ sendTime: campaign.sendTime, delta: -1 }));
    dispatch(getCampaigns());
  } catch (err) {
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const restoreCampaign = createAsyncThunk(RESTORE_CAMPAIGN, async (id, { getState }) => {
  try {
    const {
      campaigns: { campaigns },
    } = getState();
    await axios.patch(`${CAMPAIGNS_URL}/restore/${id}`);
    return campaigns.filter((campaign) => campaign.campaignId !== id);
  } catch (err) {
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const restoreCampaigns = createAsyncThunk(RESTORE_CAMPAIGNS, async (ids, { getState }) => {
  try {
    const {
      campaigns: { campaigns },
    } = getState();
    await axios.patch(`${CAMPAIGNS_URL}/restore`, { campaignIds: ids });
    return campaigns.filter((campaign) => !ids.includes(campaign.campaignId));
  } catch (err) {
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const hardDeleteCampaigns = createAsyncThunk(HARD_DELETE_CAMPAIGNS, async (ids, { getState }) => {
  try {
    const {
      campaigns: { campaigns },
    } = getState();
    const campaignIds = ids.length > 0 ? ids : campaigns.map((campaign) => campaign.campaignId);
    await axios.delete(CAMPAIGNS_URL, { params: { campaignIds: campaignIds.join(',') } });
    return campaigns.filter((campaign) => !campaignIds.includes(campaign.campaignId));
  } catch (err) {
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const duplicateCampaign = createAsyncThunk(DUPLICATE_CAMPAIGN, async (campaign, { dispatch }) => {
  try {
    const {
      campaignId,
      sendStatus,
      launched,
      batchId,
      totalContacts,
      donorSpringContacts,
      emailError,
      launchError,
      scheduledStatus,
      sendTimestamp,
      template,
      name,
      suggestedDraftId,
      createdAt,
      updatedAt,
      stripe,
      ...rest
    } = campaign;

    dispatch(resetCampaignDetails());
    dispatch(resetCampaignTemplate());

    const defaultDate = new Date();
    defaultDate.setHours(defaultDate.getHours() + 1);
    const tempId = nanoid();

    const response = await axios.post(`${EMAIL_TEMPLATES_URL}/${template.templateId}/${template.version}`, {
      campaignId: tempId,
    });
    const { templateId, templateVersion: version } = response.data;

    return {
      ...rest,
      name: `${name} Duplicate`,
      sendTime: defaultDate.toISOString(),
      template: { templateId, version },
      tempId,
    };
  } catch (err) {
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const getSuggestedCampaigns = createAsyncThunk(GET_SUGGESTED_CAMPAIGNS, async () => {
  try {
    const suggestedCampaigns = await getSuggestedCampaignsApi();
    return suggestedCampaigns;
  } catch (err) {
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});
