import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Button from '../buttons/Button';
import Input from '../inputs/Input';
import { authenticate, isStrongPassword } from '../../core/utils';
import Checkbox from '../inputs/Checkbox';
import appSettings from '../../app-settings';

const { redirectUri, appClient } = appSettings;

const formValidation = yup.object({
  email: yup.string().email('Invalid email address.').required('Email is required.'),
  password: yup
    .string()
    .test(
      'strong-password',
      'Password needs to be at least 8 characters long, contain at least 1 number, 1 special character, 1 uppercase letter and 1 lowercase letter.',
      (value) => isStrongPassword(value),
    )
    .required('Password is required.'),
  agreeToTerms: yup.boolean().oneOf([true], 'You must agree to the terms and privacy policy.'),
});

const initialValues = {
  organizationName: '',
  taxId: '',
  legalAddress: '',
  email: '',
  password: '',
  agreeToTerms: false,
};

const SignupForm = ({ disabled, onSubmit }) => {
  const form = useForm({
    initialValues,
    validate: yupResolver(formValidation),
    validateInputOnBlur: true,
  });
  const navigate = useNavigate();

  return (
    <div className="size-full overflow-y-auto py-8">
      <form
        noValidate
        className="flex min-h-full w-full flex-col items-center justify-center"
        onSubmit={form.onSubmit(onSubmit)}
      >
        <div className="max-w-[336px] space-y-8">
          <div className="space-y-4">
            <h1 className="text-h2 font-semibold">Welcome to DonorSpring</h1>
            <p className="text-lg">Sign up with your organization information, email and password</p>
            <p className="text-lg">
              Already have an account?{' '}
              <button
                onClick={() => authenticate(redirectUri, appClient, navigate, false)}
                className="text-primary-700"
              >
                Log in
              </button>
            </p>
          </div>

          <div className="space-y-6">
            <Input
              id="email"
              label="Email"
              type="email"
              {...form.getInputProps('email')}
              required
              error={!!form.errors.email}
              helperText={form.errors.email}
              size="xl"
              disabled={disabled}
            />

            <Input
              id="password"
              label="Password"
              type="password"
              {...form.getInputProps('password')}
              required
              error={!!form.errors.password}
              helperText={form.errors.password}
              size="xl"
              disabled={disabled}
            />

            <Checkbox
              id="agree-to-terms"
              label={
                <label htmlFor="agree-to-terms" className="text-base">
                  I agree to the&nbsp;
                  <a
                    href="https://www.donorspring.com/terms"
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary-700"
                  >
                    Terms
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    href="https://www.donorspring.com/privacy"
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary-700"
                  >
                    Privacy Policy
                  </a>
                </label>
              }
              checked={form.values.agreeToTerms}
              onChange={(e) => form.setFieldValue('agreeToTerms', e.target.checked)}
              error={!!form.errors.agreeToTerms}
              helperText={form.errors.agreeToTerms}
            />
          </div>

          <Button
            title="Sign Up"
            color="primary"
            size="xl"
            className="!block w-full"
            type="submit"
            disabled={disabled}
          />
        </div>
      </form>
    </div>
  );
};

SignupForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SignupForm;
