import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconArrowRight, IconArrowLeft } from '@tabler/icons-react';

import { useDomains } from '../store/domains/hooks';
import SubjectForm, { subjectFormValidation } from '../components/newcampaign/Forms/SubjectForm';
import FromForm, { fromFormValidationSchema } from '../components/newcampaign/Forms/FromForm';
import FormCard from '../components/newcampaign/FormCards/FormCard';
import { useEmailTemplates } from '../store/email-templates/hooks';
import { formStoreTargets, templateTypes } from '../core/constants';
import { noop } from '../core/utils';
import Button from '../components/buttons/Button';

const EmailConfig = () => {
  const navigate = useNavigate();
  const { getDomains } = useDomains();
  const { template } = useEmailTemplates();

  useEffect(() => {
    getDomains();
  }, [getDomains]);

  const goBack = () => {
    navigate('/email-templates', { replace: true, state: { type: templateTypes.recurring } });
  };

  const fromFormComplete = useMemo(() => {
    try {
      fromFormValidationSchema.validateSync(template.emailConfig);
      return true;
    } catch (err) {
      return false;
    }
  }, [template.emailConfig]);

  const subjectFormComplete = useMemo(() => {
    try {
      subjectFormValidation.validateSync(template.emailConfig);
      return true;
    } catch (err) {
      return false;
    }
  }, [template.emailConfig]);

  return (
    <div className="relative flex min-h-screen w-full flex-col">
      <header className="flex flex-row p-6">
        <h1 className="text-h3 dark:text-white-100">{template.title}</h1>
      </header>

      <hr />

      <div className="flex items-center justify-center">
        <div className="m-10 flex items-center justify-center">
          <div className="grid min-w-[1050px] grid-cols-2 gap-x-8">
            <FormCard
              title={'From'}
              subtitle={'Who is sending this email?'}
              form={<FromForm storeTarget={formStoreTargets.template} />}
              isExpanded={true}
              handleClick={noop}
              checked={fromFormComplete}
              containerClassName="bg-white-100 rounded-xl"
            />
            <FormCard
              title={'Subject'}
              subtitle={'What is the subject line?'}
              form={<SubjectForm storeTarget={formStoreTargets.template} />}
              isExpanded={true}
              handleClick={noop}
              checked={subjectFormComplete}
              containerClassName="bg-white-100 rounded-xl"
            />
          </div>
        </div>
      </div>

      <hr />

      {/* Footer: Back to Templates, Continue */}
      <footer>
        <div className="flex flex-row justify-between p-6">
          <Button title="Back to Templates" color="primary" onClick={goBack} LeftIcon={IconArrowLeft} />

          <Button
            title="Continue"
            color="primary"
            onClick={() => navigate('/email-editor', { state: { selectedTemplate: template } })}
            RightIcon={IconArrowRight}
            disabled={!fromFormComplete || !subjectFormComplete}
          />
        </div>
      </footer>

      {/* {loading && <Loader />} */}

      {/* <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => setSuccess(false)}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      /> */}
    </div>
  );
};

export default EmailConfig;
