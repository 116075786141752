import { Link } from 'react-router-dom';

import AccountDropdown from './AccountDropdown';
import { useAccount } from '../../store/account/hooks';
import RecommendedTasksDropdown from './RecommendedTasksDropdown';

const Header = () => {
  const { account } = useAccount();

  // This will align nicely on the right side if the main content is not tall enough to be scrollable
  return (
    <div className="sticky top-0 z-10 flex justify-between border-b border-gray-50 bg-white-100 py-3 pl-5 pr-7">
      <div className="flex items-center space-x-4">
        <Link to="/">
          <img className="h-auto w-40" src="/logo.svg" alt="logo" />
        </Link>

        {account.sandbox && (
          <div className="rounded border border-orange-200 bg-orange-50 px-1.5 py-0.5 text-orange-700">
            <p className="text-caption1 font-semibold uppercase">Sandbox Mode</p>
          </div>
        )}
      </div>

      <div className="flex items-center space-x-8">
        <RecommendedTasksDropdown />

        <AccountDropdown />
      </div>
    </div>
  );
};

export default Header;
