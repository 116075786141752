import PropTypes from 'prop-types';
import { IconSearch } from '@tabler/icons-react';
import { Input } from 'ui';

const SearchBar = ({ value, onChange, containerClassName, placeholder }) => {
  return (
    <Input
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      containerClassName={`max-w-xs ${containerClassName}`}
      LeftIcon={IconSearch}
      iconProps={{ size: 20, strokeWidth: 1.5 }}
    />
  );
};

SearchBar.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  containerClassName: PropTypes.string,
  placeholder: PropTypes.string,
};

SearchBar.defaultProps = {
  containerClassName: '',
  placeholder: 'Search',
};

export default SearchBar;
