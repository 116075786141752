import { useState } from 'react';
import PropTypes from 'prop-types';
import { RichTextEditor } from '@mantine/rte';

import Button from '../buttons/Button';

const TextEditorInput = ({ id, label, value, onChange, onReset, error, required }) => {
  const [editable, setEditable] = useState(false);

  const handleEdit = () => {
    setEditable(true);
  };

  const handleCancelEdit = () => {
    setEditable(false);
    onReset();
  };

  return (
    <div className="flex">
      <div className="flex w-3/4">
        <p className="mr-[5%] block w-[125px] text-base font-medium text-gray-950">
          <span>{label}</span>
          {required && <span className="ml-1 text-base text-red-500">*</span>}
        </p>

        <div className="w-full flex-1">
          <RichTextEditor
            id={`${id}-editor`}
            value={value}
            onChange={onChange}
            controls={[
              ['bold', 'italic', 'underline'],
              ['unorderedList', 'orderedList'],
              ['sup', 'sub'],
            ]}
            classNames={{
              root: `w-full
                text-gray-950 
                focus-within:ring-primary-300
                focus-within:border-primary-700
                focus-within:ring-offset-1
                focus-within:ring-2
                focus-within:outline-none
                focus-within:border-2
                ${editable ? 'bg-white-100 cursor-auto' : 'bg-gray-10 cursor-not-allowed'}
                ${error ? 'border-red-500 border-2' : ''}`,
              toolbar: 'w-full text-gray-950 bg-white-100',
            }}
            readOnly={!editable}
          />
          {!!error && <p className="mt-1 text-sm text-red-500">{error}</p>}
        </div>
      </div>
      <div className="flex w-1/4 flex-col">
        <Button
          title={editable ? 'Cancel' : 'Edit'}
          className="self-end"
          onClick={editable ? handleCancelEdit : handleEdit}
        />
      </div>
    </div>
  );
};

TextEditorInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  error: PropTypes.string,
  required: PropTypes.bool,
};

TextEditorInput.defaultProps = {
  error: '',
  onReset: () => {},
  required: false,
};

export default TextEditorInput;
