import { useRef, useState } from 'react';
import { ClickAwayListener, Paper, Popper, Grow } from '@mui/material';
import { DayPicker } from 'react-day-picker';
import { IconCalendar } from '@tabler/icons-react';
import { format, isValid, isPast } from 'date-fns';
import PropTypes from 'prop-types';
import styles from 'react-day-picker/dist/style.module.css';

import Input from '../inputs/Input';

const dayPickerClassNames = {
  ...styles,
  root: `px-6 py-3`,
  caption: `flex items-center justify-center relative`,
  caption_label: `text-base font-semibold text-gray-950 absolute`,
  nav: `w-full flex items-center justify-between`,
  nav_button: `h-9 w-9 rounded-full flex items-center justify-center`,
  nav_button_previous: `!-ml-2`,
  nav_button_next: `!-mr-2`,
  nav_icon: `text-gray-600`,
  head_cell: `text-sm font-semibold text-gray-600 h-7`,
  day_selected: `!bg-primary-700 !text-white-100 rounded-lg`,
  cell: `w-9 h-9 p-0`,
  day: `w-full h-full rounded-lg !text-base`,
  day_today: `!font-semibold`,
  button: `enabled:hover:bg-primary-50 enabled:cursor-pointer disabled:opacity-50`,
};

const DatePicker = ({ value, onChange, required, disabled }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(format(value, 'MM/dd/yyyy'));
  const anchorRef = useRef(null);

  const handleClose = (event) => {
    if (!event) return;

    if (event && anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleSelectDate = (date) => {
    if (!date) return; // already selected date
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const clonedDate = new Date(value.getTime());
    clonedDate.setFullYear(year, month, day);
    onChange(clonedDate);
    setInputValue(format(clonedDate, 'MM/dd/yyyy'));
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const dateString = e.target.value;

    setInputValue(dateString);

    let [month, day, year] = dateString.split('/');
    month = parseInt(month, 10) || 0;
    day = parseInt(day, 10) || 0;
    year = parseInt(year, 10) || 0;

    if (!month || !day || !year) return;

    const dateValue = new Date();
    dateValue.setFullYear(year, month - 1, day);

    if (isValid(dateValue) && !isPast(dateValue)) {
      onChange(dateValue);
    }
  };

  return (
    <>
      <Input
        id="date"
        label="Date"
        value={inputValue}
        size="xl"
        containerClassName="w-[160px]"
        LeftIcon={IconCalendar}
        iconProps={{ size: 20 }}
        onChange={handleInputChange}
        onFocus={() => setOpen(true)}
        onBlur={() => setInputValue(format(value, 'MM/dd/yyyy'))}
        required={required}
        disabled={disabled}
        ref={anchorRef}
      />

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        className="z-[100]"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper classes={{ root: '!rounded-lg my-0.5 !shadow-custom' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <DayPicker
                    mode="single"
                    selected={value}
                    onSelect={handleSelectDate}
                    classNames={dayPickerClassNames}
                    fromDate={new Date()}
                    defaultMonth={value}
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

DatePicker.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

DatePicker.defaultProps = {
  required: false,
  disabled: false,
};

export default DatePicker;
