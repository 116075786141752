import PropTypes from 'prop-types';
import { useState } from 'react';
import DomainAuthForm from '../domain-auth/DomainAuthForm';
import ConfirmDeleteModal from '../modals/ConfirmDeleteModal';
import { useDomains } from '../../store/domains/hooks';

const DomainAuthStep = ({ onPrev, onNext }) => {
  const [deleteData, setDeleteData] = useState(null);
  const { loading, deleteDomain } = useDomains();

  const handleDeleteDomain = (domain) => {
    setDeleteData({ id: String(domain.id), name: domain.domain, entity: 'domain' });
  };

  const handleDeleteDomainConfirm = async (domainId) => {
    await deleteDomain(domainId);
    setDeleteData(null);
  };

  return (
    <div className="m-auto max-w-2xl space-y-12 pb-[60px] 2xl:max-w-5xl">
      <div className="space-y-4">
        <h1 className="text-h1 font-semibold">Domain Authentication</h1>
        <div className="space-y-6 text-lg">
          <p>
            Domain authentication allows your organization to use your own domain when sending email through the
            DonorSpring platform (sender@yourdomain.org)
          </p>
          <p>
            If you do not have direct access, we recommend reaching out to the IT person or consultant who does. Here
            are common domain registrars&apos; instructions to do so:{' '}
            <a
              href="https://www.godaddy.com/help/verify-domain-ownership-dns-or-html-for-my-ssl-certificate-7452"
              target="_blank"
              rel="noreferrer"
              className="text-primary-700 underline"
            >
              GoDaddy
            </a>
            ,{' '}
            <a
              href="https://www.domain.com/help/article/dns-management-dns-records-explained"
              target="_blank"
              rel="noreferrer"
              className="text-primary-700 underline"
            >
              Domain.com
            </a>
            ,{' '}
            <a
              href="https://www.namecheap.com/support/knowledgebase/article.aspx/642/2237/dns/"
              target="_blank"
              rel="noreferrer"
              className="text-primary-700 underline"
            >
              NameCheap
            </a>
            .
          </p>
          <p>
            We also strongly suggest adding a DMARC record. DMARC is a standard that can help prevent spammers from
            trying to send email that appears to be from your domain. The DMARC record is not specific to DonorSpring,
            but some email services (like Gmail and Yahoo) require bulk senders to have it. If you already have a DMARC
            record installed you can leave it untouched.
          </p>
          <p>
            Once you have entered your domain information, you can continue on with the rest of onboarding even if you
            are waiting for the DNS records to be installed and verified. Check the &quot;I&apos;ve added these
            records&quot; checkbox, click &quot;Verify&quot;, then click &quot;Next&quot; to proceed.
          </p>
        </div>
      </div>

      <DomainAuthForm onboarding onPrev={onPrev} onNext={onNext} onDelete={handleDeleteDomain} />

      <ConfirmDeleteModal
        data={deleteData}
        onClose={() => setDeleteData(null)}
        onDelete={handleDeleteDomainConfirm}
        loading={loading}
      />
    </div>
  );
};

DomainAuthStep.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default DomainAuthStep;
