/* eslint-disable react/prop-types */
import { useCallback, useMemo } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender } from '@tanstack/react-table';
import { IconEye, IconPlus } from '@tabler/icons-react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TablePagination, TableRowActions } from 'ui';

import { RECOMMENDED_TASK_ACTIONS, RECOMMENDED_TASK_STATUS, RECOMMENDED_TASK_TYPES } from '../../core/constants';
import { useCampaigns } from '../../store/campaigns/hooks';
import StatusLabel from './StatusLabel';

const getActionOptions = (status, taskType) => {
  if (status !== RECOMMENDED_TASK_STATUS.pending) return [];
  if (taskType === RECOMMENDED_TASK_TYPES.weeklyCampaignScheduling) {
    return [RECOMMENDED_TASK_ACTIONS.scheduleCampaign];
  }
  if (taskType === RECOMMENDED_TASK_TYPES.reviewSuggestedDraft) {
    return [RECOMMENDED_TASK_ACTIONS.reviewDraft];
  }
  return [];
};

const getActionIcon = (action) => {
  switch (action) {
    case RECOMMENDED_TASK_ACTIONS.scheduleCampaign:
      return <IconPlus size={20} />;
    case RECOMMENDED_TASK_ACTIONS.reviewDraft:
      return <IconEye size={20} />;
    default:
      return null;
  }
};

const Table = (props) => {
  const { data } = props;
  const { resetCampaignDetails, resetCampaignTemplate, updateCampaignDetails } = useCampaigns();
  const navigate = useNavigate();

  const handleSelectedAction = useCallback(
    (action, task) => {
      switch (action) {
        case RECOMMENDED_TASK_ACTIONS.scheduleCampaign: {
          resetCampaignDetails();
          resetCampaignTemplate();
          const sendTime = new Date(task.expirationTimestamp);
          sendTime.setHours(new Date().getHours() + 1);
          updateCampaignDetails({ sendTime: sendTime.toISOString() });
          navigate('/new-campaign');
          break;
        }
        case RECOMMENDED_TASK_ACTIONS.reviewDraft: {
          navigate(`/suggested-drafts/${task.suggestedCampaignId}`);
          break;
        }
        default:
          break;
      }
    },
    [navigate, resetCampaignDetails, resetCampaignTemplate, updateCampaignDetails],
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'description',
        header: () => 'Name',
        footer: (props) => props.column.id,
        cell: (info) => <p className="truncate">{info.getValue()}</p>,
      },
      {
        accessorKey: 'status',
        header: () => 'Status',
        footer: (props) => props.column.id,
        cell: (info) => <StatusLabel status={info.getValue()} />,
        size: 120,
      },
      {
        accessorKey: 'expirationTimestamp',
        header: () => 'Expires At',
        footer: (props) => props.column.id,
        cell: (info) => format(new Date(info.getValue()), 'd MMM yyyy, h:mm a'),
        size: 190,
      },
      {
        accessorKey: 'taskId',
        header: () => '',
        footer: (props) => props.column.id,
        cell: (info) => (
          <TableRowActions
            actions={getActionOptions(info.row.original.status, info.row.original.type)}
            onSelectAction={(action) => {
              handleSelectedAction(action, info.row.original);
            }}
            getActionIcon={getActionIcon}
          />
        ),
        size: 68,
      },
    ],
    [handleSelectedAction],
  );

  const table = useReactTable({
    data,
    columns,
    pageCount: data.totalPages,
    defaultColumn: {
      size: 'fit-content',
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const emptyRows = 5 - data.length;

  return (
    <div className="space-y-6">
      <div className="relative overflow-x-auto sm:rounded-lg">
        <table className="w-full text-left text-base text-gray-950 dark:text-gray-400">
          <thead className="bg-white-100 text-sm uppercase text-gray-400 dark:bg-gray-700 dark:text-gray-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="px-4 py-3"
                      style={{ width: header.column.columnDef.size, minWidth: header.column.columnDef.minSize }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className="px-4 py-2"
                        style={{ width: cell.column.columnDef.size, minWidth: cell.column.columnDef.minSize }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {emptyRows > 0 &&
              Array(emptyRows)
                .fill('')
                .map((row, index) => (
                  <tr
                    key={`emptyRow-${index}`}
                    className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                  >
                    <td className="px-4 py-2" colSpan={15} style={{ minWidth: 110 }}></td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <TablePagination table={table} total={data.length} />
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Table;
