import { useMemo } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { parse, format } from 'date-fns';
import PropTypes from 'prop-types';

import { PRIMARY_BASE_COLOR, DASHBOARD_ACTIVE_CHART } from '../../core/constants';
import { formatGraphData, getFormatString, parseDonationAmount } from '../../core/utils';
import { useDashboard } from '../../store/dashboard/hooks';

const formatXAxis = (tickItem) => {
  const parsedDate = parse(tickItem, getFormatString(tickItem), new Date());
  return format(parsedDate, 'MMM d');
};

const formatYAxis = (tickItem) => {
  return new Intl.NumberFormat('en', { notation: 'compact' }).format(tickItem);
};

const CustomTooltip = ({ active, payload }) => {
  const { activeChart } = useDashboard();

  if (!active || !payload || !payload.length) return null;

  const {
    payload: { date, count },
  } = payload[0];

  const parsedDate = parse(date, getFormatString(date), new Date());

  return (
    <div className="space-y-1 rounded-lg bg-white-100 px-3 py-2 shadow-custom">
      <p className="text-sm text-gray-800">{format(parsedDate, 'MMM d, yyyy')}</p>
      <p className="text-lg text-primary-700" style={{ color: PRIMARY_BASE_COLOR }}>
        {activeChart === DASHBOARD_ACTIVE_CHART.donations_usd ? parseDonationAmount(count, 0) : count}
      </p>
    </div>
  );
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
};

const margin = {
  top: 12,
  right: 0,
  left: -6,
  bottom: 0,
};

const dot = { stroke: '#FFFFFF', strokeWidth: 2, fill: PRIMARY_BASE_COLOR, r: 4 };

const activeDot = { stroke: '#FFFFFF', strokeWidth: 2, fill: PRIMARY_BASE_COLOR, r: 6 };

const axisLine = { stroke: '#C9C9C9' };

const CustomAreaChart = () => {
  const { donations, activeChart, reports, startDate, endDate } = useDashboard();

  const data = useMemo(() => {
    if (activeChart === DASHBOARD_ACTIVE_CHART.donations_usd) {
      return formatGraphData(donations, 'createdAt', 'netAmount', startDate, endDate);
    }
    if (activeChart === DASHBOARD_ACTIVE_CHART.donations_number) {
      return formatGraphData(donations, 'createdAt', '', startDate, endDate);
    }
    if (reports.length === 1) {
      // An specific campaign has been selected
      return reports.map((report) => ({ date: format(new Date(report.sendAt * 1000), 'yyyy-MM-dd'), count: 1 }));
    }
    return formatGraphData(reports, 'sendAt', 'delivered', startDate, endDate);
  }, [donations, activeChart, reports, startDate, endDate]);

  return (
    <ResponsiveContainer width="100%" height={284}>
      <AreaChart width={696} height={284} data={data} margin={margin}>
        <defs>
          <linearGradient id="donationData" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={PRIMARY_BASE_COLOR} stopOpacity={0.15} />
            <stop offset="100%" stopColor={PRIMARY_BASE_COLOR} stopOpacity={0.015} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tickFormatter={formatXAxis}
          tickLine={false}
          tickMargin={12}
          fontSize={12}
          axisLine={axisLine}
        />
        <YAxis tickLine={false} tickMargin={12} fontSize={12} axisLine={axisLine} tickFormatter={formatYAxis} />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="linear"
          dataKey="count"
          stroke={PRIMARY_BASE_COLOR}
          fillOpacity={1}
          fill="url(#donationData)"
          dot={dot}
          activeDot={activeDot}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default CustomAreaChart;
