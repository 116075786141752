import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import SelectInput from '../inputs/SelectInput';
import Input from '../inputs/Input';
import CustomDialog from '../modals/CustomDialog';

const EditHeaderModal = ({ open, header, onConfirm, onClose, fieldMappings, options }) => {
  const [selectedField, setSelectedField] = useState('');
  const [newField, setNewField] = useState('');

  useEffect(() => {
    if (fieldMappings[header]) {
      if (options.find((option) => option.value.toLowerCase() === fieldMappings[header].toLowerCase())) {
        setSelectedField(fieldMappings[header]);
        return;
      }
      setNewField(fieldMappings[header]);
      return;
    }
    const option = options.find((option) => option.label === header);
    if (option) setSelectedField(option.value);
  }, [header, options, fieldMappings]);

  const handleClose = () => {
    setSelectedField('');
    setNewField('');
    onClose();
  };

  const handleConfirm = () => {
    if (!selectedField && newField) {
      onConfirm(newField);
      handleClose();
      return;
    }
    onConfirm(selectedField);
    handleClose();
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      title="Edit column header"
      containerClassName="sm:min-w-[448px]"
      titleClassName="text-xl"
    >
      <form noValidate>
        <SelectInput
          label="Select a field for this column"
          options={options}
          value={selectedField}
          onChange={(e) => setSelectedField(e.target.value)}
          containerClassName="mt-6"
        />

        {!selectedField && (
          <Input
            id="new-field"
            label="Enter new field:"
            containerClassName="my-6"
            value={newField}
            onChange={(e) => setNewField(e.target.value)}
          />
        )}
      </form>

      <div className="mt-2 flex items-center justify-end space-x-2">
        <Button title="Confirm" color="primary" onClick={handleConfirm} />

        <Button title="Cancel" onClick={handleClose} />
      </div>
    </CustomDialog>
  );
};

EditHeaderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  fieldMappings: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default EditHeaderModal;
