import { useState } from 'react';
import { IconDownload } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import IconButton from '../buttons/IconButton';
import Loader from '../loader/Loader';

const DownloadExport = (props) => {
  const { exportJob, downloadExportFile } = props;
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    await downloadExportFile(exportJob);
    setLoading(false);
  };

  return (
    <div className="relative">
      <IconButton
        Icon={<IconDownload size={20} />}
        srOnly="Download export file"
        color="transparent"
        circle
        onClick={handleDownload}
      />
      {loading && <Loader />}
    </div>
  );
};

DownloadExport.propTypes = {
  exportJob: PropTypes.object.isRequired,
  downloadExportFile: PropTypes.func.isRequired,
};

export default DownloadExport;
