import { useState, useMemo, useEffect } from 'react';
import { IconPlus } from '@tabler/icons-react';

import { IMPORT_JOB_STATUS, sortDirections } from '../core/constants';
import { sortDataWithDirection } from '../core/utils';
import Loader from '../components/loader/Loader';
import AlertMessage from '../components/alerts/AlertMessage';
import ImportsTable from '../components/imports/ImportsTable';
import FullScreenUpload from '../components/imports/FullScreenUpload';
import useInterval from '../hooks/useInterval';
import { useImports } from '../store/imports/hooks';
import Button from '../components/buttons/Button';
import Sorters from '../components/tables/Sorters';

const POLLING_INTERVAL_IN_MS = 15000;

const sortByOptions = [
  { label: 'File Name', value: 'filename' },
  { label: 'Date', value: 'createdAt' },
];

const Imports = () => {
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState(sortDirections.desc);
  const [uploadOpen, setUploadOpen] = useState(false);
  const { loading, error, imports, setError, getImports } = useImports();

  useEffect(() => {
    getImports();
  }, [getImports]);

  const isInProgress = useMemo(() => {
    return imports.some(({ status }) =>
      [
        IMPORT_JOB_STATUS.notStarted,
        IMPORT_JOB_STATUS.validating,
        IMPORT_JOB_STATUS.processing,
        IMPORT_JOB_STATUS.validated,
      ].includes(status),
    );
  }, [imports]);

  useInterval(getImports, isInProgress ? POLLING_INTERVAL_IN_MS : null);

  const data = useMemo(() => {
    let filteredData = [...imports];
    if (sortBy) {
      filteredData = sortDataWithDirection(filteredData, sortBy, sortDirection);
    }
    return filteredData;
  }, [imports, sortBy, sortDirection]);

  return (
    <div className="w-full space-y-6">
      <div className="flex justify-between space-x-3">
        <h1 className="text-h3">Imports</h1>

        <div className="flex items-center space-x-3">
          <Sorters
            options={sortByOptions}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
          />

          <Button
            title="Import Contacts or Suppression List"
            color="primary"
            LeftIcon={IconPlus}
            onClick={() => setUploadOpen(true)}
          />
        </div>
      </div>

      <div className="relative">
        <ImportsTable data={data} />

        {loading && <Loader />}
      </div>

      <FullScreenUpload open={uploadOpen} onClose={() => setUploadOpen(false)} />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </div>
  );
};

export default Imports;
