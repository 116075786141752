/* eslint-disable react/prop-types */
import { useMemo } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender } from '@tanstack/react-table';
import format from 'date-fns/format';
import PropTypes from 'prop-types';

import CustomPagination from '../tables/CustomPagination';
import DownloadExport from './DownloadExport';
import ExportStatus from './ExportStatus';
import ExportTypeLabel from './ExportTypeLabel';

const ExportsTable = ({ data, downloadExportFile }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'exportFilename',
        header: () => 'File Name',
        footer: (props) => props.column.id,
        cell: (info) => (info.getValue() ? `${info.getValue()}.csv` : info.row.original.name),
      },
      {
        accessorKey: 'type',
        header: () => 'Type',
        footer: (props) => props.column.id,
        cell: (info) => <ExportTypeLabel type={info.getValue()} />,
        size: 150,
      },
      {
        accessorKey: 'createdAt',
        header: () => 'Date',
        footer: (props) => props.column.id,
        cell: (info) => format(new Date(info.getValue() * 1000), "MMM d, yyyy 'at' h:mm aa"),
        size: 200,
      },
      {
        accessorKey: 'status',
        header: () => 'Status',
        footer: (props) => props.column.id,
        cell: (info) => <ExportStatus status={info.getValue()} />,
        size: 180,
      },
      {
        accessorKey: 'tenantId',
        header: () => '',
        footer: (props) => props.column.id,
        cell: (info) => {
          return <DownloadExport exportJob={info.row.original} downloadExportFile={downloadExportFile} />;
        },
        size: 52,
        minSize: 52,
      },
    ],
    [downloadExportFile],
  );

  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      size: 'fit-content',
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const emptyRows = 5 - data.length;

  return (
    <div className="space-y-6">
      <div className="relative overflow-x-auto sm:rounded-lg">
        <table className="w-full text-left text-base text-gray-950 dark:text-gray-400">
          <thead className="bg-white-100 text-sm uppercase text-gray-400 dark:bg-gray-700 dark:text-gray-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="px-4 py-3"
                      style={{ width: header.column.columnDef.size, minWidth: header.column.columnDef.minSize }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className="px-4 py-2"
                        style={{ width: cell.column.columnDef.size, minWidth: cell.column.columnDef.minSize }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {emptyRows > 0 &&
              Array(emptyRows)
                .fill('')
                .map((row, index) => (
                  <tr
                    key={`emptyRow-${index}`}
                    className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                  >
                    <td className="px-4 py-2" colSpan={15} style={{ minWidth: 110 }}></td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <CustomPagination table={table} total={data.length} />
    </div>
  );
};

ExportsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  downloadExportFile: PropTypes.func.isRequired,
};

export default ExportsTable;
