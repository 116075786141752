import { useEffect } from 'react';
import { Dialog } from '@mui/material';
import { IconX, IconAlertCircleFilled } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import { Button, IconButton, Input } from 'ui';

import { nameToSlug } from '../../core/utils';

const FORBIDDEN_SLUGS = ['default', 'opt-in'];

// Order of validators matters
const formValidation = yup.object({
  defaultNewSlug: yup
    .string()
    .matches(/^[-_a-zA-Z0-9]+$/, 'Only hyphens, underscores, letters, and numbers are allowed.')
    .required('Donation page URL is required.')
    .test('no-forbidden-slugs', 'Invalid slug.', (value) => {
      const regex = new RegExp(`^${FORBIDDEN_SLUGS.join('$|^')}$`, 'i');
      return !regex.test(value);
    }),
});

const ConfirmDefaultPageModal = ({ open, name, defaultName, defaultPageUrl, loading, onClose, onConfirm }) => {
  const form = useForm({
    initialValues: { defaultNewSlug: 'default-donation-page' },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });

  useEffect(() => {
    if (defaultName) form.setFieldValue('defaultNewSlug', nameToSlug(defaultName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultName]);

  const handleConfirm = (values) => {
    onConfirm(values.defaultNewSlug);
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={onClose}
      sx={{ '.MuiPaper-root': { padding: 0, margin: 0, borderRadius: '12px', width: '100%' } }}
    >
      <form noValidate className="space-y-6 p-6" onSubmit={form.onSubmit(handleConfirm)}>
        <div className="relative flex">
          <div className="flex items-center space-x-3">
            <IconAlertCircleFilled size={20} className="text-primary-700" />
            <h3 className="text-h6 font-semibold">Change Default Donation Page?</h3>
          </div>

          <IconButton
            Icon={<IconX size={20} className="text-gray-400" />}
            srOnly="Close"
            onClick={onClose}
            color="transparent"
            className="absolute -right-1 -top-1 !p-1"
          />
        </div>

        <p className="text-base">
          Are you sure you want to make <span className="font-semibold">{name}</span> the default donation page? Donate
          buttons in email campaigns will link to this page unless a different page is selected.
        </p>

        <div className="space-y-2">
          <p className="text-base">
            <span className="font-semibold">{name}</span> will be available at your default donation page URL:
          </p>

          <Input size="xl" value={defaultPageUrl} disabled />
        </div>

        <div className="space-y-4">
          <p className="text-base">Your previous default donation page will still be available at a new URL:</p>

          <div className="flex">
            <Input
              label="Donation Page URL"
              size="xl"
              value={defaultPageUrl}
              disabled
              containerClassName="flex-1"
              inputClassName="rounded-e-none border-e-0"
              required
            />
            <Input
              {...form.getInputProps('defaultNewSlug')}
              size="xl"
              containerClassName="w-[250px]"
              inputClassName="rounded-s-none"
              helperText={form.errors.defaultNewSlug}
            />
          </div>
        </div>

        <div className="flex justify-end space-x-3">
          <Button title="No, cancel" onClick={onClose} disabled={loading} />

          <Button
            type="submit"
            title={loading ? 'Loading...' : "Yes, I'm sure"}
            color="primary"
            disabled={loading}
            loading={loading}
          />
        </div>
      </form>
    </Dialog>
  );
};

ConfirmDefaultPageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  defaultName: PropTypes.string,
  defaultPageUrl: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmDefaultPageModal;
