import PropTypes from 'prop-types';

import AccountInfoForm from '../account-info/AccountInfoForm';

const AccountInfoStep = ({ onNext }) => {
  return (
    <div className="m-auto max-w-2xl space-y-12 pb-[60px] 2xl:max-w-5xl">
      <div className="space-y-4">
        <h1 className="text-h1 font-semibold">Account information</h1>
        <p className="text-lg">Fill out your company information.</p>
      </div>

      <AccountInfoForm onboarding onNext={onNext} />
    </div>
  );
};

AccountInfoStep.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default AccountInfoStep;
