import { Alert, Snackbar } from '@mui/material';
import PropTypes from 'prop-types';

const AlertMessage = ({ open, message, severity = 'error', onClose, autoHideDuration = 6000 }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      sx={{ '.MuiPaper-root': { padding: '6px 16px !important' } }}
    >
      <Alert onClose={onClose} severity={severity}>
        <h3 className="text-2xl font-bold capitalize">{severity}</h3>
        <p className="text-xl">{message}</p>
      </Alert>
    </Snackbar>
  );
};

AlertMessage.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  severity: PropTypes.string,
  autoHideDuration: PropTypes.any,
};

export default AlertMessage;
