import { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import axios from 'axios';

import { PLAN_BILLING_PERIODS } from '../../core/constants';
import { getNetworkError } from '../../core/utils';
import appSettings from '../../app-settings';
import { useAccount } from '../../store/account/hooks';
import Button from '../buttons/Button';
import IconButton from '../buttons/IconButton';
import SegmentControl from '../segment-control/SegmentControl';
import Loader from '../loader/Loader';
import SubscriptionPlanCard from './SubscriptionPlanCard';

const LIST_PRICING_PLANS_URL = `${appSettings.baseUrl}/accounts/subscription/plans`;
const UPDATE_SUBSCRIPTION_PLAN_URL = `${appSettings.baseUrl}/accounts/subscription/plan`;

const PLAN_BILLING_INTERVALS = {
  month: 'month',
  year: 'year',
};

const segments = [{ segment: PLAN_BILLING_PERIODS.monthly }, { segment: PLAN_BILLING_PERIODS.yearly }];

const ChangePlanModal = ({ open, onClose, onError, onSuccess }) => {
  const [loadingPlans, setLoadingPlans] = useState(false);
  const [pricingPlans, setPricingPlans] = useState([]);
  const [updatingPlan, setUpdatingPlan] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(PLAN_BILLING_PERIODS.yearly);
  const [selectedPlan, setSelectedPlan] = useState('');
  const {
    account: { subscription },
    getAccount,
  } = useAccount();

  const getSubscriptionPlans = useCallback(async () => {
    setLoadingPlans(true);
    try {
      const response = await axios.get(LIST_PRICING_PLANS_URL);
      setPricingPlans(response.data);
    } catch (err) {
      Sentry.captureException(err);
      onError(getNetworkError(err));
    }
    setLoadingPlans(false);
  }, [onError]);

  useEffect(() => {
    getSubscriptionPlans();
  }, [getSubscriptionPlans]);

  const handlePeriodChange = (period) => {
    setSelectedPlan('');
    setSelectedPeriod(period);
  };

  const resetSelectedState = () => {
    setSelectedPlan('');
    setSelectedPeriod(PLAN_BILLING_PERIODS.yearly);
  };

  const handleClose = () => {
    if (updatingPlan) return;
    resetSelectedState();
    onClose();
  };

  const handleConfirm = async () => {
    setUpdatingPlan(true);
    try {
      await axios.patch(UPDATE_SUBSCRIPTION_PLAN_URL, { pricingId: selectedPlan });
      resetSelectedState();
      onClose();
      onSuccess(
        'Subscription updated successfully. If this is not visible in your account, please check in a few minutes',
      );
      getAccount();
    } catch (err) {
      Sentry.captureException(err);
      onError(getNetworkError(err));
    }
    setUpdatingPlan(false);
  };

  const subscriptionPlans = useMemo(() => {
    let plans = [];
    if (selectedPeriod === PLAN_BILLING_PERIODS.yearly) {
      plans = pricingPlans.filter((plan) => plan.interval === 'year');
    } else {
      plans = pricingPlans.filter((plan) => plan.interval === 'month');
    }
    return plans.sort((a, b) => a.price - b.price);
  }, [pricingPlans, selectedPeriod]);

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
      sx={{ '.MuiPaper-root': { padding: 0, margin: 0, borderRadius: '12px', width: '100%' } }}
    >
      <div className="space-y-6 p-6">
        <div className="relative flex">
          <h3 className="text-h6 font-semibold">Change Plan</h3>

          <IconButton
            Icon={<IconX size={20} className="text-gray-400" />}
            srOnly="Close"
            onClick={handleClose}
            color="transparent"
            className="absolute -right-1 -top-1 !p-1"
          />
        </div>

        <div className="flex justify-center">
          <SegmentControl
            segments={segments}
            selectedSegment={selectedPeriod}
            onSelect={(segment) => handlePeriodChange(segment)}
            segmentColor="primary"
            disabled={updatingPlan}
          />
        </div>

        <div className="relative flex items-center justify-center pb-6">
          {subscriptionPlans.map((plan) => {
            const unavailable =
              (subscription?.plan.interval === PLAN_BILLING_INTERVALS.year &&
                plan.interval === PLAN_BILLING_INTERVALS.month) ||
              plan.price < subscription?.plan.amount;
            return (
              <SubscriptionPlanCard
                key={plan.id}
                name={plan.nickname.replace(/yearly plan|monthly plan/i, '')}
                price={plan.price / 100}
                interval={plan.interval}
                selected={plan.id === selectedPlan}
                onSelect={() => setSelectedPlan((prev) => (prev !== plan.id ? plan.id : ''))}
                current={plan.id === subscription?.plan.id}
                disabled={unavailable || updatingPlan}
                unavailable={unavailable}
              />
            );
          })}

          {loadingPlans && <Loader />}
        </div>

        <p className="text-base">
          By confirming, you agree that your payment method on file will be charged immediately for a prorated amount
          based on your new subscription plan and the remaining time in your subscription cycle. Your payment method
          will be charged the full subscription amount at your next automatic renewal.
        </p>

        <Button
          title="Confirm Plan Change"
          color="primary"
          onClick={handleConfirm}
          loading={updatingPlan}
          disabled={updatingPlan || !selectedPlan}
        />
      </div>
    </Dialog>
  );
};

ChangePlanModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ChangePlanModal;
