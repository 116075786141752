import PropTypes from 'prop-types';
import format from 'date-fns/format';
import isFuture from 'date-fns/isFuture';

const NameCell = ({ name, updatedAt, versions, sendAt }) => {
  let label = 'Last Edited';
  if (sendAt) {
    label = isFuture(new Date(sendAt * 1000)) ? 'Starting ' : 'Started ';
  }

  return (
    <div>
      <p className="text-base">{name}</p>
      {versions > 1 ? <p className="text-sm">{versions} Versions</p> : null}
      <p className="text-sm text-gray-600">
        {label} {format(new Date((sendAt ?? updatedAt) * 1000), "MMM d 'at' h:mma")}
      </p>
    </div>
  );
};

NameCell.propTypes = {
  name: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired, // timestamp in seconds
  versions: PropTypes.number.isRequired,
  sendAt: PropTypes.string,
};

export default NameCell;
