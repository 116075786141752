import { IconAlertCircle } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import CustomDialog from './CustomDialog';
import Button from '../buttons/Button';

const ConfirmDeleteModal = ({ data, onClose, onDelete, loading }) => {
  const handleDelete = () => {
    onDelete(data?.id);
  };

  const handleClose = () => {
    if (loading) return;
    onClose();
  };

  return (
    <CustomDialog open={!!data} onClose={handleClose} title={`Delete ${data?.entity}`} Icon={IconAlertCircle}>
      <div className="flex justify-center">
        <p className="mb-6 w-4/5 text-center text-lg font-normal text-gray-900">
          Are you sure you want to delete the &quot;{data?.name}&quot; {data?.entity}?
        </p>
      </div>

      <div className="flex w-full justify-around">
        <Button
          title={loading ? 'Loading...' : "Yes, I'm sure"}
          color="destructive"
          disabled={loading}
          onClick={handleDelete}
          loading={loading}
        />

        <Button title="No, cancel" onClick={handleClose} disabled={loading} />
      </div>
    </CustomDialog>
  );
};

ConfirmDeleteModal.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

ConfirmDeleteModal.defaultProps = {
  loading: false,
};

export default ConfirmDeleteModal;
