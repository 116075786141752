import ButtonGroup from './ButtonGroup';
import CustomAreaChart from './CustomAreaChart.jsx';

const AreaChartCard = () => {
  return (
    <div className="flex h-full flex-col space-y-5 rounded-xl bg-white-100 p-6">
      <ButtonGroup />
      <div className="flex flex-1 items-center">
        <CustomAreaChart />
      </div>
    </div>
  );
};

export default AreaChartCard;
