import { IconHelp } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import Step from './Step';
import { ONBOARDING_STEPS } from '../../core/constants';
import { useDomains } from '../../store/domains/hooks';

const steps = [
  { step: ONBOARDING_STEPS.accountInfo, title: 'Account Information', subtitle: 'Fill out your company information.' },
  {
    step: ONBOARDING_STEPS.stripeAccount,
    title: 'Connect Stripe Account',
    subtitle: 'Connect your account to process payments.',
  },
  {
    step: ONBOARDING_STEPS.donationPage,
    title: 'Set Up A Donation Page',
    subtitle: 'Create your first donation page.',
  },
  {
    step: ONBOARDING_STEPS.domainAuthentication,
    title: 'Domain Authentication',
    subtitle: 'Authenticate your domain.',
  },
  {
    step: ONBOARDING_STEPS.importContacts,
    title: 'Import Warm-up List',
    subtitle: 'Import to establish sender reputation.',
  },
];

const Sidebar = ({ currentStep }) => {
  const { authenticatedDomain } = useDomains();

  const isStepIncomplete = (step, currentStep) => {
    return (
      step === ONBOARDING_STEPS.domainAuthentication &&
      currentStep >= ONBOARDING_STEPS.importContacts &&
      !authenticatedDomain
    );
  };

  return (
    <div
      className="
        relative 
        w-[264px] 
        shrink-0 
        overflow-hidden 
        bg-primary-700 
        bg-gradient-to-b 
        from-primary-700 
        to-primary-800 
        md:w-[324px]
        lg:w-[384px]
      "
    >
      <div className="bg-sidebar absolute -top-10 left-0 h-[711px] w-full" />

      <div
        className="
        flex
          size-full 
          min-h-fit 
          flex-col 
          space-y-10
          overflow-y-auto
          px-6
          py-10
          text-white-100
          md:px-8
          lg:px-10
        "
      >
        <div className="z-[1] flex-1 space-y-8">
          <img className="h-auto w-40" src="white-logo.svg" alt="logo" />

          <h3 className="w-4/6 text-h4">Complete the setup of your account</h3>

          <div className="space-y-5">
            {steps.map((step) => (
              <Step
                key={step.title}
                {...step}
                // Onboarding starts at the account info step
                step={step.step - ONBOARDING_STEPS.accountInfo + 1}
                active={step.step === currentStep}
                completed={step.step < currentStep}
                incomplete={isStepIncomplete(step.step, currentStep)}
              />
            ))}
          </div>
        </div>

        <div className="z-[1] flex items-center justify-center">
          <div className="flex w-fit flex-row items-center space-x-2 rounded-3xl bg-primary-900 px-3 py-1.5">
            <IconHelp size={16} className="opacity-75" />
            <p className="space-x-1.5 text-sm">
              <span className="opacity-75">Need help?</span>
              <a href="mailto:help@donorspring.org" className="font-semibold">
                Contact Us
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

export default Sidebar;
