import PropTypes from 'prop-types';
import { useImports } from '../../store/imports/hooks';

import Button from '../buttons/Button';
import { IMPORT_TYPES } from '../../core/constants';

const CompleteStep = ({ onComplete, onCancel, loading }) => {
  const {
    newImport: { fileName, csvData, selectedTags, skipUpdate, importType },
    goBack,
  } = useImports();

  const getImportTypeLabel = () => {
    if (importType === IMPORT_TYPES.suppressionList) {
      return 'Suppression list';
    }
    return skipUpdate ? 'New Contacts' : 'Update Contacts';
  };

  return (
    <div className="w-full max-w-xl space-y-6 py-3">
      <div className="space-y-2">
        <h1 className="text-3xl font-medium">Review and complete your import</h1>

        <p className="text-base">
          <span className="font-bold">{(csvData.length || 0).toLocaleString()}</span> unique contacts
        </p>
      </div>

      <div className="inline-grid w-fit grid-cols-2 gap-6 pl-6" style={{ gridTemplateColumns: 'auto auto' }}>
        <p className="relative font-bold before:absolute before:-left-3 before:content-['-']">Imported From:</p>
        <p className="pl-2">{fileName}</p>
        <p className="relative font-bold before:absolute before:-left-3 before:content-['-']">Upload Type:</p>
        <p className="pl-2">{getImportTypeLabel()}</p>
        {importType === IMPORT_TYPES.contacts && (
          <>
            <p className="relative font-bold before:absolute before:-left-3 before:content-['-']">Tagged:</p>
            <p className="pl-2">{`${selectedTags.map((tag) => tag.name)}`.replace(/,/g, ', ')}</p>
          </>
        )}
      </div>

      <div className="flex items-center space-x-3">
        <Button title="Go Back" onClick={goBack} disabled={loading} />
        <Button title="Complete Import" color="primary" onClick={onComplete} loading={loading} />
        <Button title="Cancel Import" onClick={onCancel} disabled={loading} />
      </div>
    </div>
  );
};

CompleteStep.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CompleteStep;
