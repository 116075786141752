import PropTypes from 'prop-types';

const ProgressBar = ({ progress }) => {
  return (
    <div className="h-[4px] w-[80px] rounded-full bg-gray-50">
      <div className="h-[4px] rounded-full bg-primary-700" style={{ width: `${progress}%` }}></div>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default ProgressBar;
