import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

import { TRANSACTIONAL_EMAILS } from '../../core/constants';
import { useTransactional } from '../../store/transactional/hooks';
import Button from '../buttons/Button';
import Loader from '../loader/Loader';
import MergeTagBuilderModal from '../modals/MergeTagBuilderModal';
import ExpandableForm from './ExpandableForm';
import { formValidation } from './validation';

const TransactionalEmails = ({ activeEmail, setActiveEmail }) => {
  const [imageFiles, setImageFiles] = useState({});
  const [mergeTagBuilderOpen, setMergeTagBuilderOpen] = useState(false);
  const { loading, emails, edits, saveTransactionalEmails } = useTransactional();

  const handleActiveEmailChange = (email) => {
    setActiveEmail((prevState) => (prevState === email ? '' : email));
  };

  const handleSaveChanges = () => {
    try {
      formValidation.validateSync(edits);
      saveTransactionalEmails(imageFiles);
    } catch (err) {
      // Form not valid - UI already displays errors
    }
  };

  const hasBeenEdited = useMemo(() => !isEqual(emails, edits), [emails, edits]);

  return (
    <div className="relative rounded-lg bg-white-100 shadow-md">
      <div className="flex items-center justify-between px-4 py-3">
        <h2 className="text-xl dark:text-white-100">Transactional Emails</h2>

        <Button title="Merge Tags" color="primary" onClick={() => setMergeTagBuilderOpen(true)} />
      </div>

      <div className="border border-gray-50 bg-gray-10 px-4 py-2">
        <h3 className="font-bold uppercase">Donations</h3>
      </div>
      <ExpandableForm
        title="One-Time Receipt"
        isExpanded={activeEmail === TRANSACTIONAL_EMAILS.oneTimeReceipt}
        onClick={() => handleActiveEmailChange(TRANSACTIONAL_EMAILS.oneTimeReceipt)}
        keyAccessor={TRANSACTIONAL_EMAILS.oneTimeReceipt}
        setImageFiles={setImageFiles}
      />
      <ExpandableForm
        title="Donation Failed"
        isExpanded={activeEmail === TRANSACTIONAL_EMAILS.oneTimeFailed}
        onClick={() => handleActiveEmailChange(TRANSACTIONAL_EMAILS.oneTimeFailed)}
        keyAccessor={TRANSACTIONAL_EMAILS.oneTimeFailed}
        setImageFiles={setImageFiles}
      />

      <div className="border border-gray-50 bg-gray-10 px-4 py-2">
        <h3 className="font-bold uppercase">Recurring</h3>
      </div>
      <ExpandableForm
        title="Recurring Receipt"
        isExpanded={activeEmail === TRANSACTIONAL_EMAILS.recurringReceipt}
        onClick={() => handleActiveEmailChange(TRANSACTIONAL_EMAILS.recurringReceipt)}
        keyAccessor={TRANSACTIONAL_EMAILS.recurringReceipt}
        setImageFiles={setImageFiles}
      />
      <ExpandableForm
        title="Recurring Installment Receipt"
        isExpanded={activeEmail === TRANSACTIONAL_EMAILS.recurringInstallmentReceipt}
        onClick={() => handleActiveEmailChange(TRANSACTIONAL_EMAILS.recurringInstallmentReceipt)}
        keyAccessor={TRANSACTIONAL_EMAILS.recurringInstallmentReceipt}
        setImageFiles={setImageFiles}
      />
      <ExpandableForm
        title="Recurring Installment Failed"
        isExpanded={activeEmail === TRANSACTIONAL_EMAILS.recurringInstallmentFailed}
        onClick={() => handleActiveEmailChange(TRANSACTIONAL_EMAILS.recurringInstallmentFailed)}
        keyAccessor={TRANSACTIONAL_EMAILS.recurringInstallmentFailed}
        setImageFiles={setImageFiles}
      />
      <ExpandableForm
        title="Recurring Installment Cancelled"
        isExpanded={activeEmail === TRANSACTIONAL_EMAILS.recurringInstallmentCancelled}
        onClick={() => handleActiveEmailChange(TRANSACTIONAL_EMAILS.recurringInstallmentCancelled)}
        keyAccessor={TRANSACTIONAL_EMAILS.recurringInstallmentCancelled}
        setImageFiles={setImageFiles}
      />
      <div className="border border-gray-50 bg-gray-10 px-4 py-2">
        <h3 className="font-bold uppercase">Donation Page</h3>
      </div>
      <ExpandableForm
        title="Abandoned Cart"
        isExpanded={activeEmail === TRANSACTIONAL_EMAILS.abandonedCart}
        onClick={() => handleActiveEmailChange(TRANSACTIONAL_EMAILS.abandonedCart)}
        keyAccessor={TRANSACTIONAL_EMAILS.abandonedCart}
        setImageFiles={setImageFiles}
      />
      <div className="border border-gray-50 bg-gray-10 px-4 py-2">
        <h3 className="font-bold uppercase">Donations Summary</h3>
      </div>
      <ExpandableForm
        title="End of Year Summary"
        isExpanded={activeEmail === TRANSACTIONAL_EMAILS.annualDonationsReceipt}
        onClick={() => handleActiveEmailChange(TRANSACTIONAL_EMAILS.annualDonationsReceipt)}
        keyAccessor={TRANSACTIONAL_EMAILS.annualDonationsReceipt}
        setImageFiles={setImageFiles}
      />

      <div className="mt-2 bg-primary-50 p-4 pt-6">
        <Button title="Save Changes" color="primary" onClick={handleSaveChanges} disabled={!hasBeenEdited} />
      </div>

      {loading && <Loader />}

      <MergeTagBuilderModal
        open={mergeTagBuilderOpen}
        onClose={() => setMergeTagBuilderOpen(false)}
        includeAccountInfo
      />
    </div>
  );
};

TransactionalEmails.propTypes = {
  activeEmail: PropTypes.string.isRequired,
  setActiveEmail: PropTypes.func.isRequired,
};

export default TransactionalEmails;
