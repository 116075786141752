import { useState } from 'react';
import { PieChart, Pie, ResponsiveContainer, Tooltip, Cell, Sector } from 'recharts';
import PropTypes from 'prop-types';

const shadowStyle = {
  boxShadow: '0px 6px 16px 0px #00000014, 0px 2px 10px 0px #00000014, 0px 0px 0px 1px #0000000A',
};

const CustomTooltip = ({ active, payload, totalContacts }) => {
  if (!active || !payload || !payload.length) return null;

  const {
    payload: { name, count },
  } = payload[0];

  return (
    <div className="space-y-1 rounded-lg bg-white-100 px-3 py-2 shadow-md" style={shadowStyle}>
      <p className="text-sm text-gray-800">{name}</p>
      <p className="text-lg text-primary-700">
        {count} <span className="text-sm text-gray-800">({Math.round((count / totalContacts) * 100)}%)</span>
      </p>
    </div>
  );
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  totalContacts: PropTypes.number.isRequired,
};

const ActiveShape = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill }) => {
  return (
    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius - 4}
      outerRadius={outerRadius + 4}
      startAngle={startAngle + 1}
      endAngle={endAngle - 1}
      fill={fill}
    />
  );
};

ActiveShape.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  startAngle: PropTypes.number,
  endAngle: PropTypes.number,
  fill: PropTypes.string,
};

const margin = {
  top: 16,
  right: 0,
  left: 0,
  bottom: 16,
};

const allowEscapeViewBox = { x: true, y: false };

const CustomPieChart = ({ data, totalContacts }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onMouseOver = (data, index) => {
    setActiveIndex(index);
  };

  const onMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <div className="relative flex items-center justify-center">
      <div className="absolute text-center">
        <p className="text-sm text-gray-600">Total</p>
        <p className="text-sm font-semibold text-gray-950">{totalContacts}</p>
      </div>
      <ResponsiveContainer width="100%" height={154}>
        <PieChart width={154} height={154}>
          <Pie
            data={data}
            margin={margin}
            cx="50%"
            cy="50%"
            innerRadius={40}
            outerRadius={65}
            fill="#8884d8"
            dataKey="count"
            activeIndex={activeIndex}
            activeShape={ActiveShape}
            onMouseEnter={onMouseOver}
            onMouseLeave={onMouseLeave}
          >
            {data.map((entry, index) => {
              return <Cell key={`cell-${index}`} fill={entry.color} />;
            })}
          </Pie>
          <Tooltip
            content={<CustomTooltip totalContacts={totalContacts} />}
            offset={20}
            allowEscapeViewBox={allowEscapeViewBox}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

CustomPieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired, count: PropTypes.number.isRequired }))
    .isRequired,
  totalContacts: PropTypes.number.isRequired,
};

export default CustomPieChart;
