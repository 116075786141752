import { useLocation, useParams } from 'react-router-dom';

import Table from '../components/contacts/Table.jsx';
import { useContacts } from '../store/contacts/hooks.js';

function Contacts() {
  const location = useLocation();
  const { id } = useParams();

  const {
    data: { contacts },
  } = useContacts();

  const selectedContact = contacts.find((contact) => contact.id === id);

  return <Table filter={location.state} selectedContact={selectedContact} />;
}

export default Contacts;
