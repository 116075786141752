import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import Label from './Label';
import HelperText from './HelperText';

const getInputContainerSizeClassName = (size) => {
  if (size === 'xl') return 'h-[52px]';
  if (size === 'lg') return 'h-12';
  return 'h-10';
};

const getLeftPaddingClassName = (size, icon) => {
  if (!icon) {
    if (size !== 'md') return 'pl-4';
    return 'pl-3.5';
  }
  if (size !== 'md') return 'pl-[46px]';
  return 'pl-11';
};

const getRightPaddingClassName = (size, icon) => {
  if (!icon) {
    if (size !== 'md') return 'pr-4';
    return 'pr-3.5';
  }
  if (size !== 'md') return 'pr-[46px]';
  return 'pr-11';
};

const Input = forwardRef(
  (
    {
      id,
      label,
      type,
      value,
      onChange,
      containerClassName,
      labelClassName,
      inputClassName,
      inputContainerClassName,
      error,
      helperText,
      required,
      labelTooltip,
      LeftIcon,
      RightIcon,
      readOnly,
      disabled,
      size,
      placeholder,
      onBlur,
      onFocus,
      iconProps,
      ...rest
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false);

    const handleFocus = (e) => {
      setFocused(true);
      if (onFocus) onFocus(e);
    };

    const handleBlur = (e) => {
      setFocused(false);
      if (onBlur) onBlur(e);
    };

    return (
      <div className={containerClassName}>
        {!!label && size !== 'xl' && (
          <Label
            id={id}
            label={label}
            labelTooltip={labelTooltip}
            labelClassName={labelClassName}
            required={required}
          />
        )}
        <div className={inputContainerClassName}>
          <div
            className={`
            relative 
            flex 
            items-center 
            ${getInputContainerSizeClassName(size)} 
            ${disabled || readOnly ? 'cursor-not-allowed' : 'cursor-auto'}
          `}
          >
            <input
              ref={ref}
              type={type}
              id={id}
              name={id}
              className={`
                peer
                block
                size-full
                rounded-lg
                border
                bg-white-100
                p-2.5
                text-gray-950
                read-only:bg-gray-10
                hover:border
                hover:border-gray-200
                focus:ml-[-1px]
                focus:border-2
                focus:border-primary-700
                focus:outline-none
                focus:ring-2
                focus:ring-primary-300
                focus:ring-offset-1
                active:ring-0
                disabled:bg-gray-10
                ${label && placeholder ? 'placeholder:text-transparent' : ''}
                ${size === 'xl' ? 'text-lg' : 'text-base'}
                ${error ? 'border-red-500' : 'border-gray-100'}
                ${error && value ? 'border-2' : ''}
                ${getLeftPaddingClassName(size, !!LeftIcon)}
                ${getRightPaddingClassName(size, !!RightIcon)}
                ${disabled || readOnly ? 'pointer-events-none' : 'pointer-events-auto'}
                ${inputClassName}
              `}
              value={value}
              onChange={onChange}
              required={required}
              readOnly={readOnly}
              disabled={disabled}
              placeholder={!placeholder && size === 'xl' ? label : placeholder}
              {...rest}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {!!label && size === 'xl' && (
              <Label
                id={id}
                label={label}
                labelClassName={`
                absolute
                duration-300
                transform
                -translate-y-[18px]
                scale-75
                top-2
                px-1
                z-10
                origin-[0]
                bg-white-100
                peer-focus:px-1 
                peer-focus:!text-primary-700 
                peer-focus:font-semibold
                peer-placeholder-shown:scale-100 
                peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 
                peer-focus:top-2 
                peer-focus:scale-75 
                peer-focus:-translate-y-[18px] 
                peer-disabled:pointer-events-none
                peer-read-only:pointer-events-none
                peer-disabled:bg-gray-10
                peer-read-only:bg-gray-10
                ${LeftIcon && !focused && !value ? 'left-[46px]' : 'left-2.5'}
                ${value ? 'font-semibold !text-gray-950' : '!text-gray-600'}
                ${(disabled || readOnly) && value ? '!text-gray-950' : '!text-gray-400'}
                ${error && value ? '!text-red-500' : ''}
                ${labelClassName}
                
                ${
                  focused || value
                    ? `
                      before:content-['']
                      before:absolute
                      before:top-0
                      before:left-0
                      before:h-1/2
                      before:w-full
                      before:bg-white-100
                    `
                    : ''
                }
              `}
                required={required}
                requiredClassName={`
                ${focused ? '!text-primary-700' : ''} 
                ${(disabled || readOnly) && !value ? '!text-gray-400' : ''}
              `}
              />
            )}
            {LeftIcon && (
              <LeftIcon
                sx={{ fontSize: 18 }}
                className={`
                  absolute 
                  ${size === 'md' ? 'left-3.5' : 'left-4'} 
                  ${!value ? 'text-gray-600' : 'text-gray-950'} 
                  ${readOnly && value ? 'text-gray-800' : ''} 
                  peer-valid:text-gray-950 
                  peer-focus:text-gray-950 
                `}
                {...iconProps}
              />
            )}
            {RightIcon && (
              <RightIcon
                sx={{ fontSize: 18 }}
                className={`
                  absolute 
                  ${size === 'md' ? 'right-3.5' : 'right-4'} 
                  ${!value ? 'text-gray-600' : 'text-gray-950'} 
                  ${readOnly && value ? 'text-gray-800' : ''} 
                  peer-valid:text-gray-950 
                  peer-focus:text-gray-950 
                `}
                {...iconProps}
              />
            )}
          </div>
          {!!helperText && <HelperText message={helperText} error={error} containerClassName="mt-1.5" />}
        </div>
      </div>
    );
  },
);

Input.displayName = 'Input';

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  inputContainerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  labelTooltip: PropTypes.string,
  LeftIcon: PropTypes.object,
  RightIcon: PropTypes.object,
  size: PropTypes.oneOf(['md', 'lg', 'xl']),
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  iconProps: PropTypes.object,
};

Input.defaultProps = {
  type: 'text',
  containerClassName: '',
  labelClassName: '',
  inputClassName: '',
  inputContainerClassName: '',
  error: false,
  disabled: false,
  readOnly: false,
  required: false,
  size: 'md',
  iconProps: {},
};

export default Input;
