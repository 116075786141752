import { useNavigate } from 'react-router-dom';
import { IconChevronRight } from '@tabler/icons-react';
import { format } from 'date-fns';
import { Button, Loader } from 'ui';

import { RECOMMENDED_TASK_TYPES } from '../../core/constants';
import { useCampaigns } from '../../store/campaigns/hooks';
import { useRecommendedTasks } from '../../store/recommended-tasks/hooks';
import { useEffect } from 'react';

const RecommendedTasksWidget = () => {
  const navigate = useNavigate();
  const { resetCampaignDetails, resetCampaignTemplate, updateCampaignDetails } = useCampaigns();
  const { loading, pendingTasks, markRecommendedTasksAsSeen, getPendingRecommendedTasks } = useRecommendedTasks();

  useEffect(() => {
    getPendingRecommendedTasks();
  }, [getPendingRecommendedTasks]);

  useEffect(() => {
    if (pendingTasks.length > 0) {
      markRecommendedTasksAsSeen(pendingTasks.map((task) => task.taskId));
    }
  }, [pendingTasks, markRecommendedTasksAsSeen]);

  const handleItemClick = (task) => {
    if (task.type === RECOMMENDED_TASK_TYPES.weeklyCampaignScheduling) {
      resetCampaignDetails();
      resetCampaignTemplate();
      const sendTime = new Date(task.expirationTimestamp);
      sendTime.setHours(new Date().getHours() + 1);
      updateCampaignDetails({ sendTime: sendTime.toISOString() });
      navigate('/new-campaign');
    } else {
      navigate(`/suggested-drafts/${task.suggestedCampaignId}`);
    }
  };

  const hasPendingTasks = pendingTasks.length > 0;

  return (
    <div className="relative flex flex-col space-y-4 rounded-xl bg-white-100 py-5">
      <h3 className="px-6 text-lg font-semibold">Recommended Tasks</h3>

      <div className="relative flex flex-1">
        {hasPendingTasks && (
          <ul className="w-full">
            {pendingTasks.map((task) => (
              <li
                key={task.taskId}
                className={`relative 
                  border-b 
                  border-gray-50 
                  pl-6 
                  pr-4 
                  ${
                    task.seen
                      ? ''
                      : `
                    bg-primary-50 
                    before:absolute 
                    before:left-[9px] 
                    before:top-[calc(50%-3px)] 
                    before:size-[6px] 
                    before:rounded-md 
                    before:bg-primary-500 
                    before:content-['']
                  `
                  }
                `}
              >
                <div role="button" tabIndex="0" className="cursor-pointer py-4" onClick={() => handleItemClick(task)}>
                  <p className="text-base">{task.description}</p>
                  <p className="text-base text-gray-600">
                    Created&nbsp;
                    {format(task.createdAt * 1000, "d MMM 'at' h:mm a")}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        )}

        {loading && <Loader />}

        {!loading && !hasPendingTasks && (
          <div className="flex flex-1 items-center justify-center">
            <p className="text-base text-gray-600">No pending tasks. Nice work!</p>
          </div>
        )}
      </div>

      <div className="flex items-center justify-center">
        <Button
          title="View all"
          color="primary"
          variant="outlined"
          RightIcon={IconChevronRight}
          iconProps={{ size: 16 }}
          onClick={() => navigate('/recommended-tasks')}
          className="border-0 !px-3  !py-2 !shadow-none	focus:!ring-0 focus:ring-offset-0"
        />
      </div>
    </div>
  );
};

export default RecommendedTasksWidget;
