import { useState, useEffect } from 'react';
import { ChromePicker } from 'react-color';
import PropTypes from 'prop-types';

import { noop } from '../../core/utils';
import Button from '../buttons/Button';

const ColorInput = ({
  label,
  value,
  placeholder,
  onChange,
  onCancel,
  error,
  disabled,
  containerClassName,
  labelClassName,
  inputContainerClassName,
  required,
}) => {
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [hexColor, setHexColor] = useState(value || placeholder);

  useEffect(() => {
    setColorPickerOpen(disabled === false);
    if (disabled) setHexColor(value || placeholder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useEffect(() => {
    setHexColor(value);
  }, [value]);

  const handleConfirm = () => {
    onChange(hexColor);
    setColorPickerOpen(false);
  };

  return (
    <div className={containerClassName}>
      <p
        className={`
          mb-2
          block
          text-base
          font-medium
          text-gray-900
          ${labelClassName}
        `}
      >
        {label} {required && <span className="z-[1] ml-1 text-base text-red-500">*</span>}
      </p>
      <div className={`relative w-full ${inputContainerClassName}`}>
        <div
          className={`
            flex
            h-10
            w-1/4
            min-w-[127px]
            items-center
            rounded-lg
            border
            border-gray-100
            p-2.5
            text-base
            text-gray-900
            ${
              colorPickerOpen
                ? 'ml-[-1px] border-2 border-primary-700 outline-none ring-2 ring-primary-300 ring-offset-1'
                : ''
            }
            ${disabled ? 'cursor-not-allowed bg-gray-10' : 'cursor-auto bg-white-100'}
            ${error ? 'border-red-500' : ''}
          `}
          onClick={disabled ? noop : () => setColorPickerOpen(true)}
        >
          <div className="mr-4 size-7 rounded-3xl border border-gray-100" style={{ backgroundColor: hexColor }} />
          <p>{hexColor}</p>
        </div>
        {!!error && <p className="mt-1 text-sm text-red-500">{error}</p>}
        {colorPickerOpen && (
          <div className="absolute -top-10 z-50 rounded-lg bg-white-100 p-2 shadow-lg">
            <ChromePicker color={hexColor} onChangeComplete={(color) => setHexColor(color.hex)} disableAlpha />
            <div className="flex items-center justify-between">
              <Button
                title="Cancel"
                onClick={() => {
                  setColorPickerOpen(false);
                  onCancel();
                }}
                size="sm"
              />
              <Button title="Confirm" onClick={handleConfirm} size="sm" color="primary" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ColorInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  inputContainerClassName: PropTypes.string,
  required: PropTypes.bool,
};

ColorInput.defaultProps = {
  onCancel: noop,
  placeholder: '',
  error: undefined,
  disabled: undefined,
  containerClassName: '',
  labelClassName: '',
  inputContainerClassName: '',
  required: false,
};

export default ColorInput;
