import PropTypes from 'prop-types';

import SelectInput from '../inputs/SelectInput';
import Pagination from '../pagination/Pagination';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../core/constants';

const CustomPagination = ({ table, total }) => {
  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center space-x-4">
        <SelectInput
          value={table.getState().pagination.pageSize}
          onChange={(e) => table.setPageSize(Number(e.target.value))}
          options={TABLE_PAGE_SIZE_OPTIONS}
          containerClassName="min-w-[110px]"
        />

        <div>
          Showing{' '}
          <strong>
            {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1}-
            {Math.min(table.getState().pagination.pageSize * (table.getState().pagination.pageIndex + 1), total)}
          </strong>{' '}
          of <strong>{total}</strong>
        </div>
      </div>

      <Pagination
        count={table.getPageCount()}
        page={table.getState().pagination.pageIndex + 1}
        onChange={(page) => table.setPageIndex(page - 1)}
      />
    </div>
  );
};

CustomPagination.propTypes = {
  table: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
};

export default CustomPagination;
