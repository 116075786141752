import { useState, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { IconArrowBackUp } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { Button } from 'ui';

import { useCampaigns } from '../../../store/campaigns/hooks';
import Input from '../../inputs/Input';
import { DEBOUNCE_DELAY_TIME_MS } from '../../../core/constants';

const NameForm = (props) => {
  const { onRestoreDraft } = props;
  const { campaignDetails, updateCampaignDetails } = useCampaigns();
  const [name, setName] = useState(campaignDetails.name);

  const debouncedUpdateCampaignDetails = useMemo(
    () => debounce(updateCampaignDetails, DEBOUNCE_DELAY_TIME_MS),
    [updateCampaignDetails],
  );

  const handleChange = (event) => {
    const value = event.target.value;
    setName(value);
    debouncedUpdateCampaignDetails({ name: value });
  };

  return (
    <div className="space-y-6 p-8">
      <div className="flex items-center justify-between">
        <h3 className="text-h4 font-semibold">Campaign Name</h3>

        {!!campaignDetails.suggestedDraftId && (
          <Button
            title="Restore Suggested Draft"
            size="sm"
            color="destructive"
            variant="outlined"
            LeftIcon={IconArrowBackUp}
            onClick={onRestoreDraft}
          />
        )}
      </div>

      <Input
        label="Enter Campaign Name"
        value={name}
        onChange={handleChange}
        placeholder="Campaign Name"
        required
        size="xl"
        error={!campaignDetails.name}
        helperText={!campaignDetails.name ? 'Campaign name is required' : ''}
      />
    </div>
  );
};

NameForm.propTypes = {
  onRestoreDraft: PropTypes.func.isRequired,
};

export default NameForm;
