import { usePagination } from '@mui/material';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import PropTypes from 'prop-types';

const commonClassName = `
  flex 
  items-center 
  justify-center
  leading-tight 
  text-base 
  text-gray-600 
  bg-white-100
  border 
  border-solid	
  border-gray-100 
  enabled:hover:bg-gray-10
  disabled:text-gray-200
  disabled:cursor-not-allowed
  min-h-[40px]
  min-w-[40px]
  p-1
`;

const selectedClassName = `
  text-primary-700 
  font-semibold 
  !bg-gray-10
`;

const Pagination = ({ count, onChange, page, containerClassName }) => {
  const { items } = usePagination({
    count,
    page,
    defaultPage: 1,
    siblingCount: 0,
    onChange: (_, selectedPage) => onChange(selectedPage),
  });

  return (
    <div className={`flex items-center justify-between ${containerClassName}`} aria-label="Table navigation">
      <ul className="inline-flex h-10 items-center -space-x-px">
        {items.map(({ page: currentPage, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = <div className={`${commonClassName}`}>...</div>;
          } else if (type === 'page') {
            children = (
              <button className={`${commonClassName} ${selected ? selectedClassName : ''}`} {...item}>
                {currentPage}
              </button>
            );
          } else if (type === 'previous') {
            children = (
              <button className={`${commonClassName} rounded-l-lg`} {...item}>
                <span className="sr-only">Start</span>
                <IconChevronLeft size={16} />
              </button>
            );
          } else if (type === 'next') {
            children = (
              <button className={`${commonClassName} rounded-r-lg`} {...item}>
                <span className="sr-only">End</span>
                <IconChevronRight size={16} />
              </button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </ul>
    </div>
  );
};

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  page: PropTypes.number,
  containerClassName: PropTypes.string,
};

Pagination.defaultProps = {
  containerClassName: '',
};

export default Pagination;
