import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

import { Button, IconButton } from 'ui';

import appSettings from '../app-settings';
import { authenticate, getTokenExpiration, getUserRole, hasValidSession, signOut } from '../core/utils';
import Spinner from '../components/loader/Spinner';
import ProtectedComponent from './ProtectedComponent';
import { useAccount } from '../store/account/hooks';
import useInterval from '../hooks/useInterval';
import { IconAlertCircleFilled } from '@tabler/icons-react';
import { Dialog } from '@mui/material';
import { IconX } from '@tabler/icons-react';

const { redirectUri, appClient } = appSettings;

const POLLING_INTERVAL_IN_MS = 300000; // 5 minutes
const SESSION_EXPIRATION_THRESHOLD_IN_S = (POLLING_INTERVAL_IN_MS * 2) / 1000;

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => hasValidSession());
  const navigate = useNavigate();
  const { setUserRole } = useAccount();
  const [isSessionExpiring, setIsSessionExpiring] = useState(false);

  const initAuthentication = useCallback(async () => {
    const isAuth = await authenticate(redirectUri, appClient, navigate);
    setIsAuthenticated(isAuth);

    if (!isAuth) return;

    const role = getUserRole();
    setUserRole(role);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated) return;

    initAuthentication();
  }, [isAuthenticated, initAuthentication]);

  useInterval(
    () => {
      const exp = getTokenExpiration();
      const now = new Date().getTime() / 1000;
      const diff = exp - now;
      const isExpired = diff < 0;

      if (isExpired) {
        sessionStorage.clear();
        Sentry.setUser(null);
        initAuthentication();
        setIsAuthenticated(false);
      }

      const isExpiring = diff < SESSION_EXPIRATION_THRESHOLD_IN_S;
      if (isExpiring) {
        setIsSessionExpiring(true);
      }
    },
    isAuthenticated ? POLLING_INTERVAL_IN_MS : null,
  );

  if (!isAuthenticated) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center space-y-8">
        <h2 className="text-h2 font-semibold">Checking login status...</h2>
        <Spinner className="size-[34px]" />
      </div>
    );
  }

  return (
    <>
      {children ? <ProtectedComponent>{children}</ProtectedComponent> : <ProtectedComponent />}
      <Dialog
        maxWidth="xs"
        open={isSessionExpiring}
        onClose={() => setIsSessionExpiring(false)}
        sx={{ '.MuiPaper-root': { padding: 0, margin: 0, borderRadius: '12px', width: '100%' } }}
      >
        <div noValidate className="space-y-6 p-6">
          <div className="relative flex">
            <div className="flex items-center space-x-3">
              <IconAlertCircleFilled size={20} className="text-primary-700" />
              <h3 className="text-h6 font-semibold">Session Expiring</h3>
            </div>

            <IconButton
              Icon={<IconX size={20} className="text-gray-400" />}
              srOnly="Close"
              onClick={() => setIsSessionExpiring(false)}
              color="transparent"
              className="absolute -right-1 -top-1 !p-1"
            />
          </div>

          <p className="text-base">
            Your session will expire in a few minutes and you will be automatically signed out. Please save any pending
            work.
          </p>

          <div className="flex justify-end space-x-3">
            <Button title="Sign Out Now" color="destructive" variant="outlined" onClick={() => signOut()} />
            <Button title="Dismiss" color="primary" onClick={() => setIsSessionExpiring(false)} />
          </div>
        </div>
      </Dialog>
    </>
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default ProtectedRoute;
