import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { getNetworkError, replaceItemAtIndex } from 'common/utils';

import appSettings from '../../app-settings';
import {
  deleteContactTag as deleteContactTagApi,
  archiveContacts as archiveContactsApi,
  unarchiveContacts as unarchiveContactsApi,
} from '../../api';

const CONTACTS_URL = `${appSettings.baseUrl}/contacts`;

const SET_ERROR = 'contacts/SET_ERROR';
const SET_SUCCESS = 'contacts/SET_SUCCESS';
const GET_CONTACT_FIELDS = 'contacts/GET_CONTACT_FIELDS';
const GET_CONTACTS = 'contacts/GET_CONTACTS';
const DELETE_CONTACTS = 'contacts/DELETE_CONTACTS';
const CREATE_CONTACT = 'contacts/CREATE_CONTACT';
const UPDATE_CONTACT = 'contacts/UPDATE_CONTACT';
const UNSUBSCRIBE_CONTACT = 'contacts/UNSUBSCRIBE_CONTACT';
const UPDATE_CONTACT_LIST = 'contacts/UPDATE_CONTACT_LIST';
const ARCHIVE_CONTACTS = 'contacts/ARCHIVE_CONTACTS';
const UNARCHIVE_CONTACTS = 'contacts/UNARCHIVE_CONTACTS';

export const setError = createAction(SET_ERROR);

export const setSuccess = createAction(SET_SUCCESS);

export const updateContactsList = createAction(UPDATE_CONTACT_LIST);

export const getContactFields = createAsyncThunk(GET_CONTACT_FIELDS, async () => {
  try {
    const res = await axios.get(`${CONTACTS_URL}/fields`);
    return res.data;
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const getContacts = createAsyncThunk(
  GET_CONTACTS,
  async ({
    pageIndex,
    pageSize,
    sortBy,
    sortDirection,
    filter,
    searchTerm,
    segmentIds,
    tagIds,
    contactType,
    unsubscribed,
    match,
    archived,
  }) => {
    try {
      let contactsQueryUrl = CONTACTS_URL;
      let countQueryUrl = `${CONTACTS_URL}/count`;

      const contactsQueryParams = new URLSearchParams({
        page: `${pageIndex + 1}`,
        limit: `${pageSize}`,
        sort_by: sortBy,
        sort_direction: sortDirection,
      });

      const countQueryParams = new URLSearchParams();

      if (archived) {
        contactsQueryParams.append('archived', 'true');
        countQueryParams.append('archived', 'true');
      } else {
        if (filter?.type === 'file') {
          // Remove the .csv extension from the import filename so backend
          // knows it's a request from the tenant's dashboard.
          const importFilename = filter.id.replace('.csv', '');
          contactsQueryParams.append('import_filename', importFilename);
          countQueryParams.append('import_filename', importFilename);
        }

        if (searchTerm) {
          contactsQueryParams.append('search_term', searchTerm);
          countQueryParams.append('search_term', searchTerm);
        }

        if (segmentIds?.length > 0) {
          contactsQueryParams.append('segment_ids', segmentIds.join(','));
          countQueryParams.append('segment_ids', segmentIds.join(','));
        }

        if (tagIds?.length > 0) {
          contactsQueryParams.append('list_ids', tagIds.join(','));
          countQueryParams.append('list_ids', tagIds.join(','));
        }

        if (contactType) {
          contactsQueryParams.append('contact_type', contactType);
          countQueryParams.append('contact_type', contactType);
        }

        if (unsubscribed) {
          contactsQueryParams.append('unsubscribed', unsubscribed);
          countQueryParams.append('unsubscribed', unsubscribed);
        }

        if (match && (segmentIds?.length > 0 || tagIds?.length > 0 || contactType || unsubscribed)) {
          contactsQueryParams.append('match', match);
          countQueryParams.append('match', match);
        }
      }

      contactsQueryUrl += `?${contactsQueryParams.toString()}`;
      if (countQueryParams.size > 0) {
        countQueryUrl += `?${countQueryParams.toString()}`;
      }

      const [
        { data },
        {
          data: { count: totalCount },
        },
      ] = await Promise.all([axios.get(contactsQueryUrl), axios.get(countQueryUrl)]);

      return { data, totalCount };
    } catch (err) {
      Sentry.captureException(err);
      const errorMessage = getNetworkError(err);
      throw new Error(errorMessage);
    }
  },
);

export const deleteContacts = createAsyncThunk(DELETE_CONTACTS, async (contactIds) => {
  try {
    const ids = contactIds.join(',');
    await axios.delete(`${CONTACTS_URL}?ids=${ids}`);
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const createContact = createAsyncThunk(CREATE_CONTACT, async ({ contact: contactData, tagIds }) => {
  try {
    await axios.post(CONTACTS_URL, { contacts: [contactData], tag_ids: tagIds });
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const unsubscribeContact = createAsyncThunk(UNSUBSCRIBE_CONTACT, async (email) => {
  try {
    await axios.patch(`${CONTACTS_URL}/unsubscribe`, { email });
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const updateContactInList =
  ({ contact: contactUpdate, tagIdsToAdd, tagIdsToRemove }) =>
  (dispatch, getState) => {
    const {
      contacts: {
        data: { contacts },
      },
      tags: { tags },
    } = getState();

    const contactIndex = contacts.findIndex((c) => c.id === contactUpdate.id);

    if (contactIndex < 0) return;

    const contact = { ...contacts[contactIndex], ...contactUpdate };

    let contactTags = contact.tags ?? [];

    if (tagIdsToAdd?.length > 0) {
      contactTags = [...contactTags, ...tags.filter((tag) => tagIdsToAdd.includes(tag.id))];
    }
    if (tagIdsToRemove?.length > 0) {
      contactTags = contactTags.filter((tag) => !tagIdsToRemove.includes(tag.id));
    }

    const updatedContacts = replaceItemAtIndex(contacts, contactIndex, { ...contact, tags: contactTags });

    dispatch(updateContactsList(updatedContacts));
  };

export const updateContact = createAsyncThunk(
  UPDATE_CONTACT,
  async ({ contact: { id, ...contact }, tagIdsToAdd, tagIdsToRemove }, { dispatch }) => {
    try {
      await axios.patch(`${CONTACTS_URL}/${id}`, { contact, tag_ids: tagIdsToAdd });

      if (tagIdsToRemove?.length > 0) {
        await Promise.all(tagIdsToRemove.map((tagId) => deleteContactTagApi(tagId, id)));
      }

      dispatch(updateContactInList({ contact: { ...contact, id }, tagIdsToAdd, tagIdsToRemove }));
    } catch (err) {
      Sentry.captureException(err);
      const errorMessage = getNetworkError(err);
      throw new Error(errorMessage);
    }
  },
);

export const archiveContacts = createAsyncThunk(ARCHIVE_CONTACTS, async (contactIds) => {
  try {
    await archiveContactsApi(contactIds);
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});

export const unarchiveContacts = createAsyncThunk(UNARCHIVE_CONTACTS, async (contactIds) => {
  try {
    await unarchiveContactsApi(contactIds);
  } catch (err) {
    Sentry.captureException(err);
    const errorMessage = getNetworkError(err);
    throw new Error(errorMessage);
  }
});
