import { useSelector, shallowEqual } from 'react-redux';

import useActionCreator from '../../hooks/useActionCreator';
import { resetTemplate, updateTemplate } from './actions';

export const useEmailTemplates = () => {
  const emailTemplatesState = useSelector((state) => state.emailTemplates, shallowEqual);

  return {
    ...emailTemplatesState,
    updateTemplate: useActionCreator(updateTemplate),
    resetTemplate: useActionCreator(resetTemplate),
  };
};
