import EmailToolSettings from '../components/email-settings/EmailToolSettings';
import AlertMessage from '../components/alerts/AlertMessage';
import { useAccount } from '../store/account/hooks';

const EmailSettings = () => {
  const { error, success, setError: setAccountError, setSuccess: setAccountSuccess } = useAccount();

  return (
    <div className="w-full flex-auto">
      <div className="mb-6 flex">
        <h1 className="text-h3 dark:text-white-100">Email Settings</h1>
      </div>

      <EmailToolSettings />

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => {
          setAccountSuccess(false);
        }}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => {
          setAccountError(false);
        }}
        severity="error"
      />
    </div>
  );
};

export default EmailSettings;
