import PropTypes from 'prop-types';
import { IMPORT_TYPES } from '../../core/constants';

const ImportType = ({ importType }) => {
  if (importType === IMPORT_TYPES.suppressionList) {
    return (
      <div className="w-fit rounded border border-orange-200 bg-orange-50 px-1.5 py-0.5">
        <p className="text-caption1 font-semibold uppercase text-orange-700">Suppression List</p>
      </div>
    );
  }

  return (
    <div className="w-fit rounded border border-green-100 bg-green-50 px-1.5 py-0.5">
      <p className="text-caption1 font-semibold uppercase text-green-500">Contacts</p>
    </div>
  );
};

ImportType.propTypes = {
  importType: PropTypes.string.isRequired,
};

export default ImportType;
